import React, { useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Box,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  useMediaQuery,
} from "@material-ui/core";
import VerticalMenu from "../components/VerticalMenu";
import utils from "../helpers/utils";
import { Redirect } from "react-router";
import { connect } from "react-redux";
import axios from "axios";
import AddPaymentMethodForm from "../components/AddPaymentMethodForm";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "calc(100vh - 110px)",
    paddingTop: theme.spacing(30),
  },
  contentSection: {
    boxShadow: theme.section.boxShadow,
    borderRadius: theme.section.borderRadius,
    backgroundColor: "#fff",
    padding: theme.spacing(4),
  },
  label: {
    marginBottom: theme.spacing(1),
    display: "block",
  },
  value: {
    display: "block",
    fontWeight: 600,
    color: theme.paragraphDark.color,
  },
  infoWrapper: {
    marginTop: theme.spacing(3),
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  divider: {
    height: "4px",
    marginTop: theme.spacing(1),
    width: theme.spacing(5),
    backgroundColor: theme.palette.primary.main,
  },
  sectionHeading: {
    fontWeight: 600,
  },
  paymentOptionsWrapper: {
    marginTop: theme.spacing(5),
  },
  cardName: {
    fontSize: "1.5rem",
  },
  cardDetailsWrapper: {
    fontSize: "0.8rem",
  },
  inputGroupTopMargin: {
    marginTop: theme.spacing(5),
  },
}));

const OneTimePayment = (props) => {
  document.body.style.backgroundColor = "#F7F7F7";

  const classes = useStyles();

  const theme = useTheme();

  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const xs = useMediaQuery(theme.breakpoints.down("xs"));

  const [option, setOption] = useState("existing");
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [formData, setFormData] = useState({
    paymentMethodId: "",
  });

  const baseUrl = process.env.REACT_APP_SERVER_URL;

  useEffect(() => {
    if (!paymentMethods.length) {
      axios({
        url: `${baseUrl}/payment/methods`,
      })
        .then((response) => {
          // if (response.data.paymentMethods.length) {
          //   setFormData({
          //     ...formData,
          //     paymentMethodId: response.data.paymentMethods[0].id,
          //   });
          // }

          setPaymentMethods(response.data.paymentMethods);
        })
        .catch((err) => {});
    }
  });

  const { user } = props;
  const role = utils.getRole(user);

  const handlePaymentMethodId = (paymentMethodId) => {
    setFormData({ ...formData, paymentMethodId: paymentMethodId });
  };

  if (role === "doctor" && !user.isApplicationAccepted) {
    return <Redirect to="/application-status" />;
  }

  return (
    <Container maxWidth="lg" className={classes.root}>
      <Grid container direction="row" className={classes.gridWrapper}>
        {!sm && !xs ? (
          <Grid item xl={3} lg={3} md={3}>
            <VerticalMenu active="account" />
          </Grid>
        ) : null}

        <Grid item xl={9} lg={9} md={9} sm={12}>
          <Box component="div" className={classes.contentSection}>
            <Typography className={classes.sectionHeading} variant="h6">
              One Time Payment
            </Typography>
            <div className={classes.divider} />

            <div className={classes.paymentOptionsWrapper}>
              <div className={classes.inputGroup}>
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="option"
                    name="option"
                    value={option}
                    onChange={(e) => setOption(e.target.value)}
                  >
                    <FormControlLabel
                      value="existing"
                      control={
                        <Radio
                          color={option === "existing" ? "primary" : "default"}
                        />
                      }
                      label="Choose existing payment method"
                    />
                    <FormControlLabel
                      value="new"
                      control={
                        <Radio
                          color={option === "new" ? "primary" : "default"}
                        />
                      }
                      label="Add new payment method"
                    />
                  </RadioGroup>
                </FormControl>
              </div>

              {option === "existing" ? (
                <div
                  className={`${classes.inputGroup} ${classes.inputGroupTopMargin}`}
                >
                  <label className={classes.label}>
                    Select an Existing Card
                  </label>
                  <FormControl
                    variant="filled"
                    fullWidth
                    className={classes.formControl}
                  >
                    <Select
                      labelId="payment-method-select-filled-label"
                      id="payment-method-select-filled"
                      value={formData.paymentMethodId}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          [e.target.name]: e.target.value,
                        })
                      }
                      name="paymentMethodId"
                    >
                      {paymentMethods &&
                        paymentMethods.map((item) => (
                          <MenuItem key={`states-${item.id}`} value={item.id}>
                            <div className={classes.cardItem}>
                              <span className={classes.cardName}>
                                {item.cardBrand}
                              </span>
                              <div className={classes.cardDetailsWrapper}>
                                <span className={classes.expiry}>
                                  Ex: {`${item.expMonth}/${item.expYear}`}
                                </span>
                                <span
                                  className={classes.card}
                                >{`**** **** **** ${item.last4}`}</span>
                              </div>
                            </div>
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </div>
              ) : null}

              {option === "new" ? (
                <AddPaymentMethodForm paymentMethodId={handlePaymentMethodId} />
              ) : null}
            </div>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    isProcessing: state.auth0.isProcessing,
    user: state.auth0.user,
  };
};

export default connect(mapStateToProps)(OneTimePayment);
