export const SIGNUP_IN_PROGRESS = "SIGNUP_IN_PROGRESS";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_ERROR = "SIGNUP_ERROR";

export const GET_PRODUCT_SUCCESS = "GET_PRODUCT_SUCCESS";
export const GET_PRODUCT_ERROR = "GET_PRODUCT_ERROR";

export const PAYMENT_IN_PROGRESS = "PAYMENT_IN_PROGRESS";
export const PAYMENT_SUCCESS = "PAYMENT_SUCCESS";
export const PAYMENT_ERROR = "PAYMENT_ERROR";

export const UPDATE_ACCOUNT_INFO_IN_PROGRESS =
  "UPDATE_ACCOUNT_INFO_IN_PROGRESS";
export const UPDATE_ACCOUNT_INFO_SUCCESS = "UPDATE_ACCOUNT_INFO_SUCCESS";
export const UPDATE_ACCOUNT_INFO_ERROR = "UPDATE_ACCOUNT_INFO_ERROR";

export const UPDATE_AUTH_STATUS_SUCCESS = "UPDATE_AUTH_STATUS_SUCCESS";

export const DOCTOR_APPLY_IN_PROGRESS = "DOCTOR_APPLY_IN_PROGRESS";
export const DOCTOR_APPLY_SUCCESS = "DOCTOR_APPLY_SUCCESS";
export const DOCTOR_APPLY_ERROR = "DOCTOR_APPLY_ERROR";

export const LOGIN_IN_PROGRESS = "LOGIN_IN_PROGRESS";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";

export const FORGOT_PASSWORD_IN_PROGRESS = "FORGOT_PASSWORD_IN_PROGRESS";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_ERROR = "FORGOT_PASSWORD_ERROR";

export const RESET_PASSWORD_IN_PROGRESS = "RESET_PASSWORD_IN_PROGRESS";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";

export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS";

export const STEP_TWO_COMPLETE = "STEP_TWO_COMPLETE";

export const UPDATE_PROCESSING_STATUS = "UPDATE_PROCESSING_STATUS";
export const DISMISS_STATUS_MESSAGE = "DISMISS_STATUS_MESSAGE";
