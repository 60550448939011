import React, { PureComponent } from "react";
import axios from "axios";

import Checkbox from "@material-ui/core/Checkbox";
import InputLabel from "@material-ui/core/InputLabel";
import Grid from "@material-ui/core/Grid";
import Input from "@material-ui/core/Input";
import Chip from "@material-ui/core/Chip";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";

class DocParamControls extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedState: "",
      selectedIdeologies: [],
      selectedServices: [],
      selectedLastName: "",
      availableStates: [],
      availableIdeologies: [],
      availableServices: [],
    };
    this.onParamsUpdate = this.props.onParamsUpdate;
    this.baseUrl = process.env.REACT_APP_SERVER_URL;
    this.hydrateRefs();
  }

  async hydrateRefs() {
    const refs = await axios.get(`${this.baseUrl}/refs`);
    const availableStates = refs.data.states;
    const availableIdeologies = refs.data.ideologies;
    const availableServices = refs.data.services;
    this.setState({
      availableStates,
      availableIdeologies,
      availableServices,
    });
  }

  async handleChange(event, ref) {
    await this.setState({
      [ref]: event.target.value,
    });
    this.onParamsUpdate({
      stateId: this.state.selectedState,
      lastName: this.state.selectedLastName,
      ideologies: this.state.selectedIdeologies.map((selected) => selected.id),
      services: this.state.selectedServices.map((selected) => selected.id),
    });
  }

  isChecked(selecteds, item) {
    const items = selecteds.filter((selected) => selected.id === item.id);
    return items.length > 0;
  }

  render() {
    const itemsStyle = {
      minWidth: "200px",
      margin: "10px 20px 10px 0",
    };
    return (
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        <FormControl style={itemsStyle}>
          <InputLabel htmlFor="state-select">State</InputLabel>
          <Select
            value={this.state.selectedState}
            onChange={(event) => this.handleChange(event, "selectedState")}
            inputProps={{
              name: "state",
              id: "state-select",
            }}
          >
            {this.state.availableStates.map((thestate) => (
              <MenuItem key={thestate.id} value={thestate.id}>
                {thestate.name}
              </MenuItem>
            ))}
          </Select>
          {/* <FormHelperText>Required</FormHelperText> */}
        </FormControl>

        <FormControl style={itemsStyle}>
          <InputLabel htmlFor="service-select">Services</InputLabel>
          <Select
            multiple
            value={this.state.selectedServices}
            onChange={(event) => this.handleChange(event, "selectedServices")}
            input={<Input id="select-multiple-chip" />}
            renderValue={(selecteds) => (
              <div>
                {selecteds.map((selected) => (
                  <Chip key={selected.id} label={selected.text} />
                ))}
              </div>
            )}
            inputProps={{
              text: "services",
              id: "services-select",
            }}
          >
            {this.state.availableServices.map((service) => (
              <MenuItem key={service.id} value={service}>
                <Checkbox
                  checked={this.isChecked(this.state.selectedServices, service)}
                />
                <ListItemText primary={service.text} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl style={itemsStyle}>
          <InputLabel htmlFor="ideologies-select">Badges</InputLabel>
          <Select
            multiple
            value={this.state.selectedIdeologies}
            onChange={(event) => this.handleChange(event, "selectedIdeologies")}
            input={<Input id="select-multiple-chip" />}
            renderValue={(selecteds) => (
              <div>
                {selecteds.map((selected) => (
                  <Chip key={selected.id} label={selected.text} />
                ))}
              </div>
            )}
            inputProps={{
              text: "ideologies",
              id: "ideologies-select",
            }}
          >
            {this.state.availableIdeologies.map((ideology) => (
              <MenuItem key={ideology.id} value={ideology}>
                <Checkbox
                  checked={this.isChecked(
                    this.state.selectedIdeologies,
                    ideology
                  )}
                />
                <ListItemText primary={ideology.text} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    );
  }
}

export default DocParamControls;
