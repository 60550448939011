import React, { useEffect, useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { Box, CircularProgress, Container, Grid } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBackOutlined";
import utils from "../helpers/utils";
import { connect } from "react-redux";
import { NavLink, Redirect } from "react-router-dom";
import axios from "axios";
import Alert from "../components/Alert";
import AddIcon from "@material-ui/icons/AddOutlined";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "calc(100vh - 110px)",
    paddingTop: theme.spacing(30),
  },
  contentSection: {
    boxShadow: theme.section.boxShadow,
    borderRadius: theme.section.borderRadius,
    backgroundColor: "#fff",
    padding: theme.spacing(4),
  },

  divider: {
    height: "4px",
    marginTop: theme.spacing(1),
    width: theme.spacing(5),
    backgroundColor: theme.palette.primary.main,
  },
  headingWrapper: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(5),
  },
  navLink: {
    color: "#000",
    textDecoration: "none",
    fontWeight: "bold",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  icon: {
    fontSize: "2.5rem",
    marginRight: theme.spacing(2),
  },
  table: {
    minWidth: 1000,
  },
  tableHeading: {
    fontWeight: "bold",
    letterSpacing: "1px",
  },
  iconWrapper: {
    width: theme.spacing(13),
  },

  textCenter: {
    textAlign: "center !important",
  },
  capitalize: {
    textTransform: "capitalize",
  },
  linkWrapper: {
    textAlign: "right",
  },
  textPrimary: {
    color: theme.palette.primary.main,
  },
}));

const PaymentMethods = (props) => {
  document.body.style.backgroundColor = "#F7F7F7";

  const classes = useStyles();

  const [paymentMethods, setPaymentMethods] = useState([]);
  const [status, setStatus] = useState("");
  const [statusMessage, setStatusMessage] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const baseUrl = process.env.REACT_APP_SERVER_URL;

  useEffect(() => {
    axios({
      url: `${baseUrl}/payment/methods`,
    })
      .then((response) => {
        setIsLoading(false);
        setPaymentMethods(response.data.paymentMethods);
      })
      .catch((err) => {
        setIsLoading(false);
        setStatus("error");
        setStatusMessage("Error loading payment methods");
      });
  }, [baseUrl]);

  const { authUser } = props;
  const role = utils.getRole(authUser);

  if (role === "doctor" && !authUser.isApplicationAccepted) {
    return <Redirect to="/application-status" />;
  }

  return (
    <Container maxWidth="lg" className={classes.root}>
      {status ? <Alert status={status} message={statusMessage} /> : null}

      <div className={classes.headingWrapper}>
        <NavLink className={classes.navLink} to="/account">
          <ArrowBackIcon className={classes.icon} />
        </NavLink>
        <Typography variant="h5">
          Payment Methods
          <div className={classes.divider} />
        </Typography>
      </div>

      <Grid container direction="row" className={classes.gridWrapper}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Box component="div" className={classes.contentSection}>
            <div className={classes.linkWrapper}>
              <NavLink
                className={`${classes.navLink} ${classes.textPrimary}`}
                to="/payment/methods/add"
              >
                <AddIcon /> Add Payment Method
              </NavLink>
            </div>
            <TableContainer>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tableHeading}>
                      Card Type
                    </TableCell>
                    <TableCell className={classes.tableHeading}>
                      Expiry
                    </TableCell>
                    <TableCell className={classes.tableHeading}>
                      Last 4
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isLoading ? (
                    <TableRow>
                      <TableCell colSpan={5} className={classes.textCenter}>
                        <CircularProgress size={30} />
                      </TableCell>
                    </TableRow>
                  ) : null}

                  {!isLoading && !paymentMethods.length ? (
                    <TableRow>
                      <TableCell colSpan={5} className={classes.textCenter}>
                        No record found
                      </TableCell>
                    </TableRow>
                  ) : null}

                  {paymentMethods.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell component="th" scope="row">
                        <span className={classes.capitalize}>
                          {row.cardBrand}
                        </span>
                      </TableCell>
                      <TableCell>{`${row.expMonth}/${row.expYear}`}</TableCell>
                      <TableCell>{`**** **** **** ${row.last4}`}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    isProcessing: state.auth0.isProcessing,
    isMember: state.auth0.isMember,
    authUser: state.auth0.user,
  };
};

export default connect(mapStateToProps)(PaymentMethods);
