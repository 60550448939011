import { useEffect, useState } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Loading from "../../components/Loading";
import Alert from "../../components/Alert";
import Form from "./Form";
import { NavLink, Redirect } from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBackOutlined";
import axios from "axios";
import { connect } from "react-redux";
import useHasPermission from "../../hooks/useHasPermission";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(15),

    height: "100vh",
    transition: "background-image 5s ease-in-out",
  },

  formWrapper: {
    marginTop: theme.spacing(4),
  },
  headingVerticalSpacing: {
    marginBottom: theme.spacing(3),
  },
  textBold: {
    fontWeight: 700,
  },
  description: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(4),
  },
  runningText: {
    lineHeight: "26px",
  },
  mainContentWrapper: {
    backgroundColor: "#fff",
    padding: theme.spacing(6),
    borderRadius: theme.spacing(2),
  },
  label: {
    marginBottom: theme.spacing(1),
    display: "block",
  },
  submitBtnWrapper: {
    marginTop: theme.spacing(5),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  labelWithIcon: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  noDecoration: {
    textDecoration: "none",
    color: theme.textSeagreen,
    cursor: "pointer",
  },
  smallHeading: {
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(3),
    fontWeight: "600",
  },
  formControlLabel: {
    alignItems: "flex-start !important",
    "& span": {
      fontSize: "15px !important",
    },
    "& .MuiButtonBase-root": {
      paddingTop: 0,
    },
  },

  textCenter: {
    textAlign: "center",
    marginTop: theme.spacing(4),
  },
  btnContained: {
    backgroundColor: theme.backgroundSeagreen,
    color: "#fff",
    "&:hover": {
      backgroundColor: theme.backgroundSeagreenDark,
    },
  },
  mainDescriptionWrapper: {
    display: "flex",
    alignItems: "center",
  },
}));

const EditSupportUser = (props) => {
  document.body.style.background = "#fff";
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [supportUser, setSupportUser] = useState({});

  useEffect(() => {
    const fetchSupportUser = async () => {
      // const res = await services.patients.getPatient(props.match.params.id);
      let url = `${process.env.REACT_APP_SERVER_URL}/support-user/${props.match.params.id}/show`;

      const res = await axios.get(url);
      if (res?.status === 200) {
        setSupportUser(res?.data?.supportUser);
        setIsLoading(false);
      }
      // else setHasError(true);
    };

    fetchSupportUser();
  }, [props.match.params?.id]);

  const { hasPermission } = useHasPermission({ permission: "read:admins" });
  if (!hasPermission) return <Redirect to="/account" />;
  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className={classes.root}>
      {props.status ? (
        <Alert status={props.status} message={props.message} />
      ) : null}

      <Container fixed>
        <div className={classes.mainContentWrapper}>
          <div className={classes.mainDescriptionWrapper}>
            <NavLink
              className={classes.navLink}
              to="/support-users"
              style={{ margin: "6px 5px 0 0" }}
            >
              <ArrowBackIcon className={classes.icon} />
            </NavLink>
            <Typography variant="h5" className={classes.textBold}>
              Update Support User
            </Typography>
          </div>
          <Form formType="udate" supportUser={supportUser} />
        </div>
      </Container>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isProcessing: state.auth0.isProcessing,
    status: state.auth0.status,
    message: state.auth0.message,
    isAuthenticated: state.auth0.isAuthenticated,
    user: state.auth0.user,
  };
};

export default connect(mapStateToProps)(EditSupportUser);
