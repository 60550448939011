import React from "react";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import { Typography, Button, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import logoutAction from "../pages/auth/store/actions/logoutAction";

const useStyles = makeStyles((theme) => ({
  stepLabel: {
    "& .MuiStepLabel-label": {
      fontSize: "22px !important",
    },
  },
  smallHeading: {
    fontWeight: 600,
    marginBottom: theme.spacing(2),
  },
  textPrimary: {
    fontSize: "15px",
    fontWeight: 600,
    color: theme.palette.secondary.main,
  },
  stepper: {
    "& .MuiStepIcon-active": {
      fill: theme.palette.secondary.main,
    },
    "& .MuiStepIcon-completed": {
      fill: theme.palette.secondary.main,
    },
  },
  appStoreLogoContainer: {
    textAlign: "center",
  },

  bgBluishSeagreen: {
    backgroundColor: "#32a5b6",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#2b8997",
    },
  },
  btnWrapper: {
    textAlign: "right",
    marginTop: "30px",
  },
  stepContent: {
    paddingLeft: "36px",
  },

  appStoreLogo: {
    maxWidth: "150px",
    marginRight: "5px",
  },
  appstoreIconsWraper: {
    marginTop: "32px",
  },
}));

const StatusTimeline = (props) => {
  const { user } = props;
  const classes = useStyles();
  const activeStep = user.isApplicationAccepted ? 3 : 0;

  return (
    <>
      <Stepper
        activeStep={activeStep}
        className={classes.stepper}
        orientation="vertical"
      >
        {/* {user && user.isApplicationAccepted === false && ( */}
        <Step>
          <StepLabel className={` ${classes.stepLabel}`}>
            Tova profile created
          </StepLabel>
          <StepContent>
            <Typography className={` ${classes.smallHeading}`}>
              Partner screening
            </Typography>
            <Typography className={` ${classes.textPrimary}`}>
              Usually takes 1-2 days
            </Typography>
            <Typography>
              We are processing your background information. We will update you
              when your status changes.
            </Typography>
          </StepContent>
        </Step>
        {/* )} */}

        <Step>
          <StepLabel className={classes.stepLabel}>
            Congrats! You're approved
          </StepLabel>
          <StepContent>
            <Typography>
              Your application was successfully approved. You may use
              TovaHealth.
            </Typography>
          </StepContent>
        </Step>
        <Step>
          <StepLabel className={classes.stepLabel}>
            Go online and start earning
          </StepLabel>

          <StepContent>
            <Grid container>
              <Grid item xs={12} md={10}>
                <Typography>
                  Please download the <b>Tova Doctor</b> mobile app and login to
                  start using TovaHealth.
                </Typography>
              </Grid>
              <Grid item xs={12} md={2} className={classes.btnWrapper}>
                <Typography>
                  Please download the <b>Tova Doctor</b> mobile app and login to
                  start using TovaHealth.
                </Typography>
                <Button
                  className={`${classes.bgBluishSeagreen}`}
                  variant="contained"
                  onClick={props.logout}
                >
                  Logout
                </Button>
              </Grid>
            </Grid>
            {/* <div className={classes.btnWrapper}> */}
            {/* </div> */}
          </StepContent>
          {activeStep === 3 && (
            <Grid container>
              <Grid item xs={12} md={10}>
                <Typography className={classes.stepContent}>
                  Please download the <b>Tova Doctor</b> mobile app and login to
                  start using TovaHealth.
                </Typography>
              </Grid>
              <Grid item xs={12} md={2} className={classes.btnWrapper}>
                <Button
                  className={`${classes.bgBluishSeagreen}`}
                  variant="contained"
                  onClick={props.logout}
                >
                  Logout
                </Button>
              </Grid>
            </Grid>
          )}
          {activeStep === 0 && (
            <Grid container>
              <Grid item xs={12} md={10}></Grid>
              <Grid item xs={12} md={2} className={classes.btnWrapper}>
                <Button
                  className={`${classes.bgBluishSeagreen}`}
                  variant="contained"
                  onClick={props.logout}
                >
                  Logout
                </Button>
              </Grid>
            </Grid>
          )}
        </Step>
      </Stepper>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth0.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: (url) => dispatch(logoutAction(url)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StatusTimeline);
