import { SET_STRIPE_ACCOUNT_ID } from "./actionTypes";

const setStripeAccountIdAction = (id) => {
  return async (dispatch, getState) => {
    dispatch({
      type: SET_STRIPE_ACCOUNT_ID,
      payload: {
        stripeAccountId: id,
      },
    });
  };
};

export default setStripeAccountIdAction;
