import { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Radio from "@material-ui/core/Radio";
import Loading from "../../../components/Loading";
import RadioGroup from "@material-ui/core/RadioGroup";
import Chip from "@material-ui/core/Chip";
import PrimeCareSection from "../../../components/PrimeCareSection";
import BasicCareSection from "../../../components/BasicCareSection";
import { Redirect, withRouter } from "react-router";
import { connect } from "react-redux";
import logoMark from "../../../assets/images/logo-mark.png";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(15),
  },

  headingVerticalSpacing: {
    marginBottom: theme.spacing(3),
  },
  textBold: {
    fontWeight: 700,
  },

  runningText: {
    lineHeight: "26px",
  },
  mainDescriptionWrapper: {
    marginBottom: theme.spacing(5),
  },

  label: {
    marginBottom: theme.spacing(1),
    display: "block",
  },
  submitBtnWrapper: {
    marginTop: theme.spacing(5),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  labelWithIcon: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  noDecoration: {
    textDecoration: "none",
    color: theme.palette.primary.main,
    cursor: "pointer",
  },
  smallHeading: {
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(3),
    fontWeight: "600",
  },
  formControlLabel: {
    verticalAlign: "center",
    "& .MuiTypography-root": {
      fontSize: "30px !important",
      fontWeight: 600,
      fontFamily: "Jeko",
      letterSpacing: "1px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "25px !important",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "25px !important",
        lineHeight: "30px",
      },
    },
    display: "flex",
    justifyContent: "center",
  },
  formControlLabelSecondary: {
    "& .MuiTypography-root": {
      color: theme.palette.secondary.main,
    },
  },
  formControlLabelInfo: {
    "& .MuiTypography-root": {
      color: theme.palette.info.main,
    },
  },
  formControlLabelPrimary: {
    "& .MuiTypography-root": {
      color: theme.palette.primary.main,
    },
  },
  infoRadio: {
    color: theme.palette.info.main,
  },

  subHeading: {
    fontWeight: 600,
    marginTop: theme.spacing(4),

    [theme.breakpoints.down("xs")]: {
      fontSize: "16px !important",
      marginTop: theme.spacing(2),
    },
  },
  subtitile: {
    fontSize: "15px",
  },
  textCenter: {
    textAlign: "center",
  },
  textSecondary: {
    color: theme.palette.secondary.main,
  },
  textInfo: {
    color: theme.palette.info.main,
  },
  textPrimary: {
    // color: theme.palette.primary.main,
    color: theme.palette.secondary.main,
  },
  borderPrimary: {
    // borderBottomColor: `${theme.palette.primary.main} !important`,
    border: `solid 3px ${theme.palette.secondary.main} !important`,
    // borderWidth: `3px !important`,
    // borderType: "solid !important",
    borderRadius: "10px",
  },

  borderInfo: {
    border: `solid 3px ${theme.palette.info.main} !important`,
    borderRadius: "10px",
  },

  chip: {
    fontSize: "12px",
    marginTop: theme.spacing(3),
    backgroundColor: "#FFA500",
    color: "#fff",
    fontWeight: 700,
  },
  gridWrapper: {
    //border: "solid 1px #eee",
    maxWidth: "calc(100% - 18%)",
    marginLeft: "auto",
    marginRight: "auto",
    borderRadius: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      border: 0,
    },
    [theme.breakpoints.down("xs")]: {
      maxWidth: "100%",
    },
  },
  bgLight: {
    backgroundColor: "#f7f7f7",
  },
  border: {
    border: `solid 3px transparent`,
  },
  footer: {
    marginTop: "30px",
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    "& p": {
      marginTop: 0,
      marginBottom: theme.spacing(1),
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  footerHeading: {
    fontWeight: 600,
    fontSize: "20px",
  },
  inputGroup: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down("xs")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  sectionTopMargin: {
    marginTop: theme.spacing(5),
  },
  formWrapper: {},
  logoWrapper: {
    display: "none",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  logoMark: {
    maxWidth: "100px",
  },
  sectionFooter: {
    textAlign: "center",
  },
}));

const PatientSelectPlan = (props) => {
  document.body.style.background = "#fff";

  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(true);
  const [value, setValue] = useState("prime");

  useEffect(() => {
    setIsLoading(false);
  }, []);

  if (isLoading) {
    return <Loading />;
  } else if (props.isAuthenticated) {
    return <Redirect to="/account" />;
  } else if (typeof props.match.params.patientId === "undefined") {
    if (!props.stepOneDone) {
      return <Redirect to="/individual/signup" />;
    } else if (props.stepOneDone && !props.stepTwoDone) {
      return <Redirect to="/individual/signup/account-info" />;
    }
  }

  return (
    <div className={classes.root}>
      <Container fixed>
        <div className={classes.mainContentWrapper}>
          <div className={classes.mainDescriptionWrapper}>
            <div className={classes.logoWrapper}>
              <img src={logoMark} className={classes.logoMark} alt="" />
            </div>
            <Typography
              variant="h4"
              className={`${classes.textBold} ${classes.textCenter}`}
            >
              Choose your plan
            </Typography>

            {/* <p className={`${classes.description} ${classes.textCenter}`}>
              Tova has you covered with or without insurance.
            </p> */}
            <p className={`${classes.description} ${classes.textCenter}`}>
              Video or text chat with a doctor in seconds.
            </p>
          </div>
          <div className={classes.formWrapper}>
            <div className={classes.section}>
              <RadioGroup
                aria-label="plan"
                name="plan"
                value={value}
                //onChange={handleChange}
              >
                <Grid container direction="row" className={classes.gridWrapper}>
                  <Grid
                    className={`${classes.border} ${
                      value === "prime" ? classes.borderInfo : ``
                    }`}
                    item
                    xl={6}
                    lg={6}
                    md={6}
                    sm={6}
                    xs={6}
                  >
                    <div
                      className={classes.inputGroup}
                      onClick={(e) => setValue("prime")}
                    >
                      <FormControlLabel
                        className={`${classes.formControlLabel} ${
                          value === "prime"
                            ? `${classes.formControlLabelInfo}`
                            : `${classes.formControlLabelInfo}`
                        }`}
                        value="prime"
                        control={
                          <Radio
                            className={`${
                              value === "prime" ? classes.infoRadio : ""
                            }`}
                          />
                        }
                        label="Tova Prime"
                      />
                      <Typography
                        className={`${classes.subHeading} ${
                          classes.textCenter
                        } ${
                          value === "prime"
                            ? `${classes.textPrimary}`
                            : `${classes.textInfo}`
                        }`}
                        variant="h6"
                      >
                        $39
                      </Typography>
                      <Typography
                        className={`${classes.textCenter} ${
                          classes.subtitile
                        } ${
                          // value === "prime"
                          // ? `${classes.textPrimary}`
                          //  : `${classes.textSecondary}`
                          ``
                        }`}
                        variant="subtitle1"
                      >
                        Per visit{" "}
                        <span style={{ color: "#faa503" }}>(50% off)</span>
                      </Typography>
                      <Typography
                        className={`${classes.textCenter} ${classes.textBold} ${
                          // value === "prime"
                          // ? `${classes.textPrimary}`
                          //  : `${classes.textSecondary}`
                          ``
                        }`}
                        variant="subtitle1"
                      >
                        $30 per month
                      </Typography>
                      <div className={classes.textCenter}>
                        <Chip label="BEST VALUE" className={classes.chip} />
                      </div>
                    </div>
                  </Grid>
                  <Grid
                    className={`${classes.border} ${
                      value === "basic" ? classes.borderPrimary : ``
                    }`}
                    item
                    xl={6}
                    lg={6}
                    md={6}
                    sm={6}
                    xs={6}
                  >
                    <div
                      className={classes.inputGroup}
                      onClick={(e) => setValue("basic")}
                    >
                      <FormControlLabel
                        className={`${classes.formControlLabel}`}
                        value="basic"
                        style={{ color: "#38847c" }}
                        control={
                          <Radio
                            color={`${
                              value === "basic" ? `secondary` : `secondary`
                            }`}
                          />
                        }
                        label="Tova Basic"
                      />
                      <Typography
                        className={`${classes.subHeading} ${
                          classes.textCenter
                        } ${
                          //value ===
                          //"basic"// ? `${classes.textPrimary}`
                          // : `${classes.textSecondary}`
                          ``
                        }`}
                        style={{ color: "#38847c" }}
                        variant="h6"
                      >
                        $80
                      </Typography>
                      <Typography
                        className={`${classes.textCenter} ${
                          classes.subtitile
                        } ${
                          // value === "basic"
                          //   ? `${classes.textPrimary}`
                          //   : `${classes.textSecondary}`
                          ``
                        }`}
                        variant="subtitle1"
                      >
                        Per visit
                      </Typography>
                      <Typography
                        className={`${classes.textCenter} ${classes.textBold} ${
                          // value === "basic"
                          //   ? `${classes.textPrimary}`
                          //   : `${classes.textSecondary}`
                          ``
                        }`}
                        variant="subtitle1"
                      >
                        Pay per visit
                      </Typography>
                      <Typography
                        style={{ marginTop: "30px" }}
                        className={`${classes.textCenter} ${
                          classes.subtitile
                        } ${
                          // value === "basic"
                          //   ? `${classes.textPrimary}`
                          //   : `${classes.textSecondary}`
                          ``
                        }`}
                        variant="subtitle1"
                      >
                        Start for FREE!
                      </Typography>
                    </div>
                  </Grid>
                  <Grid
                    className={classes.border}
                    item
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                  >
                    <div className={`${classes.footer} ${classes.bgLight}`}>
                      <Typography
                        className={` ${classes.footerHeading} ${classes.textCenter}`}
                        variant="h6"
                      >
                        Hundreds of insurance plans accepted
                      </Typography>
                      <p className={`${classes.textCenter}`}>
                        Copay amount set by your insurance
                      </p>
                    </div>
                  </Grid>
                </Grid>
              </RadioGroup>
            </div>

            <div className={`${classes.sectionTopMargin}`}>
              {value === "basic" ? <BasicCareSection /> : <PrimeCareSection />}
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    stepOneDone: state.auth0.stepOneDone,
    stepTwoDone: state.auth0.stepTwoDone,
    isAuthenticated: state.auth0.isAuthenticated,
  };
};

export default connect(mapStateToProps)(withRouter(PatientSelectPlan));
