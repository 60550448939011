import makeStyles from "@material-ui/core/styles/makeStyles";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";

import Footer from "../components/Footer";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100% !important",
    paddingTop: "150px",

    "& .auc-text-wrapper a": {
      color: theme.palette.primary.main,
    },

    "& li": {
      marginBottom: "20px",
    },
    "& .p9, .p1, .p16, .p20, li strong": {
      fontWeight: "bold",
      marginTop: "60px",
      marginBottom: "25px",
      fontSize: "25px",
      fontFamily: "Jeko,sans-serif",
    },
  },
  mainHeading: {
    fontSize: "65px",
    fontWeight: 700,
    lineHeight: "83px",

    [theme.breakpoints.down("md")]: {
      fontSize: "45px",
      marginTop: theme.spacing(3),
      lineHeight: "65px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "35px",
      lineHeight: "30px",
      marginTop: theme.spacing(10),
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "25px",
      lineHeight: "20px",
      marginTop: theme.spacing(3),
    },
  },
  smallHeading: {
    fontWeight: "bold",
    marginTop: "60px",
    marginBottom: "25px",
    fontSize: "25px",
  },
  extraSmallHeading: {
    fontWeight: "bold",
    marginTop: "60px",
    fontSize: "20px",
    lineHeight: "30px",
  },
}));

function Terms(props) {
  document.body.style.background = "#fff";

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Container maxWidth="md">
        <div className={`${classes.termsWrapper} auc-text-wrapper`}>
          <Typography
            align="center"
            className={classes.mainHeading}
            variant="h1"
          >
            Terms of Use
          </Typography>
          <Typography align="center">
            Date last modified:January 30, 2023
          </Typography>

          <Typography className={classes.smallHeading} variant="h3">
            What is this document?
          </Typography>
          <p className="p2">
            These Terms of Use ("Terms" or "TOU") is an agreement between you
            and Tova Medical LLC. ("Tova Medical"). It describes the rules you
            agree to follow when using our mobile applications and website(s)
            (the "Apps"), including when you ask questions and when you view or
            input content on or into the Apps.
          </p>
          <p className="p3">
            These Terms of Use apply to your use of Tova Medical as a patient or
            member. Medical Experts participating on Tova Medical are subject to
            different guidelines and terms found in&nbsp;
            <a
              href="https://www.tovamedical.com/termsmedical"
              ariaInvalid="true"
            >
              <span className="s1">Medical Expert Terms of Use</span>
            </a>
            . If you are viewing these Terms of Use for members and patients in
            error and you are a Medical Expert, please review and accept the
            Terms of Use for Medical Experts
          </p>
          <p className={classes.smallHeading}>
            <strong>Definitions</strong>
          </p>
          <p className="p2">
            To make this document shorter and clearer, we've included some
            definitions:
          </p>
          <ul className="ul1">
            <li className="li5">
              <span className="s3">
                "24/7 Doctor Visits" means Tova Medical's virtual urgent care
                service.
              </span>
            </li>
            <li className="li6">
              <span className="s3">
                "Tova Medical" or the "Apps" means&nbsp;
                <a href="https://www.tovamedical.com" ariaInvalid="true">
                  <span className="s4">https://www.TovaMedical.com</span>
                </a>
                &nbsp;and related web sites and Tova Medical's mobile
                applications (including TovaHealth, TovaHealth for Doctors, and
                any other Apps we offer).{" "}
              </span>
            </li>
            <li className="li6">
              <span className="s3">
                "Content" means text, graphics, images, and any other material
                entered, processed, contained on or accessed through the Apps,
                including Content created, modified, or submitted by Medical
                Experts.
              </span>
            </li>
            <li className="li5">
              <span className="s3">
                "Tova Medical's Premium Plan" means the paid membership plan
                offered through the Apps referred to as "Tova Medical Prime".
              </span>
            </li>
            <li className="li5">
              <span className="s3">
                "Services" means services provided through the Apps.
              </span>
            </li>
            <li className="li5">
              <span className="s3">
                "Medical Expert" means a third-party medical specialist or
                professional accepted to participate on or through the Apps.
              </span>
            </li>
            <li className="li7">
              <span className="s3">
                "Virtual Consult" means a consultation between a Medical Expert
                and a patient on the Apps including, but not limited to, 24/7
                Doctor Visits.
              </span>
            </li>
          </ul>
          <p className="p8">
            Consultation with your physician or other healthcare professional is
            always recommended before using any Tova Medical Services, whether
            featured by and through the Site Offerings or otherwise, especially
            if you suffer from any medical condition including, but not limited
            to, skin diseases or ailments, strokes, high blood pressure, heart,
            liver, kidney or thyroid disease, diabetes, anemia, depression,
            anxiety, other psychiatric conditions or have a family history of
            these or other medical conditions.
            <span className="Apple-converted-space">&nbsp; </span>Your physician
            should allow for proper follow-up visits and individualize your
            treatment plan as appropriate.
            <span className="Apple-converted-space">&nbsp; </span>If you have or
            suspect that you have a medical problem as a result of using any
            Services, promptly contact your health care provider.
            <span className="Apple-converted-space">&nbsp; </span>Never
            disregard professional medical advice and/or delay in seeking
            professional advice because of something that you have read on the
            Site or in connection with any other Site Offerings.
          </p>
          <p className="p8">
            Without limiting the foregoing, those who are taking medication or
            are under treatment for any disease, or are pregnant or lactating,
            should consult with their health care professional before using any
            Tova Medical Services.
          </p>
          <p className="p8">
            The Medical Services provided by third-party Medical Experts are not
            meant to be complete substitutes for all primary or specialized
            care.<span className="Apple-converted-space">&nbsp; </span>Users of
            Medical Services should continue to follow the recommendations of
            their primary and specialized care physicians, particularly if
            suffering from medical conditions such as high blood pressure,
            heart, liver, kidney or thyroid disease, skin diseases, recent
            stroke, diabetes, anemia, depression, anxiety or other psychiatric
            ailments, or have a family history of these or other medical
            conditions.<span className="Apple-converted-space">&nbsp; </span>
            Consult your physician or other medical professionals if an
            emergency occurs that requires immediate, in-person care.
          </p>
          <p className="p9">
            <strong>Introduction</strong>
          </p>
          <p className="p1">
            <strong>Tova Medical Services.</strong>
          </p>
          <p className="p10">
            Tova Medical connects you with doctors to help you feel good and to
            live a happier, healthier life. Tova Medical offers standard (free)
            services, paid services, as well as a paid membership option,
            available through the same Apps. Tova Medical standard services
            encompass health information and not healthcare. Tova Medical's
            Premium Plan is a membership program that provides members access to
            discounts and enhanced services.
          </p>
          <p className="p1">
            <strong>Remember:</strong>
          </p>
          <ul className="ul1">
            <li className="li5">
              <span className="s3">
                Always consult with your existing doctor or healthcare provider
                or use a Virtual Consult whenever you have a personal question
                about a medical condition or symptom.
              </span>
            </li>
            <li className="li5">
              <span className="s3">
                Never disregard professional medical advice, or delay seeking
                medical advice or treatment, because of something you read or
                learn on Tova Medical. Always consult with your existing doctor
                or healthcare provider or use a Virtual Consult whenever you
                have a personal question about a medical condition or symptom.
              </span>
            </li>
            <li className="li11">
              <span className="s3">
                <strong>
                  IMMEDIATELY CALL 911 OR YOUR LOCAL EMERGENCY SERVICE OR YOUR
                  DOCTOR IF YOU BELIEVE YOU MAY OR DO HAVE A MEDICAL EMERGENCY.
                </strong>
              </span>
            </li>
          </ul>
          <p className="p1">
            <strong>These Terms of Use are an Agreement.</strong>
          </p>
          <p className="p2">
            Generally, this agreement governs your use of the Apps. Other terms
            may apply to your use of a specific feature. If there is a conflict
            between this TOU and terms posted for a specific feature, the latter
            terms apply to your use of that feature or part.
          </p>
          <p className="p10">
            <strong>
              THESE TERMS OF USE REQUIRE THE USE OF ARBITRATION TO RESOLVE
              DISPUTES AND ALSO LIMIT THE REMEDIES AVAILABLE TO YOU IN THE EVENT
              OF A DISPUTE. BY ACCEPTING THESE TERMS, YOU ARE WAIVING THE RIGHT
              TO A TRIAL BY JURY OR TO PARTICIPATE IN ANY CLASS ACTION OR
              REPRESENTATIVE PROCEEDING. SEE&nbsp;
            </strong>
            <a
              href="https://www.tovamedical.com/terms/%23arbitrationAgreement"
              ariaInvalid="true"
            >
              <span className="s1">
                <strong>ARBITRATION AGREEMENT</strong>
              </span>
            </a>
            <strong>.</strong>
          </p>
          <p className="p1">
            <strong>We May Update this Agreement.</strong>
          </p>
          <p className="p3">
            These Terms of Use may change on a going-forward basis at any time
            upon 7 days' notice. Please check these Terms periodically for
            changes. If a change to these Terms materially modifies your rights
            or obligations, we may require that you accept the modified Terms in
            order to continue to use the Service. Material modifications are
            effective upon your acceptance of the modified Terms. Immaterial
            modifications are effective upon publication. If you do not agree to
            all of these Terms of Use, please do not use Tova Medical.
          </p>
          <p className="p9">
            <strong>What We Do and What We Don't Do (Tova Medical)</strong>
          </p>
          <p className="p1">
            <strong>
              We Support Your Health Decisions through Information.
            </strong>
          </p>
          <p className="p2">
            Tova Medical is designed to support the health decisions and choices
            that you make. Always use common sense when making health decisions,
            and consult with your doctor whenever appropriate. Tova Medical
            cannot make decisions for you, but can help you find health
            information. On Tova Medical you may be able to ask informational
            questions and find educational answers and health by Medical
            Experts. Except as may be provided via a Virtual Consult, this
            information is not medical care and no doctor-patient relationship
            is created by this use of the free services. Content and non-Virtual
            Care Services on Tova Medical are not a substitute for medical
            advice, diagnosis, treatment, or care from your physician. Except as
            may be provided via a Virtual Consult, information on Tova Medical
            is not a substitute for a formal diagnosis, physical examination, or
            prescription and should not be used to treat a medical condition. Do
            not ignore or delay obtaining professional medical advice because of
            information accessed through the Apps.
          </p>
          <p className="p10">
            Please use Tova Medical's Virtual Consults or see your doctor in
            person if you are looking for a personal medical evaluation,
            diagnosis, prescription, or treatment.
          </p>
          <p className="p1">
            <strong>
              We Provide Access to Doctors for Care (Virtual Consults).
            </strong>
          </p>
          <p className="p2">
            Via Tova Medical Virtual Consults, you can obtain care (if you are
            located in a state where such service is available). Additional
            terms apply to Virtual Consults. Tova Medical Virtual Consults may
            support doctor-patient relationships. Virtual Consults may be used
            where an ongoing doctor-patient relationship exists, or where a
            temporary doctor-patient relationship is created by the use of
            Virtual Consults.
          </p>
          <p className="p10">
            Whenever we use the words "your physician" or "your doctor" or
            "healthcare provider" or similar words on Tova Medical, including in
            these Terms of Use, we mean your personal doctor with whom you have
            of an actual, mutually acknowledged, doctor/healthcare
            provider-patient relationship. If you participate in a Virtual
            Consult on Tova Medical, you understand and agree that the Medical
            Experts on Tova Medical are not your primary care physician, and you
            agree not to use Tova Medical Virtual Consults as a substitute for
            interactions with your primary care physician or health insurance.
            (Where available, you may also use Virtual Consults with your
            existing doctor(s).
          </p>
          <p className="p1">
            <strong>
              We Provide Information without Recommendation or Endorsement.
            </strong>
          </p>
          <p className="p10">
            We do not recommend or endorse any specific Content, Services,
            tests, doctors, products, procedures, opinions, or other information
            that may be mentioned on Tova Medical. Tova Medical is designed to
            be used with common sense, and in connection with the advice of your
            doctor or healthcare provider. Reliance on Tova Medical Content or
            Services is solely at your own risk. Some Services and Content
            (including posts by Medical Experts) may not be reviewed or
            certified by Tova Medical. We cannot guarantee that the Content and
            Services will help you achieve any specific goals or results.
            Content regarding dietary supplements or other treatments or
            regimens have not been evaluated by the Food and Drug Administration
            and is not intended to diagnose, treat, cure, or prevent any
            disease.
          </p>
          <p className="p1">
            <strong>We're Not for Use in Emergencies.</strong>
          </p>
          <p className="p3">
            Never use Tova Medical or Content or Services in a potential or
            actual medical emergency.&nbsp;
            <strong>
              CALL 911 OR YOUR DOCTOR FOR ALL MEDICAL EMERGENCIES.
            </strong>
          </p>
          <p className="p9">
            <strong>Tova Medical Medical Experts</strong>
          </p>
          <p className="p1">
            <strong>Independence of Tova Medical Medical Experts.</strong>
          </p>
          <p className="p2">
            The inclusion of Medical Experts, professionals and specialists on
            Tova Medical or in any professional directory on Tova Medical does
            not imply Tova Medical's recommendation or endorsement of such
            professional nor is such information intended as a tool for
            verifying the credentials, qualifications, or abilities of any
            professional. SUCH INFORMATION IS PROVIDED ON AN "AS-IS" BASIS AND
            TOVA MEDICAL DISCLAIMS ALL WARRANTIES, EITHER EXPRESS OR IMPLIED,
            INCLUDING BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF
            MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. TOVA MEDICAL
            SHALL IN NO EVENT BE LIABLE TO YOU OR TO ANYONE FOR ANY DECISION
            MADE OR ACTION TAKEN BY ANY PARTY (INCLUDING, WITHOUT LIMITATION,
            ANY USER) IN RELIANCE ON INFORMATION ABOUT MEDICAL EXPERTS,
            PROFESSIONALS AND SPECIALISTS ON TOVA MEDICAL. The use of Tova
            Medical by any entity or individual to verify the credentials of
            professionals or specialists is prohibited.
          </p>
          <p className="p2">
            Tova Medical is not an insurance provider nor are we a prescription
            fulfillment warehouse. Tova Medical&rsquo;s role is limited to
            making certain telehealth related information available to you
            and/or facilitate your access to telemedicine, expert medical
            services, and chronic condition management services. Tova Medical is
            independent from Medical Experts who provide telemedicine services
            and is not responsible for such Medical Experts acts, omissions or
            for any content of the communications made by them. Tova Medical
            does not engage in the practice of medicine. Tova Medical does not
            itself offer the Medical Services made available by third-party
            Medical Experts via Tova Medical or the Apps.
            <span className="Apple-converted-space">&nbsp; </span>The ultimate
            terms and conditions of any Medical Services made available by and
            through third-party Medical Experts will be determined by the
            applicable Medical Expert(s).
            <span className="Apple-converted-space">&nbsp; </span>
            You understand and agree that Tova Medical shall not be liable to
            you or any third-party for any services, and/or medications offered
            by or through any third-party Medical Experts(s) as part of the
            Medical Services.
          </p>
          <p className="p2">
            The use of Tova Medical&rsquo;s website, Services, or Apps do not
            provide medical advice and do not create a healthcare
            provider/patient relationship between you and Tova Medical or
            otherwise. Any services provided by or content accessed from Tova
            Medical or its Apps are for informational purposes only and are not
            intended to replace the advice of your healthcare provider. Please
            consult your doctor or other qualified healthcare provider if you
            have any questions about a medical condition. Call 911 or Your
            doctor for all medical emergencies.
          </p>
          <p className="p12">&nbsp;</p>
          <p className="p9">
            <strong>Your Account and Your Use of Tova Medical</strong>
          </p>
          <p className="p1">
            <strong>
              You are responsible for your account. Keep your password and real
              name private.
            </strong>
          </p>
          <p className="p2">
            Accurate and complete registration information is required to use
            Tova Medical. You are solely responsible for the security of your
            passwords and for any use of your account, including any access to
            personal information in your account. If you suspect unauthorized
            use of your account, change your password immediately. Allowing any
            other person or entity to use your identity for posting on or using
            Tova Medical is not permitted. Do not include your real name or any
            other information that could be used to identify you in anything you
            post that is publicly visible on Tova Medical (such as in questions
            or other public submissions).
          </p>
          <p className="p13">
            We reserve the right to revoke or deactivate your username and
            password at any time. You may terminate your account and these Terms
            at any time by selecting to deactivate your account in the settings
            page or visiting the customer service help center on
            TovaMedical.com.
          </p>
          <p className="p14">&nbsp;</p>
          <p className="p1">
            <strong>Registration Forms</strong>
          </p>
          <p className="p10">
            In order to purchase Services and/or utilize Tova Medical, you may
            be required to submit one or more registration forms (each, a
            &ldquo;Form&rdquo;).
            <span className="Apple-converted-space">&nbsp; </span>The
            information that you must supply on a Form may include, without
            limitation: (a) your full name; (b) your mailing/billing address;
            (c) your e-mail address; (d) your telephone number; (e) your gender;
            (f) your credit card information (where purchasing Services); (g)
            answers to questions regarding your health and well-being, as asked
            via medical intake Forms; and/or (h) any other information requested
            by us on the applicable registration Form (collectively,
            &ldquo;Registration Data&rdquo;).
            <span className="Apple-converted-space">&nbsp; </span>You agree to
            provide true, accurate, current and complete responses on the Forms.
          </p>
          <p className="p1">
            <strong>You must be an adult to use Tova Medical.</strong>
          </p>
          <p className="p10">
            Tova Medical Services are available only to individuals who: (a) are
            over eighteen (18) years of age (or the applicable age of majority,
            if greater than eighteen (18) years of age in their respective
            jurisdictions of residence); and (b) can enter into legally binding
            contracts under applicable law.
            <span className="Apple-converted-space">&nbsp; </span>The Services
            are not intended for individuals who are under eighteen (18) years
            of age (or the applicable age of majority, if greater than eighteen
            (18) years of age in their respective jurisdictions of residence)
            and/or individuals who cannot enter into legally binding contracts
            under applicable law. Individuals in these two categories do not
            have permission to access or use Tova Medical or its Services.
          </p>
          <p className="p1">
            <strong>Your use must be Legal and Appropriate.</strong>
          </p>
          <p className="p2">
            Your use of Tova Medical and any Content and Services must comply
            with all applicable federal and state laws, regulations, and
            ordinances. You may not access our networks, computers, or the
            Content and Services in any manner that could damage, disable,
            overburden, or impair them, or interfere with any other person's use
            and enjoyment. You may not attempt to gain unauthorized access to
            any Content or Services, other accounts, computer systems, or
            networks connected to Tova Medical, the Content, or Services. You
            may not use any automated means (such as a scraper) to access Tova
            Medical, the Content, or Services. Unauthorized access includes
            using credentials to access Tova Medical. Any attempt by any
            individual or entity to solicit login information of any other user
            or Tova Medical Medical Expert, or to access any such account, is an
            express and direct violation of these Terms and of applicable law,
            including relevant privacy and security laws and laws prohibiting
            unfair or unethical business practices.
          </p>
          <p className="p13">
            We maintain guidelines and a code of conduct for users of Tova
            Medical. By using our Services you agree to abide by our&nbsp;
            <a
              href="https://www.tovamedical.com/terms/%23userGuidelines"
              ariaInvalid="true"
            >
              <span className="s1">Guidelines</span>
            </a>
            .
          </p>
          <p className="p15">&nbsp;</p>
          <p className="p16">
            <strong>Social Media Pages</strong>
          </p>
          <p className="p10">
            The Site contains links to the various Tova Medical Social Media
            Pages.<span className="Apple-converted-space">&nbsp; </span>The
            Social Media Pages are hosted and made available on third-party
            Social Media Websites by third-party entities.
            <span className="Apple-converted-space">&nbsp; </span>Your use of
            Social Media Pages and Social Media Websites shall be governed by
            those Social Media Websites&rsquo; applicable agreements, terms and
            conditions.<span className="Apple-converted-space">&nbsp; </span>You
            understand and agree that Tova Medical shall not be liable to you,
            any other user or any third-party for any claim in connection with
            your use of, or inability to use, the Social Media Pages and/or
            Social Media Websites.
          </p>
          <p className="p1">
            <strong>We'll send you notices and information.</strong>
          </p>
          <p className="p3">
            For more details about when and how we can communicate with you,
            please consult our&nbsp;
            <a href="https://www.tovamedical.com/privacy" ariaInvalid="true">
              <span className="s1">Privacy Statement</span>
            </a>
            .
          </p>
          <p className="p9">
            <strong>Content and Services</strong>
          </p>
          <p className="p1">
            <strong>
              Tova Medical is a Free Service That Offers Paid Services.
            </strong>
          </p>
          <p className="p2">
            It's free to sign up and create an account with Tova Medical. Tova
            Medical also provides optional Services and Content that can be
            accessed for a fee, such as Virtual Consults. Tova Medical also
            offers Tova Medical's Premium Plan, a paid membership plan that
            includes discounts and enhanced services. We will never ask you for
            your credit card or other payment information unless you indicate
            that you would like to use a paid service.
          </p>
          <p className="p14">&nbsp;</p>
          <p className="p1">
            <strong>License Grant</strong>
          </p>
          <p className="p10">
            Each user is granted a non-exclusive, non-transferable, revocable
            and limited license to access and useTova Medical or its Services.
            <span className="Apple-converted-space">&nbsp; </span>Tova Medical
            may terminate this license at any time for any reason.
            <span className="Apple-converted-space">&nbsp; </span>Unless
            otherwise expressly authorized byTova Medical, users may only use
            Tova Medical or its Services for their own personal, non-commercial
            use.
            <span className="Apple-converted-space">&nbsp; </span>No part of the
            Services may be reproduced in any form or incorporated into any
            information retrieval system, electronic or mechanical.
            <span className="Apple-converted-space">&nbsp; </span>No user or
            other third party may use any automated means or form of scraping or
            data extraction to access, query or otherwise collect material from
            Tova Medical or its Services except as expressly permitted by Tova
            Medical.<span className="Apple-converted-space">&nbsp; </span>No
            user or other third party may use, copy, emulate, clone, rent,
            lease, sell, modify, decompile, disassemble, reverse engineer or
            transfer the Services or Apps, or any portion thereof.
            <span className="Apple-converted-space">&nbsp; </span>No user or
            other third party may create any &ldquo;derivative works&rdquo; by
            altering any aspect of the Services or Apps.
            <span className="Apple-converted-space">&nbsp; </span>No user or
            other third party may use the Services in conjunction with any other
            third-party content.
            <span className="Apple-converted-space">&nbsp; </span>No user or
            other third party may exploit any aspect of the Services for any
            commercial purposes not expressly permitted by Tova Medical.
            <span className="Apple-converted-space">&nbsp; </span>Each user
            further agrees to indemnify and hold Tova Medical harmless for that
            user&rsquo;s failure to comply with the Terms of Service.
            <span className="Apple-converted-space">&nbsp; </span>Tova Medical
            reserves any rights not explicitly granted in the Agreement.
          </p>
          <p className="p1">
            <strong>Tova Medical is for personal use.</strong>
          </p>
          <p className="p10">
            Tova Medical is a service for individuals to use to support their
            personal health decisions. You may use Tova Medical for personal,
            but not for commercial purposes in accordance with all applicable
            federal, state and local laws. The Content is licensed only for the
            personal, household, and educational use of a single individual. No
            commercial use or commercial redistribution of any Content is
            permitted. Licensed U.S. doctors and other qualified medical experts
            may apply to participate on Tova Medical for educational purposes as
            Medical Experts, or to provide Virtual Consults. Companies or other
            organizations may not become registered members or use our Apps
            through individual members, but we do offer enterprise services for
            organizations.
          </p>
          <p className="p1">
            <strong>
              Tova Medical does not guarantee the accuracy of Third Party
              Content.
            </strong>
          </p>
          <p className="p10">
            Tova Medical has no editorial control over or responsibility for
            Content provided by third parties. Any opinions, statements,
            products, services, or other information expressed or made available
            by third parties (including Medical Experts) or users on Tova
            Medical are those of such third parties or users. Tova Medical does
            not have any obligation to monitor such third party Content. We make
            no representations about the accuracy or reliability of any opinion,
            statement, or other information provided by any third party, and we
            do not represent or warrant that your use of the Content displayed
            or referenced on Tova Medical will not infringe the rights of third
            parties not owned by or affiliated with Tova Medical.
          </p>
          <p className="p1">
            <strong>
              We are not responsible for anything outside of Tova Medical.
            </strong>
          </p>
          <p className="p10">
            The Apps may contain links to other apps, web sites, information,
            software, data, or other content, online or offline ("External
            Content and Services"). Such External Content and Services and
            related information are outside of our control. We do not control,
            endorse, verify the truth or accuracy of, or review content outside
            of Tova Medical, and we are not responsible for such content. We do
            not warrant, nor are we in any way responsible for, information,
            software, data, or privacy policies related or pertaining to
            External Content and Services.
          </p>
          <p className="p1">
            <strong>
              Tova Medical may communicate with you via email, SMS, text and
              mobile push notification.
            </strong>
          </p>
          <p className="p2">
            When you install our app on your mobile device, you may agree to
            receive push notifications, which are messages an app sends you on
            your mobile device when the app is not on. You can turn off
            notifications by visiting your mobile device's "settings" page.
          </p>
          <p className="p2">
            We may send you emails concerning our products and services, as well
            as those of third parties. You may opt out of promotional emails by
            following the unsubscribe instructions in the promotional email
            itself.
          </p>
          <p className="p2">
            Tova Medical and those acting on our behalf may send you text (SMS)
            messages to the phone number you provide us. These messages may
            include operational messages about your use of the Services, as well
            as marketing messages. If you opt out, you may continue to receive
            text messages for a short period while Tova Medical processes your
            request, and you may also receive text messages confirming the
            receipt of your opt-out request. Opting out of receiving operational
            text messages may impact the functionality that the Service provides
            to you. Text messages may be sent using an automatic telephone
            dialing system. Your agreement to receive text messages is not a
            condition of any purchase or use of the Service. Standard messaging,
            data and other fees may be charged by your carrier.
          </p>
          <p className="p2">
            Your carrier may prohibit or restrict certain mobile features, and
            certain mobile features may be incompatible with your carrier or
            mobile device. As applicable, instructions regarding how to opt-out
            of mobile features will be disclosed in connection with such
            features (instructions typically require you to text a keyword, such
            as "STOP," "CANCEL," "UNSUBSCRIBE," to the applicable shortcode for
            the mobile feature, or to change your profile settings inside the
            Apps).
          </p>
          <p className="p10">
            You agree to notify Tova Medical of any changes to your mobile
            number and update your account(s) on the Tova Medical Platforms. You
            also understand and agree that by receiving communications you will
            be charged by your wireless or internet provider and that such
            emails, SMS, or mobile telephone notification may be generated by
            automated systems.
          </p>
          <p className="p1">
            <strong>
              You agree that we may send you limited personal information by
              email, SMS, text, and mobile push notification.
            </strong>
          </p>
          <p className="p10">
            When you use action-oriented features on Tova Medical (such
            participating in a Virtual Consult), you agree that Tova Medical or
            Medical Experts may send you automated content via email, mobile
            telephone, or other contact information provided by you in your
            account settings. This content may contain protected health
            information under HIPAA, including content related to conditions,
            treatments, and medications. You understand and agree that by using
            these features, you are expressly opting into receiving your own
            protected health information by email, SMS/text, or mobile push
            notifications. These communications from the Apps are not encrypted.
            Although unlikely, it is possible for these communications to be
            intercepted or accessed without your authorization. By using the
            Apps, you release Tova Medical from any liability arising from or
            related to any such interception or unauthorized access.
          </p>
          <p className="p1">
            <strong>Mandatory Removal of Content and Services.</strong>
          </p>
          <p className="p13">
            If you violate any of these Terms of Use, your permission to use the
            Content and Services automatically terminates and you must
            immediately destroy any copies you have made of any portion of the
            Content.
          </p>
          <p className="p15">&nbsp;</p>
          <p className="p16">
            <strong>
              We cannot control external communications (including email, SMS,
              and notifications).
            </strong>
          </p>
          <p className="p3">
            Email, short message services (SMS), text message communications and
            mobile push notifications from the Apps are not encrypted. You can
            opt out of receiving email, SMS/text messages, and mobile push
            notifications. Although unlikely, it is possible for these
            communications to be intercepted or accessed without your
            authorization. By using the Apps, you release Tova Medical from any
            liability arising from or related to any such interception or
            unauthorized access.
          </p>
          <p className="p9">
            <strong>Advertising</strong>
          </p>
          <p className="p1">
            <strong>Strictly Limited Content.</strong>
          </p>
          <p className="p10">
            To further our mission of making reliable health information
            available to the public, we accept limited advertising and
            sponsorship under strict guidelines. Advertisers and sponsors are
            not permitted to make unsubstantiated health claims or suggest that
            Tova Medical endorses any product or service. Tova Medical does not
            endorse products or services. Advertisements may be placed on our
            website or mobile apps adjacent to content related to advertiser or
            sponsor interest, and also may be presented to users using
            non-personal data. Tova Medical does not permit the collection of
            any personally identifiable information from our site visitors on
            Tova Medical web or mobile apps. For more information see below:
          </p>
          <ul className="ul1">
            <li className="li17">
              <span className="s3">
                <strong>Advertising and Promotion Criteria</strong>
              </span>
            </li>
          </ul>
          <p className="p18">
            Tova Medical offers select advertising opportunities on our site and
            in our apps. Advertising and sponsorship revenue is used to support
            our mission of providing free access to doctor-created health
            content. Criteria are subject to change without notice. For
            information contact:&nbsp;
            <a href="mailto:Support@TovaMedical.com">
              <span className="s4">Support@TovaMedical.com</span>
            </a>{" "}
            .
          </p>
          <ul className="ul1">
            <li className="li17">
              <span className="s3">
                <strong>Permissible Advertising</strong>
              </span>
            </li>
          </ul>
          <p className="p18">
            Corporate image advertising or sponsorship, FDA-approved
            over-the-counter medications or prescription drugs (excluding
            Schedule IV drugs), health-related consumer products, fitness
            products or services, medical products or devices, pharmacies
            (retail and VIPPS-compliant online).
          </p>
          <ul className="ul1">
            <li className="li17">
              <span className="s3">
                <strong>Prohibited Advertising</strong>
              </span>
            </li>
          </ul>
          <p className="p18">
            Alcohol, tobacco products, weapons, health care services (clinics,
            hospitals, procedures, lab testing), legal services, pornography,
            gambling, political ads, social cause ads, religious ads, competitor
            ads, comparative advertising (including any mention of competitive
            brand names within an advertisement), pop-ups and floating content,
            and any ads that enable collection of personally identifiable
            information on the Tova Medical site.
          </p>
          <ul className="ul1">
            <li className="li17">
              <span className="s3">
                <strong>Limitations</strong>
              </span>
              <ul className="ul1">
                <li className="li5">
                  <span className="s3">
                    No substantiated health-related claims (supporting
                    documentation may be required)
                  </span>
                </li>
                <li className="li5">
                  <span className="s3">
                    No implication of endorsement by Tova Medical, Tova Medical
                    affiliated entities, or Medical Experts on Tova Medical
                  </span>
                </li>
                <li className="li5">
                  <span className="s3">No unsolicited commercial messages</span>
                </li>
                <li className="li5">
                  <span className="s3">
                    No collection of user personal information or contact
                    information without express and legally compliant user
                    consent and no appliets, cookies, or files may be placed
                    that transmit personally identifiable information
                  </span>
                </li>
                <li className="li5">
                  <span className="s3">
                    Final approval of all content is at the discretion of Tova
                    Medical and verification of content and placement may be
                    required before it is produced on our site or apps
                  </span>
                </li>
                <li className="li5">
                  <span className="s3">
                    Paid content must be clearly identifiable as separate and
                    distinct from Tova Medical content
                  </span>
                </li>
                <li className="li5">
                  <span className="s3">
                    Tova Medical reserves the right to determine the placement
                    of paid content, and to reject, cancel or remove at any time
                    any paid content from our site or apps for any reason
                  </span>
                </li>
                <li className="li5">
                  <span className="s3">
                    No co-branding of our health information
                  </span>
                </li>
                <li className="li5">
                  <span className="s3">
                    Tova Medical does not endorse any products or services
                  </span>
                </li>
                <li className="li5">
                  <span className="s3">
                    These guidelines are not exhaustive and are subject to
                    change at Tova Medical's sole discretion at any time
                  </span>
                </li>
              </ul>
            </li>
          </ul>
          <p className="p19">
            Any paid advertising listings posted on search results may be
            identified as "Ads by Google" and may be removed if they do not meet
            our criteria. Some listings may appear that don't meet our ad
            guidelines because of the way ads are served by Google.
          </p>
          <p className="p9">
            <strong>Property Rights</strong>
          </p>
          <p className="p1">
            <strong>
              Tova Medical owns or has rights to the Content and Services.
            </strong>
          </p>
          <p className="p10">
            When you use the Apps, you do so under a license from us. Subject to
            your complete and ongoing compliance with these Terms, Tova Medical
            grants you, solely for your personal, non-commercial use, a limited,
            non-exclusive, non-transferable, non-sublicensable, revocable
            license to: (a) install and use one object code copy of our mobile
            App obtained from a legitimate marketplace on a mobile device that
            you own or control; and (b) access and use the Content and Services.
            This means that you may not use the Apps for any commercial purpose,
            that we can take away your right to use the Apps, and that you
            cannot give this license away to someone else. All right, title, and
            interest in and to the Apps, Services, and the Content, together
            with all related intellectual property rights are the property of
            Tova Medical or our affiliates, excluding your rights under
            applicable law to any information or Content related to Virtual
            Consults. Rights retained by other parties in the Content are their
            respective rights. Tova Medical reserves all rights to the Content
            not granted expressly in these Terms.
          </p>
          <p className="p1">
            <strong>
              You agree not to infringe our Copyrights, Trademarks, Trade
              Secrets, Patents, or other intellectual property rights.
            </strong>
          </p>
          <p className="p10">
            Tova Medical and other related marks are registered trademarks of
            Tova Medical, Inc. Any other trademark, brand, or content on Tova
            Medical that is not the property of Tova Medical is the property of
            its respective owner. You agree not to violate, or encourage others
            to violate, any right of a third party, including by infringing or
            misappropriating any third party intellectual property rights. You
            may not reproduce, create derivative works of, distribute, publicly
            display the Content (or any portion of it) without our prior written
            consent. However, you may use App features to repost Content or
            portions of Content, including through other third party
            applications and mediums (such as Facebook or Twitter), so long as
            you do not modify that Content or the functionality of those
            features. This re-posting right does not create any additional
            rights in such Content. Additionally, you may not use any metatags
            or any other "hidden text" utilizing the name "Tova Medical" without
            our prior written permission.
          </p>
          <p className="p1">
            <strong>
              You agree not to access, attempt to access, or use our data
              without our permission.
            </strong>
          </p>
          <p className="p10">
            Except and solely to the extent such a restriction is impermissible
            under applicable law, you may not: (a) reproduce, distribute,
            publicly display, or publicly perform the Apps, Services, or
            Content; (b) make modifications to the Apps, Services, or Content;
            or (c) interfere with or circumvent any feature of the Apps,
            including any security or access control mechanism. If you are
            prohibited under applicable law from using the Service, you may not
            use it. You agree not to access, attempt to access, request access
            not authorized by the Apps or use any App Content or data without
            our permission. This means that you agree not to transmit, download,
            upload, post, sell, rent, license, transfer, disclose, mirror,
            frame, reverse engineer, decompile, disassemble, or use any aspect
            of the Apps or any Content, in whole or in part, in any form or by
            any means.
          </p>
          <p className="p1">
            <strong>
              Contact us if you believe materials on our Apps infringe your
              copyright.
            </strong>
          </p>
          <p className="p3">
            If you believe any materials accessible on or from Tova Medical
            infringe your valid and enforceable copyright, you may request
            removal of (or access to) those materials (or access thereto) from
            us by contacting us at Support@TovaMedical.com.
          </p>
          <p className="p9">
            <strong>Submissions</strong>
          </p>
          <p className="p1">
            <strong>You agree to abide by our Submission Guidelines.</strong>
          </p>
          <p className="p10">
            You agree that you will not upload or transmit any communications or
            content of any type that infringes or violates any rights of any
            party. The personal information you submit to Tova Medical is
            governed by the&nbsp;
            <a href="https://www.tovamedical.com/privacy/" ariaInvalid="true">
              <span className="s1">Privacy Statement</span>
            </a>
            &nbsp;(the terms of which govern in the event of any inconsistency
            with this TOU). You agree that submissions will comply with Tova
            Medical's Guidelines. If you are a user, you agree to the&nbsp;
            <a
              href="https://www.tovamedical.com/terms/%23userGuidelines"
              ariaInvalid="true"
            >
              <span className="s1">User Guidelines</span>
            </a>
            . If you are a Medical Expert, you agree to the&nbsp;
            <a
              href="https://www.tovamedical.com/termsmedical/%23expertGuidelines"
              ariaInvalid="true"
            >
              <span className="s1">Medical Expert Guidelines</span>
            </a>
            .
          </p>
          <p className="p1">
            <strong>You give us rights in what you submit.</strong>
          </p>
          <p className="p2">
            Don't submit anything to us if you don't want to give us rights to
            it. If you wish to keep any content, business information, ideas,
            concepts or inventions private or proprietary, do not submit them
            on, through, or to Tova Medical, by email or otherwise. With the
            exception of any personal data or information you submit (which
            shall be maintained in accordance with our Privacy Statement) and
            other information governed by the Health Insurance Portability and
            Accountability Act of 1996, Public Law 104-191 and the rules and
            regulations promulgated thereunder (as amended to date, "HIPAA"), or
            other applicable laws, if you make any submissions (by email or
            otherwise) on, to or through Tova Medical, including but not limited
            to media (including photographs), data, questions, comments,
            suggestions, business information, ideas, concepts or inventions
            (collectively "Submissions"), you make such submission without any
            restrictions or expectation of compensation, privacy, or
            confidentiality. You agree that your Submissions may be used by us
            without restriction for any purpose whatsoever. By making any a
            Submission, you grant Tova Medical a worldwide, non-exclusive,
            irrevocable, royalty-free, fully paid right and license (with the
            right to sublicense) to host, store, transfer, display, perform,
            reproduce, modify for the purpose of formatting for display, and
            distribute your Submissions, in whole or in part, in any media
            formats and through any media channels now known or hereafter
            developed. This means Tova Medical has the complete right to freely
            use, create derivative works from and modify, such Submissions in
            any way, commercial or otherwise (including developing and marketing
            products or features using such information), and for any purpose
            whatsoever and without limitation. Tova Medical may sublicense its
            rights.
          </p>
          <p className="p2">
            By making any a Submission, you further agree to indemnify Tova
            Medical and its affiliates, directors, officers, Medical Experts,
            and employees, and to hold them harmless from any and all claims and
            expenses, including attorneys' fees, arising from your Submissions,
            or your failure to comply with these Terms.
          </p>
          <p className="p3">
            Tova Medical does not accept unsolicited recruiter or similar
            submissions. Any candidate submissions by a recruiter or other third
            party without a valid and signed recruiting agreement in place with
            Tova Medical prior to such submission will not be subject to any
            recruiter or similar fees.
          </p>
          <p className="p9">
            <strong>Termination</strong>
          </p>
          <p className="p1">
            <strong>
              All of our members are required to honor this Agreement.
            </strong>
          </p>
          <p className="p2">
            Your permission to use the Apps, Content, and Services ends
            immediately if you violate any of the terms of this Agreement. We
            may place limits on, modify, or terminate your right to access and
            use Apps and the Services and/or Content at any time for any reason
            or no reason, with or without notice. This suspension or termination
            may delete information, files, and other previously available
            Content. We also reserve the right to modify or discontinue the
            Services at any time (including by limiting or discontinuing certain
            features of the Apps), temporarily or permanently, without notice to
            you. We will have no liability whatsoever on account of any change
            to the Services or any suspension or termination of your access to
            or use of the Services.
          </p>
          <p className="p10">
            You may terminate your account at any time by visiting the customer
            service help center on TovaMedical.com. If you terminate your
            account, you remain obligated to pay all outstanding fees, if any,
            incurred prior to termination relating to your use of the Services.
          </p>
          <p className="p1">
            <strong>Effect of Termination.</strong>
          </p>
          <p className="p3">
            Upon termination of these Terms: (a) your license rights will
            terminate and you must immediately cease all use of the Service; (b)
            you will no longer be authorized to access your account or the
            Service; (c) you must pay Tova Medical any unpaid amount that was
            due prior to termination; and (d) all payment obligations accrued
            prior to termination, this section, and sections regarding
            Submissions, ownership, indemnities, disclaimer of warranties,
            limitations on liability, and the Arbitration Agreement will
            survive.
          </p>
          <p className="p9">
            <strong>Indemnification &amp; Exclusions and Limitations</strong>
          </p>
          <p className="p1">
            <strong>Exclusion of Warranties.</strong>
          </p>
          <p className="p2">
            THE APPS AND THE CONTENT AND SERVICES ARE PROVIDED "AS IS" AND ON AN
            "AS AVAILABLE" BASIS WITHOUT WARRANTY OR CONDITION OF ANY KIND,
            EITHER EXPRESS OR IMPLIED. NEITHER WE, TOVA MEDICAL MEDICAL EXPERTS,
            NOR ANY OF OUR LICENSORS MAKE ANY EXPRESS WARRANTIES, AND WE AND
            EACH OF THEM DISCLAIMS ALL IMPLIED WARRANTIES, INCLUDING THE IMPLIED
            WARRANTIES OF ACCURACY, MERCHANTABILITY, FITNESS FOR A PARTICULAR
            PURPOSE, AND NON-INFRINGEMENT. NEITHER WE, TOVA MEDICAL MEDICAL
            EXPERTS, NOR ANY OF OUR LICENSORS MAKE ANY WARRANTY THAT CONTENT OR
            SERVICES SATISFY GOVERNMENT REGULATIONS, INCLUDING THOSE REQUIRING
            DISCLOSURE OF INFORMATION ON PRESCRIPTION DRUG PRODUCTS. TOVA
            MEDICAL AND THE CONTENT AND SERVICES WERE DEVELOPED FOR USE IN THE
            UNITED STATES, AND NEITHER WE NOR ANY OF OUR LICENSORS MAKE ANY
            REPRESENTATION CONCERNING TOVA MEDICAL AND THE CONTENT OR SERVICES
            WHEN USED IN ANY OTHER COUNTRY.
          </p>
          <p className="p2">
            NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU
            FROM THE SERVICE OR TOVA MEDICAL, OR ANY MATERIALS OR CONTENT
            AVAILABLE THROUGH THE APPS, WILL CREATE ANY WARRANTY THAT IS NOT
            EXPRESSLY STATED IN THESE TERMS.
          </p>
          <p className="p10">
            Specifically, and without limiting the foregoing, we, our licensors,
            and our suppliers, make no representations or warranties about: (i)
            the accuracy, reliability, completeness, currentness, or timeliness
            of the Content provided on or through the use of the App; or (ii)
            regulations requiring disclosure of information on prescription drug
            products or the approval or compliance of any software tools with
            Tova Medical. Any location data accessed via the Apps may be
            inaccurate or incomplete and any use of such data is at your own
            risk.
          </p>
          <p className="p1">
            <strong>
              Limitation of Liability of Tova Medical and Third Party
              Beneficiaries.
            </strong>
          </p>
          <p className="p2">
            WE ARE NOT RESPONSIBLE FOR ANY DAMAGE THAT MAY RESULT FROM THE
            SERVICE AND YOUR DEALING WITH ANY OTHER USER, INCLUDING MEDICAL
            EXPERTS. YOU UNDERSTAND AND AGREE THAT YOU USE ANY PORTION OF THE
            SERVICE AT YOUR OWN DISCRETION AND RISK, AND THAT WE ARE NOT
            RESPONSIBLE FOR ANY DAMAGE TO YOUR PROPERTY OR ANY LOSS OF DATA,
            INCLUDING SUBMISSIONS.
          </p>
          <p className="p2">
            To the fullest extent permitted by law, in no event will Tova
            Medical, its licensors, suppliers, or any third parties mentioned on
            Tova Medical be liable for any personal injury, including death,
            attributable to or caused by your use or misuse of Tova Medical or
            Content (including Medical Expert Content). Any claims arising in
            connection with your use of the Apps, Services, or any Content must
            be brought within one (1) year of the first date of the event giving
            rise to such action. Remedies under these Terms are exclusive and
            are limited to those expressly provided for in these Terms. You
            expressly agree that Medical Experts are third party beneficiaries
            under these Terms and may enforce the rights hereunder, including
            Limitation of Liability rights.
          </p>
          <p className="p13">
            NEITHER WE, TOVA MEDICAL MEDICAL EXPERTS, NOR ANY OF OUR LICENSORS
            MAY BE HELD LIABLE UNDER THIS AGREEMENT FOR ANY DAMAGES OTHER THAN
            DIRECT DAMAGES, EVEN IF THE PARTY KNOWS OR SHOULD KNOW THAT OTHER
            DAMAGES ARE POSSIBLE, OR THAT DIRECT DAMAGES ARE NOT A SATISFACTORY
            REMEDY. THESE LIMITATIONS APPLY TO YOU ONLY TO THE EXTENT THEY ARE
            LAWFUL IN YOUR JURISDICTION. EXCEPT AS PROVIDED IN THE&nbsp;
            <a
              href="https://www.tovamedical.com/terms/%23arbitrationAgreement"
              ariaInvalid="true"
            >
              <span className="s1">ARBITRATION AGREEMENT</span>
            </a>
            , NEITHER US, THE MEDICAL EXPERTS ON TOVA MEDICAL, NOR ANY OF OUR
            LICENSORS MAY BE HELD LIABLE UNDER THIS AGREEMENT TO ANY USER FOR
            ANY CLAIMS (IN AGGREGATE OVER ALL TIME) FOR MORE THAN THE GREATER
            OF: (I) FEES PAID BY THE USER TO TOVA MEDICAL OVER THE COURSE OF THE
            SIX (6) MONTHS IMMEDIATELY PRIOR TO THE INCIDENT GIVING TO THE
            ALLEGED DAMAGES; OR (II) FOR USERS WHO HAVE NOT SO USED PAID
            SERVICES, TO ONE HUNDRED DOLLARS ($100).
          </p>
          <p className="p15">&nbsp;</p>
          <p className="p14">&nbsp;</p>
          <p className="p1">
            <strong>
              Limitations of Liability and Indemnity for Expert Content.
            </strong>
          </p>
          <p className="p2">
            Informational content on the Apps that is created, modified,
            submitted, or validated by Medical Experts or other healthcare
            experts (collectively, "Expert Content") is subject to the following
            additional terms and conditions and agreements by You.
          </p>
          <p className="p2">
            Expert Content is for informational purposes only. For medical
            advice, treatment, or diagnosis, see your personal doctor or
            healthcare provider. Tova Medical is not responsible for Expert
            Content. The authors or posters of Expert Content ("Posters") are
            solely responsible for such content. No representations, warranties,
            or guarantees of any kind are made regarding the Expert Content.
            Under no circumstances shall any party be liable (to you or to any
            other person) for any damages or harm (of any type or under any
            legal theory) resulting from or related to the Expert Content. No
            party shall have any liability for: (a) any loss or injury caused,
            in whole or in part, by a Poster's actions, omissions, or
            negligence, in procuring, compiling, or delivering information
            within or through Expert Content; (b) any errors, omissions, or
            inaccuracies in Expert Content (regardless of cause), or delays or
            interruptions in delivery of such information; or (c) any decision
            made or action taken or not taken in reliance upon such information.
            You agree to indemnify and hold the Poster (and the Poster's
            employer and/or sponsor) harmless from any claim or demand,
            including attorneys' fees, made by any third party as a result of
            any violation of law that occurs by you through your use of Expert
            Content or this Site and/or anything you do using Expert Content,
            our Apps and/or the information contained therein.
          </p>
          <p className="p3">
            IF YOU ARE DISSATISFIED WITH ANY OF THE CONTENT OR MATERIALS ON OUR
            SITE, OR ANY SERVICES OR INFORMATION AVAILABLE THROUGH THE SITE,
            YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE ACCESSING AND USING
            OUR SITE. THIS LIMITATION APPLIES EVEN IF YOUR REMEDIES UNDER THIS
            AGREEMENT FAIL OF THEIR ESSENTIAL PURPOSE.
          </p>
          <p className="p9">
            <strong>Licensed Content</strong>
          </p>
          <p className="p1">
            <strong>Certain Content may be licensed from third-parties.</strong>
          </p>
          <p className="p13">
            The licenses for some of this Content may contain additional terms.
            When such Content licenses contain additional terms, we will make
            these terms available to you on those pages or in the Terms of Use.
          </p>
          <p className="p15">&nbsp;</p>
          <p className="p20">
            <strong>Foreign Languages</strong>
          </p>
          <p className="p3">
            Where Tova Medical Content or Services (including these Terms) are
            translated into languages other than English, all such translations
            are for the convenience of our users only, and Tova Medical is not
            responsible or liable in the event of any translation inaccuracy.
            The English-language version of these Terms shall control and apply
            In the event of any conflict with content or translation. You
            understand that Content, including but not limited to questions and
            answers, may not have the same meaning in translation, and that
            treatments (including but not limited to medication names) and other
            information may differ from country to country and in different
            languages and may not be available in all places. Additionally, you
            understand and agree that Virtual Consults with Medical Experts may
            not be available in languages other than English, and you agree not
            to conduct a consultation with a doctor in a language in which you
            are not proficient without the use of a professional translator. If
            you are connected to a Medical Expert who speaks your language, you
            understand that the doctor may not be a native speaker and that the
            doctor's ability to communicate may be limited. If you choose to use
            a translator in connection with a Virtual Consult, you understand
            that Tova Medical is not liable for any errors or omissions in
            translation.
          </p>
          <p className="p9">
            <strong>General Legal Terms</strong>
          </p>
          <p className="p1">
            <strong>General Terms.</strong>
          </p>
          <p className="p13">
            These Terms, and the other agreements referenced in it (like
            our&nbsp;
            <a href="https://www.tovamedical.com/privacy" ariaInvalid="true">
              <span className="s1">Privacy Statement</span>
            </a>
            &nbsp;and&nbsp;
            <a href="https://www.tovamedical.com/cookies" ariaInvalid="true">
              <span className="s1">Cookie Policy</span>
            </a>
            ), are the entire agreement between you and us relating to the Tova
            Medical Apps. Your use of the Services is subject to all additional
            terms, policies, rules, or guidelines applicable to the Service or
            certain features of the Service that we may post on or link to from
            the Service (the "Additional Terms"). All Additional Terms are
            incorporated by this reference into, and made a part of these Terms.
            These Terms replace any prior agreements unless such prior or
            subsequent agreement explicitly provides otherwise and specifically
            references these Terms. If there is any conflict between these Terms
            and a mutually signed written agreement between you and us related
            to Tova Medical, the signed written agreement will control. You may
            not assign or transfer these Terms or your rights under these Terms,
            in whole or in part, by operation of law or otherwise, without our
            prior written consent. We may assign these Terms at any time without
            notice or consent.
          </p>
          <p className="p2">
            If we choose not to enforce any provision of these Terms, we retain
            the right to enforce it in the future. This means that the failure
            to enforce any provision of these Terms does not constitute a waiver
            of that provision. If any provision in these Terms is found to be
            unenforceable, that provision and any related provisions will be
            interpreted to best accomplish the unenforceable provision's
            essential purpose.
          </p>
          <p className="p2">
            This agreement is governed by Puerto Rico law. THE EXCLUSIVE VENUE
            FOR ANY DISPUTE RELATING TO THIS AGREEMENT IS Puerto Rico. YOU AND
            US CONSENT TO THE PERSONAL JURISDICTION OF THESE COURTS. Nothing in
            this agreement limits either party's ability to seek equitable
            relief.
          </p>
          <p className="p2">
            IF YOU ARE DISSATISFIED WITH ANY OF THE CONTENT ON OUR APPS, OR ANY
            SERVICES OR INFORMATION AVAILABLE ON OR THROUGH THE APPS, YOUR SOLE
            AND EXCLUSIVE REMEDY IS TO DISCONTINUE ACCESSING AND USING OUR APPS.
            THIS LIMITATION APPLIES EVEN IF YOUR REMEDIES UNDER THIS AGREEMENT
            FAIL THEIR ESSENTIAL PURPOSE.
          </p>
          <p className="p13">
            The Apps and Services are offered by Tova Medical LLC. located at
            954 PR-25, Suite 205, #10254, Puerto Rico, US. You may contact us by
            sending correspondence to that address or by visiting the customer
            service help center at&nbsp;TovaMedical.com
          </p>
          <p className="p15">&nbsp;</p>
          <p className="p2">
            If you are a California resident, under California Civil Code
            Section 1789.3, you may contact the Complaint Assistance Unit of the
            Division of Consumer Services of the California Department of
            Consumer Affairs in writing at 1625 N. Market Blvd., Suite S-202,
            Sacramento, California 95834, or by telephone at (800) 952-5210 in
            order to resolve a complaint regarding the Service or to receive
            further information regarding the use of the Service.
          </p>
          <p className="p2">
            We are under no obligation to provide support for the Service. In
            instances where we may offer support, the support will be subject to
            published policies.
          </p>
          <p className="p2">
            Notice Regarding Apple. This section only applies to the extent you
            are using our mobile application on an iOS device. You acknowledge
            that these Terms are between you and Tova Medical only, not with
            Apple Inc. ("Apple"), and Apple is not responsible for the App or
            the content thereof. Apple has no obligation to furnish any
            maintenance and support services with respect to the App. If the App
            fails to conform to any applicable warranty, you may notify Apple
            and Apple will refund any applicable purchase price for the mobile
            application to you; and, to the maximum extent permitted by
            applicable law, Apple has no other warranty obligation with respect
            to the App. Apple is not responsible for addressing any claims by
            you or any third party relating to the App or your possession and/or
            use of the App, including: (a) product liability claims; (b) any
            claim that the App fails to conform to any applicable legal or
            regulatory requirement; or (c) claims arising under consumer
            protection or similar legislation. Apple is not responsible for the
            investigation, defense, settlement and discharge of any third party
            claim that the App and/or your possession and use of the App
            infringe a third party's intellectual property rights. You agree to
            comply with any applicable third party terms when using the App.
            Apple and Apple's subsidiaries are third party beneficiaries of
            these Terms, and upon your acceptance of these Terms, Apple will
            have the right (and will be deemed to have accepted the right) to
            enforce these Terms against you as a third party beneficiary of
            these Terms. You hereby represent and warrant that: (i) you are not
            located in a country that is subject to a U.S. Government embargo,
            or that has been designated by the U.S. Government as a "terrorist
            supporting" country; and (ii) you are not listed on any U.S.
            Government list of prohibited or restricted parties.
          </p>
          <p className="p3">
            CVS and MinuteClinic, LLC and its subsidiaries and managed entities
            are not affiliated with Tova Medical. The names and symbols of CVS
            and its affiliates and subsidiaries, including MinuteClinic, LLC are
            owned by and proprietary to CVS, and have been licensed for use by
            Tova Medical.
          </p>
          <p className={classes.smallHeading}>
            <strong>User Guidelines</strong>
          </p>
          <p className="p10">
            By using Tova Medical, including Virtual Consults, you agree to
            abide by the following guidelines. Any violation may result in
            limitation, suspension, or termination of your access to our Apps.
          </p>
          <p className="p1">
            <strong>General Guidelines.</strong>
          </p>
          <ul className="ul1">
            <li className="li6">
              <span className="s3">
                <strong>Respect.</strong>&nbsp;You agree to engage with Medical
                Experts in a respectful manner and to refrain from inappropriate
                language and behavior.
              </span>
            </li>
            <li className="li11">
              <span className="s3">
                <strong>Appropriate Use.</strong>&nbsp;You agree to only use
                Tova Medical and Virtual Consults in a manner that is not:
                unlawful, threatening, harassing, abusive, defamatory,
                slanderous, libelous, harmful to minors, vulgar, gratuitously
                violent, obscene, pornographic, indecent, lewd, invasive of
                another's privacy, or racially, ethnically or otherwise
                offensive, hateful, or abusive.
              </span>
            </li>
          </ul>
          <p className="p1">
            <strong>
              Guidelines for Posting Content (Including Questions).
            </strong>
          </p>
          <ul className="ul1">
            <li className="li6">
              <span className="s3">
                <strong>Informational Questions Only.</strong>&nbsp;Only
                informational questions submitted for educational purposes are
                allowed. Do not post any facts that give the impression that a
                question is uniquely patient-specific, or are about a specific
                person, including yourself.
              </span>
            </li>
            <li className="li6">
              <span className="s3">
                <strong>
                  No Requests for Medical Care, Diagnosis, or Treatment.
                </strong>
                &nbsp;No requests for prescription, diagnosis, or treatment
                should be made on Tova Medical (for medical care, please use
                Virtual Consults, as appropriate and available). Any second
                opinions received on Tova Medical are not diagnosis,
                prescription, or treatment, and are for informational purposes
                only.
              </span>
            </li>
            <li className="li6">
              <span className="s3">
                <strong>No Guarantees.</strong>&nbsp;There is no guarantee that
                any submitted question will be answered; questions are answered
                at the sole discretion of participating Medical Experts. No
                follow-up questions or personal information should be included
                in any notes or other posts on or through Tova Medical.
              </span>
            </li>
            <li className="li6">
              <span className="s3">
                <strong>Only Adults May Post.</strong>&nbsp;Minors (persons
                under the age of&nbsp;18) may not obtain Virtual Consults on
                Tova Medical. Caregivers may post educational questions related
                to authorized care recipients (such as the mother posting
                questions about an infant).
              </span>
            </li>
            <li className="li6">
              <span className="s3">
                <strong>
                  No Posting of Personally Identifiable Information.
                </strong>
                &nbsp;Do not post name(s), email address(es), or telephone
                number(s), URLs, or any other confidential or personally
                identifiable information about you or any other person or entity
                on Tova Medical. The foregoing does not apply to the extent
                necessary to use Virtual Consults or Tova Medical's Premium Plan
                in accordance with these Terms.
              </span>
            </li>
            <li className="li6">
              <span className="s3">
                <strong>No Posting for Illegal Purposes.</strong>&nbsp;Do not
                use Tova Medical for any purpose in violation of local, state,
                federal, or international laws.
              </span>
            </li>
            <li className="li6">
              <span className="s3">
                <strong>No Infringing or Impersonating Postings.</strong>
                &nbsp;Do not post material that infringes on the copyrights or
                other intellectual property rights of others (never post a
                picture of another person without permission) or on the privacy
                or publicity rights of others. Do not post impersonating another
                person or entity.
              </span>
            </li>
            <li className="li6">
              <span className="s3">
                <strong>No Inappropriate Postings.</strong>&nbsp;Do not post
                material that is unlawful, misleading, obscene, sexually
                explicit, medically inappropriate, derogatory, defamatory,
                threatening, harassing, abusive, slanderous, hateful, or
                embarrassing to any other person or entity, or any material that
                could give rise to any civil or criminal liability under
                applicable law or regulations or that otherwise may be in
                conflict with our Terms or Privacy Statement.
              </span>
            </li>
            <li className="li6">
              <span className="s3">
                <strong>No Viruses or Software.</strong>&nbsp;You agree that you
                will not upload any material that contains software viruses or
                any other computer code, files, or programs designed to
                interrupt, destroy or limit the functionality of any computer
                software or the Apps.
              </span>
            </li>
            <li className="li6">
              <span className="s3">
                <strong>No Excessive or Inappropriate use.</strong>&nbsp;Do not
                use the Apps excessively, inappropriately, or in ways that the
                Apps were not designed for or that are not, in the opinion of
                Tova Medical Medical Experts, medically appropriate or
                legitimate.
              </span>
            </li>
            <li className="li6">
              <span className="s3">
                <strong>No Promotions or Links.</strong>&nbsp;Do not post
                advertisements, solicitations, or links to other websites or
                individuals.
              </span>
            </li>
            <li className="li6">
              <span className="s3">
                <strong>No Spam or Schemes.</strong>&nbsp;Do not post the same
                question more than once or "spam" Tova Medical. Do not post
                chain letters or pyramid or other schemes.
              </span>
            </li>
            <li className="li11">
              <span className="s3">
                <strong>No Detrimental Behavior.</strong>&nbsp;Engaging in any
                other conduct that restricts or inhibits any other person from
                using or enjoying Tova Medical, or which, in the judgement of
                Tova Medical, exposes us or any of our members, partners or
                suppliers to any liability or detriment of any type.
              </span>
            </li>
          </ul>
          <p className="p1">
            <strong>
              Guidelines Specific to Virtual Consults and Other Paid Services.
            </strong>
          </p>
          <ul className="ul1">
            <li className="li6">
              <span className="s3">
                <strong>Treatment.</strong>&nbsp;You agree not to make demands
                for treatment from a Medical Expert on Tova Medical.
              </span>
            </li>
            <li className="li6">
              <span className="s3">
                <strong>Care.</strong>&nbsp;Medical care (including Virtual
                Consults with Medical Experts, prescriptions, diagnosis, or
                treatment recommendations) is available on Tova Medical, and you
                agree that such care is limited to medically necessary and
                appropriate care, as determined by the treating Medical Expert.
              </span>
            </li>
            <li className="li6">
              <span className="s3">
                <strong>Care Limitations.</strong>&nbsp;You may use Virtual
                Consults regarding discrete and episodic medical issues.
                Examples of inappropriate uses of Virtual Consults are (but are
                not limited to) use of Virtual Consults to replace or obtain:
                emergency care; in-person routine physicals and vaccinations
                (except for vaccines that can be ordered for you to get at a
                pharmacy); health verifications or disability forms that require
                an in-person exam; in-person tests or screenings, such as Pap
                smears, breast exams, prostate exams, and strep throat swabs;
                lifestyle treatments, such as medication for smoking cessation,
                losing weight, stopping addiction, and erectile dysfunction; and
                ongoing care of chronic conditions like diabetes, high blood
                pressure, arthritis, anxiety, depression, epilepsy, lupus, and
                chronic pain (except for medical advice of a limited nature); as
                well as falsifying your location, doctor-shopping or otherwise
                repeating consults for the same issue. We reserve the right to
                review your usage and to suspend or terminate your Tova Medical
                account if we find that you have misused Virtual Consults. Our
                conclusions based on those reviews are final.
              </span>
            </li>
            <li className="li6">
              <span className="s3">
                <strong>Prescriptions and Lab Tests.</strong>&nbsp;You agree
                that you do not have a right to expect, demand, or to receive
                any particular prescription or lab test when using Tova Medical.
                Prescriptions and lab tests, where available, are provided at
                the sole discretion of the consulting Medical Expert. Patients
                who are located outside the U.S. may not receive lab orders and
                may receive prescription recommendations only.
              </span>
            </li>
            <li className="li6">
              <span className="s3">
                <strong>Prescriptions and Lab Tests Limitations.</strong>
                &nbsp;You understand and agree that not all prescriptions and
                lab tests are available using Tova Medical. The following
                medication categories cannot be prescribed or recommended in
                Virtual Consults on Tova Medical: DEA category I-IV medications,
                lifestyle medications (such as weight loss or hair loss
                medication), and U.S.-state regulated medications. Lab tests are
                currently ordered using Quest Diagnostics, and it may not be
                possible to order all of Quest Diagnostics lab tests (if any) in
                all U.S. states.
              </span>
            </li>
            <li className="li6">
              <span className="s3">
                <strong>Duration of Treatment.</strong>&nbsp;You agree that the
                duration of any treatment prescribed or recommended may be
                limited. It is up to the consulting Medical Expert to determine
                the appropriate duration of any treatment, if any, made
                available through a Virtual Consult. Where prescriptions are
                provided, they may be a short-course to hold you over until you
                are able to consult with your regular doctor.
              </span>
            </li>
            <li className="li11">
              <span className="s3">
                <strong>Dress Code.</strong>&nbsp;You agree to be appropriately
                dressed and to show personal or private body parts only when it
                is of medical relevance to do so.
              </span>
            </li>
          </ul>
          <p className="p1">
            <strong>Reservation of Rights.</strong>
          </p>
          <ul className="ul1">
            <li className="li6">
              <span className="s3">
                <strong>Rejection and Removal of content.</strong>&nbsp;Subject
                to applicable law and any related limitations, we reserve the
                right to review any submission or Content on our Apps and
                Services, and to not post such Content or to remove or request
                the removal of any Content for any reason, at any time, without
                prior notice, at our sole discretion.
              </span>
            </li>
            <li className="li7">
              <span className="s3">
                <strong>Termination.</strong>&nbsp;We reserve the right to
                restrict or terminate access to our Content or Services by any
                individual who violates our Terms, or engages in any other
                conduct that, in our sole judgement, restricts or inhibits any
                other person from using or enjoying Tova Medical or exposes us
                or any of our members, partners or suppliers to any liability or
                detriment of any type.
              </span>
            </li>
          </ul>
          <p className="p9">
            <strong>Tova Medical Arbitration Agreement</strong>
          </p>
          <p className="p1">
            <strong>Summary.</strong>
          </p>
          <p className="p10">
            Tova Medical's Terms require the use of arbitration to resolve
            disputes and also limits the remedies available to you in the event
            of a dispute. Most concerns can be resolved quickly by visiting the
            customer service help center at&nbsp;TovaMedical.com. In the
            unlikely event that we are unable to resolve a legitimate legal
            complaint, we each agree to resolve those disputes through binding
            arbitration or small claims instead of in courts of general
            jurisdiction. Arbitration is more informal than a lawsuit in court.
            Arbitration uses a neutral arbitrator instead of a judge or jury,
            allows for more limited discovery than in court, and is subject to
            very limited review by courts. Arbitrators can award the same
            damages and relief that a court can award. Any arbitration under
            this Agreement will take place on an individual basis; class
            arbitrations and class actions are not permitted. We request that
            you work with us in good faith to resolve any disputes for 30 days
            after notifying us of such issues before filing arbitration. You
            understand that any award available under arbitration is subject to
            the limitations in the Tova Medical TOUs. YOU UNDERSTAND AND AGREE
            THAT, BY ENTERING INTO THESE TERMS, YOU AND TOVA MEDICAL ARE EACH
            WAIVING THE RIGHT TO A TRIAL BY JURY OR TO PARTICIPATE IN A CLASS
            ACTION.
          </p>
          <p className="p1">
            <strong>Agreement.</strong>
          </p>
          <p className="p13">
            First things first: let's try to sort it out. We want to address
            your concerns without a formal arbitration or case. Before filing a
            claim against Tova Medical, you agree to make a good faith effort to
            try to resolve the dispute informally by contacting&nbsp;
            <a href="mailto:Support@TovaMedical.com">
              <span className="s4">Support@TovaMedical.com</span>
            </a>{" "}
            and responding promptly to any related communications. We'll try to
            resolve the dispute by contacting you via email. If a dispute is not
            resolved within 30 days of submission, you or Tova Medical may bring
            a formal proceeding.
          </p>
          <p className="p10">
            This Arbitration Agreement ("Arbitration Agreement") is a condition
            of the Terms of Use of Tova Medical and the Apps. The effective date
            of this agreement is August 30, 2014 (the "Effective Date").
          </p>
          <p className="p1">
            <strong>General.</strong>
          </p>
          <p className="p2">
            If you reside in the United States or are otherwise subject to the
            US Federal Arbitration Act, you agree that any and all disputes or
            claims that have arisen or may arise between us - except as set
            forth below ("Exceptions") - shall be resolved exclusively through
            final and binding arbitration, rather than in court. The Federal
            Arbitration Act governs the interpretation and enforcement of this
            Arbitration Agreement.
          </p>
          <p className="p10">
            Any arbitration proceedings shall be conducted by the American
            Arbitration Association ("AAA") under its rules and procedures
            applicable at that time, including the AAA's Supplementary
            Procedures for Consumer-Related Disputes (to the extent applicable),
            as modified by our Arbitration Agreement. You may review those rules
            and procedures, and obtain a form for initiating arbitration
            proceedings at the AAA's website. The AAA Rules and filing forms are
            available online at <a href="https://www.adr.org">www.adr.org</a>,
            by calling the AAA at 1-800-778-7879, or by contacting Tova Medical.
            The arbitration shall be held in Puerto Rico, or at another mutually
            agreed location. If the reasonable value of the relief sought is
            US$10,000 or less, either of us may elect to have the arbitration
            conducted by telephone or based solely on written submissions, which
            election shall be binding on us subject to the arbitrator's
            discretion to require an in-person hearing. Attendance at an
            in-person hearing may be made by telephone by you and/or us, unless
            the arbitrator requires otherwise.
          </p>
          <p className="p1">
            <strong>Exceptions.</strong>
          </p>
          <p className="p10">
            Notwithstanding the foregoing, nothing in this Arbitration Agreement
            will be deemed to waive, preclude, or otherwise limit the right of
            either party to: (a) bring an individual action in small claims
            court; (b) pursue an enforcement action through the applicable
            federal, state, or local agency if that action is available; (c)
            seek injunctive relief in a court of law in aid of arbitration; or
            (d) to file suit in a court of law to address an intellectual
            property infringement claim.
          </p>
          <p className="p1">
            <strong>Notice.</strong>
          </p>
          <p className="p2">
            A party who intends to seek arbitration must first send to the
            other, by certified mail, a written Notice of Dispute ("Notice").
            The Notice to Tova Medical should be addressed to: General Counsel,
            Tova Medical, 954 PR-25, Suite 205, #10254, Puerto Rico, US ("Notice
            Address"). The Notice must (a) describe the nature and basis of the
            claim or dispute; and (b) set forth the specific relief sought and
            the specific applicable legal basis for the requested relief
            ("Demand"). The parties will make good faith efforts to resolve the
            claim directly, but if the parties do not reach an agreement to do
            so within 30 days after the Notice of Arbitration is received, you
            or Tova Medical may commence an arbitration proceeding. All
            arbitration proceedings between the parties will be confidential
            unless otherwise agreed by the parties in writing. During the
            arbitration, the amount of any settlement offer made by you or Tova
            Medical must not be disclosed to the arbitrator until after the
            arbitrator makes a final decision and award, if any.
          </p>
          <p className="p10">
            The arbitrator will decide the substance of all claims in accordance
            with the laws of Puerto Rico, including recognized principles of
            equity, and will honor all claims of privilege recognized by law.
            The arbitrator shall not be bound by rulings in prior arbitrations
            involving different Tova Medical users, but is bound by rulings in
            prior arbitrations involving the same user to the extent required by
            applicable law. The arbitrator's award shall be final and binding
            and judgement on the award rendered by the arbitrator may be entered
            in any court having jurisdiction over the parties, except for a
            limited right of appeal under the Federal Arbitration Act.
          </p>
          <p className="p1">
            <strong>Opt-Out.</strong>
          </p>
          <p className="p10">
            If you do not wish to resolve disputes by binding arbitration, you
            may opt out of this Arbitration Agreement within 30 days after the
            date that you agree to the Terms by sending a letter to General
            Counsel, Tova Medical, Inc., 954 PR-25, Suite 205, #10254, Puerto
            Rico, US that specifies: your full legal name, the email address
            associated with your account on the Service, and a statement that
            you wish to opt out of arbitration ("Opt-Out Notice"). Once Tova
            Medical receives your Opt-Out Notice, this Arbitration Agreement
            will be void and any action arising out of the Terms will be
            resolved under the governing law and jurisdiction set forth in the
            Terms. The remaining provisions of the Terms will not be affected by
            your Opt-Out Notice.
          </p>
          <p className="p1">
            <strong>Fees.</strong>
          </p>
          <p className="p10">
            The AAA rules will govern the payment of all filing, administration
            and arbitrator fees, unless our Arbitration Agreement expressly
            provides otherwise. If the amount of any claim in an arbitration is
            US$5,000 or less, Tova Medical will pay all arbitrator fees
            associated with the arbitration, so long as: (a) you make a written
            request for such payment of fees and submit it to the AAA with your
            Demand for Arbitration; and (b) your claim is not determined by the
            arbitrator to be frivolous or without merit under Tova Medical's
            Terms of Use or otherwise ("Frivolous Claims"). In such case, we
            will make arrangements to pay all necessary fees directly to the
            AAA. If the amount of the claim exceeds US$10,000 and you are able
            to demonstrate that the costs of arbitration will be prohibitive as
            compared to the costs of litigation, Tova Medical will pay as much
            of the filing, administration, and arbitrator fees as the arbitrator
            deems necessary to prevent the arbitration from being
            cost-prohibitive. If the arbitrator determines the claim(s) you
            assert in the arbitration are Frivolous Claims, you agree to
            reimburse Tova Medical for all fees associated with the arbitration
            paid by Tova Medical. Tova Medical's obligations to pay these fees
            shall be made after a preliminary determination that the claims are
            not barred or limited by the Tova Medical Terms of Use. Bared or
            limited claims constitute Frivolous Claims.
          </p>
          <p className="p1">
            <strong>PROHIBITION OF CLASS AND REPRESENTATIVE ACTIONS</strong>
          </p>
          <p className="p10">
            YOU AND TOVA MEDICAL AGREE, AS PART OF THE ARBITRATION AGREEMENT,
            THAT EACH OF US MAY BRING CLAIMS AGAINST THE OTHER ONLY ON AN
            INDIVIDUAL BASIS AND NOT AS PART OF ANY PURPORTED CLASS OR
            REPRESENTATIVE ACTION OR PROCEEDING. WE REFER TO THIS AS THE
            "PROHIBITION OF CLASS AND REPRESENTATIVE ACTIONS". UNLESS BOTH YOU
            AND WE AGREE OTHERWISE, THE ARBITRATOR MAY NOT CONSOLIDATE OR JOIN
            YOUR OR OUR CLAIM WITH ANOTHER PERSON'S OR PARTY'S CLAIMS, AND MAY
            NOT OTHERWISE PRESIDE OVER ANY FORM OF A CONSOLIDATED,
            REPRESENTATIVE OR CLASS PROCEEDING. THE ARBITRATOR MAY ONLY AWARD
            RELIEF (INCLUDING MONETARY, INJUNCTIVE, AND DECLARATORY RELIEF) IN
            FAVOR OF THE INDIVIDUAL PARTY SEEKING RELIEF AND ONLY TO THE EXTENT
            NECESSARY TO PROVIDE RELIEF NECESSITATED BY THAT PARTY'S INDIVIDUAL
            CLAIM(S). ANY RELIEF AWARDED CANNOT AFFECT OTHER TOVA MEDICAL USERS.
          </p>
          <p className="p1">
            <strong>Enforceability.</strong>
          </p>
          <p className="p10">
            Except with respect to the Prohibition of Class and Representative
            Actions, if a court decides that any part of this Arbitration
            Agreement is invalid or unenforceable, the other parts of this
            Arbitration Agreement shall continue to apply. If a court decides
            that the Prohibition of Class and Representative Actions is invalid
            or unenforceable, then the entire Arbitration Agreement shall be
            null and void.
          </p>
          <p className="p1">
            <strong>Modifications.</strong>
          </p>
          <p className="p10">
            If Tova Medical makes any future change to this Arbitration
            Agreement, other than a change to Tova Medical's Notice Address, you
            may reject the change by sending us written notice within 30 days of
            the change to the Notice Address, in which case your account with
            Tova Medical will be immediately terminated and this arbitration
            provision, as in effect immediately prior to the changes you
            rejected will survive.
          </p>
          <p className="p1">
            <strong>CLAIMS ARE TIME-BARRED</strong>
          </p>
          <p className="p3">
            You agree that regardless of any statute or law to the contrary or
            the applicable dispute resolution process, any claim or cause of
            action you may have arising out of or related to use of the Service
            or otherwise under these must be filed within one (1) year after
            such claim or cause of action arose or you hereby agree to be
            forever barred from bringing such claims.
          </p>
          <p className={classes.smallHeading}>
            <strong>Additional Terms: Virtual Consults</strong>
          </p>
          <p className="p10">
            THE FOLLOWING TERMS APPLY TO YOUR USE OF VIRTUAL CONSULTS PERFORMED
            BY VIDEO, AUDIO (VOICE), OR TEXT CHAT, OR BY ASYNCHRONOUS TEXT INBOX
            CONSULTS (COLLECTIVELY, "VIRTUAL CONSULTS"). BY USING VIRTUAL
            CONSULTS, YOU REPRESENT THAT YOU UNDERSTAND AND AGREE TO ALL OF THE
            FOLLOWING IN ADDITION TO OUR BASIC TERMS OF USE.
          </p>
          <p className="p21">
            <strong>Understandings, Agreements, and Representations.</strong>
          </p>
          <p className="p1">
            <strong>Medical Agreement.</strong>
          </p>
          <ul className="ul1">
            <li className="li5">
              <span className="s3">
                When you engage, or participate, or purchase a Virtual Consult,
                you are entering into an agreement with the Medical Expert,
                which shall be a provider of professional medical services to
                you through the Tova Medical Apps.
              </span>
            </li>
            <li className="li5">
              <span className="s3">
                You are entering into a physician - patient relationship with
                the Medical Expert, that personally performs the Virtual Consult
                for you.
              </span>
            </li>
            <li className="li5">
              <span className="s3">
                You agree to use your legal name and real medical information in
                Virtual Consults.
              </span>
            </li>
            <li className="li5">
              <span className="s3">
                You agree to use only one Tova Medical account for maintaining
                your medical records and for doing Virtual Consults.
              </span>
            </li>
            <li className="li5">
              <span className="s3">
                You represent and warrant to the Medical Expert. that you have a
                primary care physician.
              </span>
            </li>
            <li className="li5">
              <span className="s3">
                You understand and agree that the independent, third-party
                physicians associated with Tova Medical are only providing minor
                non-emergency primary-care medical services with respect to
                Virtual Consults.
              </span>
            </li>
            <li className="li5">
              <span className="s3">
                You understand that you should never delay seeking advice from
                you primary care physician or other health professionals due to
                information provided by a Healthcare Provider through Tova
                Medical. You agree to seek emergency help when needed, and to
                continue to consult with your primary care physician as
                recommended by Medical Experts on Tova Medical and by your
                primary care physician.
              </span>
            </li>
            <li className="li5">
              <span className="s3">
                Tova Medical, LLC. is the provider of certain administrative
                services to the Medical Expert(s) and does not provide
                professional medical services.
              </span>
            </li>
            <li className="li5">
              <span className="s3">
                You agree that the independent, third-party physicians
                associated with Tova Medical, in performing a Virtual Consult,
                may not prescribe for you, the following drugs:
              </span>
              <ul className="ul1">
                <li className="li5">
                  <span className="s3">
                    prescriptions for narcotics or DEA (Drug Enforcement
                    Administration) (
                    <a
                      href="http://www.deadiversion.usdoj.gov/schedules/)"
                      ariaInvalid="true"
                    >
                      http://www.deadiversion.usdoj.gov/schedules/)
                    </a>{" "}
                    controlled substances (Schedule I, II, III, IV);
                  </span>
                </li>
                <li className="li5">
                  <span className="s3">
                    prescriptions for medications that are restricted by states;
                  </span>
                </li>
                <li className="li5">
                  <span className="s3">
                    prescriptions for medications for psychiatric illnesses; or
                  </span>
                </li>
                <li className="li5">
                  <span className="s3">
                    prescriptions for lifestyle medications such as erectile
                    dysfunction or diet drugs.
                  </span>
                </li>
              </ul>
            </li>
            <li className="li5">
              <span className="s3">
                Tova Medical is a company, not a doctor, and does not practice
                medicine and does not participate in or interfere with the
                practice of medicine by Medical Experts on Tova Medical, each of
                whom is responsible for his or her services and compliance with
                the requirements applicable to his or her profession and
                license.
              </span>
            </li>
            <li className="li5">
              <span className="s3">
                You understand that if your medical condition warrants emergency
                help, the Medical Experts associated with Tova Medical will
                direct you to the nearest local hospital emergency department or
                emergency room.
              </span>
            </li>
            <li className="li5">
              <span className="s3">
                You understand that the services offered by the Medical
                Expert(s) and Tova Medical, LLC. are not health insurance and
                are not a substitute for health insurance. You agree not to use
                such services as health insurance. Virtual Consults are designed
                for use independent of health insurance as an out-of-pocket cost
                service, and unless otherwise expressly stated, associated fees
                may not qualify for insurance or HSA or similar reimbursement.
              </span>
            </li>
            <li className="li5">
              <span className="s3">
                You agree to refrain from excessive or inappropriate use of
                Virtual Consults, including but not limited to multiple Virtual
                Consults for the same issue without a valid medical reason,
                including but not limited to: initiating a subsequent Virtual
                Consult before you have received the Doctor Notes from the
                doctor of the prior Virtual Consult; attempts to obtain
                prescriptions unavailable through Tova Medical Premium Services
                or not previously prescribed to you in a prior Virtual Consult
                for the same issue; multiple Virtual Consults for a symptom or
                condition when you have been advised to seek in-person care; or
                any other behavior deemed by Tova Medical or a doctor to be
                excessive or inappropriate.
              </span>
            </li>
            <li className="li11">
              <span className="s3">
                Other than for mutually agreed upon in-person care with a
                Medical Expert on Tova Medical, you agree to refrain from
                contacting or seeking to contact a Medical Expert for
                telemedicine care outside of the platform (such as by phone,
                email, or other messaging system). This protects both patients
                and Medical Experts and ensures clinical care is delivered in a
                reliable, continuous, and controlled platform. Tova Medical is
                not responsible for any interactions with Medical Experts not
                conducted on the Tova Medical platform.
              </span>
            </li>
          </ul>
          <p className="p1">
            <strong>Health Information.</strong>
          </p>
          <ul className="ul1">
            <li className="li5">
              <span className="s3">
                You agree to the entry of your medical records into the Tova
                Medical secure computer database and understand that all
                reasonable measures have been taken to safeguard your medical
                information, in accordance with federal HIPAA standards, as
                amended to date. You acknowledge that no computer or phone
                system is totally secure. Tova Medical recognizes your privacy
                and, in accordance with our Privacy Statement, will not release
                information to anyone without your written authorization or as
                required by law.
              </span>
            </li>
            <li className="li5">
              <span className="s3">
                Tova Medical maintains protected health information (PHI) in
                compliance with federal privacy and security rules (HIPAA and
                HITECH). Our contractual obligations with health care providers
                are as a Business Associate of health care providers who are
                Covered Entities such rules. Tova Medical collects information
                for the purposes of providing our Services (including Virtual
                Consults), marketing and promoting our Services to you, and for
                market research data.
              </span>
            </li>
            <li className="li5">
              <span className="s3">
                You understand and agree that Virtual Consults involve the
                communication of your medical information, both orally and
                visually, to Medical Experts and other health care practitioners
                located in other parts of the state/jurisdiction or outside of
                the state/jurisdiction in which you are located at the time of a
                Virtual Consult and afterward.
              </span>
            </li>
            <li className="li5">
              <span className="s3">
                You also understand that Tova Medical LLC. a "Covered Entity" as
                defined under the Health Insurance Portability and
                Accountability Act and associated regulations. However, you
                understand that the Medical Experts associated with Tova Medical
                are "Covered Entities" that are subject to the provisions of
                HIPAA pursuant to 45 CFR 103. Therefore, you understand and
                agree that your personally identifiable health information
                ("Health Information") provided to Tova Medical, and the Medical
                Expert, is subject to or protected by HIPAA. We will maintain
                the privacy of your Health Information as required by HIPAA.
              </span>
            </li>
            <li className="li5">
              <span className="s3">
                You also understand and agree that by using Tova Medical we may
                automatically send information related to your Virtual
                Consult(s), including related reminders, to you, and you opt in
                to receiving this information by email, mobile notification, or
                using other contact information provided by you in your account
                settings and profile information. You may opt out of receiving
                any such communication via email or mobile notification at any
                time through your account notification settings. If you prefer
                not to receive or do not consent to the receipt of personal
                health information by email or mobile notification, you agree to
                update your account notification settings before using Virtual
                Consults or any features utilizing reminders.
              </span>
            </li>
            <li className="li11">
              <span className="s3">
                You represent and warrant to us that, to the extent you enter
                "protected health information" (as such term is defined under
                HIPAA) for a third person, you are legally authorized to do so
                on behalf of such third person, and such third person has
                validly consented to your inputting of, and your review of
                "protected health information" of such third person on Tova
                Medical and via the Tova Medical website and mobile apps.
              </span>
            </li>
          </ul>
          <p className="p1">
            <strong>Consult Quality.</strong>
          </p>
          <ul className="ul1">
            <li className="li5">
              <span className="s3">
                Virtual Consults are primary care consults, not specialist
                consults, and are not intended to address medical concerns
                typically addressed by medical specialists.
              </span>
            </li>
            <li className="li5">
              <span className="s3">
                You agree that Medical Experts performing Virtual Consults may
                prescribe allowed medications in such Virtual Consults when in
                the Medical Expert's sole judgement it is medically appropriate
                to do so. You agree that you are not guaranteed any prescription
                in a Virtual Consult. The determination that a medical concern
                warrants a prescription is always made at the discretion of the
                consulting Medical Expert in a Virtual Consult and not prior to
                such a consult. You agree to maintain up-to-date medication
                information in your Health Profile on Tova Medical so that
                consulting Medical Experts may know your medication history.
              </span>
            </li>
            <li className="li5">
              <span className="s3">
                You understand and agree that Tova Medical and Medical Experts
                on Tova Medical are not responsible for disconnections or
                connection quality issues you may experience during or outside
                of Virtual Consults as a result of your mobile device's or
                computer's internet connectivity.
              </span>
            </li>
            <li className="li5">
              <span className="s3">
                You agree to refrain from abusive language or engaging in
                inappropriate behavior with Medical Experts during a Virtual
                Consult and agree that a Medical Expert may terminate a Virtual
                Consult at any time should inappropriate behavior or language be
                used, or if in the Medical Expert's sole judgment, the Virtual
                Consult is no longer appropriate or productive.
              </span>
            </li>
            <li className="li5">
              <span className="s3">
                Tova Medical's Premium Plan subscriptions and Virtual Consult
                fees do not include nor cover the costs of any recommendations
                or treatments associated with any Virtual Consult (including but
                not limited to: prescriptions, lab tests, services or
                treatments, devices, or referrals for non-Tova Medical
                consults).
              </span>
            </li>
            <li className="li11">
              <span className="s3">
                24/7 Doctor Visits offer primary-care consults from Medical
                Experts only via the Tova Medical Apps. Specialist care is not
                available. Services are provided under an "on call" model where
                you will be connected with the first-available licensed Tova
                Medical Medical Expert. If you select a specific doctor
                (including specialists) for a 24/7 Doctor Visit, this may
                subject to an additional per-consult fee. Tova Medical's Premium
                Plan may not cover the cost of 24/7 Doctor Visits that
                originated from searching for a specific doctor or specialty.
                Pricing will be shown prior to initiating such 24/7 Doctor Visit
                and by participating in such a 24/7 Doctor Visit you agree to
                pay the fees associated with such a consult.
              </span>
            </li>
          </ul>
          <p className="p1">
            <strong>Your Rights.</strong>
          </p>
          <p className="p2">
            You have all the following rights with respect to Virtual Consults:
          </p>
          <ul className="ul1">
            <li className="li6">
              <span className="s3">
                <strong>Free Choice.</strong>&nbsp;I have the right to withhold
                or withdraw my consent to Virtual Consults at any time without
                affecting my right to future care or treatment.
              </span>
            </li>
            <li className="li6">
              <span className="s3">
                <strong>Access to Information.</strong>&nbsp;I have the right to
                inspect all medical information transmitted during a Virtual
                Consult, including, without limitation, the name of the licensed
                Medical Expert associated with Tova Medical Medical Group, P.C.
                treating me, and may receive copies of this information for a
                reasonable fee.
              </span>
            </li>
            <li className="li6">
              <span className="s3">
                <strong>Confidentiality.</strong>&nbsp;I understand that the
                laws that protect the confidentiality of medical information
                apply to Virtual Consults, and that no information or images
                from such interaction which identifies me will be disclosed to
                other entities without my consent.
              </span>
            </li>
            <li className="li6">
              <span className="s3">
                <strong>Risks.</strong>&nbsp;I understand that there are risks
                from Virtual Consults, including the following: (i) loss of
                records from failure of electronic equipment; (ii) power or
                other technical failures with loss of communication; and (iii)
                invasion of electronic records by outsiders (hackers). Finally,
                I understand that it is impossible to list every possible risk,
                that my condition may not be cured or improved, and in rare
                cases, may get worse.
              </span>
            </li>
            <li className="li6">
              <span className="s3">
                <strong>Benefits.</strong>&nbsp;I understand that I can expect
                the following benefits from Virtual Consults, but that no
                results can be guaranteed or assured: (i) reduced visit time;
                (ii) rapid innovation of treatments; and (iii) focused
                information.
              </span>
            </li>
            <li className="li6">
              <span className="s3">
                <strong>Follow-up.</strong>&nbsp;In the event that the diagnosis
                and treatment by a Medical Expert associated with Tova Medical
                Medical Group, P.C. does not resolve the medical issue for which
                I sought a 24/7 Doctor Visit, I agree to consult with my primary
                care physician in person for follow-up treatment, and/or seek
                treatment, if necessary at a local hospital emergency
                department.
              </span>
            </li>
            <li className="li6">
              <span className="s3">
                <strong>Consequences.</strong>&nbsp;I understand that, by having
                my consent to live Virtual Consults performed by video or
                telephone or asynchronous inbox consults, my Medical Expert
                associated with Tova Medical Medical Group, P.C. may communicate
                medical information concerning me to physicians and other health
                care practitioners located in other parts of the
                state/jurisdiction or outside the state/jurisdiction.
              </span>
            </li>
            <li className="li11">
              <span className="s3">
                <strong>Quality Assurance.</strong>&nbsp;I understand that
                records related to these consults may be reviewed under a Tova
                Medical quality assurance program. Records of Tova Medical
                Quality Assurance Committee records subject to confidentiality.
              </span>
            </li>
          </ul>
          <p className="p1">
            <strong>Your Obligations</strong>
          </p>
          <ul className="ul1">
            <li className="li6">
              <span className="s3">
                <strong>Privacy Statement and Cookie Policy.</strong>&nbsp;You
                agree to Tova Medical's&nbsp;
                <a
                  href="https://www.tovamedical.com/privacy"
                  ariaInvalid="true"
                >
                  <span className="s1">Privacy Statement</span>
                </a>
                &nbsp;and&nbsp;
                <a
                  href="https://www.tovamedical.com/cookies"
                  ariaInvalid="true"
                >
                  <span className="s1">Cookie Policy</span>
                </a>
                , the terms of which are incorporated herein by reference.
              </span>
            </li>
            <li className="li6">
              <span className="s3">
                <strong>True and Accurate Information.</strong>&nbsp;You agree
                that all information you provide on Tova Medical Premium
                Services relates to you and is current, complete, and accurate.
                Additionally, you agree to update and maintain such information
                on Tova Medical Premium Services to ensure that it is true,
                accurate, current and complete at all times.
              </span>
            </li>
            <li className="li6">
              <span className="s3">
                <strong>Payment.</strong>&nbsp;If you are using Tova Medical as
                an individual and you purchase access to Tova Medical's Premium
                Plan or paid Services, you agree to the Tova Medical Payment
                Agreement available at&nbsp;
                <a
                  href="https://www.tovamedical.com/terms/payment-agreement/"
                  ariaInvalid="true"
                >
                  <span className="s4">
                    https://www.TovaMedical.com/terms/payment-agreement/
                  </span>
                </a>
                &nbsp;and to pay all fees or charges to your account in
                accordance with the fees, charges, and billing terms in effect
                at the time a fee or charge is due and payable. Enterprise users
                are subject to the Billing Agreement available at&nbsp;
                <a
                  href="https://www.tovamedical.com/payg-agreement/"
                  ariaInvalid="true"
                >
                  <span className="s4">
                    https://www.TovaMedical.com/payg-agreement/
                  </span>
                </a>
                .
              </span>
            </li>
            <li className="li6">
              <span className="s3">
                <strong>Emergency Help.</strong>&nbsp;You will agree to seek
                emergency help when needed or as recommended by Medical Experts
                you consult with. You agree to continue to consult with your
                primary care doctor as recommended by your primary care doctor
                or the Medical Experts you consult with.
              </span>
            </li>
          </ul>
        </div>
      </Container>
      <div component="div" style={{ marginTop: "150px" }}></div>
      {/* Section 2 */}
      <Footer />
      {/* /Section 2 */}
    </div>
  );
}

export default Terms;
