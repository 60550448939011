import {
  SIGNUP_IN_PROGRESS,
  SIGNUP_SUCCESS,
  SIGNUP_ERROR,
  DISMISS_STATUS_MESSAGE,
} from "./actionTypes";

import axios from "axios";
import utils from "../../../../helpers/utils";

const signupAction = (url, data) => {
  return async (dispatch, getState) => {
    dispatch({
      type: SIGNUP_IN_PROGRESS,
    });

    if (!data.agreedToTerms) {
      dispatch({
        type: SIGNUP_ERROR,
        payload: {
          status: "error",
          message: "Please agree to our terms and privacy policy.",
        },
      });
    } else if (!data.email || !data.password || !data.confirmPassword) {
      dispatch({
        type: SIGNUP_ERROR,
        payload: {
          status: "error",
          message: "All fields are mandatory.",
        },
      });
    } else if (!utils.isValidFormat(data.email, "email")) {
      dispatch({
        type: SIGNUP_ERROR,
        payload: {
          status: "error",
          message: "Email format is invalid.",
        },
      });
    } else if (data.password !== data.confirmPassword) {
      dispatch({
        type: SIGNUP_ERROR,
        payload: {
          status: "error",
          message: "Password & confirm password mismatch.",
        },
      });
    } else {
      try {
        let response = await axios({
          url,
          method: "POST",
          data,
        });
        if (response.data.status === "success") {
          dispatch({
            type: SIGNUP_SUCCESS,
            payload: {
              user: {
                email: data.email,
                password: data.password,
                authId: response.data.authId,
              },
            },
          });
        }
      } catch (error) {
        dispatch({
          type: SIGNUP_ERROR,
          payload: {
            status: "error",
            message: error?.response?.data,
          },
        });
      }
    }

    setTimeout(() => {
      dispatch({
        type: DISMISS_STATUS_MESSAGE,
      });
    }, 10000);
  };
};

export default signupAction;
