import {
  LOGIN_IN_PROGRESS,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  DISMISS_STATUS_MESSAGE,
} from "./actionTypes";

import axios from "axios";
import utils from "../../../../helpers/utils";

const loginAction = (url, data) => {
  return async (dispatch, getState) => {
    dispatch({
      type: LOGIN_IN_PROGRESS,
    });

    if (!data.email || !data.password) {
      dispatch({
        type: LOGIN_ERROR,
        payload: {
          status: "error",
          message: "All fields are mandatory.",
        },
      });
    } else if (!utils.isValidFormat(data.email, "email")) {
      dispatch({
        type: LOGIN_ERROR,
        payload: {
          status: "error",
          message: "Email format is invalid.",
        },
      });
    } else {
      try {
        let response = await axios({
          url,
          method: "POST",
          data,
        });
        if (response?.data) {
          const doctorApplicationId = response?.data?.user?.doctorApplicationId;
          const doctorId = response?.data?.user?.doctorId;

          if (doctorApplicationId === null && doctorId === null) {
            response.data.user.password = data.password;
            response.data.user.authId = response.data.user.sub;
          }

          dispatch({
            type: LOGIN_SUCCESS,
            payload: {
              user: response.data.user,
              auth: response.data.auth,
            },
          });
        }
      } catch (error) {
        if (
          typeof typeof error?.response !== "undefined" &&
          typeof error.response?.data !== "undefined"
        ) {
          dispatch({
            type: LOGIN_ERROR,
            payload: {
              status: "error",
              message: error.response.data,
            },
          });
        }
      }
    }

    setTimeout(() => {
      dispatch({
        type: DISMISS_STATUS_MESSAGE,
      });
    }, 10000);
  };
};

export default loginAction;
