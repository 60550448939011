import {
  DOCTOR_APPLY_IN_PROGRESS,
  DOCTOR_APPLY_SUCCESS,
  DOCTOR_APPLY_ERROR,
  DISMISS_STATUS_MESSAGE,
} from "./actionTypes";

import axios from "axios";

const doctorApplyAction = (url, data) => {
  return async (dispatch, getState) => {
    dispatch({
      type: DOCTOR_APPLY_IN_PROGRESS,
    });

    if (!data.firstName) {
      dispatch({
        type: DOCTOR_APPLY_ERROR,
        payload: {
          status: "error",
          message: "First name is required.",
        },
      });
    } else if (!data.lastName) {
      dispatch({
        type: DOCTOR_APPLY_ERROR,
        payload: {
          status: "error",
          message: "Last name is required.",
        },
      });
    } else if (!data.dob) {
      dispatch({
        type: DOCTOR_APPLY_ERROR,
        payload: {
          status: "error",
          message: "Date of birth is required.",
        },
      });
    } else if (!data.medicalSchool) {
      dispatch({
        type: DOCTOR_APPLY_ERROR,
        payload: {
          status: "error",
          message: "Medical school is required.",
        },
      });
    } else if (!data.degree) {
      dispatch({
        type: DOCTOR_APPLY_ERROR,
        payload: {
          status: "error",
          message: "Degree is required.",
        },
      });
    } else if (!data.graduationYear) {
      dispatch({
        type: DOCTOR_APPLY_ERROR,
        payload: {
          status: "error",
          message: "Graduation year is required.",
        },
      });
    } else if (!data.licCountry) {
      dispatch({
        type: DOCTOR_APPLY_ERROR,
        payload: {
          status: "error",
          message: "License country is required.",
        },
      });
    } else if (!data.licNumber) {
      dispatch({
        type: DOCTOR_APPLY_ERROR,
        payload: {
          status: "error",
          message: "License number is required.",
        },
      });
    } else if (!data.licStateId) {
      dispatch({
        type: DOCTOR_APPLY_ERROR,
        payload: {
          status: "error",
          message: "License state is required.",
        },
      });
    } else if (!data.licStateId) {
      dispatch({
        type: DOCTOR_APPLY_ERROR,
        payload: {
          status: "error",
          message: "License state is required.",
        },
      });
    } else if (!data.licExpDate) {
      dispatch({
        type: DOCTOR_APPLY_ERROR,
        payload: {
          status: "error",
          message: "License expiration date is required.",
        },
      });
    } else if (!data.specialtyId) {
      dispatch({
        type: DOCTOR_APPLY_ERROR,
        payload: {
          status: "error",
          message: "Speciality is required.",
        },
      });
    } else {
      try {
        let response = await axios({
          url,
          method: "PUT",
          data,
        });
        if (response.data) {
          dispatch({
            type: DOCTOR_APPLY_SUCCESS,
            payload: {
              user: response.data.user,
              auth: response.data.auth,
            },
          });
        }
      } catch (error) {
        if (typeof error.response.data !== "undefined") {
          dispatch({
            type: DOCTOR_APPLY_ERROR,
            payload: {
              status: "error",
              message: error.response.data,
            },
          });
        }
      }
    }

    setTimeout(() => {
      dispatch({
        type: DISMISS_STATUS_MESSAGE,
      });
    }, 10000);
  };
};

export default doctorApplyAction;
