import React, { useEffect, useState } from "react";
import { Typography } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Container, Grid, Box, useMediaQuery } from "@material-ui/core";

import utils from "../helpers/utils";
import { Redirect } from "react-router";
import { connect } from "react-redux";
import TalkToADoctor from "../components/TalkToADoctor";
import TypesOfCare from "../components/TypesOfCare";
import VerticalMenu from "../components/VerticalMenu";
import axios from "axios";
import { NavLink } from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBackOutlined";
import useHasPermission from "../hooks/useHasPermission";
// images
// import patient_img from "../assets/images/icons/Icons_set/Patients.png";
// import doctor_img from "../assets/images/icons/Icons_set/doctors.png";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "calc(100vh - 110px)",
    paddingTop: theme.spacing(30),
  },
  card: {
    boxShadow: "0 3px 60px rgba(0,0,0,0.16)",
    borderRadius: "5px",
    "&:hover": {
      boxShadow: "0 3px 20px rgba(0,0,0,0.30)",
    },
  },

  contentSection: {
    boxShadow: theme.section.boxShadow,
    borderRadius: theme.section.borderRadius,
    backgroundColor: "#fff",
    padding: theme.spacing(4),
  },
  label: {
    display: "block",
    fontSize: "12px",
    color: theme.paragraph.color,
  },
  value: {
    display: "block",
    fontWeight: 600,
    color: theme.paragraphDark.color,
  },
  infoWrapper: {
    marginTop: theme.spacing(3),
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  divider: {
    height: "4px",
    marginTop: theme.spacing(1),
    width: theme.spacing(5),
    backgroundColor: theme.palette.primary.main,
  },
  sectionHeading: {
    fontWeight: 600,
  },
  count: {
    color: theme.palette.primary.main,
    fontSize: "2rem",
    textAlign: "center",
    fontWeight: "bold",
  },
  sectionMainHeading: {
    fontSize: "40px",
    fontWeight: 600,
    lineHeight: "55px",

    [theme.breakpoints.down("xs")]: {
      fontSize: "33px",
      lineHeight: "45px",
    },
  },
  sectionHeaderWrapper: {
    marginBottom: "50px",
    maxWidth: "75%",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "75%",
      marginLeft: "auto",
      marginRight: "auto",
      textAlign: "center !important",
    },
  },
  contentGridWrapper: {
    paddingBottom: "100px",
  },
  typesOfCareWrapper: {
    marginTop: "80px",
  },
  remoeTopPadding: {
    paddingTop: "150px",
  },
  adminPanelContainer: {
    flexGrow: 1,
  },
  headingWrapper: {
    display: "flex",
    alignItems: "center",
  },
  container: {
    marginTop: "20px",
  },
  subHeading: {
    fontSize: "1rem",
    fontFamily: "Open Sans,sans-serif",
    fontWeight: "bold",
    lineHeight: "1.7142857142857142rem",
    color: "#000",
    letterSpacing: "1px",
  },
  navLink: {
    color: theme.palette.primary.main,
  },
}));

const Account = (props) => {
  document.body.style.backgroundColor = "#F7F7F7";
  const classes = useStyles();

  const theme = useTheme();

  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const xs = useMediaQuery(theme.breakpoints.down("xs"));
  const { user } = props;
  const role = utils.getRole(user);

  const [onlineDrsCount, setOnlineDrsCount] = useState(0);
  const [onlinePatientsCount, setOnlinePatientsCount] = useState(0);
  const [activeDrsSessoinsCount, setActiveDrsSessoinsCount] = useState(0);
  const [activePatientsSessoinsCount, setActivePatientsSessoinsCount] =
    useState(0);
  const [waitingRoomDrsCount, setWaitingRoomDrsCount] = useState(0);
  const [waitingRoomPatientsCount, setWaitingRoomPatientssCount] = useState(0);

  useEffect(() => {
    getOnlineUsersCount("doctors");
    getOnlineUsersCount("patients");
    getSessionsCount("doctors");
    getSessionsCount("patients");
    getInWaitingRoomCount("doctors");
    getInWaitingRoomCount("patients");
    let interval = setInterval(() => {
      getOnlineUsersCount("doctors");
      getOnlineUsersCount("patients");
      getSessionsCount("doctors");
      getSessionsCount("patients");
      getInWaitingRoomCount("doctors");
      getInWaitingRoomCount("patients");
    }, 1000 * 60 * 10);
    return () => clearInterval(interval);
  }, []);

  const getOnlineUsersCount = async (userType) => {
    let url = `${process.env.REACT_APP_SERVER_URL}/public/analytics`;
    let payload = { personId: userType === "doctors" ? 0 : 1 };
    const res = await axios.post(url, payload);
    if (res?.status === 200 && res?.data?.anlytics?.length) {
      if (userType === "doctors") {
        setOnlineDrsCount(res?.data?.anlytics?.length || 0);
      } else {
        setOnlinePatientsCount(res?.data?.anlytics?.length || 0);
      }
    }
  };
  const getSessionsCount = async (userType) => {
    let url = `${process.env.REACT_APP_SERVER_URL}/analytics`;
    let payload = {
      personId: userType === "doctors" ? 0 : 1,
      analyticsType: "ongoing_sessions",
    };
    const res = await axios.post(url, payload);
    if (res?.status === 200 && res?.data?.anlytics?.length) {
      if (userType === "doctors") {
        setActiveDrsSessoinsCount(res?.data?.length || 0);
      } else {
        setActivePatientsSessoinsCount(res?.data?.length || 0);
      }
    }
  };
  const getInWaitingRoomCount = async (userType) => {
    let url = `${process.env.REACT_APP_SERVER_URL}/analytics`;
    let payload = {
      personId: userType === "doctors" ? 0 : 1,
      analyticsType: "waiting_room_users",
    };
    const res = await axios.post(url, payload);
    if (res?.status === 200 && res?.data?.anlytics?.length) {
      if (userType === "doctors") {
        setWaitingRoomDrsCount(res?.data?.length || 0);
      } else {
        setWaitingRoomPatientssCount(res?.data?.length || 0);
      }
    }
  };

  const { hasPermission } = useHasPermission({
    permission: "read:app_analytics",
  });
  if (hasPermission === false) return <Redirect to="/account" />;

  if (role === "doctor" && !user.isApplicationAccepted) {
    return <Redirect to="/application-status" />;
  }
  return (
    <Container
      maxWidth="lg"
      className={`${classes.root} ${
        // role === "patient" ? classes.remoeTopPadding : ""
        classes.remoeTopPadding
      }`}
    >
      {role === "patient" && (
        <div className={classes.contentGridWrapper}>
          <div className={classes.sectionHeaderWrapper}>
            <Typography className={classes.sectionMainHeading} variant="h5">
              Talk to a doctor
            </Typography>
            <Typography>
              Get medical advice, prescriptions, tests and referrals.
            </Typography>
          </div>
          <TalkToADoctor showUnavailablePopup={true} />
          <div className={classes.typesOfCareWrapper}>
            <TypesOfCare
              showUnavailablePopup={true}
              content={{
                section1: {
                  align: "left",
                  heading: "Care for every part of your life",
                  description:
                    "Our primary care doctors can help you with a broad range of health issues, medications and lab orders by video appointment",
                },
                section2: {
                  align: "left",
                  heading: "Mental & behavioral",
                  description:
                    "Talk with our Primary Care doctors about a broad range of issues like sleep, anxiety, or addictive behaviors. Or schedule ongoing appointments with a licensed therapist.",
                },
                section3: {
                  align: "left",
                  heading: "",
                  description: "",
                },
              }}
            />
          </div>
        </div>
      )}

      {hasPermission && (
        <div className={classes.adminPanelContainer}>
          <Box>
            <div className={classes.headingWrapper}>
              <NavLink className={classes.navLink} to="/account">
                <ArrowBackIcon className={classes.icon} />
              </NavLink>
              <Typography variant="h5">
                Analytics
                <div className={classes.divider} />
              </Typography>
            </div>
          </Box>
          <Grid container spacing={4} className={classes.container}>
            <Grid item xs={12}>
              <Typography className={classes.subHeading}>
                Online Doctors/Patients
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={2}>
              <CountCard title="Online Doctors" count={onlineDrsCount} />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={2}>
              <CountCard title="Online Patients" count={onlinePatientsCount} />
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.subHeading}>
                Active Doctors/Patients Sessions
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={2}>
              <CountCard
                title="Doctors Sessions"
                count={activeDrsSessoinsCount}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={2}>
              <CountCard
                title="Patients Sessions"
                count={activePatientsSessoinsCount}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.subHeading}>
                Waiting room Doctors/Patients
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={2}>
              <CountCard title="Waiting Doctors" count={waitingRoomDrsCount} />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={2}>
              <CountCard
                title="Waiting Patients"
                count={waitingRoomPatientsCount}
              />
            </Grid>
          </Grid>
        </div>
      )}

      {role?.includes("doctor") && (
        <Grid container direction="row" className={classes.gridWrapper}>
          {!sm && !xs ? (
            <Grid item xl={3} lg={3} md={3}>
              <VerticalMenu active="account" />
            </Grid>
          ) : null}

          <Grid item xl={9} lg={9} md={9} sm={12} xs={12}>
            <Box component="div" className={classes.contentSection}>
              <Typography className={classes.sectionHeading} variant="h6">
                Account Info
              </Typography>
              <div className={classes.divider} />
            </Box>
          </Grid>
        </Grid>
      )}
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    isProcessing: state.auth0.isProcessing,
    user: state.auth0.user,
  };
};

export default connect(mapStateToProps)(Account);

const CountCard = ({ title, count }) => {
  const classes = useStyles();
  return (
    <Box
      style={{ backgroundColor: "#fff", padding: "10px" }}
      className={classes.card}
    >
      <Box className={classes.count}>{count}</Box>
      <Typography
        variant="subtitle1"
        style={{ textAlign: "center", fontSize: ".8rem" }}
      >
        {title}
      </Typography>
    </Box>
  );
};
