import React, { PureComponent } from "react";
import axios from "axios";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Chip from "@material-ui/core/Chip";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import WorkIcon from "@material-ui/icons/Work";
import StarsIcon from "@material-ui/icons/Stars";
import Snack from "../components/Snack";
import DocParamControls from "../components/DocParamControls";
import {
  InfiniteLoader,
  List,
  WindowScroller,
  AutoSizer,
  CellMeasurerCache,
  CellMeasurer,
} from "react-virtualized";

class Directory extends PureComponent {
  constructor(props) {
    super(props);
    this.baseUrl = process.env.REACT_APP_SERVER_URL;
    this.snackRef = React.createRef();
    this.state = {
      remoteRowCount: 0,
      list: [],
    };
    this.currentFilterParams = {
      stateId: 0,
      lastName: "",
      ideologies: [],
      services: [],
    };
  }

  async getStats(params) {
    const { stateId, lastName, ideologies, services } = params;
    const response = await axios.post(`${this.baseUrl}/docs-count`, {
      stateId,
      lastName,
      ideologies,
      services,
    });
    if (response.status > 400) {
      throw response.statusText ?? JSON.stringify(response);
    }
    return response.data;
  }

  async getDocs(params) {
    const { stateId, lastName, ideologies, services, startIndex, stopIndex } =
      params;
    const response = await axios.post(`${this.baseUrl}/docs`, {
      stateId,
      lastName,
      ideologies,
      services,
      startIndex,
      stopIndex,
    });
    if (response.status > 400) {
      throw response.statusText ?? JSON.stringify(response);
    }
    return response.data;
  }

  async deleteDoc(id) {
    const response = await axios.delete(`${this.baseUrl}/docs`, {
      params: {
        id,
      },
    });
    if (response.status > 400) {
      throw response.statusText ?? JSON.stringify(response);
    }
    window.location.reload();
  }

  async onParamsChanged(params) {
    try {
      const { stateId, lastName, ideologies, services } = params;
      this.currentFilterParams = {
        stateId,
        lastName,
        ideologies,
        services,
      };
      if (!stateId) {
        return;
      }
      const stats = await this.getStats(params);
      this.setState({
        remoteRowCount: stats.count,
      });
      this.loadMoreRows({
        startIndex: 0,
        stopIndex: 100,
      });
    } catch (e) {
      console.error(e);
      const message = e.message ?? JSON.stringify(e);
      this.state.snackRef?.current.eat(message);
    }
  }

  isRowLoaded({ index }) {
    return !!this.state.list[index];
  }

  loadMoreRows({ startIndex, stopIndex }) {
    const params = { ...this.currentFilterParams };
    params.startIndex = startIndex;
    params.stopIndex = stopIndex;
    return this.getDocs(params).then((docs) => {
      this.setState({
        list: [...docs],
      });
    });
  }

  cache = new CellMeasurerCache({
    defaultHeight: 30,
    fixedWidth: true,
  });

  rowRenderer({ key, index, style, parent, isScrolling }) {
    const item = this.state.list[index];
    const firstName = item?.firstName;
    const lastName = item?.lastName;
    const fullName = `${firstName} ${lastName}`;
    const avatarLetters =
      firstName && lastName
        ? firstName.substring(0, 1) + lastName.substring(0, 1)
        : "U";
    const city = item?.city;
    const state = item?.state?.name;
    const accreditation = item?.accreditation;
    const services = item?.services;
    const ideologies = item?.ideologies;

    const mainTextStyle = {
      display: "block",
      margin: "0 0 0 20px",
      minWidth: "40%",
    };
    const chipContainerStyle = {
      margin: "0 0 0 20px",
      maxWidth: "45%",
    };
    const chipStyle = {
      margin: "2px",
    };
    const cardStyle = {
      display: "flex",
      flexDirection: "column",
    };
    const servicesChips = services?.map(({ id, text }) => (
      <Chip
        key={id}
        style={chipStyle}
        icon={<WorkIcon />}
        label={text}
        color="primary"
      />
    ));
    const ideologiesChips = ideologies?.map(({ id, text }) => (
      <Chip
        key={id}
        style={chipStyle}
        icon={<StarsIcon />}
        label={text}
        color="secondary"
      />
    ));
    return (
      <CellMeasurer
        cache={this.cache}
        columnIndex={0}
        rowIndex={index}
        {...{ key, parent }}
      >
        {({ measure }) => (
          <div style={style} className="card-wrapper">
            <Card variant="outlined" key={key} style={cardStyle}>
              <CardContent>
                <ListItem alignItems="flex-start">
                  <ListItemAvatar>
                    <Avatar>{avatarLetters}</Avatar>
                  </ListItemAvatar>
                  <div style={mainTextStyle}>
                    <Typography variant="h5" component="h2">
                      Dr. {fullName}
                    </Typography>
                    <Typography color="textSecondary">
                      {city}, {state}
                    </Typography>
                    <Typography color="textSecondary">
                      {accreditation}
                    </Typography>
                  </div>
                  <div style={chipContainerStyle}>
                    {servicesChips}
                    {ideologiesChips}
                  </div>
                </ListItem>
              </CardContent>
              <CardActions>
                <Button size="small">Contact</Button>
                <Button
                  size="small"
                  component={Link}
                  to={`/doctor/${item?.id}`}
                >
                  Edit
                </Button>
                <Button size="small" onClick={() => this.deleteDoc(item?.id)}>
                  Delete
                </Button>
              </CardActions>
            </Card>
          </div>
        )}
      </CellMeasurer>
    );
  }

  render() {
    const pageStyle = {
      padding: "0 20px",
      margin: "130px 0",
    };
    const titleStyle = {
      marginBottom: "0",
    };
    const listStyle = {
      width: "calc(100% - 20px)",
      minHeight: "90vh",
      height: "calc(100vh - 350px)",
      marginTop: "20px",
    };
    const dirButton = {
      marginRight: "20px",
    };
    const noState =
      this.currentFilterParams.stateId > 0 ? null : (
        <Card className="directory-empty-card">
          <CardContent>
            <Typography variant="h5" component="h2">
              Get started by selecting a state!
            </Typography>
          </CardContent>
        </Card>
      );
    return (
      <div style={pageStyle} className="directory">
        <h1 style={titleStyle}>Directory</h1>
        <DocParamControls
          onParamsUpdate={(params) => this.onParamsChanged(params)}
        ></DocParamControls>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to="/doctor"
            style={dirButton}
          >
            Add to Directory
          </Button>
          <Chip label={this.state.remoteRowCount} />
        </Grid>
        {noState}
        <div className="loader-container">
          <InfiniteLoader
            isRowLoaded={this.isRowLoaded}
            loadMoreRows={this.loadMoreRows}
            rowCount={this.remoteRowCount}
          >
            {({ onRowsRendered, registerChild }) => (
              <WindowScroller>
                {({ height, isScrolling, scrollTop }) => (
                  <AutoSizer disableHeight>
                    {({ width }) => (
                      <List
                        style={listStyle}
                        width={width}
                        height={height}
                        autoHeight
                        onRowsRendered={onRowsRendered}
                        deferredMeasurementCache={this.cache}
                        ref={registerChild}
                        rowCount={this.state.remoteRowCount}
                        rowHeight={this.cache.rowHeight}
                        rowRenderer={(params) => this.rowRenderer(params)}
                        scrollTop={scrollTop}
                      />
                    )}
                  </AutoSizer>
                )}
              </WindowScroller>
            )}
          </InfiniteLoader>
        </div>
        <Snack ref={this.snackRef}></Snack>
      </div>
    );
  }
}

export default Directory;
