import { useEffect, useState } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import Loading from "../../../components/Loading";
import VisibilityIcon from "@material-ui/icons/VisibilityOutlined";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOffOutlined";
import Alert from "../../../components/Alert";
import { NavLink, Redirect } from "react-router-dom";
import bg1 from "../../../assets/images/bg2.jpg";
import loginAction from "../store/actions/loginAction";
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(15),

    height: "100vh",
    backgroundImage: `url(${bg1})`,
    backgroundRepeat: "no-repeat",
    position: "center",
    backgroundSize: "cover",
    transition: "background-image 5s ease-in-out",
    [theme.breakpoints.down("sm")]: {
      backgroundSize: "contained",
      //minHeight: "932px",
      backgroundPosition: "-500px",
    },
  },

  formWrapper: {
    marginTop: theme.spacing(4),
  },
  headingVerticalSpacing: {
    marginBottom: theme.spacing(3),
  },
  textBold: {
    fontWeight: 700,
  },
  description: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(4),
  },
  runningText: {
    lineHeight: "26px",
  },
  mainContentWrapper: {
    backgroundColor: "#fff",
    minWidth: "300px",
    maxWidth: "500px",
    padding: theme.spacing(6),
    borderRadius: theme.spacing(2),
  },
  label: {
    marginBottom: theme.spacing(1),
    display: "block",
  },
  submitBtnWrapper: {
    marginTop: theme.spacing(5),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  labelWithIcon: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  noDecoration: {
    textDecoration: "none",
    color: theme.textSeagreen,
    cursor: "pointer",
  },
  smallHeading: {
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(3),
    fontWeight: "600",
  },
  formControlLabel: {
    alignItems: "flex-start !important",
    "& span": {
      fontSize: "15px !important",
    },
    "& .MuiButtonBase-root": {
      paddingTop: 0,
    },
  },

  textCenter: {
    textAlign: "center",
    marginTop: theme.spacing(4),
  },
  btnContained: {
    backgroundColor: theme.backgroundSeagreen,
    color: "#fff",
    "&:hover": {
      backgroundColor: theme.backgroundSeagreenDark,
    },
  },
}));

const PatientLogin = (props) => {
  document.body.style.background = "#fff";

  const classes = useStyles();

  const [formData, setFormData] = useState({
    email: "",
    password: "",
    type: "patient",
  });

  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [visible, setVisible] = useState(false);
  const [currentBg] = useState(bg1);

  useEffect(() => {
    setIsLoading(false);
  }, []);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isValidated()) {
      return;
    }

    await props.login(`${process.env.REACT_APP_SERVER_URL}/public/login`, {
      ...formData,
    });
  };

  const isValidated = () => {
    for (let key in formData) {
      if (typeof formData[key] !== "undefined" && !formData[key]) {
        setHasError(true);
        return false;
      }
    }
    return true;
  };

  if (isLoading) {
    return <Loading />;
  } else if (props.isAuthenticated) {
    return <Redirect to="/account" />;
  }

  return (
    <div
      className={classes.root}
      style={{ backgroundImage: `url(${currentBg})` }}
    >
      {props.status ? (
        <Alert status={props.status} message={props.message} />
      ) : null}

      <Container fixed>
        <div className={classes.mainContentWrapper}>
          <div className={classes.mainDescriptionWrapper}>
            <Typography variant="h5" className={classes.textBold}>
              Patient Login
            </Typography>
          </div>
          <div className={classes.formWrapper}>
            <form onSubmit={handleSubmit}>
              <div className={classes.section}>
                <Grid container direction="row" spacing={3}>
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <div className={classes.inputGroup}>
                      <label className={classes.label}>Email</label>
                      <TextField
                        onChange={handleChange}
                        name="email"
                        label=""
                        variant="filled"
                        fullWidth
                        error={hasError && !formData.email.length}
                        value={formData.email || ""}
                        helperText={
                          hasError && !formData.email.length ? "Required" : ""
                        }
                      />
                    </div>
                  </Grid>
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <div className={classes.inputGroup}>
                      <div className={classes.labelWithIcon}>
                        <label>Password</label>
                        <IconButton
                          disableRipple={true}
                          onClick={(e) => setVisible(!visible)}
                        >
                          {!visible ? (
                            <VisibilityOffIcon />
                          ) : (
                            <VisibilityIcon />
                          )}
                        </IconButton>
                      </div>

                      <TextField
                        onChange={handleChange}
                        name="password"
                        label=""
                        variant="filled"
                        fullWidth
                        type={visible ? "text" : "password"}
                        error={hasError && !formData.password.length}
                        value={formData.password || ""}
                        helperText={
                          hasError && !formData.password.length
                            ? "Required"
                            : ""
                        }
                      />

                      <p>
                        <NavLink
                          className={classes.noDecoration}
                          to="/individual/forgot/password"
                        >
                          Forgot your password?
                        </NavLink>
                      </p>
                    </div>
                  </Grid>
                </Grid>
              </div>

              <div className={classes.submitBtnWrapper}>
                <Button
                  variant="contained"
                  type="submit"
                  fullWidth
                  className={classes.btnContained}
                  disabled={props.isProcessing}
                >
                  {props.isProcessing ? "Processing" : "Log In"}
                </Button>
              </div>

              <div className={classes.textCenter}>
                <p>
                  New user?{" "}
                  <NavLink
                    className={classes.noDecoration}
                    to="/individual/signup"
                  >
                    Create an account
                  </NavLink>
                </p>
                <p>
                  Are you a doctor?{" "}
                  <NavLink className={classes.noDecoration} to="/doctor/login">
                    Log In here
                  </NavLink>
                </p>
              </div>
            </form>
          </div>
        </div>
      </Container>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isProcessing: state.auth0.isProcessing,
    status: state.auth0.status,
    message: state.auth0.message,
    isAuthenticated: state.auth0.isAuthenticated,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    login: (url, formData) => dispatch(loginAction(url, formData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PatientLogin);
