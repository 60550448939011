import React from "react";
import appStoreIcon from "../../assets/images/app-store-icon.svg";

const styles = {
  img: {},
};

const AppStoreIcon = ({ style }) => {
  return (
    <img
      src={appStoreIcon}
      style={{ ...styles.img, ...style }}
      alt="Apple store icon"
    />
  );
};

export default AppStoreIcon;
