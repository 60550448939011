import { useState } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { Box } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import VisibilityIcon from "@material-ui/icons/VisibilityOutlined";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOffOutlined";
import Alert from "../../components/Alert";
import { connect } from "react-redux";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  root: {
    transition: "background-image 5s ease-in-out",
  },

  formWrapper: {
    marginTop: theme.spacing(4),
  },
  headingVerticalSpacing: {
    marginBottom: theme.spacing(3),
  },
  textBold: {
    marginTop: "30px",
    fontWeight: 700,
  },
  description: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(4),
  },
  runningText: {
    lineHeight: "26px",
  },
  mainContentWrapper: {
    backgroundColor: "#fff",
    minWidth: "300px",
    maxWidth: "500px",
    height: "90vh",
    borderRadius: theme.spacing(2),
  },
  mainDescriptionWrapper: {
    marginTop: "50px",
  },
  label: {
    marginBottom: theme.spacing(1),
    display: "block",
  },
  submitBtnWrapper: {
    marginTop: theme.spacing(5),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  labelWithIcon: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  noDecoration: {
    textDecoration: "none",
    color: theme.textSeagreen,
    cursor: "pointer",
  },
  smallHeading: {
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(3),
    fontWeight: "600",
  },
  formControlLabel: {
    alignItems: "flex-start !important",
    "& span": {
      fontSize: "15px !important",
    },
    "& .MuiButtonBase-root": {
      paddingTop: 0,
    },
  },

  textCenter: {
    textAlign: "center",
    marginTop: theme.spacing(4),
  },
  btnContained: {
    backgroundColor: theme.backgroundSeagreen,
    color: "#fff",
    "&:hover": {
      backgroundColor: theme.backgroundSeagreenDark,
    },
  },
  credentialsWrapper: {
    display: "flex",
    justifyContent: "space-between",
  },
  editBtn: {
    cursor: "pointer",
    color: "#32a5b6",
  },
  cancelBtn: {
    cursor: "pointer",
    paddingRight: "10px",
  },
  updateBtn: {
    fontSize: "1.1428571428571428rem",
    fontFamily: "Open Sans,sans-serif",
    fontWeight: 400,
    lineHeight: "1.5",
    padding: "0",
    background: "transparent",
    boxShadow: "none",
    color: "#32a5b6",
    "&:hover": {
      background: "transparent",
    },
  },
  updateBtnWrapper: {
    display: "flex",
    justifyContent: "end",
  },
}));
const formInitVal = {
  email: "",
  password: "",
  confirm_password: "",
};
const UpdateEmail = (props) => {
  document.body.style.background = "#fff";

  const classes = useStyles();

  const [formData, setFormData] = useState(formInitVal);

  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [visible, setVisible] = useState(false);
  const [alert, setAlert] = useState({ status: "", message: "" });
  const [editEmail, setEditEmail] = useState(false);
  const [editPassword, setEditPassword] = useState(false);
  const handleChange = (e) => {
    setHasError(false);
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isValidated()) {
      return;
    }

    setIsLoading(true);
    try {
      let updateType = editEmail ? "email" : "password";
      let url = `${
        process.env.REACT_APP_SERVER_URL
      }/accounts/${userType()}/${userId()}/edit/${updateType}`;
      let payload;
      if (editEmail) {
        payload = { email: formData.email };
      } else {
        payload = { password: formData.password };
      }

      const res = await axios.put(url, payload);

      setIsLoading(false);
      setEditEmail(false);
      setEditPassword(false);
      setFormData(formInitVal);

      setAlert({
        status: "success",
        message: `${updateType} updated successfully`,
      });
      // updating email in redux state
      if (editEmail) {
        let payload = {
          user: { ...props.user, email: res.data.res.email },
          auth: props.auth,
        };
        props.updateEmail("LOGIN_SUCCESS", payload);
      }
    } catch (error) {
      console.log(error.response);
      setIsLoading(false);
      setAlert({
        status: "error",
        message: error?.response?.data?.res?.message,
      });
    }
  };

  const isValidated = () => {
    if (editEmail) {
      if (!formData.email) {
        setHasError(true);
        return false;
      }
    } else {
      if (!formData.password || !formData.confirm_password) {
        setHasError(true);
        return false;
      }
      if (formData.password !== formData.confirm_password) {
        setHasError(true);
        return false;
      }
    }
    return true;
  };

  const userType = () => {
    return props.user["http://tova_userType"].userType;
  };
  const userId = () => {
    if (props.user["http://tova_userType"].userType === "patient") {
      return props.user.patientId;
    } else {
      return props.user.doctorId;
    }
  };

  // if (isLoading) {
  //   return <Loading />;
  // }

  return (
    <div className={classes.root}>
      {alert.status ? (
        <Alert status={alert.status} message={alert.message} />
      ) : null}

      <div className={classes.mainContentWrapper}>
        <div className={classes.formWrapper}>
          <form onSubmit={handleSubmit}>
            <div className={classes.section}>
              <Grid container direction="row" spacing={3} justify="center">
                <Grid item xs={12}>
                  {editEmail === false && (
                    <Box className={classes.credentialsWrapper}>
                      <Box>
                        <Typography>Login Email</Typography>
                        <Typography>{props.user.email}</Typography>
                      </Box>
                      {editEmail === false && (
                        <Typography
                          onClick={() => {
                            setHasError(false);
                            setEditEmail(!editEmail);
                            setEditPassword(false);
                            setFormData({
                              ...formData,
                              email: props.user.email,
                            });
                          }}
                          className={classes.editBtn}
                        >
                          Edit
                        </Typography>
                      )}
                    </Box>
                  )}

                  {editEmail && (
                    <div className={classes.inputGroup}>
                      <label className={classes.label}>Email</label>
                      <TextField
                        onChange={handleChange}
                        name="email"
                        label=""
                        variant="filled"
                        fullWidth
                        error={hasError && !formData.email.length}
                        value={formData.email || ""}
                        helperText={
                          hasError && !formData.email.length ? "Required" : ""
                        }
                      />
                      <Box className={classes.updateBtnWrapper}>
                        <Typography
                          onClick={() => setEditEmail(false)}
                          className={classes.cancelBtn}
                        >
                          Cancel
                        </Typography>
                        <Button type="submit" className={classes.updateBtn}>
                          {isLoading ? "Processing" : "Update"}
                        </Button>
                      </Box>
                    </div>
                  )}
                </Grid>

                <Grid item xs={12}>
                  <Box className={classes.credentialsWrapper}>
                    <Box>
                      <Typography>Password</Typography>
                      <Typography>********</Typography>
                    </Box>
                    {editPassword === false && (
                      <Typography
                        onClick={() => {
                          setEditPassword(!editPassword);
                          setEditEmail(false);
                          setHasError(false);
                        }}
                        className={classes.editBtn}
                      >
                        Edit
                      </Typography>
                    )}
                  </Box>

                  {editPassword && (
                    <>
                      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <div className={classes.inputGroup}>
                          <div className={classes.labelWithIcon}>
                            <label>Password</label>
                            <IconButton
                              disableRipple={true}
                              onClick={(e) => setVisible(!visible)}
                            >
                              {!visible ? (
                                <VisibilityOffIcon />
                              ) : (
                                <VisibilityIcon />
                              )}
                            </IconButton>
                          </div>

                          <TextField
                            onChange={handleChange}
                            name="password"
                            label=""
                            variant="filled"
                            fullWidth
                            type={visible ? "text" : "password"}
                            error={hasError && !formData.password.length}
                            value={formData.password || ""}
                            helperText={
                              hasError && !formData.password.length
                                ? "Required"
                                : ""
                            }
                          />
                        </div>
                      </Grid>
                      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <div className={classes.inputGroup}>
                          <div className={classes.labelWithIcon}>
                            <label>Confirm Password</label>
                            <IconButton
                              disableRipple={true}
                              onClick={(e) => setVisible(!visible)}
                            >
                              {!visible ? (
                                <VisibilityOffIcon />
                              ) : (
                                <VisibilityIcon />
                              )}
                            </IconButton>
                          </div>

                          <TextField
                            onChange={handleChange}
                            name="confirm_password"
                            label=""
                            variant="filled"
                            fullWidth
                            type={visible ? "text" : "password"}
                            error={
                              (hasError && !formData.confirm_password.length) ||
                              (hasError &&
                                formData.password !== formData.confirm_password)
                            }
                            value={formData.confirm_password || ""}
                            helperText={
                              (hasError && !formData.confirm_password.length) ||
                              (hasError &&
                                formData.password !== formData.confirm_password)
                                ? "Password and confirm password should same"
                                : ""
                            }
                          />
                        </div>
                        <Box className={classes.updateBtnWrapper}>
                          <Typography
                            onClick={() => setEditPassword(false)}
                            className={classes.cancelBtn}
                          >
                            Cancel
                          </Typography>
                          <Button type="submit" className={classes.updateBtn}>
                            {isLoading ? "Processing" : "Update"}
                          </Button>
                        </Box>
                      </Grid>
                    </>
                  )}
                </Grid>
              </Grid>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isProcessing: state.auth0.isProcessing,
    status: state.auth0.status,
    message: state.auth0.message,
    isAuthenticated: state.auth0.isAuthenticated,
    user: state.auth0?.user,
    auth: state.auth0.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateEmail: (action, payload) => {
      return dispatch({ type: action, payload });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UpdateEmail);
