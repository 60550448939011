import {
  FORGOT_PASSWORD_IN_PROGRESS,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  DISMISS_STATUS_MESSAGE,
} from "./actionTypes";

import axios from "axios";
import utils from "../../../../helpers/utils";

const forgotPasswordAction = (url, data) => {
  return async (dispatch, getState) => {
    dispatch({
      type: FORGOT_PASSWORD_IN_PROGRESS,
    });

    if (!data.email) {
      dispatch({
        type: FORGOT_PASSWORD_ERROR,
        payload: {
          status: "error",
          message: "All fields are mandatory.",
        },
      });
    } else if (!utils.isValidFormat(data.email, "email")) {
      dispatch({
        type: FORGOT_PASSWORD_ERROR,
        payload: {
          status: "error",
          message: "Email format is invalid.",
        },
      });
    } else {
      try {
        let response = await axios({
          url,
          method: "POST",
          data,
        });
        if (response.data) {
          dispatch({
            type: FORGOT_PASSWORD_SUCCESS,
            payload: {
              status: "success",
              message: response.data,
            },
          });
        }
      } catch (error) {
        if (typeof error.response.data !== "undefined") {
          dispatch({
            type: FORGOT_PASSWORD_ERROR,
            payload: {
              status: "error",
              message: error.response.data,
            },
          });
        }
      }
    }

    setTimeout(() => {
      dispatch({
        type: DISMISS_STATUS_MESSAGE,
      });
    }, 10000);
  };
};

export default forgotPasswordAction;
