import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import { List, ListItem, ListItemText, Typography } from "@material-ui/core";
import { NavLink, withRouter } from "react-router-dom";
import Drawer from "@material-ui/core/Drawer";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import { makeStyles } from "@material-ui/core/styles";
import { useState } from "react";

const useStyles = makeStyles((theme) => ({
  h6: {
    color: theme.paragraphDark.color,
    fontWeight: 600,
    marginTop: "40px",
    marginLeft: "25px",
    fontSize: "22px",
  },
  underline: {
    height: "5px",
    width: "50px",
    backgroundColor: theme.palette.primary.main,
    marginLeft: "25px",
    marginTop: "10px",
    marginBottom: "40px",
  },
  closeIconWrapper: {
    textAlign: "right",
    padding: "10px 10px 0 0",
  },
  closeIcon: {},
  navLink: {
    color: "#333",
    margin: "0 32px",
    textDecoration: "none",
    [theme.breakpoints.down("lg")]: {
      margin: "0 10px",
    },
  },

  nav: {
    display: "flex",
    justifyContent: "center",
    menuIcon: {
      color: "red",
    },
  },
  button: {
    maxHeight: "48px",
    alignSelf: "center",
    marginLeft: "32px",
    marginRight: "32px",
    padding: "10px 50px",
    color: "#fff",
    "& span": {
      color: "#ffffff",
    },
  },
  navItemBordered: {
    borderLeft: `solid 1px ${theme.border.color}`,
    borderRight: `solid 1px ${theme.border.color}`,

    padding: "0 32px",
    height: "110px",
  },
  menuIcon: {
    color: "#fff",
  },
  menuIconDark: {
    color: "#000",
  },
}));

const MobileMenu = (props) => {
  const classes = useStyles();

  const [state, setState] = useState({ right: false });

  const toggleMenu = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  return (
    <List component="nav" className={classes.nav} aria-label="main navigation">
      <span className={classes.navLink} onClick={toggleMenu("right", true)}>
        <IconButton edge="start" aria-label="menu">
          <MenuIcon
            // className={
            //   props.navType === "" ? classes.menuIconDark : classes.menuIcon
            // }
            className={classes.menuIconDark}
            fontSize="large"
          />
        </IconButton>
      </span>

      <Drawer
        anchor="right"
        open={state.right}
        onClose={toggleMenu("right", false)}
      >
        <div className={classes.closeIconWrapper}>
          <CloseOutlinedIcon
            className={classes.closeIcon}
            onClick={toggleMenu("right", false)}
          />
        </div>
        <Typography variant="h6" className={classes.h6}>
          Menu
        </Typography>
        <div className={classes.underline}></div>
        <NavLink
          className={classes.navLink}
          onClick={toggleMenu("right", false)}
          to="/#types-of-care"
        >
          <ListItem button disableRipple>
            <ListItemText primary="Types of Care" />
          </ListItem>
        </NavLink>
        <NavLink
          className={classes.navLink}
          onClick={toggleMenu("right", false)}
          to="/#our-doctors"
        >
          <ListItem button disableRipple>
            <ListItemText primary="Our Doctors" />
          </ListItem>
        </NavLink>
        <NavLink
          className={classes.navLink}
          onClick={toggleMenu("right", false)}
          to="/#how-tova-works"
        >
          <ListItem button disableRipple>
            <ListItemText primary="How Tova Works" />
          </ListItem>
        </NavLink>

        <NavLink
          className={classes.navLink}
          onClick={toggleMenu("right", false)}
          to="/#what-we-dont-treat"
        >
          <ListItem button disableRipple>
            <ListItemText primary="What We Don't Treat" />
          </ListItem>
        </NavLink>
        <NavLink
          className={classes.navLink}
          onClick={toggleMenu("right", false)}
          to="/#faq"
        >
          <ListItem button disableRipple>
            <ListItemText primary="FAQ" />
          </ListItem>
        </NavLink>
        <NavLink
          className={classes.navLink}
          onClick={toggleMenu("right", false)}
          to="/about"
        >
          <ListItem button disableRipple>
            <ListItemText primary="About Us" />
          </ListItem>
        </NavLink>
        <NavLink
          className={classes.navLink}
          onClick={toggleMenu("right", false)}
          to="/individual/login"
        >
          <ListItem button disableRipple>
            <ListItemText primary="Patient Log In" />
          </ListItem>
        </NavLink>
        <NavLink
          className={classes.navLink}
          onClick={toggleMenu("right", false)}
          to="/doctor/login"
        >
          <ListItem button disableRipple>
            <ListItemText primary="Doctor Log In" />
          </ListItem>
        </NavLink>
        <NavLink
          className={classes.navLink}
          onClick={toggleMenu("right", false)}
          to="/individual/signup"
        >
          <ListItem button disableRipple>
            <ListItemText primary="Patient Sign Up" />
          </ListItem>
        </NavLink>
        <NavLink
          className={classes.navLink}
          onClick={toggleMenu("right", false)}
          to="/doctor/signup"
        >
          <ListItem button disableRipple>
            <ListItemText primary="Doctor Sign Up" />
          </ListItem>
        </NavLink>
      </Drawer>
    </List>
  );
};

export default withRouter(MobileMenu);
