import { SET_STRIPE_ACCOUNT_ID } from "../../../store/actions/actionTypes";
import {
  SIGNUP_ERROR,
  SIGNUP_IN_PROGRESS,
  SIGNUP_SUCCESS,
  GET_PRODUCT_SUCCESS,
  GET_PRODUCT_ERROR,
  PAYMENT_IN_PROGRESS,
  PAYMENT_SUCCESS,
  PAYMENT_ERROR,
  STEP_TWO_COMPLETE,
  UPDATE_PROCESSING_STATUS,
  UPDATE_ACCOUNT_INFO_IN_PROGRESS,
  UPDATE_ACCOUNT_INFO_SUCCESS,
  UPDATE_ACCOUNT_INFO_ERROR,
  UPDATE_AUTH_STATUS_SUCCESS,
  LOGOUT_USER_SUCCESS,
  DOCTOR_APPLY_IN_PROGRESS,
  DOCTOR_APPLY_SUCCESS,
  DOCTOR_APPLY_ERROR,
  LOGIN_IN_PROGRESS,
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  FORGOT_PASSWORD_IN_PROGRESS,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  RESET_PASSWORD_IN_PROGRESS,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  DISMISS_STATUS_MESSAGE,
} from "../actions/actionTypes";

const INIT = {
  stepOneDone: false,
  stepTwoDone: false,
  stepThreeDone: false,
  isProcessing: false,
  status: null,
  message: null,
  user: null,
  auth: null,
  product: null,
  isAuthenticated: false,
  loginScreenRoute: "",
  wasAuthenticated: false,
};

const auth0Reducer = (state = INIT, action) => {
  switch (action.type) {
    case SIGNUP_IN_PROGRESS:
      return { ...state, isProcessing: true };
    case SIGNUP_SUCCESS:
      return {
        ...state,
        isProcessing: false,
        user: action.payload.user,
        stepOneDone: true,
      };
    case SIGNUP_ERROR:
      return {
        ...state,
        isProcessing: false,
        status: action.payload.status,
        message: action.payload.message,
      };

    //Stripe payment
    case PAYMENT_IN_PROGRESS:
      return { ...state, isProcessing: true };
    case PAYMENT_SUCCESS:
      return {
        ...state,
        isProcessing: false,
        stepTwoDone: true,
      };
    case PAYMENT_ERROR:
      if (action.payload.code) {
        return {
          ...state,
          isProcessing: false,
          status: action.payload.status,
          message: action.payload.message,
          stepOneDone: false,
          stepTwoDone: false,
          stepThreeDone: false,
          user: null,
        };
      }
      return {
        ...state,
        isProcessing: false,
        status: action.payload.status,
        message: action.payload.message,
      };
    case UPDATE_PROCESSING_STATUS:
      return {
        ...state,
        isProcessing: action.payload.processing,
      };
    case STEP_TWO_COMPLETE:
      return {
        ...state,
        isProcessing: false,
        stepTwoDone: true,
      };

    //Stripe product
    case GET_PRODUCT_SUCCESS:
      return {
        ...state,
        isProcessing: false,
        product: action.payload.product,
      };
    case GET_PRODUCT_ERROR:
      return {
        ...state,
        status: "error",
        product: null,
      };

    case UPDATE_ACCOUNT_INFO_IN_PROGRESS:
      return { ...state, isProcessing: true };
    case UPDATE_ACCOUNT_INFO_SUCCESS:
      return {
        ...state,
        isProcessing: false,
        user: action.payload.user,
        auth: action.payload.auth,
        wasAuthenticated: true,
        stepTwoDone: true,
      };
    case UPDATE_AUTH_STATUS_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        stepOneDone: false,
        stepTwoDone: false,
      };
    case UPDATE_ACCOUNT_INFO_ERROR:
      return {
        ...state,
        isProcessing: false,
        status: action.payload.status,
        message: action.payload.message,
      };

    // Doctor apply
    case DOCTOR_APPLY_IN_PROGRESS:
      return { ...state, isProcessing: true };
    case DOCTOR_APPLY_SUCCESS:
      return {
        ...state,
        isProcessing: false,
        user: action.payload.user,
        auth: action.payload.auth,
        isAuthenticated: true,
        stepOneDone: false,
        stepTwoDone: false,
      };
    case DOCTOR_APPLY_ERROR:
      return {
        ...state,
        isProcessing: false,
        status: action.payload.status,
        message: action.payload.message,
      };

    case LOGOUT_USER_SUCCESS:
      return {
        ...state,
        isProcessing: false,
        user: null,
        auth: null,
        isAuthenticated: false,
        loginScreenRoute: action.payload.loginScreenRoute,
      };

    // Login
    case LOGIN_IN_PROGRESS:
      return { ...state, isProcessing: true };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isProcessing: false,
        user: action.payload.user,
        auth: action.payload.auth,
        isAuthenticated: true,
        stepOneDone: false,
        stepTwoDone: false,
      };

    case LOGIN_ERROR:
      return {
        ...state,
        isProcessing: false,
        status: action.payload.status,
        message: action.payload.message,
      };

    // Forgot password
    case FORGOT_PASSWORD_IN_PROGRESS:
      return { ...state, isProcessing: true };
    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        isProcessing: false,
        status: action.payload.status,
        message: action.payload.message,
      };

    case FORGOT_PASSWORD_ERROR:
      return {
        ...state,
        isProcessing: false,
        status: action.payload.status,
        message: action.payload.message,
      };

    // Reset forgotten password
    case RESET_PASSWORD_IN_PROGRESS:
      return { ...state, isProcessing: true };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        isProcessing: false,
        user: action.payload.user,
        auth: action.payload.auth,
        isAuthenticated: true,
        status: action.payload.status,
        message: action.payload.message,
      };
    case RESET_PASSWORD_ERROR:
      return {
        ...state,
        isProcessing: false,
        status: action.payload.status,
        message: action.payload.message,
      };
    case SET_STRIPE_ACCOUNT_ID:
      return {
        ...state,
        user: {
          ...state.user,
          stripeAccountId: action.payload.stripeAccountId,
        },
      };

    //Common action to dismis snack alert
    case DISMISS_STATUS_MESSAGE:
      return {
        ...state,
        isProcessing: false,
        status: "",
        message: "",
        loginScreenRoute: "",
      };
    default:
      return state;
  }
};

export default auth0Reducer;
