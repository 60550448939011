import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core/styles";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { theme } from "./theme/styles";

// Redux Stuff
import thunk from "redux-thunk";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import storageSession from "redux-persist/lib/storage/session";
import hardSet from "redux-persist/lib/stateReconciler/hardSet";
import { encryptTransform } from "redux-persist-transform-encrypt";
import rootReducer from "./store/reducers/rootReducer";
import GlobalScroller from "./components/GlobalScroller";

const persistConfig = {
  key: "bucket",
  storage: storageSession,
  stateReconciler: hardSet,
  transforms: [
    encryptTransform({
      secretKey: "asjdfpioasnf;welnf;waelfinawefa;ienfra;we;",
      onError: function (error) {
        //console.log( error )
      },
    }),
  ],
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

let store = createStore(persistedReducer, applyMiddleware(thunk));
let persistor = persistStore(store);

ReactDOM.render(
  <BrowserRouter>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <GlobalScroller>
            <App />
          </GlobalScroller>
        </PersistGate>
      </Provider>
    </ThemeProvider>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
