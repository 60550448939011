import {
  PAYMENT_IN_PROGRESS,
  PAYMENT_SUCCESS,
  PAYMENT_ERROR,
  DISMISS_STATUS_MESSAGE,
} from "./actionTypes";

import axios from "axios";
import utils from "../../../../helpers/utils";

const stripePaymentAction = (url, data, cb = null) => {
  return async (dispatch, getState) => {
    dispatch({
      type: PAYMENT_IN_PROGRESS,
    });

    if (!data.cardHolderName) {
      dispatch({
        type: PAYMENT_ERROR,
        payload: {
          status: "error",
          message: "Cardholder name is required.",
        },
      });
    } else if (!data.paymentMethodId) {
      dispatch({
        type: PAYMENT_ERROR,
        payload: {
          status: "error",
          message: "Payment method error. Please try again.",
        },
      });
    }
    // else if (!data.authId) {
    //   dispatch({
    //     type: PAYMENT_ERROR,
    //     payload: {
    //       status: "error",
    //       message: "An error has occured while procesing your request.",
    //     },
    //   });
    // }
    // else if (!data.postalCode) {
    //   dispatch({
    //     type: PAYMENT_ERROR,
    //     payload: {
    //       status: "error",
    //       message: "Postal code is required.",
    //     },
    //   });
    // }
    else if (!data.email) {
      dispatch({
        type: PAYMENT_ERROR,
        payload: {
          status: "error",
          message: "Email address is required.",
        },
      });
    } else if (!utils.isValidFormat(data.email, "email")) {
      dispatch({
        type: PAYMENT_ERROR,
        payload: {
          status: "error",
          message: "Email format is invalid.",
        },
      });
    } else {
      try {
        let response = await axios({
          url,
          method: "POST",
          data,
        });
        if (response.data.status === "success") {
          if (cb) {
            cb(response.data);
          }

          dispatch({
            type: PAYMENT_SUCCESS,
            payload: response.data,
          });
        }
      } catch (error) {
        if (cb) {
          cb(null);
        }
        dispatch({
          type: PAYMENT_ERROR,
          payload: {
            status: "error",
            code: error.response.status,
            message: error.response.data,
          },
        });
      }
    }

    setTimeout(() => {
      dispatch({
        type: DISMISS_STATUS_MESSAGE,
      });
    }, 10000);
  };
};

export default stripePaymentAction;
