import { NavLink } from "react-router-dom";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";

import Footer from "../components/Footer";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100% !important",
    paddingTop: "150px",

    "& .auc-text-wrapper a": {
      color: theme.palette.primary.main,
    },
  },
  mainHeading: {
    fontSize: "65px",
    fontWeight: 700,
    lineHeight: "83px",

    [theme.breakpoints.down("md")]: {
      fontSize: "45px",
      marginTop: theme.spacing(3),
      lineHeight: "65px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "35px",
      lineHeight: "30px",
      marginTop: theme.spacing(10),
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "25px",
      lineHeight: "20px",
      marginTop: theme.spacing(3),
    },
  },
  smallHeading: {
    fontWeight: "bold",
    marginTop: "60px",
    marginBottom: "25px",
    fontSize: "25px",
  },
  extraSmallHeading: {
    fontWeight: "bold",
    marginTop: "10px",
    fontSize: "20px",
    lineHeight: "30px",
  },
}));

function CookiePolicy(props) {
  document.body.style.background = "#fff";

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Container maxWidth="md">
        <div className={`${classes.termsWrapper} auc-text-wrapper`}>
          <Typography
            align="center"
            className={classes.mainHeading}
            variant="h1"
          >
            Cookie Policy
          </Typography>
          <Typography align="center">
            Date last modified: December 16, 2021
          </Typography>
          <p>
            <br />
            <br />
            <br />
          </p>
          <p>
            This Tova Medical LLC. cookie policy ("Cookie Policy") explains what
            cookies are and how we use them on the Tova Medical website (
            <NavLink className={classes.navLink} to="/">
              www.TovaMedical.com
            </NavLink>
            ) and dependent pages (the "Site"). It supplements our Privacy
            Statement, which you can{" "}
            <NavLink className={classes.navLink} to="/privacy">
              <strong>find here</strong>
            </NavLink>
            . By using our Site, you agree with our use of cookies as described
            in this Cookie Policy.
          </p>
          <p> </p>

          <Typography className={classes.smallHeading} variant="h3">
            About cookies
          </Typography>

          <p> </p>
          <p>
            Cookies are small bits of data that a site can send to a computer or
            device browser. Cookies are used to help sites recognize users and
            keep track of their preferences. Session cookies are deleted from
            your computer or device when you close your browser. Persistent
            cookies remain on your device until they expire or are deleted.
          </p>
          <p> </p>

          <Typography className={classes.smallHeading} variant="h3">
            How and why we use cookies on the Site
          </Typography>

          <p> </p>
          <p>
            We use both session and persistent cookies, but most of our cookies
            are session-based or expire when you log out of our service. Some
            locally stored cookies we use for specific features do not expire
            but can be deleted by you.
          </p>
          <p> </p>

          <Typography className={classes.smallHeading} variant="h3">
            We use cookies to improve your experience of our service by:
          </Typography>

          <p> </p>
          <p>
            Remembering your personal settings, privacy preferences, including
            collecting your login information.
          </p>
          <p>
            Enabling functionality and personalization not available without
            cookies.
          </p>
          <p>
            Delivering the most relevant information to you about Tova Medical,
            including product updates, upgrade information, new content, and
            special offers.
          </p>
          <p>
            Improving the content and performance of our Site by tracking visits
            to discover how users navigate our service (this data is aggregated
            across all of our users and is anonymous).
          </p>
          <p>What cookies we use on the site</p>
          <p> </p>

          <Typography className={classes.smallHeading} variant="h3">
            Below is an overview of the types of Cookies we and third parties
            may use to collect Personal Information.
          </Typography>

          <p> </p>
          <p>
            Operational Cookies necessary for the Site: Some Cookies are
            strictly necessary to make our Site available to you. You can block
            these Cookies by changing your browser settings but, because they
            are strictly necessary for the operation of the Site, we cannot
            provide you with the Site without this type of Cookies.
          </p>
          <p> </p>
          <p>
            Functional Cookies: Functional Cookies let us operate certain
            functions of the Site according to your preferences. Deletion of
            these types of Cookies will result in limited functionality of the
            Site and less personalized experience.
          </p>
          <p> </p>
          <p>
            Analytical Cookies: We use Cookies for website analytics purposes in
            order to operate, maintain and improve our Site. We may use our own
            analytics Cookies or use third party analytics providers such as
            Google Analytics and Google Tag Manager to collect and process
            certain analytics data on our behalf. These third parties may also
            collect information about your use of other websites, apps, and
            online resources. For instance, Google Analytics helps us understand
            how users engage with the Site. Like many services, Google Analytics
            uses first-party Cookies to track user interactions. This
            information is used to compile reports and to help us improve our
            Site. The reports disclose website trends without identifying
            individual visitors.{" "}
          </p>
          <p> </p>
          <p>
            Advertising Cookies: We work with third-party advertising partners
            to show you ads that we think may interest you, including about Tova
            Medical as well as third party websites and apps. To do so, we and
            our advertising partners may place and access Cookies through our
            Site and otherwise collect or access Personal Information collected
            over time and across different online services. We do not permit the
            collection of Personally Identifiable Information by third-party
            advertisers about you while you are on our site. Our advertising
            partners may also collect information about your use of other
            websites, apps, and online resources.
          </p>
          <p>
            Where required by applicable law, we obtain your consent to use
            Cookies.
          </p>
          <p> </p>

          <Typography className={classes.smallHeading} variant="h3">
            How you can manage and disable cookies
          </Typography>

          <p> </p>
          <p>
            Disabling or rejecting cookies will limit the functionality and
            convenience of our service. You can set your browser to notify you
            when you receive cookies and allow you to reject them. You can also
            delete cookies already on your computer, and set most web browsers
            to prevent new cookies from being set. However, if you delete
            cookies and/or prevent new ones, you will have to reenter
            preferences and settings every time you visit our Site, and some
            services and functionality may not work.
          </p>
          <p> </p>

          <Typography className={classes.smallHeading} variant="h3">
            To learn more about how to control and/or delete your cookies, visit{" "}
            <a href="www.aboutcookies.org">www.aboutcookies.org</a>.
          </Typography>

          <p> </p>
          <p>
            More information about how tova Medical sets third-party cookies is
            contained in our Privacy Statement.
          </p>
          <p> </p>
        </div>
      </Container>
      <div component="div" style={{ marginTop: "150px" }}></div>
      {/* Section 2 */}
      <Footer />
      {/* /Section 2 */}
    </div>
  );
}

export default CookiePolicy;
