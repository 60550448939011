import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Container, Grid } from "@material-ui/core";
import { connect } from "react-redux";
import utils from "../helpers/utils";
import Error from "./Error";
import StatusTimeline from "../components/StatusTimeline";
import { Redirect } from "react-router-dom";
import AppStoreIcon from "../components/Icons/AppStoreIcon";
import GooglePlayIcon from "../components/Icons/GooglePlayIcon";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "calc(100vh - 110px)",
    paddingTop: theme.spacing(30),
  },
  contentSection: {
    boxShadow: theme.section.boxShadow,
    borderRadius: theme.section.borderRadius,
    backgroundColor: "#fff",
    padding: theme.spacing(4),
  },
  timelineWrapper: {
    marginTop: theme.spacing(4),
    // marginBottom: theme.spacing(4),
  },
  gridWrapper: {
    // justifyContent: "center",
  },
  appStoreLogoContainer: {
    textAlign: "center",
  },
  appStoreLogo: {
    maxWidth: "150px",
    marginRight: "5px",
  },
  appstoreIconsWraper: {
    marginTop: "32px",
  },
}));

const DoctorApplicationStatus = (props) => {
  document.body.style.backgroundColor = "#F7F7F7";

  const classes = useStyles();

  const { user } = props;

  const role = utils.getRole(user);

  const doctorApplicationId = user?.doctorApplicationId;
  const doctorId = user?.doctorId;

  if (role !== "doctor") {
    return <Error code="404" />;
  } else if (doctorApplicationId === null && doctorId === null) {
    return <Redirect to="/apply" />;
  }

  return (
    <Container maxWidth="lg" className={classes.root}>
      <Grid
        container
        direction="row"
        justify="center"
        className={classes.gridWrapper}
      >
        <Grid item xl={8} lg={8} md={10} sm={12}>
          <Box component="div" className={classes.contentSection}>
            <Typography className={classes.fontBold} variant="h5">
              {user && user.isApplicationAccepted === false
                ? "We're processing your information"
                : "You've been appoved!"}
            </Typography>
            <div className={classes.timelineWrapper}>
              <StatusTimeline user={user} />
            </div>
          </Box>
          {/* App store icons */}
          <div className={classes.appstoreIconsWraper}>
            <div className={classes.appStoreLogoContainer}>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://apps.apple.com/in/app/tova-doctor/id376771144"
              >
                <AppStoreIcon style={{ width: "150px", marginRight: "4px" }} />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://play.google.com/store/apps/details?id=com.tovamedical.doctor"
              >
                <GooglePlayIcon style={{ width: "150px" }} />
              </a>
            </div>
          </div>
          {/* /App store icons */}
        </Grid>
      </Grid>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth0.user,
  };
};

export default connect(mapStateToProps)(DoctorApplicationStatus);
