import React, { useState, useRef } from "react";
import {
  Typography,
  TextField,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  Button,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useAuth0 } from "@auth0/auth0-react";
import Error from "./Error";
import { Box, Grid, Tooltip, IconButton } from "@material-ui/core";
import utils from "../helpers/utils";
import Loading from "../components/Loading";
import { Redirect } from "react-router";
import EditIcon from "@material-ui/icons/Edit";
import { KeyboardDatePicker } from "@material-ui/pickers";
import axios from "axios";
import Alert from "../components/Alert";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import AlertMessage from "@material-ui/lab/Alert";
import { AlertTitle } from "@material-ui/lab";
import AddIcon from "@material-ui/icons/AddOutlined";
import TrashIcon from "@material-ui/icons/DeleteOutlineOutlined";
import CircularProgressBar from "../components/CircularProgressBar";

const Person = (props) => {
  document.body.style.backgroundColor = "#F7F7F7";
  const { person, states } = props;

  const useStyles = makeStyles((theme) => ({
    root: {
      height: "calc(100vh - 110px)",
      paddingTop: theme.spacing(30),
    },
    heading: {
      fontSize: "16px",
      color: "#939393",
    },
    contentSection: {},

    divider: {
      height: "4px",
      marginTop: theme.spacing(1),
      width: theme.spacing(5),
      backgroundColor: theme.palette.primary.main,
    },
    headingWrapper: {
      display: "flex",
      alignItems: "center",
    },
    navLink: {
      color: "#000",
    },

    table: {
      minWidth: 1000,
    },
    tableHeading: {
      fontWeight: "bold",
      letterSpacing: "1px",
    },

    textCenter: {
      textAlign: "center !important",
    },
    textRight: {
      textAlign: "right !important",
    },
    capitalize: {
      textTransform: "capitalize",
    },
    subHeading: {
      fontWeight: "bold",
      fontSize: "20px",
    },
    sectionTopMargin: {
      //   marginTop: theme.spacing(4),
      //   marginBottom: theme.spacing(4),
    },
    verified: { color: "green", textTransform: "capitalize" },
    unverified: { color: "red", textTransform: "capitalize" },
    submitBtnWrapper: {
      marginTop: theme.spacing(5),
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
    },

    sectionHeading: {
      fontWeight: 600,
    },
    iconWrapper: {
      paddingRight: theme.spacing(1),
    },

    item: {
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "space-between",
      backgroundColor: "#eeeeee61",
      padding: theme.spacing(1),
      marginBottom: theme.spacing(2),
    },
    innerWrapper: {
      display: "flex",
    },
    bankName: {
      fontSize: "22px",
      fontWeight: 600,
      display: "flex",
      alignItems: "center",
    },
    routingNumber: {
      marginRight: theme.spacing(2),
    },
    status: {
      fontSize: "14px",
      marginLeft: theme.spacing(1),
    },
    success: {
      color: "green",
    },
    alertTitle: {
      fontWeight: "bold",
    },
    alert: {
      marginBottom: theme.spacing(3),
    },
    smallHeading: {
      fontWeight: "bold",
      fontSize: "1.2rem",
    },
    inputGroup: {
      marginBottom: theme.spacing(4),
    },
    p: {
      marginTop: theme.spacing(1),
    },
    label: {
      display: "block",
    },
    addFileLink: {
      color: theme.palette.primary.main,
      display: "flex",
      alignItems: "center",
      fontWeight: 700,
      cursor: "pointer",
    },
    marginTop: {
      marginTop: theme.spacing(6),
    },
    icon: {
      fontSize: "25px",
    },
    inputField: {
      display: "none",
    },
    buttonWrapper: {
      marginTop: theme.spacing(3),
      textAlign: "right",
    },
    fileInfo: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      backgroundColor: "#f5f5f5",
      padding: theme.spacing(1),
      borderRadius: "3px",
    },
    statusMessage: {
      color: "red",
      fontSize: "0.9rem",
    },
    error: {
      backgroundColor: "#fdecea",
      border: "solid 1px red",
    },
    infoWrapper: {
      display: "flex",
      alignItems: "flex-start",
      flexDirection: "column",
    },
    fieldInfoMargin: {
      marginBottom: theme.spacing(2),
    },
    fileName: {
      fontWeight: "bold",
      fontSize: "0.9rem",
    },
    smallFont: {
      fontSize: "12px",
    },
    trashIcon: {
      color: "#888",
      cursor: "pointer",
      "&:hover": {
        color: "#333",
      },
    },
    progressButtonWrapper: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    uploadingLabel: {
      marginLeft: theme.spacing(2),
    },
    bankIcon: {
      fontSize: "50px",
      color: "#666",
    },
    cardNumber: {
      position: "relative",
      left: "-15px",
      fontSize: "12px",
    },
    linkWrapper: {
      display: "flex",
      justifyContent: "center",
      marginBottom: theme.spacing(2),
      "& a": {
        textDecoration: "none",
        margin: "0 5px",
      },
      "& .MuiSvgIcon-root": {
        fontSize: "23px",
      },
    },
  }));

  const frontInputRef = useRef();
  const backInputRef = useRef();

  const classes = useStyles();
  const verifications = utils.getVerificationTypes(person);

  const { isAuthenticated, user, error } = useAuth0();
  const isAdmin = utils.isAdmin(user);
  const [status, setStatus] = useState(false);
  const [message, setMessage] = useState(false);
  const [alertKey, setAlertKey] = useState("");
  const [isProcessing, setIsProcessing] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [frontError, setFrontError] = useState(false);
  const [backError, setBackError] = useState(false);
  const [progress, setProgress] = useState(0);
  const [formData, setFormData] = useState({
    firstName: person?.first_name,
    lastName: person?.last_name,
    phone: "(907) 895-5010",
    email: person?.email,
    dob: `${person?.dob?.month}/0${person?.dob?.day}/${person?.dob?.year}`,
    ssnLast4: "1234",
    id_number: "",
    address: {
      city: person?.address?.city,
      line1: person?.address?.line1,
      postal_code: person?.address?.postal_code,
      state: person?.address?.state,
      country: person?.address?.country,
    },
  });

  const [images, setImages] = useState({
    front: null,
    back: null,
    documents: null,
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    let data = formData;

    if (isPersonValidated(data)) {
      data.dob = utils.getFormattedDate(data.dob, "MM/DD/YYYY");
      data.address.state = states.find(
        (s) => Number(s.id) === Number(data?.address?.state)
      )?.name;
      setIsProcessing(true);

      //   data.dob = utils.getFormattedDate(data.dob, "MM/DD/YYYY");

      try {
        await axios({
          url: `${process.env.REACT_APP_SERVER_URL}/account/${person.account}/person/${person.id}`,
          method: "put",
          data,
        });

        setIsProcessing(false);

        props.pullAccountPersons(person.account);
        setEditMode(false);

        // props.history.push("/get/paid");
      } catch (error) {
        setIsProcessing(false);
        setStatus("error");
        setMessage(error.response.data.message);
      }
    }
  };
  const onClose = () => {
    setStatus("");
    setMessage("");
  };

  const documentsUploading = async (e) => {
    e.preventDefault();

    const obj = utils.getVerificationTypes(person);

    if (obj.identityVerification) {
      if (!images.front || !images.back) {
        setAlertKey(utils.uuid());
        setStatus("error");
        setMessage("Please select required documents to upload");
        return;
      }
    }

    setIsProcessing(true);

    let data = new FormData();

    data.append("front", images.front);
    data.append("back", images.back);

    for (let index in images.documents) {
      data.append(`documents`, images.documents[index]);
    }

    try {
      await axios({
        url: `${process.env.REACT_APP_SERVER_URL}/account/${person.account}/person/${person.id}/identity/verification`,
        method: "post",
        data: data,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          let percent = Math.round((loaded / total) * 100);
          setProgress(percent);
        },
      });

      setProgress(100);
      setIsProcessing(false);
      //   setStatus(response.data.status);
      //   setMessage(response.data.message);
      //   props.history.push("/get/paid");

      props.pullAccountPersons(person.account);
    } catch (error) {
      setIsProcessing(false);
      if (typeof error.response !== "undefined") {
        setStatus(error.response.data.status);
        setMessage(error.response.data.message);
      }
    }
  };

  const isPersonValidated = (args) => {
    for (let data in args) {
      if (typeof args[data] !== "undefined" && !args[data]) {
        for (let key in args[data]) {
          if (typeof key !== "undefined" && !args[data][key]) {
            setHasError(true);
            return false;
          }
        }
        setHasError(true);
        return false;
      }
    }
    return true;
  };

  if (isAuthenticated && props.isProcessing) {
    return <Loading />;
  } else if (isAuthenticated && props.isMember === false && !isAdmin) {
    return <Redirect to="/signup" />;
  }

  if (error) {
    return <Error code="500" />;
  }

  return (
    <Grid container direction="row">
      <Grid item xs={12}>
        <Box component="div" className={classes.contentSection}>
          <div className={classes.contentWrapper}>
            {editMode ? (
              <>
                <form onSubmit={handleSubmit}>
                  {status ? <Alert status={status} message={message} /> : null}
                  <Grid container spacing={3}>
                    <Grid
                      item
                      xs={12}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "32px",
                      }}
                    >
                      <div className={classes.sectionHeading}>
                        <Typography
                          className={`${classes.subHeading} ${classes.sectionTopMargin}`}
                          variant="h6"
                        >
                          <span className={classes.capitalize}>
                            {person?.relationship?.title || "Representative"}
                          </span>{" "}
                          Details
                          <div className={classes.divider} />
                        </Typography>
                      </div>

                      <div className={classes.textRight}>
                        <Tooltip
                          title="Edit Details"
                          onClick={() => setEditMode(false)}
                        >
                          <IconButton>
                            <CloseOutlinedIcon />
                          </IconButton>
                        </Tooltip>
                      </div>
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                      <div className={classes.inputGroup}>
                        <label className={classes.label}>First Name</label>
                        <TextField
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              [e.target.name]: e.target.value,
                            })
                          }
                          name="firstName"
                          label=""
                          variant="filled"
                          fullWidth
                          error={hasError && !formData.firstName.length}
                          value={formData.firstName}
                          helperText={
                            hasError && !formData.firstName.length
                              ? "Required"
                              : ""
                          }
                        />
                      </div>
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                      <div className={classes.inputGroup}>
                        <label className={classes.label}>Last Name</label>
                        <TextField
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              [e.target.name]: e.target.value,
                            })
                          }
                          name="lastName"
                          label=""
                          variant="filled"
                          fullWidth
                          error={hasError && !formData.lastName.length}
                          value={formData.lastName}
                          helperText={
                            hasError && !formData.lastName.length
                              ? "Required"
                              : ""
                          }
                        />
                      </div>
                    </Grid>

                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                      <div className={classes.inputGroup}>
                        <label className={classes.label}>Phone Number</label>
                        <TextField
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              [e.target.name]: utils.formatPhone(
                                e.target.value,
                                "stripe"
                              ),
                            });
                          }}
                          name="phone"
                          label=""
                          variant="filled"
                          fullWidth
                          error={hasError && !formData.phone.length}
                          value={formData.phone}
                          helperText={
                            hasError && !formData.phone.length ? "Required" : ""
                          }
                        />
                      </div>
                    </Grid>

                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                      <div className={classes.inputGroup}>
                        <label className={classes.label}>Date of birth</label>
                        <KeyboardDatePicker
                          autoOk
                          className={classes.aucdatePicker}
                          disableFuture
                          fullWidth
                          inputVariant={"filled"}
                          variant="inline"
                          name="dob"
                          format="DD/MM/YYYY"
                          views={["year", "month", "date"]}
                          onChange={(date) => {
                            setFormData({
                              ...formData,
                              dob: date,
                            });
                          }}
                          error={hasError && !formData?.dob?.length}
                          value={formData?.dob}
                          helperText={
                            hasError && !formData?.dob?.length ? "Required" : ""
                          }
                        />
                      </div>
                    </Grid>

                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                      <div className={classes.inputGroup}>
                        <label className={classes.label}>
                          SSN Last 4 Digits
                        </label>
                        <TextField
                          onChange={(e) => {
                            let last4 = e.target.value.replace(/\D/g, "");

                            if (last4.length < 5) {
                              setFormData({
                                ...formData,
                                [e.target.name]: e.target.value,
                              });
                            }
                          }}
                          name="ssnLast4"
                          label=""
                          variant="filled"
                          fullWidth
                          error={hasError && !formData.ssnLast4.length}
                          value={formData.ssnLast4}
                          helperText={
                            hasError && !formData.ssnLast4.length
                              ? "Required"
                              : ""
                          }
                        />
                      </div>
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                      <div className={classes.inputGroup}>
                        <label className={classes.label}>ID Number</label>
                        <TextField
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              [e.target.name]: e.target.value.replace(
                                /\D/g,
                                ""
                              ),
                            });
                          }}
                          name="id_number"
                          label=""
                          variant="filled"
                          fullWidth
                          error={hasError && !formData.id_number.length}
                          value={formData.id_number}
                          helperText={
                            hasError && !formData.id_number.length
                              ? "Required"
                              : ""
                          }
                        />
                      </div>
                    </Grid>
                  </Grid>
                  <Typography
                    className={`${classes.subHeading} ${classes.sectionTopMargin}`}
                    variant="h6"
                  >
                    Location Details
                  </Typography>
                  <Grid container spacing={3}>
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                      <div className={classes.inputGroup}>
                        <label className={classes.label}>Address Line</label>
                        <TextField
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              address: {
                                ...formData.address,
                                [e.target.name]: e.target.value,
                              },
                            });
                          }}
                          name="line1"
                          label=""
                          variant="filled"
                          fullWidth
                          error={hasError && !formData.address.line1.length}
                          value={formData.address.line1}
                          helperText={
                            hasError && !formData.address.line1.length
                              ? "Required"
                              : ""
                          }
                        />
                      </div>
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                      <div className={classes.inputGroup}>
                        <label className={classes.label}>City</label>
                        <TextField
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              address: {
                                ...formData.address,
                                [e.target.name]: e.target.value,
                              },
                            });
                          }}
                          name="city"
                          label=""
                          variant="filled"
                          fullWidth
                          error={hasError && !formData.address.city.length}
                          value={formData.address.city}
                          helperText={
                            hasError && !formData.address.city.length
                              ? "Required"
                              : ""
                          }
                        />
                      </div>
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                      <div className={classes.inputGroup}>
                        <label className={classes.label}>State</label>

                        <FormControl
                          variant="filled"
                          fullWidth
                          className={classes.formControl}
                        >
                          <Select
                            MenuProps={{
                              classes: { paper: classes.menuPaper },
                            }}
                            value={formData?.address?.state}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                address: {
                                  ...formData.address,
                                  state: e.target.value,
                                },
                              })
                            }
                            name="state"
                            error={hasError && !formData?.address?.state}
                          >
                            {states.map((item) => (
                              <MenuItem
                                key={`states-${item.id}`}
                                value={item.id}
                              >
                                {item.name}
                              </MenuItem>
                            ))}
                          </Select>
                          {hasError && !formData.licStateId ? (
                            <FormHelperText>Required</FormHelperText>
                          ) : null}
                        </FormControl>
                      </div>
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                      <div className={classes.inputGroup}>
                        <label className={classes.label}>Country</label>
                        <TextField
                          readOnly
                          label=""
                          variant="filled"
                          fullWidth
                          value={"United States"}
                        />
                      </div>
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                      <div className={classes.inputGroup}>
                        <label className={classes.label}>Postal Code</label>
                        <TextField
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              address: {
                                ...formData.address,
                                [e.target.name]: e.target.value,
                              },
                            });
                          }}
                          name="postal_code"
                          label=""
                          variant="filled"
                          fullWidth
                          error={
                            hasError && !formData?.address?.postal_code?.length
                          }
                          value={formData.address.postal_code}
                          helperText={
                            hasError && !formData?.address?.postal_code?.length
                              ? "Required"
                              : ""
                          }
                        />
                      </div>
                    </Grid>
                  </Grid>
                  <div className={classes.submitBtnWrapper}>
                    <Button
                      variant="contained"
                      type="submit"
                      color="primary"
                      disabled={isProcessing}
                    >
                      {isProcessing ? "Processing" : "Update Person"}
                    </Button>
                  </div>
                </form>
              </>
            ) : (
              <Grid container spacing={4}>
                <Grid
                  item
                  xs={12}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "32px",
                  }}
                >
                  <div className={classes.headingWrapper}>
                    <Typography
                      className={`${classes.subHeading} ${classes.sectionTopMargin}`}
                      variant="h6"
                    >
                      <span className={classes.capitalize}>
                        {person?.relationship?.title || "Representative"}
                      </span>{" "}
                      Details
                      <div className={classes.divider} />
                    </Typography>
                  </div>

                  <div className={classes.textRight}>
                    <Tooltip
                      title="Edit Details"
                      onClick={() => setEditMode(true)}
                    >
                      <IconButton>
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <form onSubmit={documentsUploading}>
                    {!verifications.identityVerification ? null : (
                      <>
                        <AlertMessage
                          className={classes.alert}
                          severity="error"
                        >
                          <AlertTitle className={classes.alertTitle}>
                            Verification required
                          </AlertTitle>
                          Please upload the required documents to complete the
                          verification process.
                        </AlertMessage>
                        <div>
                          {status && message ? (
                            <Alert
                              key={alertKey}
                              onClose={onClose}
                              status={status}
                              message={message}
                            />
                          ) : null}
                        </div>
                      </>
                    )}

                    <div>
                      {!verifications.identityVerification ? null : (
                        <>
                          <Grid container>
                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                              <div className={classes.inputGroup}>
                                <Typography
                                  className={classes.smallHeading}
                                  variant="h6"
                                >
                                  Identity Verification
                                </Typography>
                                <p className={classes.p}>
                                  The front and back of an identifying document,
                                  either a passport or local ID card. The
                                  uploaded file needs to be a color image
                                  (smaller than 8,000px by 8,000px), in JPG or
                                  PNG, or PDF format, and less than 10 MB in
                                  size.
                                </p>
                              </div>
                            </Grid>
                            <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
                              {images.front ? (
                                <div className={classes.fieldInfoWrapper}>
                                  <div
                                    className={`${classes.fileInfo} ${
                                      frontError ? classes.error : ""
                                    }`}
                                  >
                                    <div className={classes.infoWrapper}>
                                      <Typography className={classes.fileName}>
                                        {images.front.name}
                                      </Typography>
                                      <div className={classes.smallFont}>
                                        {utils.getFileSize(images.front)}
                                      </div>
                                    </div>
                                    <div className={classes.action}>
                                      <TrashIcon
                                        onClick={(e) => {
                                          setFrontError(null);
                                          setImages({
                                            ...images,
                                            front: null,
                                          });
                                        }}
                                        className={classes.trashIcon}
                                      />
                                    </div>
                                  </div>
                                  {frontError ? (
                                    <Typography
                                      className={`${classes.statusMessage} ${classes.errorMessage}`}
                                    >
                                      {frontError}
                                    </Typography>
                                  ) : null}
                                </div>
                              ) : (
                                <div className={classes.fielGroup}>
                                  <span
                                    className={classes.addFileLink}
                                    onClick={(e) =>
                                      frontInputRef.current.click()
                                    }
                                  >
                                    <AddIcon className={classes.icon} /> Select
                                    document front
                                  </span>
                                  <input
                                    type="file"
                                    ref={frontInputRef}
                                    className={classes.inputField}
                                    onChange={(e) => {
                                      console.log(
                                        e.target.files[0],
                                        "see file"
                                      );
                                      const file = e.target.files[0];
                                      const isValidSize =
                                        utils.hasValidSize(file);
                                      const isValidType =
                                        utils.isValidFile(file);

                                      if (!isValidSize) {
                                        setFrontError(
                                          "File size should be under 10 MB"
                                        );
                                      } else if (!isValidType) {
                                        setFrontError(
                                          "Only jpg, png & pdf files are accepted"
                                        );
                                      } else {
                                        setFrontError(null);
                                      }

                                      setImages({
                                        ...images,
                                        front: file,
                                      });
                                    }}
                                  />
                                </div>
                              )}
                            </Grid>
                            <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
                              {images.back ? (
                                <div className={classes.fieldInfoWrapper}>
                                  <div
                                    className={`${classes.fileInfo} ${
                                      backError ? classes.error : ""
                                    }`}
                                  >
                                    <div className={classes.infoWrapper}>
                                      <Typography className={classes.fileName}>
                                        {images.back.name}
                                      </Typography>
                                      <div className={classes.smallFont}>
                                        {utils.getFileSize(images.back)}
                                      </div>
                                    </div>
                                    <div className={classes.action}>
                                      <TrashIcon
                                        onClick={(e) => {
                                          setBackError(null);
                                          setImages({
                                            ...images,
                                            back: null,
                                          });
                                        }}
                                        className={classes.trashIcon}
                                      />
                                    </div>
                                  </div>
                                  {backError ? (
                                    <Typography
                                      className={`${classes.statusMessage} ${classes.errorMessage}`}
                                    >
                                      {backError}
                                    </Typography>
                                  ) : null}
                                </div>
                              ) : (
                                <div className={classes.fielGroup}>
                                  <span
                                    className={classes.addFileLink}
                                    onClick={(e) =>
                                      backInputRef.current.click()
                                    }
                                  >
                                    <AddIcon className={classes.icon} /> Select
                                    document back
                                  </span>
                                  <input
                                    type="file"
                                    ref={backInputRef}
                                    className={classes.inputField}
                                    onChange={(e) => {
                                      const file = e.target.files[0];
                                      const isValidSize =
                                        utils.hasValidSize(file);
                                      const isValidType =
                                        utils.isValidFile(file);

                                      if (!isValidSize) {
                                        setBackError(
                                          "File size should be under 10 MB"
                                        );
                                      } else if (!isValidType) {
                                        setBackError(
                                          "Only jpg, png & pdf files are accepted"
                                        );
                                      } else {
                                        setBackError(null);
                                      }

                                      setImages({
                                        ...images,
                                        back: file,
                                      });
                                    }}
                                  />
                                </div>
                              )}
                            </Grid>
                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                              <div className={classes.buttonWrapper}>
                                <Button
                                  type="submit"
                                  disabled={isProcessing}
                                  variant="contained"
                                  color="primary"
                                >
                                  {isProcessing ? (
                                    <div
                                      className={classes.progressButtonWrapper}
                                    >
                                      <CircularProgressBar
                                        progress={progress}
                                      />{" "}
                                      <div className={classes.uploadingLabel}>
                                        {progress === 100
                                          ? "Submitting Documents..."
                                          : "Uploading"}
                                      </div>
                                    </div>
                                  ) : (
                                    "Upload"
                                  )}
                                </Button>
                              </div>
                            </Grid>
                          </Grid>
                        </>
                      )}
                    </div>
                  </form>
                </Grid>

                <Grid item xs={12}>
                  <Typography
                    className={`${classes.subHeading} ${classes.sectionTopMargin}`}
                    variant="h6"
                  >
                    Legal Details
                  </Typography>
                </Grid>
                <Grid item md={4} sm={4} xs={6}>
                  <Typography className={classes.heading}>Status</Typography>
                  <Typography
                    className={
                      person?.verification?.status === "verified"
                        ? classes.verified
                        : classes.unverified
                    }
                  >
                    {person?.verification?.status}
                  </Typography>
                </Grid>
                <Grid item md={4} sm={4} xs={6}>
                  <Typography className={classes.heading}>
                    First Name
                  </Typography>
                  <Typography>{person?.first_name}</Typography>
                </Grid>
                <Grid item md={4} sm={4} xs={6}>
                  <Typography className={classes.heading}>Last Name</Typography>
                  <Typography>{person?.last_name}</Typography>
                </Grid>
                <Grid item md={4} sm={4} xs={6}>
                  <Typography className={classes.heading}>Email</Typography>
                  <Typography>{person?.email}</Typography>
                </Grid>
                <Grid item md={4} sm={4} xs={6}>
                  <Typography className={classes.heading}>
                    Date of birth
                  </Typography>
                  <Typography>
                    {person?.dob?.day}/{person?.dob?.month}/{person?.dob?.year}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    className={`${classes.subHeading} ${classes.sectionTopMargin}`}
                    variant="h6"
                  >
                    Location Details
                  </Typography>
                </Grid>

                <Grid item md={4} sm={4} xs={6}>
                  <Typography className={classes.heading}>Address</Typography>
                  <Typography>
                    {person?.address?.line1} {person?.address?.line2}
                  </Typography>
                </Grid>
                <Grid item md={4} sm={4} xs={6}>
                  <Typography className={classes.heading}>City</Typography>
                  <Typography>{person?.address?.city}</Typography>
                </Grid>
                <Grid item md={4} sm={4} xs={6}>
                  <Typography className={classes.heading}>State</Typography>
                  <Typography>{person?.address?.state}</Typography>
                </Grid>
                <Grid item md={4} sm={4} xs={6}>
                  <Typography className={classes.heading}>
                    Postal Code
                  </Typography>
                  <Typography>{person?.address?.postal_code}</Typography>
                </Grid>
                <Grid item md={4} sm={4} xs={6}>
                  <Typography className={classes.heading}>Country</Typography>
                  <Typography>{person?.address?.country}</Typography>
                </Grid>
              </Grid>
            )}
          </div>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Person;
