import {loadStripe} from '@stripe/stripe-js';
import {
    Elements, 
    ElementsConsumer
  } from "@stripe/react-stripe-js";
import StripeForm from './StripeForm';


  const InjectedCheckoutForm = () => (
    <ElementsConsumer>
      {({stripe, elements}) => ( 
        <StripeForm stripe={stripe} elements={elements} />
      )}
    </ElementsConsumer>
  );

  
  const stripePromise = loadStripe( process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY );
  
  const StripeFormContainer = () => (
    <Elements stripe={stripePromise}>
      <InjectedCheckoutForm />
    </Elements>
  );



export default StripeFormContainer;
