import dayjs from "dayjs";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";

const utils = {
  getRole: (user) => {
    return typeof user !== "undefined" &&
      user &&
      typeof user["http://tova_roles"] !== "undefined" &&
      user["http://tova_roles"].length
      ? user["http://tova_roles"].toString()
      : user["http://tova_userType"].userType;
  },
  isAccountExists: async (id) => {
    try {
      let url = `${process.env.REACT_APP_SERVER_URL}/account/exists/${id}`;
      await axios.get(url);
    } catch (error) {}
  },

  isAdmin: (user) => {
    if (
      typeof user !== "undefined" &&
      user &&
      typeof user["http://tova_roles"] !== "undefined" &&
      user["http://tova_roles"].length
    ) {
      for (const role of user["http://tova_roles"]) {
        if (
          role.toLowerCase() === "admin" ||
          role.toLowerCase() === "docadmin"
        ) {
          return true;
        }
      }
    }
    return false;
  },
  isEditor: (user) => {
    if (
      typeof user !== "undefined" &&
      user &&
      typeof user["http://tova_roles"] !== "undefined" &&
      user["http://tova_roles"].length
    ) {
      for (const role of user["http://tova_roles"]) {
        if (role.toLowerCase() === "editor") {
          return true;
        }
      }
    }
    return false;
  },
  isSupportUser: (user) => {
    if (
      typeof user !== "undefined" &&
      user &&
      typeof user["http://tova_roles"] !== "undefined" &&
      user["http://tova_roles"].length
    ) {
      for (const role of user["http://tova_roles"]) {
        if (role.toLowerCase() === "support") {
          return true;
        }
      }
    }
    return false;
  },
  getFullYear: () => {
    const d = new Date();

    return d.getFullYear();
  },

  getYearsList: () => {
    const d = new Date();

    let arr = [];
    const currentYear = d.getFullYear();

    for (let i = 0; i < 70; i++) {
      arr.push(currentYear - i);
    }

    return arr;
  },
  getFormattedDate: (timestamp = null, format, duration = null) => {
    if (timestamp && format) {
      return dayjs(timestamp).format(format);
    } else if (duration && typeof duration.month !== "undefined") {
      return dayjs().add(duration.month, "M").format(format);
    }
    return dayjs().format(format);
  },
  hasPermission: (user, permission) => {
    if (typeof user.permissions === "undefined") {
      return false;
    }
    if (user.permissions.includes(permission)) {
      return true;
    }
    return false;
  },
  isValidFormat: (arg, type) => {
    if (type === "email") {
      const emailRegexp =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (arg.match(emailRegexp)) {
        return true;
      }
    }
    return false;
  },
  viewPort: (onlyNumber = null) => {
    var myWidth = 0,
      myHeight = 0;
    if (typeof window.innerWidth == "number") {
      //Non-IE
      myWidth = window.innerWidth;
      myHeight = window.innerHeight;
    } else if (
      document.documentElement &&
      (document.documentElement.clientWidth ||
        document.documentElement.clientHeight)
    ) {
      //IE 6+ in 'standards compliant mode'
      myWidth = document.documentElement.clientWidth;
      myHeight = document.documentElement.clientHeight;
    } else if (
      document.body &&
      (document.body.clientWidth || document.body.clientHeight)
    ) {
      //IE 4 compatible
      myWidth = document.body.clientWidth;
      myHeight = document.body.clientHeight;
    }

    if (onlyNumber) {
      return {
        width: myWidth,
        height: myHeight,
      };
    }

    return {
      width: myWidth + "px",
      height: myHeight + "px",
    };
  },
  formatPhone: (phoneNumber) => {
    let phone = phoneNumber.replace(/\D/g, "");

    phone = phone.substring(0, 10);

    var size = phone.length;
    if (!size) {
      phone = "";
    } else if (size < 4) {
      phone = "(" + phone;
    } else if (size < 7) {
      phone = "(" + phone.substring(0, 3) + ")  " + phone.substring(3, 6);
    } else {
      phone =
        "(" +
        phone.substring(0, 3) +
        ")  " +
        phone.substring(3, 6) +
        " - " +
        phone.substring(6, 10);
    }
    return phone;
  },
  getStripeAccountId: (user) => {
    return typeof user !== "undefined" &&
      user &&
      typeof user["http://tova_userType"] !== "undefined" &&
      typeof user["http://tova_userType"].stripeAccountId !== "undefined"
      ? user["http://tova_userType"].stripeAccountId
      : null;
  },
  formatCard: (number) => {
    if (!number) return number;

    let v = number.replace(/\s+/g, "").replace(/[^0-9]/gi, "");
    let matches = v.match(/\d{4,20}/g);
    let match = (matches && matches[0]) || "";
    let parts = [];

    for (let i = 0, len = match.length; i < len; i += 4) {
      parts.push(match.substring(i, i + 4));
    }
    if (parts.length) {
      return parts.join(" ");
    }
    return number;
  },

  hasValidSize: (file, size = 10) => {
    const fileSize = file.size / 1024 / 1024;

    if (fileSize < size) {
      return true;
    }
    return false;
  },
  isValidFile: (file, type = ["pdf", "png", "jpeg", "jpeg"]) => {
    let ext = file.name.split(".").pop();
    ext = ext.toLowerCase();
    if (type.includes(ext)) {
      return true;
    }

    return false;
  },
  getFileSize: (file, unit = "MB") => {
    let size = null;

    if (unit === "MB") {
      size = file.size / 1024 / 1024;
    }

    return `${size.toFixed(3)} ${unit}`;
  },
  uuid: () => {
    return uuidv4();
  },

  getVerificationTypes: (account) => {
    let verifications = {
      identityVerification: false,
      bankAccountOwnershipVerification: false,
    };

    let requirements = null;

    if (typeof account.requirements !== "undefined") {
      let currentlyDue = account.requirements.currently_due;
      let eventuallyDue = account.requirements.eventually_due;

      requirements = currentlyDue.concat(eventuallyDue);
    }

    if (requirements && Array.isArray(requirements)) {
      if (
        requirements.includes(
          "documents.bank_account_ownership_verification.files"
        )
      ) {
        verifications.bankAccountOwnershipVerification = true;
      }

      if (
        requirements.includes("legal_entity.verification.document") ||
        requirements.includes("verification.document")
      ) {
        verifications.identityVerification = true;
      }
    }

    return verifications;
  },
  getExternalAccountTypes: (account) => {
    let resources = {
      bank: null,
      card: null,
    };

    for (let act of account.external_accounts.data) {
      if (act.object === "card") {
        resources.card = true;
      } else if (act.object === "bank_account") {
        resources.bank = true;
      }
    }

    return resources;
  },

  setCookie: (name, value, days) => {
    let expires = "";
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
  },
  getCookie: (name) => {
    let nameEQ = name + "=";
    let ca = document.cookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  },
  removeCookie: (name) => {
    document.cookie =
      name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
  },

  getSelectedSpeciality: (id, specialities) => {
    for (let key in specialities) {
      if (specialities[key].id === id) {
        return specialities[key].text;
      }
    }
  },
};

export default utils;
