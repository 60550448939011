import React from "react";
import { connect } from "react-redux";
import { Redirect, Route } from "react-router";

const ProtectedRoute = ({
  path,
  component: Component,
  render,
  isAuthenticated,
  loginScreenRoute,
  ...rest
}) => {
  return (
    <Route
      path={path}
      {...rest}
      render={(props) => {
        if (isAuthenticated) {
          return Component ? <Component {...props} /> : render(props);
        }
        return (
          <Redirect
            to={{
              pathname: `${
                loginScreenRoute ? loginScreenRoute : `/individual/login`
              }`,
              state: { from: props.location },
            }}
          />
        );
      }}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    loginScreenRoute: state.auth0.loginScreenRoute,
    isAuthenticated: state.auth0.isAuthenticated,
  };
};

export default connect(mapStateToProps)(ProtectedRoute);
