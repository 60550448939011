import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    height: "calc(100vh - 110px)",
    paddingTop: theme.spacing(30),

    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(20),
    },
  },
  contentSection: {
    boxShadow: theme.section.boxShadow,
    borderRadius: theme.section.borderRadius,
    backgroundColor: "#fff",
    padding: theme.spacing(4),
  },
  label: {
    display: "block",
    fontSize: "14px",
    color: theme.paragraph.color,
  },
  value: {
    display: "block",
    fontWeight: 600,
    color: theme.paragraphDark.color,
  },
  infoWrapper: {
    marginTop: theme.spacing(3),
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  divider: {
    height: "4px",
    marginTop: theme.spacing(1),
    marginBottom: 20,
    width: theme.spacing(5),
    backgroundColor: theme.palette.primary.main,
  },

  item: {
    marginBottom: theme.spacing(3),
    padding: "0 10px",
  },
  mt: {
    marginTop: theme.spacing(6),
  },
  heading: {
    fontSize: "14px",
    color: "#666",
  },
  textSuccess: {
    color: "green",
    fontSize: "20px",
  },
  textGrey: {
    color: "orange",
    fontSize: "20px",
  },
  chipsWrapper: {
    paddingTop: theme.spacing(1),
  },
  chip: {
    marginRight: theme.spacing(1),
  },
  loaderWrapper: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  textCenter: {
    textAlign: "center !important",
  },
  textRight: {
    textAlign: "right !important",
  },
  headingWrapper: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(5),
  },
  icon: {
    fontSize: "2.5rem",
    marginRight: theme.spacing(2),
  },
  navLink: {
    color: "#000",
  },
  editIcon: {
    alignSelf: "flex-end",
    cursor: "pointer",
  },
  btnContained: {
    backgroundColor: theme.backgroundSeagreen,
    color: "#fff",
    "&:hover": {
      backgroundColor: theme.backgroundSeagreenDark,
    },
  },
}));
