import { useEffect, useState } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Loading from "../../../components/Loading";
import Alert from "../../../components/Alert";
import { NavLink, Redirect } from "react-router-dom";
import bg1 from "../../../assets/images/female-doctor.jpeg";
import forgotPasswordAction from "../store/actions/forgotPasswordAction";
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(15),

    height: "100vh",
    backgroundImage: `url(${bg1})`,
    backgroundRepeat: "no-repeat",
    position: "center",
    backgroundSize: "cover",
    transition: "background-image 5s ease-in-out",
  },

  formWrapper: {
    marginTop: theme.spacing(4),
  },
  headingVerticalSpacing: {
    marginBottom: theme.spacing(3),
  },
  textBold: {
    fontWeight: 700,
  },
  description: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(4),
  },
  runningText: {
    lineHeight: "26px",
  },
  mainContentWrapper: {
    backgroundColor: "#fff",
    minWidth: "300px",
    maxWidth: "500px",
    padding: theme.spacing(6),
    borderRadius: theme.spacing(2),
  },
  label: {
    marginBottom: theme.spacing(1),
    display: "block",
  },
  submitBtnWrapper: {
    marginTop: theme.spacing(5),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  labelWithIcon: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  noDecoration: {
    textDecoration: "none",
    color: theme.textSeagreen,
    cursor: "pointer",
  },
  smallHeading: {
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(3),
    fontWeight: "600",
  },
  formControlLabel: {
    alignItems: "flex-start !important",
    "& span": {
      fontSize: "15px !important",
    },
    "& .MuiButtonBase-root": {
      paddingTop: 0,
    },
  },

  textCenter: {
    textAlign: "center",
    marginTop: theme.spacing(4),
  },
  btnContained: {
    backgroundColor: theme.backgroundSeagreen,
    color: "#fff",
    "&:hover": {
      backgroundColor: theme.backgroundSeagreenDark,
    },
  },
}));

const DoctorForgotPassword = (props) => {
  document.body.style.background = "#fff";

  const classes = useStyles();

  const [formData, setFormData] = useState({
    email: "",
    type: "doctor",
  });

  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [currentBg] = useState(bg1);

  useEffect(() => {
    setIsLoading(false);
  }, []);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isValidated()) {
      return;
    }

    await props.sendResetPasswordLink(
      `${process.env.REACT_APP_SERVER_URL}/public/password/reset/link`,
      {
        ...formData,
      }
    );

    setFormData({ ...formData, email: "" });
  };

  const isValidated = () => {
    for (let key in formData) {
      if (typeof formData[key] !== "undefined" && !formData[key]) {
        setHasError(true);
        return false;
      }
    }
    return true;
  };

  if (isLoading) {
    return <Loading />;
  } else if (props.isAuthenticated) {
    return <Redirect to="/account" />;
  }

  return (
    <div
      className={classes.root}
      style={{ backgroundImage: `url(${currentBg})` }}
    >
      {props.status ? (
        <Alert status={props.status} message={props.message} />
      ) : null}

      <Container fixed>
        <div className={classes.mainContentWrapper}>
          <div className={classes.mainDescriptionWrapper}>
            <Typography variant="h5" className={classes.textBold}>
              Forgot Password
            </Typography>
          </div>
          <div className={classes.formWrapper}>
            <form onSubmit={handleSubmit}>
              <div className={classes.section}>
                <Grid container direction="row" spacing={3}>
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <div className={classes.inputGroup}>
                      <label className={classes.label}>
                        Your account email
                      </label>
                      <TextField
                        onChange={handleChange}
                        name="email"
                        label=""
                        variant="filled"
                        fullWidth
                        error={hasError && !formData.email.length}
                        value={formData.email || ""}
                        helperText={
                          hasError && !formData.email.length ? "Required" : ""
                        }
                      />
                    </div>
                  </Grid>
                </Grid>
              </div>

              <div className={classes.submitBtnWrapper}>
                <Button
                  variant="contained"
                  type="submit"
                  fullWidth
                  disabled={props.isProcessing}
                  className={classes.btnContained}
                >
                  {props.isProcessing
                    ? "Processing"
                    : "Send Reset Password Link"}
                </Button>
              </div>

              <div className={classes.textCenter}>
                <p>
                  Remember your password?{" "}
                  <NavLink className={classes.noDecoration} to="/doctor/login">
                    Log In
                  </NavLink>
                </p>
              </div>
            </form>
          </div>
        </div>
      </Container>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isProcessing: state.auth0.isProcessing,
    status: state.auth0.status,
    message: state.auth0.message,
    isAuthenticated: state.auth0.isAuthenticated,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    sendResetPasswordLink: (url, formData) =>
      dispatch(forgotPasswordAction(url, formData)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DoctorForgotPassword);
