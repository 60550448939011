import React, { useEffect, useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { useAuth0 } from "@auth0/auth0-react";
import Error from "./Error";
import {
  Box,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBackOutlined";
import ApproveIcon from "@material-ui/icons/DoneOutlined";
import DenyIcon from "@material-ui/icons/CloseOutlined";
import EyeIcon from "@material-ui/icons/VisibilityOutlined";
import utils from "../helpers/utils";
import Loading from "../components/Loading";
import { Redirect } from "react-router";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import axios from "axios";
import Modal from "../components/Modal";
import Alert from "../components/Alert";
import useHasPermission from "../hooks/useHasPermission";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "calc(100vh - 110px)",
    paddingTop: theme.spacing(30),
  },
  contentSection: {
    boxShadow: theme.section.boxShadow,
    borderRadius: theme.section.borderRadius,
    backgroundColor: "#fff",
    padding: theme.spacing(4),
  },

  divider: {
    height: "4px",
    marginTop: theme.spacing(1),
    width: theme.spacing(5),
    backgroundColor: theme.palette.primary.main,
  },
  headingWrapper: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(5),
  },
  navLink: {
    color: "#000",
  },
  icon: {
    fontSize: "2.5rem",
    marginRight: theme.spacing(2),
  },
  table: {
    minWidth: 1000,
  },
  tableHeading: {
    fontWeight: "bold",
    letterSpacing: "1px",
  },
  iconWrapper: {
    width: theme.spacing(13),
  },

  textCenter: {
    textAlign: "center !important",
  },
  iconDefaultColor: {
    color: theme.backgroundSeagreen,
  },
}));

const Applications = (props) => {
  document.body.style.backgroundColor = "#F7F7F7";

  const classes = useStyles();

  const [applications, setApplications] = useState([]);

  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState("");
  const [statusMessage, setStatusMessage] = useState("");
  const [message, setMessage] = useState("");
  const [buttonTitle, setButtonTitle] = useState("");
  const [applicationId, setApplicationId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isProcessing, setIsProcessing] = useState(false);

  const { isAuthenticated, user, error } = useAuth0();
  const isAdmin = utils.isAdmin(user);
  const baseUrl = process.env.REACT_APP_SERVER_URL;

  useEffect(() => {
    axios({
      url: `${baseUrl}/doc-applications`,
    })
      .then((response) => {
        setIsLoading(false);
        setApplications(response.data);
      })
      .catch((err) => {
        setIsLoading(false);
        setStatus("error");
        setStatusMessage("Error loading doctor applications");
      });
  }, [baseUrl]);

  const { hasPermission } = useHasPermission({ permission: "read:doctors" });

  // initial value is null so needs to check the type of the value as well
  if (hasPermission === false) {
    return <Redirect to="/account" />;
  }
  if (isAuthenticated && (props.isProcessing || !props.authUser)) {
    return <Loading />;
  } else if (isAuthenticated && props.isMember === false && !isAdmin) {
    return <Redirect to="/signup" />;
  }

  if (error) {
    return <Error code="500" />;
  }

  const handleOpenModal = (status, id) => {
    setOpen(true);
    setApplicationId(id);

    if (status) {
      setMessage("Are you sure you want to accept this application?");
      setButtonTitle("Accept");
    } else {
      setMessage("Are you sure you want to reject this application?");
      setButtonTitle("Reject");
    }
  };

  const handleCloseModal = () => {
    setOpen(false);
  };
  const handleConfirmation = () => {
    axios({
      url: `${process.env.REACT_APP_SERVER_URL}/public/accept-application`,
      method: "put",
      data: { accept: true, id: applicationId },
    })
      .then((response) => {
        const temp = applications.filter((app) => {
          return app.id !== applicationId;
        });

        setApplications(temp);
        setStatus("success");
        setOpen(false);
        setStatusMessage(response.data.message);
        setIsProcessing(false);
      })
      .catch((err) => {
        setStatus("error");
        setOpen(false);
        setStatusMessage(err.response.data.message);
      });
  };

  return (
    <Container maxWidth="lg" className={classes.root}>
      <Modal
        open={open}
        handleConfirmation={handleConfirmation}
        onClose={handleCloseModal}
        message={message}
        buttonTitle={buttonTitle}
        isProcessing={isProcessing}
        setIsProcessing={setIsProcessing}
      />

      {status ? <Alert status={status} message={statusMessage} /> : null}

      <div className={classes.headingWrapper}>
        <NavLink className={classes.navLink} to="/account">
          <ArrowBackIcon className={classes.icon} />
        </NavLink>
        <Typography variant="h5">
          Applications
          <div className={classes.divider} />
        </Typography>
      </div>

      <Grid container direction="row" className={classes.gridWrapper}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Box component="div" className={classes.contentSection}>
            <TableContainer>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tableHeading}>Name</TableCell>
                    <TableCell className={classes.tableHeading}>DOB</TableCell>
                    <TableCell className={classes.tableHeading}>
                      Medical School
                    </TableCell>
                    <TableCell className={classes.tableHeading}>
                      Degree
                    </TableCell>
                    <TableCell className={classes.tableHeading}>
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isLoading ? (
                    <TableRow>
                      <TableCell colSpan={5} className={classes.textCenter}>
                        <CircularProgress size={30} />
                      </TableCell>
                    </TableRow>
                  ) : null}

                  {!isLoading && !applications.length ? (
                    <TableRow>
                      <TableCell colSpan={5} className={classes.textCenter}>
                        No record found
                      </TableCell>
                    </TableRow>
                  ) : null}

                  {applications.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell component="th" scope="row">
                        {`${row.firstName} ${row.lastName}`}
                      </TableCell>
                      <TableCell>
                        {" "}
                        {utils.getFormattedDate(row.dob, "MM/DD/YYYY")}
                      </TableCell>
                      <TableCell>{row.medicalSchool}</TableCell>
                      <TableCell>{row.degree}</TableCell>
                      <TableCell>
                        <div className={classes.iconWrapper}>
                          <IconButton
                            size="small"
                            onClick={() => handleOpenModal(true, row.id)}
                          >
                            {!row.accepted ? (
                              <Tooltip title="Approve" placement="top-start">
                                <ApproveIcon
                                  className={classes.iconDefaultColor}
                                />
                              </Tooltip>
                            ) : (
                              <DenyIcon className={classes.iconDefaultColor} />
                            )}
                          </IconButton>
                          <IconButton
                            size="small"
                            onClick={() => handleOpenModal(false, row.id)}
                          >
                            {!row.accepted ? (
                              <Tooltip title="Disapprove" placement="top-start">
                                <DenyIcon
                                  className={classes.iconDefaultColor}
                                />
                              </Tooltip>
                            ) : (
                              <DenyIcon />
                            )}
                          </IconButton>
                          <IconButton
                            size="small"
                            component={NavLink}
                            to={`/applications/${row.id}`}
                          >
                            <Tooltip title="View Details" placement="top-start">
                              <EyeIcon className={classes.iconDefaultColor} />
                            </Tooltip>
                          </IconButton>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    isProcessing: state.auth0.isProcessing,
    isMember: state.auth0.isMember,
    authUser: state.auth0.user,
  };
};

export default connect(mapStateToProps)(Applications);
