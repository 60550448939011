import { useEffect, useState } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import Checkbox from "@material-ui/core/Checkbox";
import Loading from "../../../components/Loading";
import VisibilityIcon from "@material-ui/icons/VisibilityOutlined";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOffOutlined";
import Alert from "../../../components/Alert";
import { NavLink, Redirect } from "react-router-dom";
import bg1 from "../../../assets/images/female-doctor.jpeg";
import { connect } from "react-redux";
import signupAction from "../store/actions/signupAction";
import aicpaLogo from "../../../assets/images/aicpa-white.png";
import hippaLogo from "../../../assets/images/hippa-compliance-white.webp";
import gdprLogo from "../../../assets/images/gdpr-white.png";
import privacyShieldLogo from "../../../assets/images/privacy-shiled-white-2.png";
import ccpaLogo from "../../../assets/images/ccpa-white.png";
import { Box, useMediaQuery, useTheme } from "@material-ui/core";
import CustomCard from "../../../components/CustomCard";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(15),
    paddingBottom: theme.spacing(10),
    minHeight: "100vh",
    backgroundImage: `url(${bg1})`,
    backgroundRepeat: "no-repeat",

    backgroundSize: "cover",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      backgroundSize: "contained",
      //minHeight: "932px",
      backgroundPosition: "-500px",
    },
  },
  bgOverlay: {
    content: '"',
    position: "absolute !important",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    right: 0,
    zIndex: "11",
    backgroundColor: theme.backgroundTransparentOverlayDark,
  },

  headingVerticalSpacing: {
    marginBottom: theme.spacing(3),
  },
  textBold: {
    fontWeight: 700,
  },
  description: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(4),
  },
  runningText: {
    lineHeight: "26px",
  },
  mainContentWrapper: {
    backgroundColor: "#fff",
    minWidth: "300px",
    maxWidth: "500px",
    padding: theme.spacing(6),
    borderRadius: theme.spacing(2),
    position: "relative",
    zIndex: 11,
    marginBottom: "100px",
  },
  label: {
    marginBottom: theme.spacing(1),
    display: "block",
  },
  submitBtnWrapper: {
    marginTop: theme.spacing(5),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  labelWithIcon: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  noDecoration: {
    textDecoration: "none",
    color: theme.textSeagreen,
    cursor: "pointer",
  },
  smallHeading: {
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(3),
    fontWeight: "600",
    fontSize: "1.5rem",
  },
  formControlLabel: {
    alignItems: "flex-start !important",
    "& span": {
      fontSize: "15px !important",
    },
    "& .MuiButtonBase-root": {
      paddingTop: 0,
    },
  },

  textCenter: {
    textAlign: "center",
    marginTop: theme.spacing(4),
  },
  btnContained: {
    backgroundColor: theme.backgroundSeagreen,
    color: "#fff",
    "&:hover": {
      backgroundColor: theme.backgroundSeagreenDark,
    },
  },
  checkbox: {
    color: theme.textSeagreen,
  },
  sectionPadding: {
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10),
  },
  howitworksWrapper: {
    backgroundColor: "#30a89d",
    minHeight: "500px",
    position: "relative",
    "&::before": {
      content: '""',
      position: "absolute",
      width: "75%",
      top: 0,
      bottom: 0,
      backgroundColor: "#1c9585",
      borderTopRightRadius: "50%",
      borderBottomRightRadius: "50%",
    },
  },
  cardWrapper: {
    position: "relative",
    zIndex: "2",
  },
  sectionMainHeading: {
    fontSize: "40px",
    fontWeight: "bold",
    lineHeight: "55px",

    marginBottom: "20px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "33px",
      lineHeight: "45px",
    },
  },
  card: {
    display: "flex",
    minHeight: "120px",

    padding: theme.spacing(3),
    borderRadius: "5px",
  },
  active: {
    backgroundColor: "#30a89d",
  },
  iconWrapper: {
    textAlign: "center",
    marginRight: "20px",
  },
  icon: {
    borderRadius: "50%",
    paddingTop: "3px",
    backgroundColor: "#fff",
    width: "30px",
    height: "30px",
    fontWeight: "bold",
  },
  cardRunningText: {
    color: "#fff",
    fontWeight: 600,
    fontSize: "1.2rem",
  },
  virtualVisitWrapper: {
    backgroundColor: "#efefef",
  },
  textWhite: {
    color: "#fff",
  },
  userCardWrapper: {
    maxWidth: "350px",
    backgroundColor: "#fff",
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    borderRadius: "5px",
    boxShadow: "0 3px 60px rgba(0,0,0,0.16)",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: theme.spacing(5),
  },
  cardHeader: {
    textAlign: "center",
    marginLeft: "auto",
    marginRight: "auto",
  },
  avatar: {
    width: "60px",
    height: "60px",
    borderRadius: "100%",
    position: "relative",
    margin: -3,
    border: "solid 2px #fff",
  },
  smallAvatar: {
    width: "20px",
    height: "20px",
  },
  headingWrapper: {
    marginTop: theme.spacing(3),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  circleIcon: {
    fontSize: "20px",
    color: "#4caf50",
    border: "solid 3px #4caf5000",
    borderRadius: "100%",
    backgroundColor: "#4caf505e",
    position: "relative",
    marginRight: "5px",
    top: "3px",
  },
  // smallHeading: {
  //   fontWeight: "bold",
  //   textAlign: "center",
  // },
  smallSubheading: {
    fontWeight: 600,
    textAlign: "center",

    backgroundColor: "#c3ede9",
    borderRadius: "3px",
  },
  ratingWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: theme.spacing(2),
  },
  ratingLabel: {
    fontSize: "1.1rem",
  },
  buttonWrapper: {
    textAlign: "center",
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(1),
  },
  disabledText: {
    color: "#ccc",
    marginRight: "10px",
    marginLeft: "10px",
    textDecoration: "line-through",
  },
  textNormal: {
    textTransform: "normal !important",
  },
  smallSubheadingWrapper: {
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
  },
  smallBgSubheading: {
    textAlign: "center",
    paddingLeft: "10px",
    paddingRight: "10px",
    display: "block",
    backgroundColor: "#a6d9d4",
    borderRadius: "3px",
  },
  textOverlay: {
    //backgroundColor: "rgba(0,0,0,0.5)",
    position: "relative",
    zIndex: 11,
    padding: theme.spacing(2),
    borderRadius: theme.spacing(2),
    "& *": {
      color: "white",
    },
  },
  runningTextWrapper: {
    marginTop: theme.spacing(4),
  },
  textItem: {
    marginBottom: theme.spacing(3),
  },
  whiteBorderBottom: {
    borderBottom: "solid 1px rgba(255,255,255,0.5)",
  },
  logoWrapper: {
    position: "relative",
    zIndex: 11,
  },
  aicpa: {
    maxWidth: "70px",
    [theme.breakpoints.down("sm")]: {
      zoom: "60% !important",
    },
  },
  hippa: {
    maxWidth: "130px",
    [theme.breakpoints.down("sm")]: {
      zoom: "60% !important",
    },
  },
  gdpr: {
    maxWidth: "80px",
    [theme.breakpoints.down("sm")]: {
      zoom: "60% !important",
    },
  },
  privacyShield: {
    maxWidth: "80px",
    [theme.breakpoints.down("sm")]: {
      zoom: "60% !important",
    },
  },
  ccpa: {
    maxWidth: "90px",
    [theme.breakpoints.down("sm")]: {
      zoom: "60% !important",
    },
  },
  groupLogo: {
    display: "flex",

    alignItems: "center",
    justifyContent: "space-around",
  },
  bottomSectionMainWrapper: {
    marginTop: theme.spacing(12),
    marginBottom: theme.spacing(12),
  },
  headlineWrapper: {
    marginBottom: theme.spacing(5),
  },
  marginTopGrid: {
    marginBottom: 40,
  },
}));

const DoctorSignup = (props) => {
  document.body.style.background = "#fff";

  const classes = useStyles();

  const theme = useTheme();

  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const xs = useMediaQuery(theme.breakpoints.down("xs"));

  const [formData, setFormData] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    agreedToTerms: false,
    type: "doctor",
  });

  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setIsLoading(false);
  }, []);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isValidated()) {
      await props.signup(
        `${process.env.REACT_APP_SERVER_URL}/public/create/account`,
        { ...formData, type: "doctor" }
      );
    }
  };

  const isValidated = () => {
    for (let key in formData) {
      if (
        key !== "agreedToTerms" &&
        typeof formData[key] !== "undefined" &&
        !formData[key]
      ) {
        setHasError(true);
        return false;
      }
    }
    return true;
  };

  if (isLoading) {
    return <Loading />;
  } else if (props.isAuthenticated) {
    return <Redirect to="/account" />;
  } else if (props.stepOneDone) {
    return <Redirect to="/apply" />;
  }

  return (
    <>
      <div className={classes.root}>
        <div className={classes.bgOverlay} />
        {props.status ? (
          <Alert status={props.status} message={props.message} />
        ) : null}

        <Container fixed>
          <Grid container spacing={4}>
            <Grid item xl={6} lg={6} md={6}>
              <div className={classes.mainContentWrapper}>
                <div className={classes.mainDescriptionWrapper}>
                  <Typography variant="h4" className={classes.textBold}>
                    Tova Doctor
                  </Typography>
                  <Typography variant="h4" className={classes.textBold}>
                    Network Application
                  </Typography>
                  <p className={classes.description}>
                    It's <strong>free, secure</strong> and only takes a minute.
                    Join thousands of other doctors who use Tova to see patients
                    and earn.
                  </p>
                </div>
                <div className={classes.formWrapper}>
                  <form onSubmit={handleSubmit}>
                    <div className={classes.section}>
                      <Grid
                        container
                        direction="row"
                        spacing={3}
                        alignItems="stretch"
                      >
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                          <div className={classes.inputGroup}>
                            <label className={classes.label}>Email</label>
                            <TextField
                              onChange={handleChange}
                              name="email"
                              label=""
                              variant="filled"
                              fullWidth
                              error={hasError && !formData.email.length}
                              value={formData.email}
                              helperText={
                                hasError && !formData.email.length
                                  ? "Required"
                                  : ""
                              }
                            />
                          </div>
                        </Grid>
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                          <div className={classes.inputGroup}>
                            <div className={classes.labelWithIcon}>
                              <label>Password</label>
                              <IconButton
                                disableRipple={true}
                                onClick={(e) => setVisible(!visible)}
                              >
                                {!visible ? (
                                  <VisibilityOffIcon />
                                ) : (
                                  <VisibilityIcon />
                                )}
                              </IconButton>
                            </div>

                            <TextField
                              onChange={handleChange}
                              name="password"
                              label=""
                              variant="filled"
                              fullWidth
                              type={visible ? "text" : "password"}
                              error={hasError && !formData.password.length}
                              value={formData.password}
                              helperText={
                                hasError && !formData.password.length
                                  ? "Required"
                                  : ""
                              }
                            />
                          </div>
                        </Grid>
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                          <div className={classes.inputGroup}>
                            <label className={classes.label}>
                              Confirm Password
                            </label>
                            <TextField
                              onChange={handleChange}
                              name="confirmPassword"
                              label=""
                              variant="filled"
                              fullWidth
                              type={visible ? "text" : "password"}
                              error={
                                hasError && !formData.confirmPassword.length
                              }
                              value={formData.confirmPassword}
                              helperText={
                                hasError && !formData.confirmPassword.length
                                  ? "Required"
                                  : ""
                              }
                            />
                          </div>
                        </Grid>
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                          <div className={classes.inputGroup}>
                            <FormControlLabel
                              className={classes.formControlLabel}
                              control={
                                <Checkbox
                                  checked={formData.agreedToTerms}
                                  onChange={(e) => {
                                    setFormData({
                                      ...formData,
                                      [e.target.name]: !formData.agreedToTerms,
                                    });
                                  }}
                                  name="agreedToTerms"
                                  error={
                                    hasError && !formData.agreedToTerms
                                      ? formData.agreedToTerms.toString()
                                      : ""
                                  }
                                  className={classes.checkbox}
                                />
                              }
                              label={
                                <div className={classes.smallText}>
                                  <span>I agree to Tova's </span>{" "}
                                  <NavLink
                                    className={classes.noDecoration}
                                    to="/termsmedical"
                                  >
                                    Terms
                                  </NavLink>{" "}
                                  and{" "}
                                  <NavLink
                                    className={classes.noDecoration}
                                    to="/privacy"
                                  >
                                    Privacy Policy
                                  </NavLink>
                                </div>
                              }
                            />
                          </div>
                        </Grid>
                      </Grid>
                    </div>

                    <div className={classes.submitBtnWrapper}>
                      <Button
                        variant="contained"
                        type="submit"
                        fullWidth
                        className={classes.btnContained}
                        disabled={props.isProcessing}
                      >
                        {props.isProcessing ? "Processing" : "Agree & Continue"}
                      </Button>
                    </div>

                    <div className={classes.textCenter}>
                      Have an account?{" "}
                      <NavLink
                        to="/doctor/login"
                        className={classes.noDecoration}
                      >
                        Log In
                      </NavLink>
                    </div>
                  </form>
                </div>
              </div>
            </Grid>
            <Grid item xl={6} lg={6} md={6}>
              <div className={classes.textOverlay}>
                <div className={classes.mainDescriptionWrapper}>
                  <Typography variant="h4" className={`${classes.textBold} `}>
                    Why Use Tova?
                  </Typography>
                </div>
                <div className={classes.runningTextWrapper}>
                  <div
                    className={`${classes.textItem} ${classes.whiteBorderBottom}`}
                  >
                    <Typography variant="h6" className={`${classes.textBold} `}>
                      Grow your practice
                    </Typography>
                    <p>
                      Increase your patient base by seeing patients on the Tova
                      app.
                    </p>
                  </div>
                  <div
                    className={`${classes.textItem} ${classes.whiteBorderBottom}`}
                  >
                    <Typography variant="h6" className={`${classes.textBold} `}>
                      Set your own schedule
                    </Typography>
                    <p>
                      Decide when and where you want to practice. Login at any
                      time and begin seeing patients via video call.
                    </p>
                  </div>
                  <div
                    className={`${classes.textItem} ${classes.whiteBorderBottom}`}
                  >
                    <Typography variant="h6" className={`${classes.textBold} `}>
                      Earn with convenience
                    </Typography>
                    <p>
                      Practice from the comfort of your home and earn $40 - $80
                      per visit. Due to our streamlined care process, most
                      visits take less than 10 minutes.
                    </p>
                  </div>
                  <div className={classes.textItem}>
                    <Typography variant="h6" className={`${classes.textBold} `}>
                      Audited and certified
                    </Typography>
                    <p>
                      Tova is a leading telemedicine provider that matches
                      patients with doctors withtin state. We are HIPPA
                      compliant, GDPR compliant, CCPA compliant, and ensure all
                      doctors that use our platform are licensed and insured.
                    </p>
                  </div>
                </div>
              </div>

              <div className={classes.iconsWrapper}>
                <div className={classes.asideWrapper}>
                  {/* <div className={classes.descriptionWrapper}>
                      <Typography variant="h5" className={classes.textBold}>
                        Our privacy and security standards
                      </Typography>
                      <p className={classes.runningText}>
                        We are audited and certified by
                      </p>
                    </div> */}
                  <div className="">
                    <div className={classes.groupLogo}>
                      <div className={classes.logoWrapper}>
                        <img src={aicpaLogo} alt="" className={classes.aicpa} />
                      </div>
                      <div className={classes.logoWrapper}>
                        <img src={hippaLogo} alt="" className={classes.hippa} />
                      </div>

                      <div className={classes.logoWrapper}>
                        <img src={gdprLogo} alt="" className={classes.gdpr} />
                      </div>
                      <div className={classes.logoWrapper}>
                        <img
                          src={privacyShieldLogo}
                          alt=""
                          className={classes.privacyShield}
                        />
                      </div>

                      <div className={classes.logoWrapper}>
                        <img src={ccpaLogo} alt="" className={classes.ccpa} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
      <div className={classes.bottomSectionMainWrapper}>
        <Container fixed>
          <Box component="div" className={classes.bottomSectionWrapper}>
            <Box component="div" className={classes.headlineWrapper}>
              <Typography variant="h4" className={classes.textBold}>
                Practice when you want
              </Typography>
            </Box>
            <Grid container spacing={sm || xs ? 0 : 10}>
              <Grid
                item
                xl={4}
                lg={4}
                md={4}
                className={sm || xs ? classes.marginTopGrid : ""}
              >
                <CustomCard
                  icon="dollar"
                  heading="Earn anytime, anywhere"
                  description="You can practice when you want, where you want, and how you want. And you can choose how and when you want to get paid."
                />
              </Grid>
              <Grid
                item
                xl={4}
                lg={4}
                md={4}
                className={sm || xs ? classes.marginTopGrid : ""}
              >
                <CustomCard
                  icon="calendar"
                  heading="Set your own schedule"
                  description="Only practice when it works for you. Choose your availability and go live on the Tova app."
                />
              </Grid>
              <Grid
                item
                xl={4}
                lg={4}
                md={4}
                className={sm || xs ? classes.marginTopGrid : ""}
              >
                <CustomCard
                  icon="signup"
                  heading="Signing up is easy"
                  description="Sign up to gain access to the app. After your account activation is complete, you can start earning."
                />
              </Grid>
            </Grid>
          </Box>
        </Container>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    isProcessing: state.auth0.isProcessing,
    status: state.auth0.status,
    message: state.auth0.message,
    stepOneDone: state.auth0.stepOneDone,
    stepTwoDone: state.auth0.stepTwoDone,
    isAuthenticated: state.auth0.isAuthenticated,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    signup: (url, formData) => dispatch(signupAction(url, formData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DoctorSignup);
