import utils from "../../../../helpers/utils";
import { LOGOUT_USER_SUCCESS, DISMISS_STATUS_MESSAGE } from "./actionTypes";

const logoutAction = (url) => {
  return async (dispatch, getState) => {
    const user = getState().auth0.user;
    if (user) {
      const role = utils.getRole(user);

      if (!role || (role && role === "patient")) {
        dispatch({
          type: LOGOUT_USER_SUCCESS,
          payload: {
            loginScreenRoute: "/individual/login",
          },
        });
      } else if (role === "doctor") {
        dispatch({
          type: LOGOUT_USER_SUCCESS,
          payload: {
            loginScreenRoute: "/doctor/login",
          },
        });
      } else {
        dispatch({
          type: LOGOUT_USER_SUCCESS,
          payload: {
            loginScreenRoute: "/admin/login",
          },
        });
      }
    }

    setTimeout(() => {
      dispatch({
        type: DISMISS_STATUS_MESSAGE,
      });
    }, 10000);
  };
};

export default logoutAction;
