import React from "react";
import {
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Chip,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { NavLink, withRouter } from "react-router-dom";
import ArrowForwardIcon from "@material-ui/icons/ArrowForwardIosOutlined";
import meetingIcon from "../assets/images/icons/urgent-care-now-4.svg";
import primaryCareIcon from "../assets/images/icons/primary-care-appointment-4.svg";
import conciregeMedicineIcon from "../assets/images/icons/concirede-medicine.svg";
import { useAppContext } from "../context/AppContext";

const useStyles = makeStyles((theme) => ({
  card: {
    boxShadow: "none",
    borderRadius: "5px",
    padding: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
    position: "relative",
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(1),
    },
    cardContent: {
      position: "relative !important",
      paddingBottom: 0,
    },
    "&:hover": {
      boxShadow: "0 3px 20px rgba(0,0,0,0.30)",
    },
  },
  cursorPointer: {
    cursor: "pointer",
  },
  shadow: {
    boxShadow: "0 3px 60px rgba(0,0,0,0.16)",
  },
  arrowIcon: {
    position: "absolute",
    right: theme.spacing(1),
    color: theme.paragraph.color,
    cursor: "pointer",
    fontSize: "20px",
    "&:hover": {
      color: theme.paragraphDark.color,
    },
  },
  contentWrapper: {
    display: "flex",
  },
  cardIconLarge: {
    width: "120px",
    height: "120px",
    [theme.breakpoints.down("xs")]: {
      width: "50px",
      height: "50px",
    },
  },
  cardTextWrapper: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  cardRunningText: {
    marginTop: theme.spacing(1),
  },
  runningText: {
    lineHeight: "25px",
  },
  containerAlignRight: {
    marginLeft: "auto",
  },
  cardDelText: {
    marginRight: theme.spacing(1),
    fontSize: "16px",
    color: "#666",
    fontWeight: 600,
  },
  justifySpaceBetwen: {
    alignItems: "center",
    width: "100%",
  },
  cardAction: {
    marginTop: 0,
    width: "100%",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  cardChip: {
    backgroundColor: "#4DD35C",
    color: "#fff",
    fontWeight: 600,
    marginLeft: "auto",
  },
  cardHeadingLarge: {
    fontSize: "23px",
    fontWeight: 600,
    color: theme.paragraphDark.color,
  },
  cardHeadingSmallBoxLarge: {
    fontSize: "20px",
    fontWeight: 600,
    marginBottom: 0,
    color: theme.paragraphDark.color,
  },
}));

const TalkToADoctor = (props) => {
  const { notAvailable, openPopup } = useAppContext();
  const classes = useStyles();
  const { showUnavailablePopup } = props;
  const showPopup = typeof showUnavailablePopup !== "undefined" ? true : false;
  const redirectToSignup = () => {
    if (notAvailable && showPopup) {
      openPopup();
    } else {
      props.history.push("/individual/signup");
    }
  };

  return (
    <Grid
      container
      direction="row"
      style={{ justifyContent: "center" }}
      spacing={3}
    >
      <Grid item xl={6} lg={6} md={6} sm={10} xs={12}>
        <Card
          className={`${classes.card} ${classes.shadow} ${classes.cursorPointer}`}
          onClick={redirectToSignup}
        >
          <CardContent className={classes.cardContent}>
            <div className={classes.arrowIcon}>
              {notAvailable && showPopup ? (
                <ArrowForwardIcon className={classes.arrowIcon} />
              ) : (
                <NavLink to="/learn-more">
                  <ArrowForwardIcon className={classes.arrowIcon} />
                </NavLink>
              )}
            </div>
            <div className={classes.contentWrapper}>
              <div className={`${classes.iconLargeWrapper}`}>
                <CardMedia
                  className={classes.cardIconLarge}
                  image={meetingIcon}
                />
              </div>
              <div className={classes.cardTextWrapper}>
                <Typography className={classes.cardHeadingLarge} variant="h6">
                  Urgent care now
                </Typography>
                <p
                  className={`${classes.cardRunningText} ${classes.runningText}`}
                >
                  Video or text chat with the next available U.S. doctor -- 24/7
                  within minutes.
                </p>
              </div>
            </div>
          </CardContent>
          <CardActions className={classes.cardAction}>
            <div
              className={`${classes.contentWrapper} ${classes.justifySpaceBetwen}`}
            >
              <small className={classes.cardSmallText}>
                50% off with Tova Prime
              </small>
              <div className={classes.containerAlignRight}>
                <del className={classes.cardDelText}>$80</del>
                <Chip label="$39" className={classes.cardChip} />
              </div>
            </div>
          </CardActions>
        </Card>
      </Grid>
      <Grid item xl={6} lg={6} md={6} sm={10} xs={12}>
        <Card
          className={`${classes.card} ${classes.shadow} ${classes.cursorPointer}`}
          onClick={redirectToSignup}
        >
          <CardContent className={classes.cardContent}>
            <div className={classes.arrowIcon}>
              {notAvailable && showPopup ? (
                <ArrowForwardIcon className={classes.arrowIcon} />
              ) : (
                <NavLink to="/learn-more">
                  <ArrowForwardIcon className={classes.arrowIcon} />
                </NavLink>
              )}
            </div>
            <div className={classes.contentWrapper}>
              <div className={`${classes.iconLargeWrapper}`}>
                <CardMedia
                  className={classes.cardIconLarge}
                  image={primaryCareIcon}
                />
              </div>
              <div className={classes.cardTextWrapper}>
                <Typography className={classes.cardHeadingLarge} variant="h6">
                  Primary care appointment
                </Typography>
                <p
                  className={`${classes.cardRunningText} ${classes.runningText}`}
                >
                  Choose your primary care doctor and book a video appointment.
                </p>
              </div>
            </div>
          </CardContent>
          <CardActions className={classes.cardAction}>
            <div
              className={`${classes.contentWrapper} ${classes.justifySpaceBetwen}`}
            >
              <small className={classes.cardSmallText}>
                Only available with Tova Prime
              </small>
              <Chip label="$39" className={classes.cardChip} />
            </div>
          </CardActions>
        </Card>
      </Grid>
      <Grid item xs={12} sm={10} md={6}>
        <Card
          className={`${classes.card} ${classes.shadow} ${classes.cursorPointer}`}
          onClick={redirectToSignup}
        >
          <CardContent className={classes.cardContent}>
            <div className={classes.arrowIcon}>
              {notAvailable && showPopup ? (
                <ArrowForwardIcon className={classes.arrowIcon} />
              ) : (
                <NavLink to="/learn-more">
                  <ArrowForwardIcon className={classes.arrowIcon} />
                </NavLink>
              )}
            </div>
            <div className={classes.contentWrapper}>
              <div className={`${classes.iconLargeWrapper}`}>
                <CardMedia
                  className={classes.cardIconLarge}
                  image={conciregeMedicineIcon}
                />
              </div>
              <div className={classes.cardTextWrapper}>
                <Typography className={classes.cardHeadingLarge} variant="h6">
                  Concierge medicine
                </Typography>
                <p
                  className={`${classes.cardRunningText} ${classes.runningText}`}
                >
                  Schedule an at-home visit with a doctor of your choice. The
                  doctor will conduct the visit within the comfort of your home.
                </p>
              </div>
            </div>
          </CardContent>
          <CardActions className={classes.cardAction}>
            <div
              className={`${classes.contentWrapper} ${classes.justifySpaceBetwen}`}
            >
              <small className={classes.cardSmallText}>
                Only available with Tova Prime
              </small>
              <Chip label="$129" className={classes.cardChip} />
            </div>
          </CardActions>
        </Card>
      </Grid>
    </Grid>
  );
};

export default withRouter(TalkToADoctor);
