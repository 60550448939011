import makeStyles from "@material-ui/core/styles/makeStyles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import aboutUsImage from "../assets/images/about-us4.jpeg";
import PhoneIcon from "@material-ui/icons/PhoneIphoneOutlined";
import TrendIcon from "@material-ui/icons/TrendingUpOutlined";
import Footer from "../components/Footer";
import ourMissionImage from "../assets/images/our-history.jpg";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100% !important",
  },
  header: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: "100px 0",
    marginTop: "64px",
    backgroundColor: "#f7f9fa !important",
  },
  containerCustomWidth: {
    maxWidth: "1220px",
  },
  sectionHeading: {
    fontSize: "40px",
    fontWeight: 600,
    lineHeight: "55px",
    marginBottom: "30px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "33px",
      lineHeight: "45px",
    },
  },
  runningText: {
    lineHeight: "25px",
  },
  sectionDescription: {
    maxWidth: "705px",

    marginBottom: theme.spacing(7),
  },
  contentWrapper: {
    padding: "80px 0",
  },
  sideImage: {
    width: "100%",
    borderRadius: "5px",
    boxShadow: theme.section.boxShadow,
  },
  listWrapper: {
    display: "flex",
  },
  iconWrapper: {
    marginRight: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  icon: {
    color: theme.backgroundSeagreen,
  },
  ourMissinImageWrapper: {
    textAlign: "right",
    [theme.breakpoints.down("sm")]: {
      textAlign: "left",
      marginBottom: "50px",
    },
  },
  ourMissionImage: {
    maxWidth: "400px",
    borderRadius: "5px",
    boxShadow: theme.section.boxShadow,
  },
  smallImageGrid: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  largeImageGrid: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

function About(props) {
  document.body.style.background = "#fff";

  const classes = useStyles();

  return (
    <div className={classes.root}>
      {/* Section 1 */}
      <div className={classes.header}>
        <Container className={classes.containerCustomWidth} maxWidth="lg">
          <Grid container alignItems="center">
            <Grid
              item
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
              className={classes.smallImageGrid}
            >
              <div className={classes.ourMissinImageWrapper}>
                <img
                  src={ourMissionImage}
                  className={classes.ourMissionImage}
                  alt=""
                />
              </div>
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <Typography className={classes.sectionHeading} variant="h1">
                Our Mission
              </Typography>
              <p
                className={`${classes.sectionDescription} ${classes.runningText}`}
              >
                Tova belives that quality healthcare is a fundamental human
                right and should be accessible to all. Therefore, we strive to
                make our services as affordable as possible -- with or without
                insurance. We develop medical technology to increase access to
                quality healthcare, especially in communities where it could be
                limited.
              </p>
            </Grid>
            <Grid
              item
              xl={6}
              lg={6}
              md={6}
              sm={12}
              xs={12}
              className={classes.largeImageGrid}
            >
              <div className={classes.ourMissinImageWrapper}>
                <img
                  src={ourMissionImage}
                  className={classes.ourMissionImage}
                  alt=""
                />
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
      {/* /Section 1 */}

      <div className={classes.contentWrapper}>
        <Container className={classes.containerCustomWidth} maxWidth="lg">
          <Grid container spacing={8}>
            <Grid item xl={5} lg={6} md={6} sm={12} xs={12}>
              <div className={classes.imageWrapper}>
                <img src={aboutUsImage} className={classes.sideImage} alt="" />
              </div>
            </Grid>
            <Grid item xl={7} lg={6} md={6} sm={12} xs={12}>
              <div className={classes.textContent}>
                <Typography className={classes.sectionHeading} variant="h3">
                  Our History
                </Typography>
                <p
                  className={`${classes.sectionDescription} ${classes.runningText}`}
                >
                  Tova Medical opened its United States headquarters in 2020.
                  Since then, we have worked to develop medical technology that
                  modernizes the process of seeing a physician. We developed
                  mobile applications with both physician and consumer facing
                  products to allow ease-of-access telemedicine consultations
                  with qualified physicians.
                </p>
                <div className={classes.listOuterWrapper}>
                  <div className={classes.listWrapper}>
                    <div className={classes.iconWrapper}>
                      <PhoneIcon fontSize="large" className={classes.icon} />
                    </div>
                    <div className={classes.descriptionWrapper}>
                      See an urgent care doctor for immediate issues, or choose
                      a primary care physician to manage chronic or ongoing
                      conditions.
                    </div>
                  </div>
                  <div className={classes.listWrapper}>
                    <div className={classes.iconWrapper}>
                      <TrendIcon fontSize="large" className={classes.icon} />
                    </div>
                    <div className={classes.descriptionWrapper}>
                      Doctors can increase their patient base and earn by seeing
                      patients in jurisdictions that they are licensed.
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>

      {/* Section 2 */}
      <Footer />
      {/* /Section 2 */}
    </div>
  );
}

export default About;
