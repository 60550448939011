import { useEffect, useState } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import Checkbox from "@material-ui/core/Checkbox";
import Loading from "../../../components/Loading";
import VisibilityIcon from "@material-ui/icons/VisibilityOutlined";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOffOutlined";
import Alert from "../../../components/Alert";
import { NavLink, Redirect } from "react-router-dom";
import bg1 from "../../../assets/images/bg2.jpg";
import { connect } from "react-redux";
import signupAction from "../store/actions/signupAction";
import getStripeProductAction from "../store/actions/getStripeProductAction";
import Badge from "@material-ui/core/Badge";
import CircleIcon from "@material-ui/icons/FiberManualRecord";
import { Avatar, Box } from "@material-ui/core";
import Rating from "@material-ui/lab/Rating";
import Doc1 from "../../../assets/images/doc1.jpg";
import Doc2 from "../../../assets/images/doc2.jpg";
import Doc3 from "../../../assets/images/doc3.jpg";
import Verified from "../../../assets/images/verified-badge1.png";
import MobileSideImage from "../../../assets/images/mobile-screen.png";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(15),
    paddingBottom: theme.spacing(10),
    minHeight: "100vh",
    backgroundImage: `url(${bg1})`,
    backgroundRepeat: "no-repeat",
    position: "center, center",
    backgroundSize: "cover",
    [theme.breakpoints.down("sm")]: {
      backgroundSize: "contained",
      //minHeight: "932px",
      backgroundPosition: "-500px",
    },
  },

  headingVerticalSpacing: {
    marginBottom: theme.spacing(3),
  },
  textBold: {
    fontWeight: 700,
  },
  description: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(4),
  },
  runningText: {
    lineHeight: "26px",
  },
  mainContentWrapper: {
    backgroundColor: "#fff",
    minWidth: "300px",
    maxWidth: "500px",
    padding: theme.spacing(6),
    borderRadius: theme.spacing(2),

    marginBottom: "100px",
  },
  label: {
    marginBottom: theme.spacing(1),
    display: "block",
  },
  submitBtnWrapper: {
    marginTop: theme.spacing(5),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  labelWithIcon: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  noDecoration: {
    textDecoration: "none",
    color: theme.textSeagreen,
    cursor: "pointer",
  },
  smallHeading: {
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(3),
    fontWeight: "600",
    fontSize: "1.5rem",
  },
  formControlLabel: {
    alignItems: "flex-start !important",
    "& span": {
      fontSize: "15px !important",
    },
    "& .MuiButtonBase-root": {
      paddingTop: 0,
    },
  },

  textCenter: {
    textAlign: "center",
    marginTop: theme.spacing(4),
  },
  btnContained: {
    backgroundColor: theme.backgroundSeagreen,
    color: "#fff",
    "&:hover": {
      backgroundColor: theme.backgroundSeagreenDark,
    },
  },
  checkbox: {
    color: theme.textSeagreen,
  },
  sectionPadding: {
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10),
  },
  howitworksWrapper: {
    backgroundColor: "#32a5b6",
    minHeight: "500px",
    position: "relative",
    maxHeight: "800px",
    overflow: "hidden",
    "&::before": {
      content: '""',
      position: "absolute",
      width: "75%",
      top: 0,
      bottom: 0,
      backgroundColor: "#2d8d9b",
      borderTopRightRadius: "50%",
      borderBottomRightRadius: "50%",
    },
    [theme.breakpoints.down("sm")]: {
      maxHeight: "initial",
    },
  },
  cardWrapper: {
    position: "relative",
    zIndex: "2",
  },
  sectionMainHeading: {
    fontSize: "40px",
    fontWeight: "bold",
    lineHeight: "55px",

    marginBottom: "20px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "33px",
      lineHeight: "45px",
    },
  },
  card: {
    display: "flex",
    minHeight: "120px",

    padding: theme.spacing(3),
    borderRadius: "5px",
  },
  active: {
    backgroundColor: "#32a5b6",
  },
  iconWrapper: {
    textAlign: "center",
    marginRight: "20px",
  },
  icon: {
    borderRadius: "50%",
    paddingTop: "3px",
    backgroundColor: "#fff",
    width: "30px",
    height: "30px",
    fontWeight: "bold",
  },
  cardRunningText: {
    color: "#fff",
    fontWeight: 600,
    fontSize: "1.2rem",
  },
  virtualVisitWrapper: {
    backgroundColor: "#efefef",
  },
  textWhite: {
    color: "#fff",
  },
  userCardWrapper: {
    maxWidth: "350px",
    backgroundColor: "#fff",
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    borderRadius: "5px",
    boxShadow: "0 3px 60px rgba(0,0,0,0.16)",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: theme.spacing(5),
  },
  cardHeader: {
    textAlign: "center",
    marginLeft: "auto",
    marginRight: "auto",
  },
  avatar: {
    width: "60px",
    height: "60px",
    borderRadius: "100%",
    position: "relative",
    margin: -3,
    border: "solid 2px #fff",
  },
  smallAvatar: {
    width: "20px",
    height: "20px",
  },
  headingWrapper: {
    marginTop: theme.spacing(3),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  circleIcon: {
    fontSize: "20px",
    color: "#4caf50",
    border: "solid 3px #4caf5000",
    borderRadius: "100%",
    backgroundColor: "#4caf505e",
    position: "relative",
    marginRight: "5px",
    top: "3px",
  },
  // smallHeading: {
  //   fontWeight: "bold",
  //   textAlign: "center",
  // },
  smallSubheading: {
    fontWeight: 600,
    textAlign: "center",

    backgroundColor: "#c3ede9",
    borderRadius: "3px",
  },
  ratingWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: theme.spacing(2),
  },
  ratingLabel: {
    fontSize: "1.1rem",
  },
  buttonWrapper: {
    textAlign: "center",
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(1),
  },
  disabledText: {
    color: "#ccc",
    marginRight: "10px",
    marginLeft: "10px",
    textDecoration: "line-through",
  },
  textNormal: {
    textTransform: "normal !important",
  },
  smallSubheadingWrapper: {
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
  },
  smallBgSubheading: {
    textAlign: "center",
    paddingLeft: "10px",
    paddingRight: "10px",
    display: "block",
    backgroundColor: "#a6d9d4",
    borderRadius: "3px",
  },
  sideImageWrapper: {
    textAlign: "right",
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
    },
  },
  sideImage: {
    position: "relative",
    zIndex: 5,
    width: "65%",
    [theme.breakpoints.down("md")]: {
      width: "80%",
    },
  },
}));

const PatientSignup = (props) => {
  document.body.style.background = "#fff";

  const classes = useStyles();

  const [formData, setFormData] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    agreedToTerms: false,
    type: "patient",
  });

  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setIsLoading(false);
  }, []);

  // useEffect(() => {
  //   if (!props.product) {
  //     getProduct();
  //   }
  // });

  // const getProduct = async () => {
  //   await props.getProduct();
  // };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isValidated()) {
      await props.signup(
        `${process.env.REACT_APP_SERVER_URL}/public/create/account`,
        { ...formData, type: "patient" }
      );
    }
  };

  const isValidated = () => {
    for (let key in formData) {
      if (
        key !== "agreedToTerms" &&
        typeof formData[key] !== "undefined" &&
        !formData[key]
      ) {
        setHasError(true);
        return false;
      }
    }
    return true;
  };

  if (isLoading) {
    return <Loading />;
  } else if (props.isAuthenticated) {
    return <Redirect to="/account" />;
  } else if (props.stepOneDone) {
    return <Redirect to="/individual/signup/account-info" />;
  } else if (props.stepOneDone && props.stepTwoDone) {
    return <Redirect to="/individual/signup/select-plan" />;
  }

  return (
    <>
      <div className={classes.root}>
        {props.status ? (
          <Alert status={props.status} message={props.message} />
        ) : null}

        <Container fixed>
          <div className={classes.mainContentWrapper}>
            <div className={classes.mainDescriptionWrapper}>
              <Typography variant="h5" className={classes.textBold}>
                Create An Account
              </Typography>

              <p className={classes.description}>
                It's <strong>free, secure</strong> and only takes a minute.
              </p>
            </div>
            <div className={classes.formWrapper}>
              <form onSubmit={handleSubmit}>
                <div className={classes.section}>
                  <Grid container direction="row" spacing={3}>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                      <div className={classes.inputGroup}>
                        <label className={classes.label}>Email</label>
                        <TextField
                          onChange={handleChange}
                          name="email"
                          label=""
                          variant="filled"
                          fullWidth
                          error={hasError && !formData.email.length}
                          value={formData.email}
                          helperText={
                            hasError && !formData.email.length ? "Required" : ""
                          }
                        />
                      </div>
                    </Grid>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                      <div className={classes.inputGroup}>
                        <div className={classes.labelWithIcon}>
                          <label>Password</label>
                          <IconButton
                            disableRipple={true}
                            onClick={(e) => setVisible(!visible)}
                          >
                            {!visible ? (
                              <VisibilityOffIcon />
                            ) : (
                              <VisibilityIcon />
                            )}
                          </IconButton>
                        </div>

                        <TextField
                          onChange={handleChange}
                          name="password"
                          label=""
                          variant="filled"
                          fullWidth
                          type={visible ? "text" : "password"}
                          error={hasError && !formData.password.length}
                          value={formData.password}
                          helperText={
                            hasError && !formData.password.length
                              ? "Required"
                              : ""
                          }
                        />
                      </div>
                    </Grid>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                      <div className={classes.inputGroup}>
                        <label className={classes.label}>
                          Confirm Password
                        </label>
                        <TextField
                          onChange={handleChange}
                          name="confirmPassword"
                          label=""
                          variant="filled"
                          fullWidth
                          type={visible ? "text" : "password"}
                          error={hasError && !formData.confirmPassword.length}
                          value={formData.confirmPassword}
                          helperText={
                            hasError && !formData.confirmPassword.length
                              ? "Required"
                              : ""
                          }
                        />
                      </div>
                    </Grid>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                      <div className={classes.inputGroup}>
                        <FormControlLabel
                          className={classes.formControlLabel}
                          control={
                            <Checkbox
                              checked={formData.agreedToTerms}
                              onChange={(e) => {
                                setFormData({
                                  ...formData,
                                  [e.target.name]: !formData.agreedToTerms,
                                });
                              }}
                              name="agreedToTerms"
                              error={
                                hasError && !formData.agreedToTerms
                                  ? formData.agreedToTerms.toString()
                                  : ""
                              }
                              className={classes.checkbox}
                            />
                          }
                          label={
                            <div className={classes.smallText}>
                              <span>
                                By continuing, I confirm that I am over 16 years
                                old and agree to Tova's{" "}
                              </span>{" "}
                              <NavLink
                                className={classes.noDecoration}
                                to="/terms"
                              >
                                Terms
                              </NavLink>{" "}
                              and{" "}
                              <NavLink
                                className={classes.noDecoration}
                                to="/privacy"
                              >
                                Privacy Policy
                              </NavLink>
                            </div>
                          }
                        />
                      </div>
                    </Grid>
                  </Grid>
                </div>

                <div className={classes.submitBtnWrapper}>
                  <Button
                    variant="contained"
                    type="submit"
                    fullWidth
                    className={classes.btnContained}
                    disabled={props.isProcessing}
                  >
                    {props.isProcessing ? "Processing" : "Agree & Continue"}
                  </Button>
                </div>

                <div className={classes.textCenter}>
                  Have an account?{" "}
                  <NavLink
                    to="/individual/login"
                    className={classes.noDecoration}
                  >
                    Log In
                  </NavLink>
                </div>
              </form>
            </div>
          </div>
        </Container>
      </div>
      {/* How It works */}
      <div className={`${classes.howitworksWrapper} ${classes.sectionPadding}`}>
        <Container fixed>
          <Grid container>
            <Grid item xl={5} lg={5} md={6} sm={12} xs={12}>
              <div className={classes.cardsWrapper}>
                <div className={classes.cardWrapper}>
                  <Typography
                    align="center"
                    variant="h3"
                    className={`${classes.sectionMainHeading} ${classes.textWhite}`}
                  >
                    How it works
                  </Typography>
                  <div className={`${classes.card} ${classes.active}`}>
                    <div className={classes.iconWrapper}>
                      <div className={classes.icon}>1</div>
                    </div>
                    <div className={classes.textWrapper}>
                      <Typography className={classes.cardRunningText}>
                        Connect with the next available doctor in minutes via
                        video call.
                      </Typography>
                    </div>
                  </div>
                  <div className={classes.card}>
                    <div className={classes.iconWrapper}>
                      <div className={classes.icon}>2</div>
                    </div>
                    <div className={classes.textWrapper}>
                      <Typography className={classes.cardRunningText}>
                        Get personalized private care by video chat to receive a
                        diagnosis on a medical condition, get a new prescription
                        medicine, receive a doctors note for work or school, and
                        ask questions.
                      </Typography>
                    </div>
                  </div>
                  <div className={classes.card}>
                    <div className={classes.iconWrapper}>
                      <div className={classes.icon}>3</div>
                    </div>
                    <div className={classes.textWrapper}>
                      <Typography className={classes.cardRunningText}>
                        Have your new prescription sent to your local pharmacy
                        for pickup, or have it delivered to your home. Follow up
                        with treatment plans, lab test referrals, or receive a
                        referral for specialized care.
                      </Typography>
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xl={7} lg={7} md={6} sm={12} xs={12}>
              <Box component="div" className={classes.sideImageWrapper}>
                <img
                  src={MobileSideImage}
                  alt=""
                  className={classes.sideImage}
                />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </div>
      {/* /How It works */}

      {/* Virtual Visit */}
      <div
        className={`${classes.virtualVisitWrapper} ${classes.sectionPadding}`}
      >
        <Container fixed>
          <Typography
            align="center"
            variant="h3"
            className={`${classes.sectionMainHeading} `}
          >
            Start your virtual doctor visit
          </Typography>
          <div className={classes.userCardWrapper}>
            <div className={classes.plainCard}>
              <div className={classes.cardHeader}>
                <Badge
                  // overlap="circular"
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  badgeContent={
                    <Avatar
                      className={classes.smallAvatar}
                      alt=""
                      src={Verified}
                    />
                  }
                >
                  <Avatar className={classes.avatar} alt="" src={Doc1} />
                </Badge>
                <Badge
                  // overlap="circular"
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  badgeContent={
                    <Avatar
                      className={classes.smallAvatar}
                      alt=""
                      src={Verified}
                    />
                  }
                >
                  <Avatar className={classes.avatar} alt="" src={Doc2} />
                </Badge>
                <Badge
                  // overlap="circular"
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  badgeContent={
                    <Avatar
                      className={classes.smallAvatar}
                      alt=""
                      src={Verified}
                    />
                  }
                >
                  <Avatar className={classes.avatar} alt="" src={Doc3} />
                </Badge>
              </div>
              <div className={classes.headingWrapper}>
                <div className={classes.elipse}>
                  <CircleIcon className={classes.circleIcon} />
                </div>
                <div className={classes.smallHeadingWrapper}>
                  <Typography className={classes.smallHeading}>
                    24/7 Urgent Care
                  </Typography>
                </div>
              </div>
              <div className={classes.smallSubheadingWrapper}>
                <span className={classes.smallBgSubheading}>
                  Average wait time: 1 minute
                </span>
              </div>
              <div className={classes.ratingWrapper}>
                <Rating
                  defaultValue={4.6}
                  precision={0.1}
                  name="read-only"
                  readOnly
                />
                <span className={classes.ratingLabel}> 4.6 (21,633)</span>
              </div>
              <div className={classes.buttonWrapper}>
                <Button
                  variant="contained"
                  component={NavLink}
                  to="/individual/signup"
                  className={`${classes.btnContained} ${classes.textNormal}`}
                >
                  Start a visit now $80
                  {/* <span className={classes.disabledText}>$99</span> $39 */}
                </Button>
              </div>
            </div>
          </div>
        </Container>
      </div>
      {/* /Virtual Visit */}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    isProcessing: state.auth0.isProcessing,
    status: state.auth0.status,
    message: state.auth0.message,
    stepOneDone: state.auth0.stepOneDone,
    stepTwoDone: state.auth0.stepTwoDone,
    isAuthenticated: state.auth0.isAuthenticated,
    product: state.auth0.product,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    signup: (url, formData) => dispatch(signupAction(url, formData)),
    getProduct: () => dispatch(getStripeProductAction()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PatientSignup);
