import React, { useState, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import { useAuth0 } from "@auth0/auth0-react";
import Error from "../Error";
import {
  Box,
  Container,
  Grid,
  CircularProgress,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
} from "@material-ui/core";
import utils from "../../helpers/utils";
import Loading from "../../components/Loading";
import { Redirect } from "react-router";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBackOutlined";
import { useStyles } from "./styles";
import { KeyboardDatePicker } from "@material-ui/pickers";
import axios from "axios";
import Alert from "../../components/Alert";
import useHasPermission from "../../hooks/useHasPermission";

const EditPatient = (props) => {
  document.body.style.backgroundColor = "#F7F7F7";

  const classes = useStyles();

  const { isAuthenticated, user, error } = useAuth0();
  const isAdmin = utils.isAdmin(user);

  const [states, setStates] = useState([]);
  const [hasError, setHasError] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    dob: new Date(),
    gender: "male",
    email: "",
    phoneNumber: "",
    password: "",
    stateId: "",
    pin: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [alertSettings, setAlertSettings] = useState({ status: false });
  useEffect(() => {
    const fetchPatient = async () => {
      let url = `${process.env.REACT_APP_SERVER_URL}/accounts/patient/${props.match.params.id}/show`;

      const res = await axios.get(url);

      if (res.status === 200) {
        let phoneNumber = res?.data?.headOfAccount?.phoneNumber;
        let data = {
          id: res?.data?.headOfAccount?.id,
          firstName: res?.data?.headOfAccount?.firstName,
          lastName: res?.data?.headOfAccount?.lastName,
          email: res?.data?.email,
          gender: res?.data?.headOfAccount?.gender,
          phoneNumber: utils.formatPhone(phoneNumber),
          dob: res?.data?.headOfAccount?.dob,
          stateId: res?.data?.headOfAccount?.stateId,
          password: "",
          pin: "",
        };
        setFormData(data);
      } else setHasError(true);
    };

    fetchPatient();
    getStates();
  }, [props.match.params?.id]);
  useEffect(() => {
    if (alertSettings.status) {
      setTimeout(() => {
        setAlertSettings({ status: false });
      }, 3000);
    }
  }, [alertSettings?.status]);

  const getStates = async () => {
    try {
      const response = await axios({
        url: `${process.env.REACT_APP_SERVER_URL}/public/states`,
        method: "get",
      });

      setStates(response.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]:
        e.target.name === "phoneNumber"
          ? utils.formatPhone(e.target.value)
          : e.target.value,
    });
  };
  const isValidated = () => {
    //password can be empty in case of update so we are deleting.
    delete formData.password;

    for (let key in formData) {
      if (
        key !== "password" &&
        key !== "pin" &&
        typeof formData[key] !== "undefined" &&
        !formData[key]
      ) {
        setHasError(true);
        return false;
      }
    }
    return true;
  };

  const handleDateChange = (date, field) => {
    setFormData({
      ...formData,
      [field]: date,
    });
  };

  const updatePatient = async () => {
    if (!isValidated()) return;
    setIsLoading(true);

    try {
      const res = await axios({
        url: `${process.env.REACT_APP_SERVER_URL}/patient/prfile/update?id=${formData?.id}`,
        method: "put",
        data: formData,
      });
      setIsLoading(false);
      if (res?.status === 200) {
        let phoneNumber = res?.data?.phoneNumber;
        let data = {
          id: res?.data?.id,
          firstName: res?.data?.firstName,
          lastName: res?.data?.lastName,
          email: res?.data?.email,
          gender: res?.data?.gender,
          phoneNumber: utils.formatPhone(phoneNumber),
          dob: res?.data?.dob,
          stateId: res?.data?.stateId,
          pin: res?.data?.pin,
        };
        setFormData(data);
        setAlertSettings({
          status: true,
          message: "Profile updated successfully",
          type: "success",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const { hasPermission } = useHasPermission({ permission: "read:patients" });

  // initial value is null so needs to check the type of the value as well
  if (hasPermission === false) {
    return <Redirect to="/account" />;
  }

  if (isAuthenticated && (props.isProcessing || !props.authUser)) {
    return <Loading />;
  } else if (isAuthenticated && props.isMember === false && !isAdmin) {
    return <Redirect to="/signup" />;
  }

  if (error) {
    return <Error code="500" />;
  }

  if (isAuthenticated && props.isProcessing) {
    return <Loading />;
  } else if (isAuthenticated && props.isMember === false && !isAdmin) {
    return <Redirect to="/signup" />;
  }

  if (error) {
    return <Error code="500" />;
  }
  return (
    <Container maxWidth="lg" className={classes.root}>
      <Grid container direction="row" className={classes.gridWrapper}>
        {alertSettings.status ? (
          <Alert
            status={alertSettings?.type}
            message={alertSettings?.message}
          />
        ) : null}

        <div className={classes.headingWrapper}>
          <NavLink className={classes.navLink} to="/account">
            <ArrowBackIcon className={classes.icon} />
          </NavLink>
          <Typography variant="h5">
            Update Details
            <div className={classes.divider} />
          </Typography>
        </div>
        <Grid item xs={12}>
          <Box component="div" className={classes.contentSection}>
            <div className={classes.contentWrapper}>
              {!formData && !hasError ? (
                <div
                  className={`${classes.textCenter} ${classes.loaderWrapper}`}
                >
                  <CircularProgress size={30} />{" "}
                </div>
              ) : null}

              {formData ? (
                <>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <div className={classes.item}>
                        <Typography className={classes.heading} variant="h6">
                          First Name
                        </Typography>

                        <div className={classes.inputGroup}>
                          <TextField
                            onChange={handleChange}
                            name="firstName"
                            label=""
                            variant="filled"
                            fullWidth
                            error={hasError && !formData.firstName.length}
                            value={formData.firstName}
                            helperText={
                              hasError && !formData.firstName.length
                                ? "Required"
                                : ""
                            }
                          />
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <div className={classes.item}>
                        <Typography className={classes.heading} variant="h6">
                          Last Name
                        </Typography>
                        <div className={classes.inputGroup}>
                          <TextField
                            onChange={handleChange}
                            name="lastName"
                            label=""
                            variant="filled"
                            fullWidth
                            error={hasError && !formData.lastName.length}
                            value={formData.lastName}
                            helperText={
                              hasError && !formData.lastName.length
                                ? "Required"
                                : ""
                            }
                          />
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <div className={classes.item}>
                        <Typography className={classes.heading} variant="h6">
                          Email
                        </Typography>
                        <div className={classes.inputGroup}>
                          <TextField
                            onChange={handleChange}
                            name="email"
                            label=""
                            variant="filled"
                            fullWidth
                            error={hasError && !formData.email.length}
                            value={formData.email}
                            helperText={
                              hasError && !formData.email.length
                                ? "Required"
                                : ""
                            }
                          />
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <div className={classes.item}>
                        <Typography className={classes.heading} variant="h6">
                          Password
                        </Typography>
                        <div className={classes.inputGroup}>
                          <TextField
                            onChange={handleChange}
                            name="password"
                            label=""
                            variant="filled"
                            fullWidth
                            value={formData.password}
                          />
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <div className={classes.item}>
                        <label className={classes.label}>Date of birth</label>
                        <KeyboardDatePicker
                          autoOk
                          disableFuture
                          fullWidth
                          inputVariant={"filled"}
                          variant="inline"
                          name="dob"
                          format="MM/DD/YYYY"
                          views={["year", "month", "date"]}
                          onChange={(date) => handleDateChange(date, "dob")}
                          error={hasError && !formData?.dob?.length}
                          value={formData?.dob}
                          helperText={
                            hasError && !formData?.dob?.length ? "Required" : ""
                          }
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <div className={classes.item}>
                        <Typography className={classes.heading} variant="h6">
                          Phone Number
                        </Typography>

                        <TextField
                          onChange={handleChange}
                          name="phoneNumber"
                          label=""
                          variant="filled"
                          fullWidth
                          error={hasError && !formData.phoneNumber.length}
                          value={formData.phoneNumber}
                          helperText={
                            hasError && !formData.phoneNumber.length
                              ? "Required"
                              : ""
                          }
                        />
                      </div>
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                      <div className={classes.inputGroup}>
                        <label className={classes.label}>State</label>
                        <FormControl
                          variant="filled"
                          fullWidth
                          className={classes.formControl}
                        >
                          <Select
                            MenuProps={{
                              classes: { paper: classes.menuPaper },
                            }}
                            labelId="state-select-filled-label"
                            id="state-select-filled"
                            value={formData.stateId}
                            onChange={handleChange}
                            name="stateId"
                          >
                            <MenuItem key={`states-3213012`} value="">
                              Outside of United States
                            </MenuItem>
                            {states.map((item) => (
                              <MenuItem
                                key={`states-${item.id}`}
                                value={item.id}
                              >
                                {item.name}
                              </MenuItem>
                            ))}
                          </Select>
                          {hasError && !formData.stateId ? (
                            <FormHelperText>Required</FormHelperText>
                          ) : null}
                        </FormControl>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <div className={classes.item}>
                        <Typography className={classes.heading} variant="h6">
                          Pin
                        </Typography>
                        <div className={classes.inputGroup}>
                          <TextField
                            onChange={(e) => {
                              let pin = e.target.value.replace(/\D/g, "");
                              if (pin.length < 5) {
                                setFormData({
                                  ...formData,
                                  [e.target.name]: pin,
                                });
                              }
                            }}
                            name="pin"
                            label=""
                            variant="filled"
                            fullWidth
                            value={formData.pin}
                          />
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <div className={`${classes.item} ${classes.inputGroup}`}>
                        <Typography className={classes.heading} variant="h6">
                          Gender
                        </Typography>
                        <RadioGroup
                          aria-label="gender"
                          name="gender"
                          value={formData?.gender}
                          onChange={handleChange}
                        >
                          <div className={classes.radioWrapper}>
                            <FormControlLabel
                              value="male"
                              control={
                                <Radio
                                  checked={
                                    formData.gender?.toLowerCase() === "male"
                                  }
                                />
                              }
                              label="Male"
                            />
                            <FormControlLabel
                              value="female"
                              control={
                                <Radio
                                  checked={
                                    formData.gender?.toLowerCase() === "female"
                                  }
                                />
                              }
                              label="Female"
                            />
                          </div>
                        </RadioGroup>
                      </div>
                    </Grid>
                  </Grid>

                  <div className={classes.item} style={{ textAlign: "right" }}>
                    <Button
                      type="button"
                      variant="contained"
                      onClick={updatePatient}
                      className={classes.btnContained}
                    >
                      {isLoading ? "Processing" : "Update"}
                    </Button>
                  </div>
                </>
              ) : null}
            </div>
            {isLoading && (
              <div style={{ textAlign: "center" }}>
                <CircularProgress color="primary" />
              </div>
            )}
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    isProcessing: state.auth0.isProcessing,
    isMember: state.auth0.isMember,
    authUser: state.auth0.user,
  };
};

export default connect(mapStateToProps)(EditPatient);
