import {
  Grid,
  TextField,
  Typography,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import utils from "../helpers/utils";
import { KeyboardDatePicker } from "@material-ui/pickers";

const useStyles = makeStyles((theme) => ({
  label: {
    display: "block",
    fontSize: "12px",
    color: theme.paragraph.color,
  },
  submitBtnWrapper: {
    marginTop: theme.spacing(5),
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  subHeading: {
    fontWeight: "bold",
    marginBottom: theme.spacing(3),
    fontSize: "20px",
  },
  sectionTopMargin: {
    marginTop: theme.spacing(5),
  },

  value: {
    display: "block",
    fontWeight: 600,
    color: theme.paragraphDark.color,
  },
}));

const AddPerson = (props) => {
  const classes = useStyles();
  const { setFormData, formData, hasError, states } = props;

  const handleDateChange = (date, field) => {
    let formattedDate = utils.getFormattedDate(date.toString(), "MM/DD/YYYY");
    setFormData({
      ...formData,
      [field]: formattedDate,
    });
  };

  return (
    <>
      <Typography
        className={classes.subHeading}
        variant="h6"
        style={{ marginTop: "24px" }}
      >
        {props.title}
      </Typography>
      <Grid container spacing={3}>
        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <div className={classes.inputGroup}>
            <label className={classes.label}>First Name</label>
            <TextField
              onChange={(e) =>
                setFormData({
                  ...formData,
                  [e.target.name]: e.target.value,
                })
              }
              name="firstName"
              label=""
              variant="filled"
              fullWidth
              error={hasError && !formData.firstName.length}
              value={formData.firstName}
              helperText={
                hasError && !formData.firstName.length ? "Required" : ""
              }
            />
          </div>
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <div className={classes.inputGroup}>
            <label className={classes.label}>Last Name</label>
            <TextField
              onChange={(e) =>
                setFormData({
                  ...formData,
                  [e.target.name]: e.target.value,
                })
              }
              name="lastName"
              label=""
              variant="filled"
              fullWidth
              error={hasError && !formData.lastName.length}
              value={formData.lastName}
              helperText={
                hasError && !formData.lastName.length ? "Required" : ""
              }
            />
          </div>
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <div className={classes.inputGroup}>
            <label className={classes.label}>Email</label>
            <TextField
              onChange={(e) =>
                setFormData({
                  ...formData,
                  [e.target.name]: e.target.value,
                })
              }
              name="email"
              label=""
              variant="filled"
              fullWidth
              error={hasError && !formData.email.length}
              value={formData.email}
              helperText={hasError && !formData.email.length ? "Required" : ""}
            />
          </div>
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <div className={classes.inputGroup}>
            <label className={classes.label}>Phone Number</label>
            <TextField
              onChange={(e) => {
                setFormData({
                  ...formData,
                  [e.target.name]: utils.formatPhone(e.target.value, "stripe"),
                });
              }}
              name="phone"
              label=""
              variant="filled"
              fullWidth
              error={hasError && !formData.phone.length}
              value={formData.phone}
              helperText={hasError && !formData.phone.length ? "Required" : ""}
            />
          </div>
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <div className={classes.inputGroup}>
            <label className={classes.label}>Date of birth</label>
            <KeyboardDatePicker
              autoOk
              className={classes.aucdatePicker}
              disableFuture
              fullWidth
              inputVariant={"filled"}
              variant="inline"
              name="dob"
              format="MM/DD/YYYY"
              views={["year", "month", "date"]}
              onChange={(date) => handleDateChange(date?.$d, "dob")}
              error={hasError && !formData?.dob}
              value={formData?.dob}
              helperText={hasError && !formData?.dob ? "Required" : ""}
            />
          </div>
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <div className={classes.inputGroup}>
            <label className={classes.label}>SSN Last 4 Digits</label>
            <TextField
              onChange={(e) => {
                let last4 = e.target.value.replace(/\D/g, "");

                if (last4.length < 5) {
                  setFormData({
                    ...formData,
                    [e.target.name]: e.target.value,
                  });
                }
              }}
              name="ssnLast4"
              label=""
              variant="filled"
              fullWidth
              error={hasError && !formData.ssnLast4.length}
              value={formData.ssnLast4}
              helperText={
                hasError && !formData.ssnLast4.length ? "Required" : ""
              }
            />
          </div>
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <div className={classes.inputGroup}>
            <label className={classes.label}>ID Number</label>
            <TextField
              onChange={(e) => {
                setFormData({
                  ...formData,
                  [e.target.name]: e.target.value.replace(/\D/g, ""),
                });
              }}
              name="id_number"
              label=""
              variant="filled"
              fullWidth
              error={hasError && !formData.id_number.length}
              value={formData.id_number}
              helperText={
                hasError && !formData.id_number.length ? "Required" : ""
              }
            />
          </div>
        </Grid>
      </Grid>
      <Typography
        className={`${classes.subHeading} ${classes.sectionTopMargin}`}
        variant="h6"
      >
        Location Details
      </Typography>
      <Grid container spacing={3}>
        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <div className={classes.inputGroup}>
            <label className={classes.label}>Address Line</label>
            <TextField
              onChange={(e) => {
                setFormData({
                  ...formData,
                  address: {
                    ...formData.address,
                    [e.target.name]: e.target.value,
                  },
                });
              }}
              name="line1"
              label=""
              variant="filled"
              fullWidth
              error={hasError && !formData?.address?.line1?.length}
              value={formData.address.line1}
              helperText={
                hasError && !formData.address.line1.length ? "Required" : ""
              }
            />
          </div>
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <div className={classes.inputGroup}>
            <label className={classes.label}>City</label>
            <TextField
              onChange={(e) => {
                setFormData({
                  ...formData,
                  address: {
                    ...formData.address,
                    [e.target.name]: e.target.value,
                  },
                });
              }}
              name="city"
              label=""
              variant="filled"
              fullWidth
              error={hasError && !formData.address.city.length}
              value={formData.address.city}
              helperText={
                hasError && !formData.address.city.length ? "Required" : ""
              }
            />
          </div>
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <div className={classes.inputGroup}>
            <label className={classes.label}>State</label>
            <FormControl
              variant="filled"
              fullWidth
              className={classes.formControl}
            >
              <Select
                MenuProps={{
                  classes: { paper: classes.menuPaper },
                }}
                value={formData?.address?.state}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    address: {
                      ...formData.address,
                      state: e.target.value,
                    },
                  });
                }}
                name="state"
                error={hasError && !formData?.address?.state}
              >
                {states.map((item) => (
                  <MenuItem key={`states-${item.id}`} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
              {hasError && !formData.licStateId ? (
                <FormHelperText>Required</FormHelperText>
              ) : null}
            </FormControl>
          </div>
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <div className={classes.inputGroup}>
            <label className={classes.label}>Country</label>
            <TextField
              readOnly
              label=""
              variant="filled"
              fullWidth
              value={"United States"}
            />
          </div>
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
          <div className={classes.inputGroup}>
            <label className={classes.label}>Postal Code</label>
            <TextField
              onChange={(e) => {
                setFormData({
                  ...formData,
                  address: {
                    ...formData.address,
                    [e.target.name]: e.target.value,
                  },
                });
              }}
              name="postal_code"
              label=""
              variant="filled"
              fullWidth
              error={hasError && !formData.address.postal_code.length}
              value={formData.address.postal_code}
              helperText={
                hasError && !formData.address.postal_code.length
                  ? "Required"
                  : ""
              }
            />
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default AddPerson;
