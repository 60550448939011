import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Box, Container, Grid, useMediaQuery } from "@material-ui/core";
import VerticalMenu from "../components/VerticalMenu";
import utils from "../helpers/utils";
import { Redirect } from "react-router";
import { connect } from "react-redux";
import AddPaymentMethodForm from "../components/AddPaymentMethodForm";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "calc(100vh - 110px)",
    paddingTop: theme.spacing(30),
  },
  contentSection: {
    boxShadow: theme.section.boxShadow,
    borderRadius: theme.section.borderRadius,
    backgroundColor: "#fff",
    padding: theme.spacing(4),
  },
  label: {
    display: "block",
    fontSize: "12px",
    color: theme.paragraph.color,
  },
  value: {
    display: "block",
    fontWeight: 600,
    color: theme.paragraphDark.color,
  },
  infoWrapper: {
    marginTop: theme.spacing(3),
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  divider: {
    height: "4px",
    marginTop: theme.spacing(1),
    width: theme.spacing(5),
    backgroundColor: theme.palette.primary.main,
  },
  sectionHeading: {
    fontWeight: 600,
  },
}));

const AddPaymentMethod = (props) => {
  document.body.style.backgroundColor = "#F7F7F7";

  const classes = useStyles();

  const theme = useTheme();

  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const xs = useMediaQuery(theme.breakpoints.down("xs"));

  const { user } = props;
  const role = utils.getRole(user);

  if (role === "doctor" && !user.isApplicationAccepted) {
    return <Redirect to="/application-status" />;
  }

  return (
    <Container maxWidth="lg" className={classes.root}>
      <Grid container direction="row" className={classes.gridWrapper}>
        {!sm && !xs ? (
          <Grid item xl={3} lg={3} md={3}>
            <VerticalMenu active="account" />
          </Grid>
        ) : null}

        <Grid item xl={9} lg={9} md={9} sm={12}>
          <Box component="div" className={classes.contentSection}>
            <Typography className={classes.sectionHeading} variant="h6">
              Add Payment Method
            </Typography>
            <div className={classes.divider} />
            <AddPaymentMethodForm />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    isProcessing: state.auth0.isProcessing,
    user: state.auth0.user,
    auth: state.auth0.auth,
  };
};

export default connect(mapStateToProps)(AddPaymentMethod);
