import { useEffect, useState } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import {
  TextField,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Loading from "../../components/Loading";
import VisibilityIcon from "@material-ui/icons/VisibilityOutlined";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOffOutlined";
import Alert from "../../components/Alert";
import axios from "axios";
import { useHistory } from "react-router-dom";
import utils from "../../helpers/utils";

import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(15),

    height: "100vh",
    transition: "background-image 5s ease-in-out",
  },

  formWrapper: {
    marginTop: theme.spacing(4),
  },
  headingVerticalSpacing: {
    marginBottom: theme.spacing(3),
  },
  textBold: {
    fontWeight: 700,
  },
  description: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(4),
  },
  runningText: {
    lineHeight: "26px",
  },
  mainContentWrapper: {
    backgroundColor: "#fff",
    padding: theme.spacing(6),
    borderRadius: theme.spacing(2),
  },
  label: {
    marginBottom: theme.spacing(1),
    display: "block",
  },
  submitBtnWrapper: {
    marginTop: theme.spacing(5),
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
  },
  labelWithIcon: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  noDecoration: {
    textDecoration: "none",
    color: theme.textSeagreen,
    cursor: "pointer",
  },
  smallHeading: {
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(3),
    fontWeight: "600",
  },
  formControlLabel: {
    alignItems: "flex-start !important",
    "& span": {
      fontSize: "15px !important",
    },
    "& .MuiButtonBase-root": {
      paddingTop: 0,
    },
  },

  textCenter: {
    textAlign: "center",
    marginTop: theme.spacing(4),
  },
  btnContained: {
    backgroundColor: theme.backgroundSeagreen,
    color: "#fff",
    "&:hover": {
      backgroundColor: theme.backgroundSeagreenDark,
    },
  },
}));
const formInitialValues = {
  first_name: "",
  last_name: "",
  email: "",
  // phone_number: "",
  password: "",
  confirm_password: "",
  role: "",
};
const Form = (props) => {
  document.body.style.background = "#fff";

  const classes = useStyles();

  const [formData, setFormData] = useState(formInitialValues);
  const [isProcessing, setIsProcessing] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [visible, setVisible] = useState(false);
  const [roles, setRoles] = useState([]);
  const [alert, setAlert] = useState({ status: "", message: "" });
  const [btnText, setBtnText] = useState(
    props.formType === "create" ? "Create" : "Update"
  );

  const history = useHistory();

  useEffect(() => {
    setIsLoading(false);
    getRoles();
    populateFormFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]:
        e.target.name === "phone_number"
          ? utils.formatPhone(e.target.value)
          : e.target.value,
    });
  };

  const populateFormFields = () => {
    if (props?.admin?.email) {
      let admin = props.admin;
      let obj = {
        user_id: admin?.account?.authId,
        first_name: admin?.firstName,
        last_name: admin?.lastName,
        email: admin?.email,
        role: admin?.roleId,
      };
      setFormData(obj);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isValidated()) {
      return;
    }
    if (formData.password) {
      if (formData.password !== formData.confirm_password) {
        setHasError(true);
        return;
      }
    }

    setIsProcessing(true);
    setBtnText("Processing");
    let url;
    if (props.formType === "create") {
      url = `${process.env.REACT_APP_SERVER_URL}/create-admin`;
    } else {
      url = `${process.env.REACT_APP_SERVER_URL}/update-admin`;
    }

    const res = await axios
      .post(url, formData)
      .then((res) => res)
      .catch((err) => err);

    if (res?.response?.status === 500) {
      setAlert({
        status: "error",
        message: "something went wrong please try again.",
      });
      return;
    }
    if (res?.response?.status >= 400) {
      setAlert({ status: "error", message: res?.response?.data?.message });
      let text = props.formType === "create" ? "Create" : "Update";
      setBtnText(text);
      setIsProcessing(false);
      return;
    }

    setAlert({
      status: "success",
      message: `Admin ${
        props.formType === "create" ? "Created" : "Updated"
      } successfully`,
    });
    setFormData(formInitialValues);
    history.push("/admins");
  };

  const isValidated = () => {
    for (let key in formData) {
      if (typeof formData[key] !== "undefined" && !formData[key]) {
        setHasError(true);
        return false;
      }
    }
    return true;
  };

  const getRoles = async () => {
    let url = `${process.env.REACT_APP_SERVER_URL}/roles`;
    const res = await axios.get(url);
    if (res.status === 200) {
      const supportedRoles = ["admin", "editor", "support"];
      setRoles(res?.data.filter((role) => supportedRoles.includes(role.name)));
    }
  };

  if (isLoading) {
    return <Loading />;
  }
  return (
    <div className={classes.formWrapper}>
      {alert.status ? (
        <Alert status={alert.status} message={alert.message} />
      ) : null}
      <form onSubmit={handleSubmit}>
        <div className={classes.section}>
          <Grid container direction="row" spacing={3}>
            <Grid item xs={12} md={6}>
              <div className={classes.inputGroup}>
                <label className={classes.label}>First Name</label>
                <TextField
                  onChange={handleChange}
                  name="first_name"
                  label=""
                  variant="filled"
                  fullWidth
                  error={hasError && !formData?.first_name?.length}
                  value={formData.first_name || ""}
                  helperText={
                    hasError && !formData?.first_name?.length ? "Required" : ""
                  }
                />
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <div className={classes.inputGroup}>
                <label className={classes.label}>Last Name</label>
                <TextField
                  onChange={handleChange}
                  name="last_name"
                  label=""
                  variant="filled"
                  fullWidth
                  error={hasError && !formData.last_name.length}
                  value={formData.last_name || ""}
                  helperText={
                    hasError && !formData.last_name.length ? "Required" : ""
                  }
                />
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <div className={classes.inputGroup}>
                <label className={classes.label}>Email</label>
                <TextField
                  onChange={handleChange}
                  name="email"
                  label=""
                  variant="filled"
                  fullWidth
                  error={hasError && !formData.email.length}
                  value={formData.email || ""}
                  helperText={
                    hasError && !formData.email.length ? "Required" : ""
                  }
                />
              </div>
            </Grid>
            {/* <Grid item xs={12} md={6}>
              <div className={classes.inputGroup}>
                <label className={classes.label}>Phone Number</label>
                <TextField
                  onChange={handleChange}
                  name="phone_number"
                  label=""
                  variant="filled"
                  fullWidth
                  error={hasError && !formData.phone_number.length}
                  value={formData.phone_number || ""}
                  helperText={
                    hasError && !formData.phone_number.length ? "Required" : ""
                  }
                />
              </div>
            </Grid> */}
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <div className={classes.inputGroup}>
                <label className={classes.label}>Roles</label>
                <FormControl
                  variant="filled"
                  fullWidth
                  className={classes.formControl}
                >
                  <Select
                    MenuProps={{
                      classes: { paper: classes.menuPaper },
                    }}
                    value={formData.role}
                    onChange={handleChange}
                    name="role"
                    error={hasError && !formData.role.length}
                  >
                    {roles.map((role) => (
                      <MenuItem
                        key={role.id}
                        value={role.id}
                        style={{ textTransform: "capitalize" }}
                      >
                        {role.name}
                      </MenuItem>
                    ))}
                  </Select>
                  {hasError && !formData.role ? (
                    <FormHelperText>Required</FormHelperText>
                  ) : null}
                </FormControl>
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <div className={classes.inputGroup}>
                <div className={classes.labelWithIcon}>
                  <label>Password</label>
                  <IconButton
                    disableRipple={true}
                    onClick={() => setVisible(!visible)}
                    style={{ padding: "2px" }}
                  >
                    {!visible ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  </IconButton>
                </div>

                <TextField
                  onChange={handleChange}
                  name="password"
                  label=""
                  variant="filled"
                  fullWidth
                  type={visible ? "text" : "password"}
                  error={hasError && !formData.password.length}
                  value={formData.password || ""}
                  helperText={
                    hasError && !formData.password.length ? "Required" : ""
                  }
                />
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <div className={classes.inputGroup}>
                <div className={classes.labelWithIcon}>
                  <label>Confirm Password</label>
                  <IconButton
                    disableRipple={true}
                    onClick={(e) => setVisible(!visible)}
                    style={{ padding: "2px" }}
                  >
                    {!visible ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  </IconButton>
                </div>

                <TextField
                  onChange={handleChange}
                  name="confirm_password"
                  label=""
                  variant="filled"
                  fullWidth
                  type={visible ? "text" : "password"}
                  error={hasError && !formData?.confirm_password?.length}
                  value={formData.confirm_password || ""}
                  helperText={
                    hasError && formData.confirm_password !== formData.password
                      ? "Password and confirm password should be the same"
                      : ""
                  }
                />
              </div>
            </Grid>
          </Grid>
        </div>

        <div className={classes.submitBtnWrapper}>
          <Button
            variant="contained"
            type="submit"
            className={classes.btnContained}
            disabled={isProcessing}
          >
            {btnText}
          </Button>
        </div>
      </form>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isProcessing: state.auth0.isProcessing,
    status: state.auth0.status,
    message: state.auth0.message,
    isAuthenticated: state.auth0.isAuthenticated,
  };
};

export default connect(mapStateToProps)(Form);
