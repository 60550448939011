import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.backgroundLight.color
  },
  loaderWrapper:{
    textAlign: "center",
    paddingBottom: "100px",
    "& > div":{
      marginTop: theme.spacing(2)
    }
  }
}));

const Loading = () => {
  const classes = useStyles();

   return(
     <div className={classes.root}>
       <div className={classes.loaderWrapper}>
         <CircularProgress color="primary" />
         <div>Loading..</div>
       </div>
        
     </div>
   )
};

export default Loading;