import utils from "../helpers/utils";
import { useSelector } from "react-redux";

const useHasPermission = ({ permission }) => {
  const user = useSelector((state) => state.auth0.user);

  let hasPermission = utils.hasPermission(user, permission);

  return { hasPermission };
};

export default useHasPermission;
