import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import HttpError from "../components/HttpError";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "calc(100vh - 110px)",
  },
  h6: {
    fontWeight: 600,
  },
  box: {
    maxWidth: "560px",
    marginLeft: "auto",
    marginRight: "auto",
    [theme.breakpoints.down("xs")]: {
      padding: `0 ${theme.spacing(4)}px`,
    },
  },
  forgotPassword: {
    textAlign: "right",
    "& a": {
      color: theme.paragraphDark.color,
      textDecoration: "none",
      position: "relative",
      bottom: "-10px",
    },
  },
  buttonWrapper: {
    marginTop: "30px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& a": {
      color: theme.paragraphDark.color,
      textDecoration: "none",
      position: "relative",
      bottom: "-10px",
      fontSize: theme.typography.fontSize,
    },
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  button: {
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  separator: {
    backgroundColor: theme.paragraphDark.color,
    maxHeight: "0",
    textAlign: "center",
    fontSize: "18px",
    fontWeight: 600,
    position: "relative",
    border: `solid 1px ${theme.border.color}`,

    marginTop: "50px",
    "& span": {
      color: theme.paragraphDark.color,
      position: "absolute",
      top: "-5px",
      bottom: 0,
      marginTop: "auto",
      marginBottom: "auto",
      height: "25px",
      width: "50px",
      left: 0,
      right: 0,
      marginLeft: "auto",
      marginRight: "auto",
      backgroundColor: theme.backgroundLight.color,
    },
  },
  facebookButton: {
    backgroundColor: "#3162A4",
    color: "#fff",
    width: "253px",
    "&:hover": {
      backgroundColor: "#29538B",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  googleButton: {
    backgroundColor: "#F07878",
    color: "#fff",
    width: "253px",
    "&:hover": {
      backgroundColor: "#DB5454",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginTop: theme.spacing(2),
    },
  },
}));

const Error = (props) => {
  const classes = useStyles();

  document.body.style.backgroundColor = "#F7F7F7";

  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      className={classes.root}
      spacing={3}
    >
      <Grid item xs={12} xl={12}>
        <div className={classes.box}>
          <HttpError code={props.code} />
        </div>
      </Grid>
    </Grid>
  );
};

export default Error;
