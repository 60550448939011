import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Container, useMediaQuery } from "@material-ui/core";
import Logo from "./Logo";
import DesktopMenu from "./DesktopMenu";
import MobileMenu from "./MobileMenu";
import AuthenticatedDesktopMenu from "./AuthenticatedDesktopMenu";
import AuthenticatedMobileMenu from "./AuthenticatedMobileMenu";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import useScroll from "../hooks/useScroll";

const useStyles = makeStyles((theme) => ({
  transparentBar: {
    backgroundColor: "transparent !important",
    boxShadow: "none",
  },
  navLink: {
    margin: "0 32px",
    "&:last-child": {
      marginRight: "100px",
    },
    [theme.breakpoints.down("lg")]: {
      margin: "0 10px",
    },
  },

  logo: {
    maxWidth: "380px",
    padding: 0,
    margin: 0,
    "& img": {
      width: "100%",
      marginTop: "5px",
    },
  },
}));

const Navbar = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  const { isAuthenticated } = props;
  const { scrollTop } = useScroll();

  if (isAuthenticated) {
    return (
      <AppBar
        position="fixed"
        color={
          props.location.pathname === "/" && scrollTop === 0
            ? "primary"
            : "default"
        }
        className={props.location.pathname === "/" && scrollTop === 0 ? "" : ""}
      >
        <Container maxWidth="xl">
          <Toolbar>
            <Logo className={classes.logo} />

            {sm ? (
              <AuthenticatedMobileMenu
                navType={
                  props.location.pathname === "/" && scrollTop === 0
                    ? "white"
                    : ""
                }
              />
            ) : (
              <AuthenticatedDesktopMenu
                navType={
                  props.location.pathname === "/" && scrollTop === 0
                    ? "white"
                    : ""
                }
              />
            )}
          </Toolbar>
        </Container>
      </AppBar>
    );
  }

  return (
    <AppBar
      className={props.location.pathname === "/" && scrollTop === 0 ? "" : ""}
      position="fixed"
      color={
        props.location.pathname === "/" && scrollTop === 0
          ? "default"
          : "default"
      }
    >
      <Container maxWidth="lg">
        <Toolbar>
          <Logo
            className={classes.logo}
            type={
              props.location.pathname === "/" && scrollTop === 0 ? "white" : ""
            }
          />

          {sm ? (
            <MobileMenu
              navType={
                props.location.pathname === "/" && scrollTop === 0
                  ? "white"
                  : ""
              }
            />
          ) : (
            <DesktopMenu
              navType={
                props.location.pathname === "/" && scrollTop === 0
                  ? "white"
                  : ""
              }
            />
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
};

const mapStateToProps = (state) => {
  return {
    isProcessing: state.auth0.isProcessing,
    status: state.auth0.status,
    message: state.auth0.message,

    isAuthenticated: state.auth0.isAuthenticated,
  };
};

export default connect(mapStateToProps)(withRouter(Navbar));
