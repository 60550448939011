import List from "@material-ui/core/List/List";
import ListItem from "@material-ui/core/ListItem/ListItem";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import Menu from "@material-ui/core/Menu/Menu";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import ArrowDownIcon from "@material-ui/icons/ArrowDropDownOutlined";
import UserIcon from "@material-ui/icons/PersonOutlineOutlined";
import { NavLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { useRef, useState } from "react";

const useStyles = makeStyles((theme) => ({
  navLink: {
    margin: "0 32px 0 0",
  },
  menuItemMarginRight: {
    marginRight: "20px",
  },
  dropdownLink: {
    color: theme.paragraphDark.color,
    textDecoration: "none",
    minWidth: "200px",
    display: "flex",
  },
  userIcon: {
    marginRight: theme.spacing(1),
  },
  nav: {
    display: "flex",
    justifyContent: "center",
  },
  button: {
    maxHeight: "48px",
    alignSelf: "center",
    marginLeft: "32px",
    marginRight: "32px",
    padding: "10px 50px",
    color: "#fff",
    "& span": {
      color: "#ffffff",
    },
  },
  navItemBordered: {
    borderLeft: `solid 1px ${theme.border.color}`,
    borderRight: `solid 1px ${theme.border.color}`,

    padding: "0 32px",
    height: "110px",
  },
  menuIcon: {
    color: "#fff",
  },
}));

const DesktopMenu = () => {
  const classes = useStyles();

  const signUpMenuRef = useRef(null);

  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [anchorEl0, setAnchorEl0] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  const handleClick0 = (event) => {
    setAnchorEl0(event.currentTarget);
  };

  const handleClose0 = () => {
    setAnchorEl0(null);
  };

  return (
    <List component="nav" className={classes.nav} aria-label="main navigation">
      <div
        className={`${classes.lastNavLink} ${classes.menuItemMarginRight}`}
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        onMouseOver={handleClick}
      >
        <ListItem button disableRipple>
          <ListItemText primary="Resources" />
          <ArrowDownIcon />
        </ListItem>
      </div>

      <Menu
        id="simple-menu"
        className={classes.dropdownMenu}
        anchorEl={anchorEl}
        // keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        MenuListProps={{ onMouseLeave: handleClose }}
      >
        <MenuItem onClick={handleClose}>
          <NavLink className={classes.dropdownLink} to="/#home">
            Home
          </NavLink>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <NavLink className={classes.dropdownLink} to="/#types-of-care">
            Types of Care
          </NavLink>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <NavLink className={classes.dropdownLink} to="/#our-doctors">
            Our Doctors
          </NavLink>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <NavLink className={classes.dropdownLink} to="/#how-tova-works">
            How Tova Works
          </NavLink>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <NavLink className={classes.dropdownLink} to="/#what-we-dont-treat">
            What We Don't Treat
          </NavLink>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <NavLink className={classes.dropdownLink} to="/#faq">
            FAQ
          </NavLink>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <NavLink className={classes.dropdownLink} to="/about">
            About Us
          </NavLink>
        </MenuItem>
      </Menu>
      <div
        ref={signUpMenuRef}
        className={`${classes.lastNavLink} ${classes.menuItemMarginRight}`}
        aria-controls="simple-menu0"
        aria-haspopup="true"
        onClick={handleClick0}
        onMouseOver={handleClick0}
      >
        <ListItem button disableRipple>
          <ListItemText primary="Log In" />
          <ArrowDownIcon />
        </ListItem>
      </div>

      <Menu
        id="simple-menu0"
        className={classes.dropdownMenu}
        anchorEl={anchorEl0}
        keepMounted
        open={Boolean(anchorEl0)}
        onClose={handleClose0}
        MenuListProps={{ onMouseLeave: handleClose0 }}
      >
        <MenuItem onClick={handleClose0}>
          <NavLink className={classes.dropdownLink} to="/individual/login">
            <UserIcon className={classes.userIcon} /> Log In as Patient
          </NavLink>
        </MenuItem>
        <MenuItem onClick={handleClose0}>
          <NavLink className={classes.dropdownLink} to="/doctor/login">
            <UserIcon className={classes.userIcon} /> Log In as Doctor
          </NavLink>
        </MenuItem>
      </Menu>

      <div
        className={`${classes.lastNavLink}`}
        aria-controls="simple-menu2"
        aria-haspopup="true"
        onClick={handleClick2}
        onMouseOver={handleClick2}
      >
        <ListItem button disableRipple>
          <ListItemText primary="Sign Up" />
          <ArrowDownIcon />
        </ListItem>
      </div>

      <Menu
        id="simple-menu2"
        className={classes.dropdownMenu}
        anchorEl={anchorEl2}
        keepMounted
        open={Boolean(anchorEl2)}
        onClose={handleClose2}
        MenuListProps={{ onMouseLeave: handleClose2 }}
      >
        <MenuItem onClick={handleClose2}>
          <NavLink className={classes.dropdownLink} to="/individual/signup">
            <UserIcon className={classes.userIcon} /> Sign Up as Patient
          </NavLink>
        </MenuItem>
        <MenuItem onClick={handleClose2}>
          <NavLink className={classes.dropdownLink} to="/doctor/signup">
            <UserIcon className={classes.userIcon} /> Sign Up as Doctor
          </NavLink>
        </MenuItem>
      </Menu>
    </List>
  );
};

export default DesktopMenu;
