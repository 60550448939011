import { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import utils from "../../helpers/utils";
import Loading from "../../components/Loading";
import axios from "axios";
import Alert from "../../components/Alert";
import { connect } from "react-redux";
import Chip from "@material-ui/core/Chip";
import { KeyboardDatePicker } from "@material-ui/pickers";
import AdditionalLicenses from "../../components/AdditionalLicenses";
import { NavLink } from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBackOutlined";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(30),
    paddingBottom: theme.spacing(10),
    minHeight: "100vh",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      backgroundSize: "contained",
      //minHeight: "932px",
      backgroundPosition: "-500px",
    },
  },
  icon: {
    fontSize: "2.5rem",
    marginRight: theme.spacing(2),
  },
  divider: {
    height: "4px",
    marginTop: theme.spacing(1),
    width: theme.spacing(5),
    backgroundColor: theme.palette.primary.main,
  },
  headingWrapper: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(4),
  },
  navLink: {
    color: "#000",
  },
  mainContentWrapper: {
    backgroundColor: "#fff",
    padding: theme.spacing(6),
    paddingTop: theme.spacing(1),
    borderRadius: theme.spacing(2),
    position: "relative",
    zIndex: 11,
    marginBottom: "100px",
    boxShadow: theme.section.boxShadow,
  },
  textOverlay: {
    //backgroundColor: "rgba(0,0,0,0.5)",
    position: "relative",
    zIndex: 11,
    padding: theme.spacing(2),
    borderRadius: theme.spacing(2),
    "& *": {
      color: "white",
    },
  },
  section: {
    marginTop: theme.spacing(8),
  },
  headingVerticalSpacing: {
    marginBottom: theme.spacing(3),
  },
  textBold: {
    fontWeight: 700,
  },
  tinyFieldsWrapper: {
    display: "flex",
    alignItems: "center",
  },
  tinyField: {
    width: "80px",
  },
  alignRight: {
    textAlign: "right",
  },
  slash: {
    color: "#aaa",
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    fontSize: "1.5rem",
  },
  label: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    display: "block",
  },
  runningText: {
    lineHeight: "26px",
  },
  descriptionWrapper: {
    marginBottom: theme.spacing(5),
  },
  submitBtnWrapper: {
    textAlign: "right",
    marginTop: theme.spacing(5),
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  select: {
    "& #spaciality-select-filled > div": {
      display: "flex",
      flexWrap: "wrap",
    },
  },
  menuPaper: {
    maxHeight: "300px",
  },
  btnContained: {
    backgroundColor: theme.backgroundSeagreen,
    color: "#fff",
    "&:hover": {
      backgroundColor: theme.backgroundSeagreenDark,
    },
  },
}));

const EditDoctor = (props) => {
  document.body.style.background = "#fff";

  const classes = useStyles();

  const degrees = [
    "MD",
    "DO",
    "MBBS",
    "MBChB",
    "DMD",
    "DDS",
    "DPM",
    "EdD",
    "PsyD",
    "PhD",
    "PharmD",
  ];
  const years = utils.getYearsList();

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    dob: new Date(),
    medicalSchool: "",
    degree: "",
    graduationYear: years[0],
    licCountry: "",
    licNumber: "",
    licExpDate: new Date(),
    licStateId: "",
    specialtyId: [],
    appliedForLicensure: false,
    personalNpiNumber: "",
    additionalLicenses: [],
    phoneNumber: "",
    password: "",
  });

  const [isLoading, setIsLoading] = useState(true);
  const [isProcessing, setIsProcessing] = useState(false);
  const [states, setStates] = useState([]);
  const [specialities, setSpecialities] = useState([]);
  const [hasError, setHasError] = useState(false);
  const [toast, setToast] = useState({ open: false, status: "", message: "" });
  const timedToast = useRef(null);

  useEffect(() => {
    fetchRefs();
  }, []);

  // Listeners

  useEffect(() => {
    let id = props.match.params.id;

    const fetchDoctor = async () => {
      await axios
        .get(`${process.env.REACT_APP_SERVER_URL}/accounts/doctor/${id}/show`)
        .then((res) => {
          if (res?.data?.firstName) {
            let {
              firstName,
              lastName,
              dob,
              medicalSchool,
              degree,
              graduationYear,
              licCountry,
              licNumber,
              licExpDate,
              specialties,
              appliedForLicensure,
              personalNpiNumber,
              licenses,
              phoneNumber,
              email,
              state,
            } = res.data;

            setFormData({
              firstName: firstName ? firstName : "",
              lastName: lastName ? lastName : "",
              dob: dob ? dob : new Date(),
              medicalSchool: medicalSchool ? medicalSchool : "",
              degree: degree ? degree : "",
              graduationYear: graduationYear ? graduationYear : years[0],
              licCountry: licCountry ? licCountry : "",
              licNumber: licNumber ? licNumber : "",
              licExpDate: licExpDate ? licExpDate : new Date(),
              licStateId: state?.id ? state?.id : "",
              appliedForLicensure: appliedForLicensure
                ? appliedForLicensure
                : false,
              personalNpiNumber: personalNpiNumber ? personalNpiNumber : "",
              phoneNumber: phoneNumber ? phoneNumber : "",
              email: email ? email : "",
              additionalLicenses: licenses.length ? licenses : [],
              specialtyId: specialties.length
                ? specialties.map((el) => el.id)
                : [],
            });
          }
        })
        .catch((err) => {});
    };

    fetchDoctor();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.match.params.id]);

  const fetchRefs = async () => {
    try {
      const response = await axios({
        url: `${process.env.REACT_APP_SERVER_URL}/public/refs`,
        method: "get",
      });

      setStates(response.data.states);
      setSpecialities(response.data.specialties);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (e) => {
    if (e.target.name === "appliedForLicensure") {
      let checked = false;

      if (e.target.value === "true") {
        checked = true;
      } else {
        checked = false;
      }

      setFormData({
        ...formData,
        [e.target.name]: checked,
      });
    } else {
      setFormData({
        ...formData,
        [e.target.name]:
          e.target.name === "phoneNumber"
            ? utils.formatPhone(e.target.value)
            : e.target.value,
      });
    }
  };

  const handleDateChange = (date, field) => {
    //console.log(date, field);
    setFormData({
      ...formData,
      [field]: date,
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isValidated()) {
      setIsProcessing(true);

      await axios
        .put(
          `${process.env.REACT_APP_SERVER_URL}/accounts/doctor/${props.match.params.id}/edit`,
          formData
        )
        .then((res) => {
          if (res?.data?.status === "success") {
            setToast({
              open: true,
              status: "success",
              message: "Details are updated successfully",
            });
          } else {
            setToast({
              open: true,
              status: "error",
              message: "Failed to update",
            });
          }
        })
        .catch((err) => {
          setToast({
            open: true,
            status: "error",
            message: "Failed to update",
          });
        });

      setIsProcessing(false);
      resetToast();
    }
  };

  const resetToast = () => {
    const hideToast = () => {
      setToast({ open: false, status: "", message: "" });
      clearTimeout(timedToast.current);
    };
    timedToast.current = hideToast;
    setTimeout(timedToast.current, 2000);
  };

  const isValidated = () => {
    for (let key in formData) {
      if (
        typeof formData[key] !== "undefined" &&
        key !== "personalNpiNumber" &&
        key !== "additionalLicenses" &&
        key !== "appliedForLicensure" &&
        !formData[key]
      ) {
        setHasError(true);
        return false;
      }
    }
    return true;
  };

  const handleAdditionalLicences = (list) => {
    setFormData({ ...formData, additionalLicenses: list });
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className={classes.root}>
      <div className={classes.bgOverlay} />
      {toast.open ? (
        <Alert status={toast.status} message={toast.message} />
      ) : null}

      <Container>
        <div className={classes.headingWrapper}>
          <NavLink className={classes.navLink} to="/account">
            <ArrowBackIcon className={classes.icon} />
          </NavLink>
          <Typography variant="h5">
            Update Details
            <div className={classes.divider} />
          </Typography>
        </div>
        <div className={classes.mainContentWrapper}>
          <div className={classes.formWrapper}>
            <form onSubmit={handleSubmit}>
              <div className={classes.section}>
                <Typography
                  variant="h5"
                  className={`${classes.textBold} ${classes.headingVerticalSpacing}`}
                >
                  Basic Info
                </Typography>
                <Grid container direction="row" spacing={3}>
                  <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <div className={classes.inputGroup}>
                      <label className={classes.label}>First Name</label>
                      <TextField
                        onChange={handleChange}
                        name="firstName"
                        label=""
                        variant="filled"
                        fullWidth
                        error={hasError && !formData.firstName.length}
                        value={formData.firstName}
                        helperText={
                          hasError && !formData.firstName.length
                            ? "Required"
                            : ""
                        }
                      />
                    </div>
                  </Grid>

                  <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <div className={classes.inputGroup}>
                      <label className={classes.label}>Last Name</label>
                      <TextField
                        onChange={handleChange}
                        type="text"
                        name="lastName"
                        label=""
                        variant="filled"
                        fullWidth
                        error={hasError && !formData.lastName.length}
                        value={formData.lastName}
                        helperText={
                          hasError && !formData.lastName.length
                            ? "Required"
                            : ""
                        }
                      />
                    </div>
                  </Grid>
                  <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <div className={classes.inputGroup}>
                      <label className={classes.label}>Email</label>
                      <TextField
                        onChange={handleChange}
                        name="email"
                        label=""
                        variant="filled"
                        fullWidth
                        error={hasError && !formData.email}
                        value={formData.email}
                        helperText={
                          hasError && !formData.email ? "Required" : ""
                        }
                      />
                    </div>
                  </Grid>
                  <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <div className={classes.inputGroup}>
                      <label className={classes.label}>Password</label>
                      <TextField
                        onChange={handleChange}
                        name="password"
                        label=""
                        variant="filled"
                        fullWidth
                        error={hasError && !formData.passwordd}
                        value={formData.passwordd}
                        helperText={
                          hasError && !formData.passwordd ? "Required" : ""
                        }
                      />
                    </div>
                  </Grid>
                  <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <div className={classes.inputGroup}>
                      <label className={classes.label}>Date of birth</label>
                      <KeyboardDatePicker
                        autoOk
                        disableFuture
                        fullWidth
                        inputVariant={"filled"}
                        variant="inline"
                        name="dob"
                        format="MM/DD/YYYY"
                        views={["year", "month", "date"]}
                        onChange={(date) => handleDateChange(date?.$d, "dob")}
                        error={hasError && !formData.dob}
                        value={formData.dob}
                        helperText={hasError && !formData.dob ? "Required" : ""}
                      />
                    </div>
                  </Grid>
                  <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <div className={classes.inputGroup}>
                      <label className={classes.label}>Phone number</label>
                      <TextField
                        type="phone"
                        fullWidth
                        variant="filled"
                        name="phoneNumber"
                        onChange={handleChange}
                        error={hasError && !formData.phoneNumber}
                        value={formData.phoneNumber}
                        helperText={
                          hasError && !formData.phoneNumber ? "Required" : ""
                        }
                      />
                    </div>
                  </Grid>
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <div className={classes.inputGroup}>
                      <label className={classes.label}>
                        Medical/Graduate School
                      </label>
                      <TextField
                        onChange={handleChange}
                        name="medicalSchool"
                        label=""
                        variant="filled"
                        fullWidth
                        error={hasError && !formData.medicalSchool.length}
                        value={formData.medicalSchool}
                        helperText={
                          hasError && !formData.medicalSchool.length
                            ? "Required"
                            : ""
                        }
                      />
                    </div>
                  </Grid>

                  <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <div className={classes.inputGroup}>
                      <label className={classes.label}>Degree</label>
                      <FormControl
                        variant="filled"
                        fullWidth
                        className={classes.formControl}
                      >
                        <Select
                          MenuProps={{
                            classes: { paper: classes.menuPaper },
                          }}
                          labelId="degree-select-filled-label"
                          id="degree-select-filled"
                          value={formData.degree}
                          onChange={handleChange}
                          name="degree"
                          error={hasError && !formData.degree.length}
                        >
                          {degrees.map((item) => (
                            <MenuItem key={`degree-${item}`} value={item}>
                              {item}
                            </MenuItem>
                          ))}
                        </Select>
                        {hasError && !formData.degree ? (
                          <FormHelperText>Required</FormHelperText>
                        ) : null}
                      </FormControl>
                    </div>
                  </Grid>
                  <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <div className={classes.inputGroup}>
                      <label className={classes.label}>Graduation year</label>
                      <FormControl
                        variant="filled"
                        fullWidth
                        className={classes.formControl}
                      >
                        <Select
                          MenuProps={{
                            classes: { paper: classes.menuPaper },
                          }}
                          labelId="graduation-year-label"
                          id="graduation-year"
                          value={formData.graduationYear}
                          onChange={handleChange}
                          name="graduationYear"
                          error={hasError && !formData.graduationYear}
                        >
                          {years.map((item) => (
                            <MenuItem
                              key={`graduation-year-${item}`}
                              value={item}
                            >
                              {item}
                            </MenuItem>
                          ))}
                        </Select>
                        {hasError && !formData.graduationYear ? (
                          <FormHelperText>Required</FormHelperText>
                        ) : null}
                      </FormControl>
                    </div>
                  </Grid>
                </Grid>
              </div>

              <div className={classes.section}>
                <Typography
                  variant="h5"
                  className={`${classes.textBold} ${classes.headingVerticalSpacing}`}
                >
                  License
                </Typography>
                <Grid container direction="row" spacing={3}>
                  <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <div className={classes.inputGroup}>
                      <label className={classes.label}>Country</label>
                      <TextField
                        onChange={handleChange}
                        name="licCountry"
                        label=""
                        variant="filled"
                        fullWidth
                        error={hasError && !formData.licCountry.length}
                        value={formData.licCountry}
                        helperText={
                          hasError && !formData.licCountry.length
                            ? "Required"
                            : ""
                        }
                      />
                    </div>
                  </Grid>
                  <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <div className={classes.inputGroup}>
                      <label className={classes.label}>
                        Personal NPI number (optional)
                      </label>
                      <TextField
                        onChange={handleChange}
                        name="personalNpiNumber"
                        label=""
                        variant="filled"
                        fullWidth
                        error={hasError && !formData.personalNpiNumber}
                        value={formData.personalNpiNumber}
                        helperText={
                          hasError && !formData.personalNpiNumber
                            ? "Required"
                            : ""
                        }
                      />
                    </div>
                  </Grid>
                  <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                    <div className={classes.inputGroup}>
                      <label className={classes.label}>State/Province</label>
                      <FormControl
                        variant="filled"
                        fullWidth
                        className={classes.formControl}
                      >
                        <Select
                          MenuProps={{
                            classes: { paper: classes.menuPaper },
                          }}
                          labelId="state-select-filled-label"
                          id="state-select-filled"
                          value={formData.licStateId}
                          onChange={handleChange}
                          name="licStateId"
                          error={hasError && !formData.licStateId}
                        >
                          {states.map((item) => (
                            <MenuItem key={`states-${item.id}`} value={item.id}>
                              {item.name}
                            </MenuItem>
                          ))}
                        </Select>
                        {hasError && !formData.licStateId ? (
                          <FormHelperText>Required</FormHelperText>
                        ) : null}
                      </FormControl>
                    </div>
                  </Grid>
                  <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                    <div className={classes.inputGroup}>
                      <label className={classes.label}>License number</label>
                      <TextField
                        onChange={handleChange}
                        name="licNumber"
                        label=""
                        variant="filled"
                        fullWidth
                        error={hasError && !formData.licNumber}
                        value={formData.licNumber}
                        helperText={
                          hasError && !formData.licNumber ? "Required" : ""
                        }
                      />
                    </div>
                  </Grid>
                  <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                    <div className={classes.inputGroup}>
                      <label className={classes.label}>Expiration date</label>

                      <KeyboardDatePicker
                        autoOk
                        fullWidth
                        disableToolbar
                        inputVariant={"filled"}
                        variant="inline"
                        name="licExpDate"
                        format="MM/DD/YYYY"
                        views={["year", "month", "date"]}
                        onChange={(date) =>
                          handleDateChange(date?.$d, "licExpDate")
                        }
                        error={hasError && !formData.licExpDate}
                        value={formData.licExpDate}
                        helperText={
                          hasError && !formData.licExpDate ? "Required" : ""
                        }
                      />
                    </div>
                  </Grid>

                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <AdditionalLicenses
                      isEditing={true}
                      states={states}
                      additionalLicenses={formData.additionalLicenses}
                      handleAdditionalLicences={handleAdditionalLicences}
                    />
                  </Grid>

                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <FormControl component="fieldset">
                      <label className={classes.label}>
                        Have you applied for any licensure through the
                        Interstate Medical Licensure Compact (IMLC)?{" "}
                      </label>
                      <RadioGroup
                        aria-label="imlc"
                        name="appliedForLicensure"
                        value={formData.appliedForLicensure}
                        onChange={handleChange}
                      >
                        <FormControlLabel
                          value={true}
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value={false}
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Grid>
              </div>

              <div className={classes.section}>
                <Typography
                  variant="h5"
                  className={`${classes.textBold} ${classes.headingVerticalSpacing}`}
                >
                  Speciality
                </Typography>
                <Grid container direction="row" spacing={3}>
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <div className={classes.inputGroup}>
                      <label className={classes.label}>Speciality</label>
                      <FormControl
                        variant="filled"
                        fullWidth
                        className={classes.formControl}
                      >
                        <Select
                          MenuProps={{
                            classes: { paper: classes.menuPaper },
                          }}
                          multiple
                          className={classes.select}
                          labelId="spaciality-select-filled-label"
                          id="spaciality-select-filled"
                          value={formData.specialtyId}
                          onChange={handleChange}
                          name="specialtyId"
                          renderValue={(selected) => (
                            <div className={classes.chips}>
                              {selected.map((value, index) => (
                                <Chip
                                  key={value}
                                  label={utils.getSelectedSpeciality(
                                    value,
                                    specialities
                                  )}
                                  className={classes.chip}
                                  color="primary"
                                />
                              ))}
                            </div>
                          )}
                          error={hasError && !formData.specialtyId}
                        >
                          {specialities.map((item) => (
                            <MenuItem
                              key={`specialities-${item.id}`}
                              value={item.id}
                            >
                              {item.text}
                            </MenuItem>
                          ))}
                        </Select>
                        {hasError && !formData.specialtyId ? (
                          <FormHelperText>Required</FormHelperText>
                        ) : null}
                      </FormControl>
                    </div>
                  </Grid>
                </Grid>
              </div>

              <div className={classes.submitBtnWrapper}>
                <Button
                  variant="contained"
                  type="submit"
                  className={classes.btnContained}
                  disabled={isProcessing}
                >
                  {isProcessing ? "Processing" : "Submit"}
                </Button>
              </div>
            </form>
          </div>
        </div>
      </Container>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isProcessing: state.auth0.isProcessing,
    status: state.auth0.status,
    message: state.auth0.message,
    user: state.auth0.user,
    isAuthenticated: state.auth0.isAuthenticated,
  };
};

export default connect(mapStateToProps)(EditDoctor);
