import { Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles";


const useStyles = makeStyles((theme) => ({
    h1:{
        fontSize: "10rem",
        textAlign: "center",
        color: theme.palette.primary.main
    },
    h2:{
        color: theme.paragraph.color
    }


})); 

const HttpError  = ( props ) => {   

    const classes = useStyles();

    if( props.code === "404" ){
        return (
            <>
                <Typography className={ classes.h1 } align="center" variant="h1">{ props.code }</Typography>
                <Typography className={ classes.h2 } align="center" variant="h5">Page not found</Typography>
            </>
        )
    }
    else if( props.code === "401" ){
        return (
            <>
                <Typography className={ classes.h1 } align="center" variant="h1">{ props.code }</Typography>
                <Typography className={ classes.h2 } align="center" variant="h5">Not Authorized</Typography>
            </>
        )
    }
    else if( props.code === "403" ){
        return (
            <>
                <Typography className={ classes.h1 } align="center" variant="h1">{ props.code }</Typography>
                <Typography className={ classes.h2 } align="center" variant="h5">Forbidden</Typography>
            </>
        )
    }
    else if( props.code === "500" ){
        return (
            <>
                <Typography className={ classes.h1 } align="center" variant="h1">{ props.code }</Typography>
                <Typography className={ classes.h2 } align="center" variant="h5">Internal Server Error</Typography>
            </>
        )
    }
    return null;


}

export default HttpError;