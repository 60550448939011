import { useState, useEffect } from "react";

const useScroll = () => {
  const [scrollTop, setScrollTop] = useState(0);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrollTop]);

  const handleScroll = () => {
    setScrollTop(window.pageYOffset);
  };

  return { scrollTop };
};
export default useScroll;
