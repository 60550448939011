import { Button, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import utils from "../helpers/utils";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    width: "100%",
    bottom: 0,
    left: 0,
    padding: " 20px 30px",
    borderTop: "solid 1px #eee",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#eee",
    zIndex: 15,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      textAlign: "center",
    },
  },
  textWrapper: {
    paddingRight: "50px",
    [theme.breakpoints.down("sm")]: {
      padding: 0,
    },
  },
  buttonWrapper: {
    minWidth: "245px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "20px",
    },
  },
  btnLink: {
    color: theme.textSeagreen,
    boxShadow: "none",
    marginRight: "20px",
    "&:hover": {
      backgroundColor: "#fff",
    },
  },
  btnContained: {
    backgroundColor: theme.backgroundSeagreen,
    color: "#fff",
    "&:hover": {
      backgroundColor: theme.backgroundSeagreenDark,
    },
  },
}));

const CookiesConsent = () => {
  const classes = useStyles();
  const cookieName = "tova_cookie_consent";
  const expiryDays = 30;
  const cookieValue = true;

  const [accepted, setAccepted] = useState(false);
  const location = useLocation();
  useEffect(() => {
    if (!accepted) {
      let isCookieSet = utils.getCookie(cookieName);
      setAccepted(isCookieSet ? true : false);
    }
  }, [accepted]);

  const accept = () => {
    utils.setCookie(cookieName, cookieValue, expiryDays);
    let isCookieSet = utils.getCookie(cookieName);
    setAccepted(isCookieSet ? true : false);
  };

  const paymentPage =
    location.pathname.includes("individual") ||
    location.pathname.includes("doctor")
      ? true
      : false;
  if (accepted || paymentPage) return null;

  return (
    <div className={classes.root}>
      <div className={classes.textWrapper}>
        We are asking our website visitors to consent to the use of cookies by
        Tova Medical to continue to our website. We use cookies to enhance your
        site experience and for analytics purposes.{" "}
      </div>
      <div className={classes.buttonWrapper}>
        <Button component={NavLink} className={classes.btnLink} to="/cookies">
          Learn more
        </Button>
        <Button
          onClick={accept}
          variant="contained"
          className={classes.btnContained}
        >
          Accept
        </Button>
      </div>
    </div>
  );
};

export default CookiesConsent;
