import axios from "axios";
import React, { PureComponent } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import InputLabel from "@material-ui/core/InputLabel";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Input from "@material-ui/core/Input";
import Chip from "@material-ui/core/Chip";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import dayjs from "dayjs";
// import {
//   KeyboardDatePicker,
// } from '@material-ui/pickers';

class Doctor extends PureComponent {
  constructor(props) {
    super(props);
    this.baseUrl = process.env.REACT_APP_SERVER_URL;
    this.snackRef = React.createRef();
    this.state = {
      id: props.match.params.id,
      selectedState: "",
      selectedLicState: "",
      selectedIdeologies: [],
      selectedServices: [],
      selectedSpecialties: [],
      firstName: "",
      lastName: "",
      city: "",
      accreditation: "",
      dob: "",
      medicalSchool: "",
      degree: "",
      graduationYear: "",
      licCountry: "",
      licNumber: "",
      licExpDate: "",
      contact: "",
      availableStates: [],
      availableIdeologies: [],
      availableServices: [],
      availableSpecialties: [],
    };
    this.hydrateRefs();
    if (this.state.id) {
      this.fetchDoc(this.state.id);
    }
  }

  async fetchDoc(id) {
    const docResponse = await axios.get(`${this.baseUrl}/docs`, {
      params: {
        id,
      },
    });
    this.updateDocInState(docResponse.data);
  }

  updateDocInState(doc) {
    this.setState({
      selectedState: doc.state?.id,
      selectedLicState: doc.licState?.id,
      selectedIdeologies: doc.ideologies,
      selectedServices: doc.services,
      selectedSpecialties: doc.specialties,
      city: doc.city,
      firstName: doc.firstName,
      lastName: doc.lastName,
      accreditation: doc.accreditation, // Deprecated
      dob: doc.dob,
      medicalSchool: doc.medicalSchool,
      degree: doc.degree,
      graduationYear: doc.graduationYear,
      licCountry: doc.licCountry,
      licNumber: doc.licNumber,
      licExpDate: doc.licExpDate
        ? dayjs(doc.licExpDate).format("YYYY-MM-DD")
        : "",
      contact: doc.contact,
    });
  }

  async submit() {
    // Go from state to the server
    if (this.isValid()) {
      const id = this.state.id;
      const selectedStateId = this.state.selectedState;
      const selectedLicStateId = this.state.selectedLicState;
      const services = this.state.selectedServices;
      const ideologies = this.state.selectedIdeologies;
      const specialties = this.state.selectedSpecialties;
      const firstName = this.state.firstName;
      const lastName = this.state.lastName;
      const city = this.state.city;
      const accreditation = this.state.accreditation;
      const dob = this.state.dob;
      const medicalSchool = this.state.medicalSchool;
      const degree = this.state.degree;
      const graduationYear = this.state.graduationYear;
      const licCountry = this.state.licCountry;
      const licNumber = this.state.licNumber;
      const licExpDate = this.state.licExpDate;
      const contact = this.state.contact;
      try {
        const docResponse = await axios.post(`${this.baseUrl}/upsert-doc`, {
          id,
          state: { id: selectedStateId },
          licState: selectedLicStateId ? { id: selectedLicStateId } : undefined,
          services,
          ideologies,
          specialties,
          firstName,
          lastName,
          city,
          accreditation,
          dob,
          medicalSchool,
          degree,
          graduationYear,
          licCountry,
          licNumber,
          licExpDate,
          contact,
        });
        const doc = docResponse.data;
        this.updateDocInState(doc);
        alert("Saved!");
      } catch (e) {
        alert(`Uh oh: ${e.message}`);
      }
    } else {
      alert("Please fill out all fields before submitting");
    }
  }

  isValid() {
    return (
      this.state.selectedState &&
      this.state.selectedServices &&
      this.state.selectedIdeologies &&
      this.state.firstName &&
      this.state.lastName &&
      this.state.city &&
      this.state.accreditation
    );
  }

  async hydrateRefs() {
    const refs = await axios.get(`${this.baseUrl}/refs`);
    const availableStates = refs.data.states;
    const availableIdeologies = refs.data.ideologies;
    const availableServices = refs.data.services;
    const availableSpecialties = refs.data.specialties;
    this.setState({
      availableStates,
      availableIdeologies,
      availableServices,
      availableSpecialties,
    });
  }

  isChecked(selecteds, item) {
    if (!selecteds) {
      return false;
    }
    const items = selecteds.filter((selected) => selected.id === item.id);
    return items.length > 0;
  }

  async handleChange(event, ref) {
    await this.setState({
      [ref]: event.target.value,
    });
  }

  render() {
    const pageStyle = {
      padding: "20px",
      margin: "100px 20px 0",
    };
    const itemsStyle = {
      minWidth: "300px",
      margin: "10px 20px 10px 0",
    };
    return (
      <div style={pageStyle} className="doctor">
        <h1>{this.state.id === undefined ? "New" : "Edit"} Doctor</h1>
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <TextField
            style={itemsStyle}
            id="outlined-basic"
            label="First Name"
            variant="outlined"
            value={this.state.firstName}
            onChange={(event) => this.handleChange(event, "firstName")}
          />
          <TextField
            style={itemsStyle}
            id="outlined-basic"
            label="Last Name"
            variant="outlined"
            value={this.state.lastName}
            onChange={(event) => this.handleChange(event, "lastName")}
          />
          <TextField
            style={itemsStyle}
            id="outlined-basic"
            label="City"
            variant="outlined"
            value={this.state.city}
            onChange={(event) => this.handleChange(event, "city")}
          />
          <TextField
            style={itemsStyle}
            id="outlined-basic"
            label="DOB"
            type="date"
            variant="outlined"
            value={this.state.dob || ""}
            onChange={(event) => this.handleChange(event, "dob")}
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            style={itemsStyle}
            id="outlined-basic"
            label="Medical School"
            variant="outlined"
            value={this.state.medicalSchool || ""}
            onChange={(event) => this.handleChange(event, "medicalSchool")}
          />
          <TextField
            style={itemsStyle}
            id="outlined-basic"
            label="Degree"
            variant="outlined"
            value={this.state.degree || ""}
            onChange={(event) => this.handleChange(event, "degree")}
          />
          <TextField
            style={itemsStyle}
            id="outlined-basic"
            label="Graduation Year"
            variant="outlined"
            value={this.state.graduationYear || ""}
            onChange={(event) => this.handleChange(event, "graduationYear")}
          />
          <TextField
            style={itemsStyle}
            id="outlined-basic"
            label="Licensed Country"
            variant="outlined"
            value={this.state.licCountry || ""}
            onChange={(event) => this.handleChange(event, "licCountry")}
          />
          <TextField
            style={itemsStyle}
            id="outlined-basic"
            label="License Number"
            variant="outlined"
            value={this.state.licNumber || ""}
            onChange={(event) => this.handleChange(event, "licNumber")}
          />
          <TextField
            style={itemsStyle}
            id="outlined-basic"
            label="License Exp Date"
            type="date"
            variant="outlined"
            value={this.state.licExpDate || ""}
            onChange={(event) => this.handleChange(event, "licExpDate")}
            InputLabelProps={{ shrink: true }}
          />
          {/* <KeyboardDatePicker
            style={itemsStyle}
            margin="normal"
            id="date-picker-dialog"
            label="License Exp Date"
            format="MM/dd/yyyy"
            defaultValue={''}
            value={this.state.licExpDate || ''}
            maxDate={new Date()}
            onChange={(event) => {
              const toReturn = { target: { value: dayjs(event).format('MM/DD/YYYY') }}
              return this.handleChange(toReturn, 'licExpDate')
            }}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          /> */}
          <TextField
            style={itemsStyle}
            id="outlined-basic"
            label="Contact"
            variant="outlined"
            value={this.state.contact || ""}
            onChange={(event) => this.handleChange(event, "contact")}
          />

          <FormControl style={itemsStyle}>
            <InputLabel htmlFor="state-select">State</InputLabel>
            <Select
              value={this.state.selectedState}
              onChange={(event) => this.handleChange(event, "selectedState")}
              inputProps={{
                name: "state",
                id: "state-select",
              }}
            >
              {this.state.availableStates.map((thestate) => (
                <MenuItem key={thestate.id} value={thestate.id}>
                  {thestate.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl style={itemsStyle}>
            <InputLabel htmlFor="service-select">Services</InputLabel>
            <Select
              multiple
              value={this.state.selectedServices}
              onChange={(event) => this.handleChange(event, "selectedServices")}
              input={<Input id="select-multiple-chip" />}
              renderValue={(selecteds) => (
                <div>
                  {selecteds.map((selected) => (
                    <Chip key={selected.id} label={selected.text} />
                  ))}
                </div>
              )}
              inputProps={{
                text: "services",
                id: "services-select",
              }}
            >
              {this.state.availableServices.map((service) => (
                <MenuItem key={service.id} value={service}>
                  <Checkbox
                    checked={this.isChecked(
                      this.state.selectedServices,
                      service
                    )}
                  />
                  <ListItemText primary={service.text} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl style={itemsStyle}>
            <InputLabel htmlFor="ideologies-select">Badges</InputLabel>
            <Select
              multiple
              value={this.state.selectedIdeologies}
              onChange={(event) =>
                this.handleChange(event, "selectedIdeologies")
              }
              input={<Input id="select-multiple-chip" />}
              renderValue={(selecteds) => (
                <div>
                  {selecteds.map((selected) => (
                    <Chip key={selected.id} label={selected.text} />
                  ))}
                </div>
              )}
              inputProps={{
                text: "ideologies",
                id: "ideologies-select",
              }}
            >
              {this.state.availableIdeologies.map((ideology) => (
                <MenuItem key={ideology.id} value={ideology}>
                  <Checkbox
                    checked={this.isChecked(
                      this.state.selectedIdeologies,
                      ideology
                    )}
                  />
                  <ListItemText primary={ideology.text} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl style={itemsStyle}>
            <InputLabel htmlFor="specialties-select">Specialties</InputLabel>
            <Select
              multiple
              value={this.state.selectedSpecialties}
              onChange={(event) =>
                this.handleChange(event, "selectedSpecialties")
              }
              input={<Input id="select-multiple-chip" />}
              renderValue={(selecteds) => (
                <div>
                  {selecteds.map((selected) => (
                    <Chip key={selected.id} label={selected.text} />
                  ))}
                </div>
              )}
              inputProps={{
                text: "specialties",
                id: "specialties-select",
              }}
            >
              {this.state.availableSpecialties.map((specialty) => (
                <MenuItem key={specialty.id} value={specialty}>
                  <Checkbox
                    checked={this.isChecked(
                      this.state.selectedSpecialties,
                      specialty
                    )}
                  />
                  <ListItemText primary={specialty.text} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* <TextField
            style={itemsStyle}
            id="outlined-basic"
            label="Accreditation"
            variant="outlined"
            value={this.state.accreditation}
            onChange={(event) => this.handleChange(event, 'accreditation')}
          /> */}

          <Button
            variant="contained"
            color="primary"
            onClick={() => this.submit()}
          >
            {this.state.id === undefined ? "Add" : "Save Changes"}
          </Button>
        </Grid>
      </div>
    );
  }
}

export default Doctor;
