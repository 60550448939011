import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import DollarIcon from "@material-ui/icons/LocalAtmOutlined";
import CalendarIcon from "@material-ui/icons/CalendarTodayOutlined";
import CheckIcon from "@material-ui/icons/CheckBoxOutlined";

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: "none",
    "& .MuiCardHeader-root": {
      padding: 0,
    },
    "& .MuiCardContent-root": {
      padding: 0,
    },
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: "transparent",
    borderRadius: "0",
  },
  icon: {
    color: "#000",
  },
  heading: {
    fontWeight: 700,
    fontSize: "1.25rem",
    marginTop: "20px",
    marginBottom: "20px",
  },
  description: {
    color: "#000000de",
  },
}));

const CustomCard = (props) => {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardHeader
        sx={{ padding: 0 }}
        avatar={
          <Avatar aria-label="recipe" className={classes.avatar}>
            {props.icon === "dollar" ? (
              <DollarIcon fontSize="large" className={classes.icon} />
            ) : null}
            {props.icon === "calendar" ? (
              <CalendarIcon fontSize="large" className={classes.icon} />
            ) : null}
            {props.icon === "signup" ? (
              <CheckIcon fontSize="large" className={classes.icon} />
            ) : null}
          </Avatar>
        }
      />
      <CardContent>
        <Typography className={classes.heading} variant="subtitle1">
          {props.heading}
        </Typography>
        <Typography
          variant="body2"
          className={classes.description}
          color="textSecondary"
          component="p"
        >
          {props.description}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default CustomCard;
