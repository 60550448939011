import React from "react";
import googlePlayIcon from "../../assets/images/google-play-icon.svg";

const styles = {
  img: {},
};
const GooglePlayIcon = ({ style }) => {
  return (
    <img
      src={googlePlayIcon}
      style={{ ...styles.img, ...style }}
      alt="Google play icon"
    />
  );
};

export default GooglePlayIcon;
