import { useState } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

const Alert = (props) => {
  const [state, setState] = useState({
    open: true,
    vertical: "bottom",
    horizontal: "right",
  });

  const { vertical, horizontal, open } = state;

  const handleClose = () => {
    setState({
      ...state,
      open: false,
    });

    if (typeof props.onClose !== "undefined") {
      props.onClose();
    }
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical, horizontal }}
      open={open}
      autoHideDuration={8000}
      onClose={handleClose}
    >
      <MuiAlert severity={props.status} elevation={6} variant="filled">
        {props.message}
      </MuiAlert>
    </Snackbar>
  );
};

export default Alert;
