import React, { useEffect, useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";

import { useAuth0 } from "@auth0/auth0-react";
import Error from "../Error";
import {
  Box,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  Tooltip,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  Input,
  FormControl,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBackOutlined";
import ApproveIcon from "@material-ui/icons/DoneOutlined";
import DenyIcon from "@material-ui/icons/Block";
import EyeIcon from "@material-ui/icons/VisibilityOutlined";
import utils from "../../helpers/utils";
import Loading from "../../components/Loading";
import { Redirect } from "react-router";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import Modal from "../../components/Modal";
import Alert from "../../components/Alert";
import { Pagination } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import Edit from "@material-ui/icons/Edit";
import axios from "axios";
import useHasPermission from "../../hooks/useHasPermission";

const Doctors = (props) => {
  document.body.style.backgroundColor = "#F7F7F7";

  const useStyles = makeStyles((theme) => ({
    root: {
      height: "calc(100vh - 110px)",
      paddingTop: theme.spacing(30),
    },
    contentSection: {
      boxShadow: theme.section.boxShadow,
      borderRadius: theme.section.borderRadius,
      backgroundColor: "#fff",
      padding: theme.spacing(4),
    },

    divider: {
      height: "4px",
      marginTop: theme.spacing(1),
      width: theme.spacing(5),
      backgroundColor: theme.palette.primary.main,
    },
    headingWrapper: {
      display: "flex",
      alignItems: "center",
    },
    navLink: {
      color: "#000",
    },
    icon: {
      fontSize: "2.5rem",
      marginRight: theme.spacing(2),
    },
    table: {
      minWidth: 1000,
    },
    tableHeading: {
      fontWeight: "bold",
      letterSpacing: "1px",
    },
    iconWrapper: {
      width: theme.spacing(13),
    },

    textCenter: {
      textAlign: "center !important",
    },
    iconDefaultColor: {
      color: theme.backgroundSeagreen,
    },
  }));

  const classes = useStyles();

  const [doctors, setDoctors] = useState([]);

  const [open, setOpen] = useState(false);
  const [alertSettings, setAlertSettings] = useState({
    status: "",
    msg: "",
    type: "",
  });
  const [message, setMessage] = useState("");
  const [buttonTitle, setButtonTitle] = useState("");
  const [authId, setAuthId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [keyword, setKeyword] = useState("");
  const [filter, setFilter] = useState("firstName");
  const [isProcessing, setIsProcessing] = useState(false);
  const timer = React.useRef();

  const { isAuthenticated, user, error } = useAuth0();
  const isAdmin = utils.isAdmin(user);

  const getDoctors = async () => {
    setIsLoading(true);

    const res = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/accounts/doctors`,
      { skip: (page - 1) * 10, keyword, filter }
    );
    setIsLoading(false);
    if (res.status === 200) {
      setDoctors(res.data[0]);
      setCount(Math.ceil(res.data[1] / 10));
    }
    if (res.status > 399) {
      setAlertSettings({ type: "error", msg: res.data.message, status: true });
    }
  };

  useEffect(() => {
    clearTimeout(timer.current);
    timer.current = setTimeout(() => getDoctors(1), 2000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyword, page]);

  const handleFilterTextChange = (e) => {
    setKeyword(e.target.value);
  };

  const { hasPermission } = useHasPermission({ permission: "read:doctors" });
  if (!hasPermission) return <Redirect to="/account" />;

  if (isAuthenticated && (props.isProcessing || !props.authUser)) {
    return <Loading />;
  } else if (isAuthenticated && props.isMember === false && !isAdmin) {
    return <Redirect to="/signup" />;
  }

  // initial value is null so needs to check the type of the value as well
  if (hasPermission === false) {
    return <Redirect to="/account" />;
  }
  if (error) {
    return <Error code="500" />;
  }

  const handleOpenModal = (status, id) => {
    setOpen(true);
    setAuthId(id);

    if (status) {
      setMessage("Are you sure you want to Unban this doctor?");
      setButtonTitle("Unban");
    } else {
      setMessage("Are you sure you want to Ban this doctor?");
      setButtonTitle("Ban");
    }
  };

  const handleCloseModal = () => {
    setOpen(false);
  };
  const handleConfirmation = async () => {
    let url = `${process.env.REACT_APP_SERVER_URL}/accounts/ban`;

    const res = await axios.post(url, { authId: authId });
    setOpen(false);
    if (res.status === 200) getDoctors(page);
    setIsProcessing(false);
  };

  return (
    <Container maxWidth="lg" className={classes.root}>
      <Modal
        open={open}
        handleConfirmation={handleConfirmation}
        onClose={handleCloseModal}
        message={message}
        buttonTitle={buttonTitle}
        isProcessing={isProcessing}
        setIsProcessing={setIsProcessing}
      />

      {alertSettings?.status && (
        <Alert status={alertSettings.type} message={alertSettings.msg} />
      )}

      <div className={classes.headingWrapper}>
        <NavLink className={classes.navLink} to="/account">
          <ArrowBackIcon className={classes.icon} />
        </NavLink>
        <Typography variant="h5">
          Doctors
          <div className={classes.divider} />
        </Typography>
      </div>

      <Box style={{ padding: "10px", margin: "20px 0px", borderRadius: 10 }}>
        <Grid container direction="row" spacing={4}>
          <Grid item xs={12} sm={8} md={10} lg={10}>
            <Box style={{ paddingTop: "15px" }}>
              {filter === "dob" ? (
                <Input
                  type="date"
                  fullWidth
                  onChange={(e) => handleFilterTextChange(e)}
                />
              ) : (
                <TextField
                  placeholder="Search..."
                  fullWidth
                  onChange={(e) => handleFilterTextChange(e)}
                />
              )}
            </Box>
          </Grid>
          <Grid item xs={12} sm={4} md={2} lg={2}>
            <FormControl fullWidth>
              <InputLabel name="filter" value="Name">
                Filter
              </InputLabel>
              <Select
                name="filter"
                defaultValue="firstName"
                onChange={(e) => setFilter(e.target.value)}
                fullWidth
              >
                {[
                  { value: "email", name: "Email" },
                  { value: "firstName", name: "Name" },
                  { value: "medicalSchool", name: "Medical School" },
                  { value: "degree", name: "Degree" },
                ].map((el) => (
                  <MenuItem key={el.value} name={el.value} value={el.value}>
                    {el.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Box>

      <Grid container direction="row" className={classes.gridWrapper}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Box component="div" className={classes.contentSection}>
            <TableContainer>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tableHeading}>Name</TableCell>
                    <TableCell className={classes.tableHeading}>
                      Email
                    </TableCell>
                    <TableCell className={classes.tableHeading}>
                      Phone
                    </TableCell>
                    <TableCell className={classes.tableHeading}>
                      Medical School
                    </TableCell>
                    <TableCell className={classes.tableHeading}>
                      Degree
                    </TableCell>
                    <TableCell className={classes.tableHeading}>
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isLoading ? (
                    <TableRow>
                      <TableCell colSpan={6} className={classes.textCenter}>
                        <CircularProgress size={30} />
                      </TableCell>
                    </TableRow>
                  ) : (
                    !isLoading &&
                    !doctors.length && (
                      <TableRow>
                        <TableCell colSpan={6} className={classes.textCenter}>
                          No record found
                        </TableCell>
                      </TableRow>
                    )
                  )}

                  {doctors.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell component="th" scope="row">
                        {`${row.doctor.firstName} ${row.doctor.lastName}`}
                      </TableCell>
                      <TableCell>{row.doctor.email}</TableCell>
                      <TableCell>{row.doctor.phoneNumber}</TableCell>
                      <TableCell>{row.doctor.medicalSchool}</TableCell>
                      <TableCell>{row.doctor.degree}</TableCell>
                      <TableCell>
                        <div className={classes.iconWrapper}>
                          <IconButton
                            size="small"
                            onClick={() =>
                              handleOpenModal(row?.isBanned, row?.authId)
                            }
                          >
                            <Tooltip
                              title={`${row?.isBanned ? "Unban" : "Ban"}`}
                              placement="top-start"
                            >
                              {row.isBanned ? (
                                <ApproveIcon
                                  className={classes.iconDefaultColor}
                                />
                              ) : (
                                <DenyIcon
                                  className={classes.iconDefaultColor}
                                />
                              )}
                            </Tooltip>
                          </IconButton>
                          <IconButton
                            size="small"
                            component={NavLink}
                            to={`/doctor/${row.doctor.id}/edit`}
                          >
                            <Tooltip title="Edit Details" placement="top-start">
                              <Edit className={classes.iconDefaultColor} />
                            </Tooltip>
                          </IconButton>
                          <IconButton
                            size="small"
                            component={NavLink}
                            to={`/account/doctor/${row.doctor.id}/view`}
                          >
                            <Tooltip title="View Details" placement="top-start">
                              <EyeIcon className={classes.iconDefaultColor} />
                            </Tooltip>
                          </IconButton>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            {count > 1 && (
              <Pagination
                count={count}
                page={page}
                onChange={(e, val) => setPage(val)}
              />
            )}
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    isProcessing: state.auth0.isProcessing,
    isMember: state.auth0.isMember,
    authUser: state.auth0.user,
  };
};

export default connect(mapStateToProps)(Doctors);
