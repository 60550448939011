import React, { useState, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Box, Container, Grid, useMediaQuery } from "@material-ui/core";
import VerticalMenu from "../components/VerticalMenu";
import utils from "../helpers/utils";
import { Redirect } from "react-router";
import { connect } from "react-redux";
import AddCardForm from "../components/AddCardForm";
import axios from "axios";
import Person from "./Person";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "calc(100vh - 110px)",
    paddingTop: theme.spacing(30),
  },
  contentSection: {
    boxShadow: theme.section.boxShadow,
    borderRadius: theme.section.borderRadius,
    backgroundColor: "#fff",
    padding: theme.spacing(4),
  },
  label: {
    display: "block",
    fontSize: "12px",
    color: theme.paragraph.color,
  },
  value: {
    display: "block",
    fontWeight: 600,
    color: theme.paragraphDark.color,
  },
  infoWrapper: {
    marginTop: theme.spacing(3),
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  divider: {
    height: "4px",
    marginTop: theme.spacing(1),
    width: theme.spacing(5),
    backgroundColor: theme.palette.primary.main,
  },
  notFoundWrapper: {
    textAlign: "center",
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  sadIcon: {
    fontSize: "80px",
    color: "#ddd",
  },
  warningDescription: {
    color: "#aaa",
    fontWeight: "bold",
  },
  navLink: {
    textDecoration: "none",
    color: theme.palette.primary.main,
  },
  sectionHeading: {
    fontWeight: 600,
  },
}));

const AddCard = (props) => {
  document.body.style.backgroundColor = "#F7F7F7";
  const [accPersons, setAccPersons] = useState([]);
  const [states, setStates] = useState([]);

  const classes = useStyles();

  const theme = useTheme();

  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const xs = useMediaQuery(theme.breakpoints.down("xs"));

  const { user } = props;
  const role = utils.getRole(user);

  useEffect(() => {
    fetchRefs();
  }, []);

  const pullAccountPersons = async (accId) => {
    try {
      const response = await axios({
        url: `${process.env.REACT_APP_SERVER_URL}/account/${accId}/persons`,
        method: "get",
      });
      const { data } = response;
      setAccPersons(data.data);
    } catch (error) {
      console.log(error.message);
    }
  };

  const fetchRefs = async () => {
    try {
      const response = await axios({
        url: `${process.env.REACT_APP_SERVER_URL}/public/refs`,
        method: "get",
      });

      setStates(response.data.states);
    } catch (error) {
      console.log(error);
    }
  };

  if (role === "doctor" && !user.isApplicationAccepted) {
    return <Redirect to="/application-status" />;
  }

  return (
    <Container maxWidth="lg" className={classes.root}>
      <Grid container direction="row" className={classes.gridWrapper}>
        {!sm && !xs ? (
          <Grid item xl={3} lg={3} md={3}>
            <VerticalMenu active="bank-account" />
          </Grid>
        ) : null}

        <Grid item xl={9} lg={9} md={9} sm={12}>
          <Box component="div" className={classes.contentSection}>
            <Typography className={classes.sectionHeading} variant="h6">
              Add Debit Card
            </Typography>
            <div className={classes.divider} />
            <AddCardForm pullAccountPersons={pullAccountPersons} />
            {accPersons?.map((person) => (
              <Person
                person={person}
                key={person.id}
                states={states}
                pullAccountPersons={pullAccountPersons}
              />
            ))}
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    isProcessing: state.auth0.isProcessing,
    user: state.auth0.user,
  };
};

export default connect(mapStateToProps)(AddCard);
