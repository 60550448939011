import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Avatar,
  Box,
  Divider,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { NavLink } from "react-router-dom";
import UserIcon from "@material-ui/icons/PermIdentityOutlined";
// import KeyIcon from "@material-ui/icons/VpnKeyOutlined";
import SettingsIcon from "@material-ui/icons/Settings";
import FavoriteIcon from "@material-ui/icons/FavoriteBorderOutlined";
import NotificationIcon from "@material-ui/icons/NotificationsOutlined";
import UsersIcon from "@material-ui/icons/PeopleAltOutlined";
import PowerIcon from "@material-ui/icons/PowerSettingsNewOutlined";
import FileIcon from "@material-ui/icons/DescriptionOutlined";
import WalletIcon from "@material-ui/icons/AccountBalanceWalletOutlined";
import CardIcon from "@material-ui/icons/CreditCardOutlined";
import utils from "../helpers/utils";
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: theme.section.boxShadow,
    borderRadius: theme.section.borderRadius,
    backgroundColor: "#fff",
    minHeight: "825px",
    marginRight: theme.spacing(4),
  },
  avatarWrapper: {
    textAlign: "center",
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
  },
  infoWrapper: {
    marginTop: theme.spacing(4),
  },
  username: {
    display: "block",
    fontWeight: 600,
  },
  type: {
    display: "block",
    fontWeight: 600,
    color: theme.paragraph.color,
    textTransform: "capitalize",
  },
  avatar: {
    width: theme.spacing(14),
    height: theme.spacing(14),
    boxShadow: theme.section.boxShadow,
    margin: "0 auto",
  },
  navWrapper: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  listItem: {
    paddingLeft: theme.spacing(3),
    //borderRight: "solid 5px #fff"
  },
  listItemActive: {
    paddingLeft: theme.spacing(3),
    borderRight: `solid 3px ${theme.palette.primary.main} !important`,
  },
  navLink: {
    color: theme.navItem.color,
    textDecoration: "none",
    fontSize: theme.typography.fontSize,
    fontWeight: 500,
  },
  listItemIcon: {
    width: "20px",
    textAlign: "center",
    paddingRight: theme.spacing(2),
  },
  icon: {
    fontSize: "25px",
    marginLeft: "auto",
    color: theme.navItem.color,
  },
}));

const VerticalMenu = (props) => {
  const classes = useStyles();

  const { user } = props;
  const role = utils.getRole(user);

  return (
    <Box component="div" className={classes.root}>
      <div className={classes.avatarWrapper}>
        <Avatar
          alt={user.nickname}
          src={user.picture}
          className={classes.avatar}
        />
        <div className={classes.infoWrapper}>
          <span className={classes.username}>
            {user.given_name} {user.family_name}
          </span>
          <span className={classes.type}>{role ? role : "User"}</span>
        </div>
      </div>
      <Divider />
      <div className={classes.navWrapper}>
        <List component="nav" aria-label="account page navigation">
          <NavLink className={classes.navLink} to="/account">
            <ListItem
              className={
                props.active === "account"
                  ? classes.listItemActive
                  : classes.listItem
              }
              button
              disableRipple
            >
              <ListItemIcon className={classes.listItemIcon}>
                <UserIcon className={classes.icon} />
              </ListItemIcon>
              <ListItemText>Account</ListItemText>
            </ListItem>
          </NavLink>
          {/* <ListItem className={classes.listItem} button disableRipple>
            <ListItemIcon className={classes.listItemIcon}>
              <KeyIcon className={classes.icon} />
            </ListItemIcon>
            <ListItemText>
              <NavLink className={classes.navLink} to="/password">
                Password
              </NavLink>
            </ListItemText>
          </ListItem> */}
          <NavLink className={classes.navLink} to="/settings">
            {role !== "patient" && (
              <ListItem className={classes.listItem} button disableRipple>
                <ListItemIcon className={classes.listItemIcon}>
                  <SettingsIcon className={classes.icon} />
                </ListItemIcon>
                <ListItemText>Settings</ListItemText>
              </ListItem>
            )}
          </NavLink>
          <NavLink className={classes.navLink} to="/payment/methods">
            <ListItem className={classes.listItem} button disableRipple>
              <ListItemIcon className={classes.listItemIcon}>
                <CardIcon className={classes.icon} />
              </ListItemIcon>
              <ListItemText>Payment Methods</ListItemText>
            </ListItem>
          </NavLink>
          {role.includes("doctor") ? (
            <NavLink className={classes.navLink} to="/get/paid">
              <ListItem
                className={
                  props.active === "bank-account"
                    ? classes.listItemActive
                    : classes.listItem
                }
                button
                disableRipple
              >
                <ListItemIcon className={classes.listItemIcon}>
                  <WalletIcon className={classes.icon} />
                </ListItemIcon>
                <ListItemText>Get Paid</ListItemText>
              </ListItem>
            </NavLink>
          ) : null}

          <NavLink className={classes.navLink} to="/favorites">
            <ListItem className={classes.listItem} button disableRipple>
              <ListItemIcon className={classes.listItemIcon}>
                <FavoriteIcon className={classes.icon} />
              </ListItemIcon>
              <ListItemText>Favorites</ListItemText>
            </ListItem>
          </NavLink>
          <NavLink className={classes.navLink} to="/notifications">
            <ListItem className={classes.listItem} button disableRipple>
              <ListItemIcon className={classes.listItemIcon}>
                <NotificationIcon className={classes.icon} />
              </ListItemIcon>
              <ListItemText>Notifications</ListItemText>
            </ListItem>
          </NavLink>

          {props.user &&
          role === "docadmin" &&
          utils.hasPermission(props.user, "write:doc") ? (
            <NavLink className={classes.navLink} to="/applications">
              <ListItem
                className={
                  props.active === "applications"
                    ? classes.listItemActive
                    : classes.listItem
                }
                button
                disableRipple
              >
                <ListItemIcon className={classes.listItemIcon}>
                  <FileIcon className={classes.icon} />
                </ListItemIcon>
                <ListItemText>Applications</ListItemText>
              </ListItem>
            </NavLink>
          ) : null}
          <NavLink className={classes.navLink} to="/directory">
            <ListItem
              className={
                props.active === "directory"
                  ? classes.listItemActive
                  : classes.listItem
              }
              button
              disableRipple
            >
              <ListItemIcon className={classes.listItemIcon}>
                <UsersIcon className={classes.icon} />
              </ListItemIcon>
              <ListItemText>Directory</ListItemText>
            </ListItem>
          </NavLink>
          <ListItem className={classes.listItem} button disableRipple>
            <ListItemIcon className={classes.listItemIcon}>
              <PowerIcon className={classes.icon} />
            </ListItemIcon>
            <ListItemText>
              <div
                className={classes.navLink}
                onClick={
                  () => {}
                  // logout({
                  //   returnTo: window.location.origin,
                  // })
                }
              >
                Log Out
              </div>
            </ListItemText>
          </ListItem>
        </List>
      </div>
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth0.user,
  };
};

export default connect(mapStateToProps)(VerticalMenu);
