import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import { List, ListItem, ListItemText, Typography } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import Drawer from "@material-ui/core/Drawer";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import { makeStyles } from "@material-ui/core/styles";
import { useState } from "react";
import utils from "../helpers/utils";
import { connect } from "react-redux";
import logoutAction from "../pages/auth/store/actions/logoutAction";
import { useAppContext } from "../context/AppContext";

const useStyles = makeStyles((theme) => ({
  h6: {
    color: theme.paragraphDark.color,
    fontWeight: 600,
    marginTop: "40px",
    marginLeft: "25px",
    fontSize: "22px",
  },
  underline: {
    height: "5px",
    width: "50px",
    backgroundColor: theme.palette.primary.main,
    marginLeft: "25px",
    marginTop: "10px",
    marginBottom: "40px",
  },
  closeIconWrapper: {
    textAlign: "right",
    padding: "10px 10px 0 0",
  },
  closeIcon: {},
  navLink: {
    color: "#333",
    margin: "0 32px",
    textDecoration: "none",
    [theme.breakpoints.down("lg")]: {
      margin: "0 10px",
    },
  },

  nav: {
    display: "flex",
    justifyContent: "center",

    menuIcon: {
      color: "red",
    },
  },
  button: {
    maxHeight: "48px",
    alignSelf: "center",
    marginLeft: "32px",
    marginRight: "32px",
    padding: "10px 50px",
    color: "#fff",
    "& span": {
      color: "#ffffff",
    },
  },
  navItemBordered: {
    borderLeft: `solid 1px ${theme.border.color}`,
    borderRight: `solid 1px ${theme.border.color}`,

    padding: "0 32px",
    height: "110px",
  },
  menuIcon: {
    color: "#000",
  },
}));

const AuthenticatedMobileMenu = (props) => {
  const classes = useStyles();

  const { user } = props;
  const { notAvailable, openPopup } = useAppContext();

  const [state, setState] = useState({ right: false });

  const role = utils.getRole(user);

  const toggleMenu = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  if (role === "patient") {
    if (notAvailable) {
      return (
        <List
          component="nav"
          className={classes.nav}
          aria-label="main navigation"
        >
          <span className={classes.navLink} onClick={toggleMenu("right", true)}>
            <IconButton edge="start" aria-label="menu">
              <MenuIcon className={classes.menuIcon} fontSize="large" />
            </IconButton>
          </span>

          <Drawer
            anchor="right"
            open={state.right}
            onClose={toggleMenu("right", false)}
          >
            <div className={classes.closeIconWrapper}>
              <CloseOutlinedIcon
                className={classes.closeIcon}
                onClick={toggleMenu("right", false)}
              />
            </div>
            <Typography variant="h6" className={classes.h6}>
              Menu
            </Typography>
            <div className={classes.underline}></div>

            <ListItem
              button
              disableRipple
              onClick={(e) => {
                openPopup();
                toggleMenu("right", false);
              }}
            >
              <ListItemText primary="Account" />
            </ListItem>

            <ListItem
              button
              disableRipple
              onClick={(e) => {
                openPopup();
                toggleMenu("right", false);
              }}
            >
              <ListItemText primary="Password" />
            </ListItem>

            <ListItem
              button
              disableRipple
              onClick={(e) => {
                openPopup();
                toggleMenu("right", false);
              }}
            >
              <ListItemText primary="My Care" />
            </ListItem>

            <ListItem
              button
              disableRipple
              onClick={(e) => {
                openPopup();
                toggleMenu("right", false);
              }}
            >
              <ListItemText primary="My Records" />
            </ListItem>

            <ListItem
              button
              disableRipple
              onClick={(e) => {
                openPopup();
                toggleMenu("right", false);
              }}
            >
              <ListItemText primary="Notifications" />
            </ListItem>

            <div
              className={classes.navLink}
              onClick={() => {
                toggleMenu("right", false);
                props.logout();
              }}
            >
              <ListItem button disableRipple>
                <ListItemText primary="Log Out" />
              </ListItem>
            </div>
          </Drawer>
        </List>
      );
    }

    return (
      <List
        component="nav"
        className={classes.nav}
        aria-label="main navigation"
      >
        <span className={classes.navLink} onClick={toggleMenu("right", true)}>
          <IconButton edge="start" aria-label="menu">
            <MenuIcon className={classes.menuIcon} fontSize="large" />
          </IconButton>
        </span>

        <Drawer
          anchor="right"
          open={state.right}
          onClose={toggleMenu("right", false)}
        >
          <div className={classes.closeIconWrapper}>
            <CloseOutlinedIcon
              className={classes.closeIcon}
              onClick={toggleMenu("right", false)}
            />
          </div>
          <Typography variant="h6" className={classes.h6}>
            Menu
          </Typography>
          <div className={classes.underline}></div>
          <NavLink
            className={classes.navLink}
            onClick={toggleMenu("right", false)}
            to="/account"
          >
            <ListItem button disableRipple>
              <ListItemText primary="Account" />
            </ListItem>
          </NavLink>

          <NavLink
            className={classes.navLink}
            onClick={toggleMenu("right", false)}
            to="/password"
          >
            <ListItem button disableRipple>
              <ListItemText primary="Password" />
            </ListItem>
          </NavLink>
          <NavLink
            className={classes.navLink}
            onClick={toggleMenu("right", false)}
            to="/password"
          >
            <ListItem button disableRipple>
              <ListItemText primary="My Care" />
            </ListItem>
          </NavLink>
          <NavLink
            className={classes.navLink}
            onClick={toggleMenu("right", false)}
            to="/password"
          >
            <ListItem button disableRipple>
              <ListItemText primary="My Records" />
            </ListItem>
          </NavLink>
          <NavLink
            className={classes.navLink}
            onClick={toggleMenu("right", false)}
            to="/notifications"
          >
            <ListItem button disableRipple>
              <ListItemText primary="Notifications" />
            </ListItem>
          </NavLink>

          <div
            className={classes.navLink}
            onClick={() => {
              toggleMenu("right", false);
              props.logout();
            }}
          >
            <ListItem button disableRipple>
              <ListItemText primary="Log Out" />
            </ListItem>
          </div>
        </Drawer>
      </List>
    );
  } else if (role === "doctor") {
    return (
      <List
        component="nav"
        className={classes.nav}
        aria-label="main navigation"
      >
        <span className={classes.navLink} onClick={toggleMenu("right", true)}>
          <IconButton edge="start" aria-label="menu">
            <MenuIcon className={classes.menuIcon} fontSize="large" />
          </IconButton>
        </span>

        <Drawer
          anchor="right"
          open={state.right}
          onClose={toggleMenu("right", false)}
        >
          <div className={classes.closeIconWrapper}>
            <CloseOutlinedIcon
              className={classes.closeIcon}
              onClick={toggleMenu("right", false)}
            />
          </div>
          <Typography variant="h6" className={classes.h6}>
            Menu
          </Typography>
          <div className={classes.underline}></div>
          <NavLink
            className={classes.navLink}
            onClick={toggleMenu("right", false)}
            to="/account"
          >
            <ListItem button disableRipple>
              <ListItemText primary="Account" />
            </ListItem>
          </NavLink>
          <div
            className={classes.navLink}
            onClick={() => {
              toggleMenu("right", false);
              props.logout();
            }}
          >
            <ListItem button disableRipple>
              <ListItemText primary="Log Out" />
            </ListItem>
          </div>
        </Drawer>
      </List>
    );
  }

  return (
    <List component="nav" className={classes.nav} aria-label="main navigation">
      <span className={classes.navLink} onClick={toggleMenu("right", true)}>
        <IconButton edge="start" aria-label="menu">
          <MenuIcon className={classes.menuIcon} fontSize="large" />
        </IconButton>
      </span>

      <Drawer
        anchor="right"
        open={state.right}
        onClose={toggleMenu("right", false)}
      >
        <div className={classes.closeIconWrapper}>
          <CloseOutlinedIcon
            className={classes.closeIcon}
            onClick={toggleMenu("right", false)}
          />
        </div>
        <Typography variant="h6" className={classes.h6}>
          Menu
        </Typography>
        <div className={classes.underline}></div>
        <NavLink
          className={classes.navLink}
          onClick={toggleMenu("right", false)}
          to="/account"
        >
          <ListItem button disableRipple>
            <ListItemText primary="Account" />
          </ListItem>
        </NavLink>
        <NavLink
          className={classes.navLink}
          onClick={toggleMenu("right", false)}
          to="/password"
        >
          <ListItem button disableRipple>
            <ListItemText primary="Password" />
          </ListItem>
        </NavLink>
        <NavLink
          className={classes.navLink}
          onClick={toggleMenu("right", false)}
          to="/payment/methods"
        >
          <ListItem button disableRipple>
            <ListItemText primary="Payment Methods" />
          </ListItem>
        </NavLink>
        <NavLink
          className={classes.navLink}
          onClick={toggleMenu("right", false)}
          to="/get/paid"
        >
          <ListItem button disableRipple>
            <ListItemText primary="Get paid" />
          </ListItem>
        </NavLink>
        <NavLink
          className={classes.navLink}
          onClick={toggleMenu("right", false)}
          to="/favorites"
        >
          <ListItem button disableRipple>
            <ListItemText primary="Favorites" />
          </ListItem>
        </NavLink>
        <NavLink
          className={classes.navLink}
          onClick={toggleMenu("right", false)}
          to="/notifications"
        >
          <ListItem button disableRipple>
            <ListItemText primary="Notifications" />
          </ListItem>
        </NavLink>
        {user &&
        role === "docadmin" &&
        utils.hasPermission(user, "write:doc") ? (
          <NavLink
            className={classes.navLink}
            onClick={toggleMenu("right", false)}
            to="/applications"
          >
            <ListItem button disableRipple>
              <ListItemText primary="Applications" />
            </ListItem>
          </NavLink>
        ) : null}
        <NavLink
          className={classes.navLink}
          onClick={toggleMenu("right", false)}
          to="/directory"
        >
          <ListItem button disableRipple>
            <ListItemText primary="Directory" />
          </ListItem>
        </NavLink>

        <div
          className={classes.navLink}
          onClick={() => {
            toggleMenu("right", false);
            props.logout();
          }}
        >
          <ListItem button disableRipple>
            <ListItemText primary="Log Out" />
          </ListItem>
        </div>
      </Drawer>
    </List>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth0.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: (url) => dispatch(logoutAction(url)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthenticatedMobileMenu);
