import React, { useEffect, useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";

import { useAuth0 } from "@auth0/auth0-react";
import Error from "../Error";
import {
  Box,
  CircularProgress,
  Container,
  Grid,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBackOutlined";
import EyeIcon from "@material-ui/icons/VisibilityOutlined";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import utils from "../../helpers/utils";
import Loading from "../../components/Loading";
import { Redirect } from "react-router";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import Modal from "../../components/Modal";
import Alert from "../../components/Alert";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import EditIcon from "@material-ui/icons/Edit";
import useHasPermission from "../../hooks/useHasPermission";

const SupportUsers = (props) => {
  document.body.style.backgroundColor = "#F7F7F7";

  const useStyles = makeStyles((theme) => ({
    root: {
      height: "calc(100vh - 110px)",
      paddingTop: theme.spacing(30),
    },
    contentSection: {
      boxShadow: theme.section.boxShadow,
      borderRadius: theme.section.borderRadius,
      backgroundColor: "#fff",
      padding: theme.spacing(4),
    },

    divider: {
      height: "4px",
      marginTop: theme.spacing(1),
      width: theme.spacing(5),
      backgroundColor: theme.palette.primary.main,
    },
    headingWrapper: {
      display: "flex",
      alignItems: "center",
      marginBottom: "30px",
      justifyContent: "space-between",
    },
    navLink: {
      color: "#000",
    },
    icon: {
      fontSize: "2.5rem",
      marginRight: theme.spacing(2),
    },
    table: {
      minWidth: 1000,
    },
    tableHeading: {
      fontWeight: "bold",
      letterSpacing: "1px",
    },
    iconWrapper: {
      width: theme.spacing(13),
    },

    textCenter: {
      textAlign: "center !important",
    },
    iconDefaultColor: {
      color: theme.backgroundSeagreen,
    },
  }));

  const classes = useStyles();

  const [supportUsers, setSupportUsers] = useState([]);

  const [alert, setAlert] = useState({
    status: "",
    msg: "",
    type: "",
  });
  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [supportUserId, setSupportUserId] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);

  const { isAuthenticated, user, error } = useAuth0();
  const isSupportUser = utils.isSupportUser(user);

  const getSupportUsers = async () => {
    setIsLoading(true);
    let url = `${process.env.REACT_APP_SERVER_URL}/support-users`;
    const res = await axios.get(url);
    if (res.status === 200) {
      setSupportUsers(res?.data?.supportUsers);
    }
    if (res.status > 400)
      setAlert({
        status: res.status,
        msg: res.data.message,
        type: "error",
      });
    setIsLoading(false);
  };
  useEffect(() => {
    getSupportUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleConfirmation = async () => {
    setIsProcessing(true);
    let url = `${process.env.REACT_APP_SERVER_URL}/delete-support-user/${supportUserId}`;

    const res = await axios.delete(url);
    setIsProcessing(false);
    if (res?.status === 204) {
      setAlert({ status: "success", msg: "Support User deleted successfully" });
      setOpen(false);
      getSupportUsers();
      return;
    }
    setAlert({ status: "error", msg: res?.response?.data?.message });
  };

  const deleteSupportUser = (id) => {
    setOpen(true);
    setSupportUserId(id);
  };

  const { hasPermission } = useHasPermission({ permission: "read:admins" });
  if (!hasPermission) return <Redirect to="/account" />;

  if (isAuthenticated && (props.isProcessing || !props.authUser)) {
    return <Loading />;
  } else if (isAuthenticated && props.isMember === false && !isSupportUser) {
    return <Redirect to="/signup" />;
  }

  // let hasPermission = null;

  // if (props.authUser) {
  //   hasPermission = utils.hasPermission(props.authUser, "read:admins");
  // }
  // // initial value is null so needs to check the type of the value as well
  // if (hasPermission === false) {
  //   return <Redirect to="/account" />;
  // }
  if (error) {
    return <Error code="500" />;
  }

  const getAuthId = (str) => {
    return str.substr(6, str.length);
  };

  return (
    <Container maxWidth="lg" className={classes.root}>
      {alert.status && <Alert status={alert.type} message={alert.msg} />}
      <div className={classes.headingWrapper}>
        <Box display="flex">
          <NavLink className={classes.navLink} to="/account">
            <ArrowBackIcon className={classes.icon} />
          </NavLink>
          <Typography variant="h5">
            Support Users
            <div className={classes.divider} />
          </Typography>
        </Box>
        <IconButton
          size="small"
          component={NavLink}
          to={`/support-user/create`}
        >
          <Tooltip title={`Create new supportUser`} placement="top-start">
            <AddCircleOutlineIcon className={classes.iconDefaultColor} />
          </Tooltip>
        </IconButton>
      </div>

      <Grid container direction="row" className={classes.gridWrapper}>
        <Grid item xs={12}>
          <Box component="div" className={classes.contentSection}>
            <TableContainer>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tableHeading}>Id</TableCell>
                    <TableCell className={classes.tableHeading}>Name</TableCell>
                    <TableCell className={classes.tableHeading}>
                      Email
                    </TableCell>
                    <TableCell className={classes.tableHeading}>
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isLoading ? (
                    <TableRow>
                      <TableCell colSpan={5} className={classes.textCenter}>
                        <CircularProgress size={30} />
                      </TableCell>
                    </TableRow>
                  ) : (
                    !isLoading &&
                    !supportUsers.length && (
                      <TableRow>
                        <TableCell colSpan={5} className={classes.textCenter}>
                          No record found
                        </TableCell>
                      </TableRow>
                    )
                  )}

                  {supportUsers.map((row, index) => (
                    <TableRow key={row.user_id}>
                      <TableCell component="th" scope="row">
                        {`${index + 1}`}
                      </TableCell>
                      <TableCell>{row?.name}</TableCell>
                      {/* <TableCell>
                        {utils.getFormattedDate(row.doctor.dob, "MM/DD/YYYY")}
                      </TableCell> */}
                      <TableCell>{row?.email}</TableCell>
                      {/* <TableCell>{row.doctor.degree}</TableCell> */}
                      <TableCell>
                        <div className={classes.iconWrapper}>
                          {hasPermission && (
                            <IconButton
                              size="small"
                              component={NavLink}
                              to={`/support-user/${getAuthId(
                                row?.user_id
                              )}/edit`}
                            >
                              <Tooltip title={`Edit`} placement="top-start">
                                <EditIcon
                                  className={classes.iconDefaultColor}
                                />
                              </Tooltip>
                            </IconButton>
                          )}
                          {hasPermission && (
                            <IconButton
                              size="small"
                              component={NavLink}
                              to={`/support-user/${getAuthId(
                                row?.user_id
                              )}/view`}
                            >
                              <Tooltip
                                title="View Details"
                                placement="top-start"
                              >
                                <EyeIcon className={classes.iconDefaultColor} />
                              </Tooltip>
                            </IconButton>
                          )}

                          {hasPermission &&
                            row?.user_id !== props?.authUser?.sub && (
                              <IconButton
                                size="small"
                                onClick={() =>
                                  deleteSupportUser(getAuthId(row?.user_id))
                                }
                              >
                                <Tooltip
                                  title="Delete Support User"
                                  placement="top-start"
                                >
                                  <DeleteForeverIcon
                                    className={classes.iconDefaultColor}
                                  />
                                </Tooltip>
                              </IconButton>
                            )}
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            {/* {count > 1 && (
              <Pagination
                count={count}
                page={page}
                onChange={(e, val) => {
                  setPage(val);
                  history.push(`/patients/page/${val}`);
                }}
              />
            )} */}
          </Box>
        </Grid>
      </Grid>

      <Modal
        open={open}
        handleConfirmation={handleConfirmation}
        onClose={() => setOpen(false)}
        message={"Are you sure to delete supportUser"}
        buttonTitle={"Delete"}
        isProcessing={isProcessing}
        setIsProcessing={setIsProcessing}
      />
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    isProcessing: state.auth0.isProcessing,
    isMember: state.auth0.isMember,
    authUser: state.auth0.user,
  };
};

export default connect(mapStateToProps)(SupportUsers);
