import React from "react";
import AlertDialog from "../components/AlertDialog";
import { AppContext } from "./AppContext";

const AppProvider = (props) => {
  const [open, setOpen] = React.useState(false);

  const notAvailable = true;

  const openPopup = () => {
    setOpen(true);
  };

  return (
    <AppContext.Provider
      value={{
        notAvailable,
        openPopup,
      }}
    >
      {open ? (
        <AlertDialog open={open} handleClose={(e) => setOpen(false)} />
      ) : null}
      {props.children}
    </AppContext.Provider>
  );
};

export default AppProvider;
