import React from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/core/styles/makeStyles";
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";
import useTheme from "@material-ui/core/styles/useTheme";
import { connect } from "react-redux";
import updateAuthStatusAction from "../pages/auth/store/actions/updateAuthStatusAction";
import { Redirect, withRouter } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(8),
  },
  mainDescriptionWrapper: {
    marginBottom: theme.spacing(5),
  },
  textBold: {
    fontWeight: 700,
  },
  textCenter: {
    textAlign: "center",
  },
  submitBtnWrapper: {
    textAlign: "center",
    marginBottom: theme.spacing(5),
  },
  btnContained: {
    backgroundColor: theme.backgroundSeagreen,
    color: "#fff",
    "&:hover": {
      backgroundColor: theme.backgroundSeagreenDark,
    },
  },
}));

const BasicCareSection = (props) => {
  const classes = useStyles();

  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  if (props.wasAuthenticated && props.isAuthenticated) {
    return <Redirect to="/account" />;
  }

  const handleClick = () => {
    if (props.wasAuthenticated) {
      props.updateAuthStatus();
    } else {
      if (typeof props.match.params.patientId !== "undefined") {
        props.history.push(`${props.location.pathname}?status=success`);
      }
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.mainDescriptionWrapper}>
        <Typography
          variant="h5"
          className={`${classes.textBold} ${classes.textCenter}`}
        >
          Start your Tova Basic membership
        </Typography>
      </div>
      <div className={classes.submitBtnWrapper}>
        <Button
          onClick={handleClick}
          variant="contained"
          type="button"
          fullWidth={sm}
          className={classes.btnContained}
        >
          Continue with Basic
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    wasAuthenticated: state.auth0.wasAuthenticated,
    isAuthenticated: state.auth0.isAuthenticated,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateAuthStatus: () => dispatch(updateAuthStatusAction()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(BasicCareSection));
