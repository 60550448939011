import React, { PureComponent } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

class Snack extends PureComponent {

  constructor(props) {
    super(props);
    this.state = { open: false, errorMessage: '' };
  }

  eat = (message) => {
    this.setState({
      open: true,
      errorMessage: message
    });
  }
  
  closeDialog = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      open: false,
      errorMessage: ''
    });
  };

  render () {
    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={this.state.open}
        autoHideDuration={6000}
        onClose={this.closeDialog}
        message={this.state.errorMessage}
        action={
          <React.Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={this.closeDialog}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    );
  }
};

export default Snack;