import { NavLink, Redirect, withRouter } from "react-router-dom";
import makeStyles from "@material-ui/core/styles/makeStyles";
import useTheme from "@material-ui/core/styles/useTheme";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
// import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
// import ArrowForwardIcon from "@material-ui/icons/ArrowForwardIosOutlined";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import checkIcon from "../assets/images/icons/check-mark.svg";
import banIcon from "../assets/images/icons/warming.svg";
import doctorPatientIcon from "../assets/images/icons/doctor-patient-circled.svg";
import ambulanceIcon from "../assets/images/icons/ambulance.svg";
import notAllowedIcon from "../assets/images/icons/not-allowed.svg";
import pillsImage from "../assets/images/prescription-delivered.jpg";
import medicine from "../assets/images/icons/medicine-1.svg";
// import User1 from "../assets/images/1.jpeg";
// import User2 from "../assets/images/2.jpeg";
// import User3 from "../assets/images/3.jpeg";
//import brandIcons from "../assets/images/brands-logos.png";
import doctorCallImage from "../assets/images/a2.png";
import { useAuth0 } from "@auth0/auth0-react";
import Footer from "../components/Footer";
import Loading from "../components/Loading";
import utils from "../helpers/utils";
import { connect } from "react-redux";
// import EmbeddedVideo from "../components/EmbeddedVideo";
import TalkToADoctor from "../components/TalkToADoctor";
import TypesOfCare from "../components/TypesOfCare";
import newLogo from "../assets/images/newest-logo-updated-new.png";
import howItWorksImg from "../assets/images/hiw-1.png";
import HealthcareIcon from "../assets/images/icons/heart-health-100.png";
import StethoscopeIcon from "../assets/images/icons/stethoscope-100.png";
import { Box } from "@material-ui/core";
import AngleRightIcon from "@material-ui/icons/ChevronRightOutlined";
import DonationIcon from "../assets/images/icons/donation-100.png";
import DoubleMobile from "../assets/images/hiw-1.png";
import MobileCircle from "../assets/images/hiw-2.png";
import FullPageImage from "../assets/images/fullscreen-mobile-screens.jpg";
import AppStoreIcon from "../components/Icons/AppStoreIcon";
import GooglePlayIcon from "../components/Icons/GooglePlayIcon";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100% !important",
  },
  banner: {
    position: "relative",
    // overflowX: "hidden",
    padding: "0 !important",

    [theme.breakpoints.down("md")]: {
      //marginTop: theme.spacing(8),
    },
    [theme.breakpoints.down("sm")]: {
      //marginTop: theme.spacing(8),
    },
    [theme.breakpoints.down("xs")]: {
      //marginTop: theme.spacing(7),
      height: "460px",
    },
  },
  bannerContainer: {
    // position: "absolute !important",
    width: "100%",
    height: "100%",
    // top: 0,
    // left: 0,
    // right: 0,
    zIndex: "1",
    paddingTop: "100px",
    // backgroundColor: theme.backgroundTransparentOverlayDark,
  },

  bannerTypographyOuterWrapper: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    // paddingLeft: theme.spacing(3),
  },
  newLogo: {
    width: "200px",
  },
  bannerTyporgraphWrapper: {
    position: "relative",
    zIndex: "2",
    color: "#fff",
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      // maxWidth: "500px",
    },
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      marginLeft: "auto",
      marginRight: "auto",
      marginTop: theme.spacing(3),
      // maxWidth: "900px",
    },
  },

  stretch: {
    width: "100%",
  },
  alignItemsLarge: {
    [theme.breakpoints.between("lg", "xl")]: {
      alignItems: "center",
    },
  },

  textShadow: {
    textShadow: "1px 1px rgba(0,0,0,0.5)",
  },

  bannerHeading: {
    fontSize: "46px",
    fontWeight: "500",
    lineHeight: "83px",
    color: "#3773c5",
    marginTop: "-40px",
    fontFamily: "Poppins",
    [theme.breakpoints.down("md")]: {
      fontSize: "40px",
      lineHeight: "55px",
      marginTop: "-20px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "35px",
      lineHeight: "30px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "25px",
      lineHeight: "20px",
    },
  },
  bannerRunningText: {
    fontSize: "23px",
    fontWeight: "500",
    maxWidth: "600px",
    margin: "0 auto",
    color: "#3773c5",
    lineHeight: "1.3",
    marginTop: "20px",
    fontFamily: "Poppins",
    [theme.breakpoints.down("md")]: {
      fontSize: "23px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
      // display: "none",
    },
  },
  sectionTopBottomPadding: {
    paddingTop: theme.spacing(10.5),
    paddingBottom: theme.spacing(10.5),
  },
  sectionTopMargin: {
    marginTop: theme.spacing(25),
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(15),
    },
  },
  sectionPadding: {
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10),
  },
  sectionDoublePadding: {
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(20),
  },
  sectionInnerTopMargin: {
    marginTop: theme.spacing(10),
  },
  sectionHeader: {
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
  },
  capsSmallHeading: {
    textTransform: "uppercase",
    color: theme.paragraph.color,
    fontWeight: 600,
    marginBottom: "10px",
    fontSize: "16px",
  },
  smallImage: {
    width: "75px",
    marginBottom: theme.spacing(3),
  },
  sectionTypographyWrapper: {
    [theme.breakpoints.down("lg")]: {
      paddingRight: 0,
    },
    [theme.breakpoints.down("md")]: {
      paddingRight: 0,
    },
    [theme.breakpoints.between("xs", "sm")]: {
      paddingRight: 0,
      marginBottom: "20px",
    },
  },
  sectionHeading: {
    fontSize: "40px",
    fontWeight: 600,
    lineHeight: "55px",
    marginBottom: "30px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "33px",
      lineHeight: "45px",
    },
  },
  textOrange: {
    color: "#f3a22a",
  },
  subtitle1: {
    fontWeight: 700,
  },
  smallContainer: {
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "3%",
      paddingRight: "3%",
    },
  },
  sectionHeadingWidth: {
    maxWidth: "800px",
  },
  sectionHeadingCenter: {
    marginLeft: "auto",
    marginRight: "auto",
  },
  textPrimary: {
    color: theme.palette.primary.main,
  },

  bgLightGray: {
    backgroundColor: "#f9fafb",
  },
  sectionDescription: {
    maxWidth: "705px",
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: theme.spacing(7),
  },
  containerCustomWidth: {
    maxWidth: "1124px",
  },
  descriptiveHeading: {
    fontSize: "30px",
    fontWeight: 600,
    maxWidth: "800px",
    marginLeft: "auto",
    marginRight: "auto",
    // marginBottom: theme.spacing(10),
  },
  smallHeading: {
    fontWeight: 600,
    marginTop: "60px",
    marginBottom: "45px",
    fontSize: "30px",
  },
  innerSectionTopMargin: {
    marginTop: theme.spacing(14),
  },

  card: {
    position: "relative",
    zIndex: 10,
    boxShadow: "none",
    borderRadius: "5px",
    padding: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(1),
    },
    "&:hover": {
      boxShadow: "0 3px 20px rgba(0,0,0,0.30)",
    },
  },
  priceCard: {
    boxShadow: "none",
  },
  priceCardMainHeading: {
    fontWeight: 700,
  },
  lightGrayBg: {
    backgroundColor: theme.backgroundLight.color,
  },
  lightWheatBg: {
    backgroundColor: "#f5deb340",
  },

  cardIconLarge: {
    width: "120px",
    height: "120px",
    [theme.breakpoints.down("xs")]: {
      width: "50px",
      height: "50px",
    },
  },
  cardIconSmall: {
    width: "70px",
    height: "70px",
    [theme.breakpoints.down("xs")]: {
      width: "50px",
      height: "50px",
    },
  },
  cardContent: {
    position: "relative",
    paddingBottom: 0,
  },
  contentWrapper: {
    display: "flex",
  },
  verticalMargins: {
    marginTop: theme.spacing(5),
    marginBottom: "-10px",
  },
  planContentWrapper: {
    textAlign: "center",
  },
  textSemibold: {
    fontWeight: 600,
  },
  cardHeadingLarge: {
    fontSize: "23px",
    fontWeight: 600,
    color: theme.paragraphDark.color,
  },
  cardHeadingSmallBoxLarge: {
    fontSize: "20px",
    fontWeight: 600,
    marginBottom: 0,
    color: theme.paragraphDark.color,
  },
  cardTextWrapper: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  cardRunningText: {
    marginTop: theme.spacing(1),
  },
  runningText: {
    lineHeight: "25px",
  },
  cardSmallRunningText: {
    fontSize: "15px",
    margin: 0,
    color: "#666",
  },
  arrowIcon: {
    position: "absolute",
    right: theme.spacing(1),
    color: theme.paragraph.color,
    cursor: "pointer",
    fontSize: "20px",
    "&:hover": {
      color: theme.paragraphDark.color,
    },
  },
  cardAction: {
    marginTop: 0,
    width: "100%",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  cardChip: {
    backgroundColor: "#4DD35C",
    color: "#fff",
    fontWeight: 600,
    marginLeft: "auto",
  },
  justifySpaceBetwen: {
    alignItems: "center",
    width: "100%",
  },
  cardDelText: {
    marginRight: theme.spacing(1),
    fontSize: "16px",
    color: "#666",
    fontWeight: 600,
  },

  sideImage: {
    width: "100%",
    borderRadius: "5px",
    boxShadow: theme.section.boxShadow,
  },
  alignRight: {
    textAlign: "right",
  },
  shadow: {
    boxShadow: "0 3px 60px rgba(0,0,0,0.16)",
  },
  textCenter: {
    textAlign: "center",
  },
  alignCenter: {
    display: "flex",
    justifyContent: "center",
  },
  extraPadding: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(5),
  },
  textWarning: {
    color: theme.palette.warning.main,
  },
  textDecorationNone: {
    textDecoration: "none",
  },
  marginTop: {
    marginTop: theme.spacing(5),
  },

  selfCenter: {
    margin: "auto",
  },
  accordion: {
    backgroundColor: "transparent",
    boxShadow: "none",
    // borderBottom: "solid 1px #f7f7f7",
    paddingLeft: 0,
    marginLeft: 0,
  },
  accordionSummary: {
    backgroundColor: "transparent",
    boxShadow: "none",
    paddingLeft: 0,
  },
  accordionSummaryHeading: {
    fontWeight: 600,
    color: theme.paragraphDark.color,
    fontSize: "20px",
  },
  accordionSummaryHeadingLarge: {
    fontSize: "25px",
    fontWeight: 600,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    color: theme.paragraphDark.color,
  },
  ul: {
    marginLeft: 0,
    paddingLeft: 0,
    listStyleType: "none",
  },
  ulSimple: {
    marginLeft: theme.spacing(2),
    paddingLeft: 0,
  },
  listItem: {
    position: "relative",
    paddingLeft: theme.spacing(3),
    marginBottom: theme.spacing(2),
    "&::before": {
      content: '" "',
      position: "absolute",
      backgroundImage: `url(${checkIcon})`,
      height: "15px",
      width: "15px",
      left: "-10px",
      marginTop: "auto",
      marginBottom: "auto",
      top: 0,
      bottom: 0,
    },
  },
  listItemSimple: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  listItemNegative: {
    position: "relative",
    paddingLeft: theme.spacing(3),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(2),
    "&::before": {
      content: '" "',
      position: "absolute",
      height: "2px",
      width: "15px",
      left: "-10px",
      backgroundColor: theme.paragraphDark.color,
      marginTop: "auto",
      marginBottom: "auto",
      top: 0,
      bottom: 0,
    },
  },
  accordionDetails: {
    borderBottom: "solid 1px rgba(0,0,0,0.2)",
  },
  flexWrapper: {
    display: "flex",
    alignItems: "center",
  },
  listIcon: {
    width: "25px",
    marginRight: theme.spacing(2),
  },
  listHeadingBold: {
    fontWeight: 500,
    fontSize: "18px",
    paddingLeft: 0,
    marginTop: theme.spacing(4),
  },
  innerListTopMargin: {
    marginTop: theme.spacing(2),
  },
  accordionSimple: {
    borderBottom: "solid 1px #c7c1a5",
    borderRadius: "0",
    borderTop: 0,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    backgroundColor: "#efe4b0",
    boxShadow: "none",
    "&::before": {
      height: 0,
    },
  },

  section2ImageWrapper: {
    [theme.breakpoints.down("xl")]: {
      textAlign: "center",
    },
    [theme.breakpoints.down("lg")]: {
      textAlign: "center",
    },
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
    },
  },
  buttonWrapper: {
    marginBottom: "30px",
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(5),
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(1),
    },
  },
  bannerButtonContaind: {
    maxHeight: "initial",
    marginTop: "30px",
    marginRight: "25px",
    [theme.breakpoints.down("md")]: {
      marginTop: "0px",
    },
  },

  bannerButtonContaind5: {
    minWidth: "275px",
    backgroundColor: "#ffffff",
    color: theme.palette.primary.main,
    height: "62px",
    marginTop: "60px",

    "&:hover": {
      backgroundColor: "#ffffff",
    },
  },
  section2TypographyWrapper: {
    paddingRight: "300px",
    [theme.breakpoints.down("lg")]: {
      paddingRight: theme.spacing(8),
    },
    [theme.breakpoints.down("md")]: {
      paddingLeft: theme.spacing(25),
      paddingRight: theme.spacing(25),
    },
    [theme.breakpoints.between("xs", "sm")]: {
      paddingRight: 0,
      marginBottom: "40px",
    },
  },
  section: {
    marginTop: "200px",
    [theme.breakpoints.down("lg")]: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
    [theme.breakpoints.down("md")]: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "150px",
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
  },
  customContentHeight: {
    maxHeight: "650px",
    overflowY: "hidden",
    [theme.breakpoints.down("md")]: {
      maxHeight: "initial",
      overflowY: "initial",
    },
  },
  marginBottomLg: {
    [theme.breakpoints.down("lg")]: {
      marginBottom: theme.spacing(5),
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(5),
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(0),
    },
  },

  mobileImage: {
    marginBottom: theme.spacing(2),
    width: "100%",
    maxWidth: "430px",
  },
  section2: {
    marginTop: "200px",
    backgroundImage: "linear-gradient(to left, rgba(0,0,0,0.03), #fff)",
    paddingTop: "50px",
    [theme.breakpoints.down("md")]: {
      paddingBottom: theme.spacing(6),
    },
    [theme.breakpoints.between("xs", "sm")]: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
  },

  p: {
    display: "block",
    color: theme.paragraphDark.color,
    lineHeight: "28px",
    [theme.breakpoints.down("md")]: {
      marginBottom: "30px",
    },
    [theme.breakpoints.down("xs")]: {
      lineHeight: "30px",
    },
  },
  pMarginBottom: {
    marginBottom: theme.spacing(12),
  },

  cardIcon: {
    fontSize: "85px",
  },
  cardText: {
    fontSize: "18px",
    fontWeight: 600,
    marginTop: "20px",
  },

  underline: {
    height: "5px",
    width: "135px",
    backgroundColor: "#31A49D",
    marginTop: "20px",
    marginBottom: "40px",
  },
  section4: {
    marginTop: "200px",
    backgroundColor: theme.backgroundLight.color,
    padding: "100px 0",
    [theme.breakpoints.between("xs", "sm")]: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
  },

  avatar: {
    width: "90px",
    height: "90px",
    marginLeft: "auto",
    marginRight: "auto",
  },
  username: {
    fontWeight: 600,
    display: "block",
    textAlign: "center",
    marginTop: "20px",
  },
  spetialization: {
    textAlign: "center",
    display: "block",
    marginTop: "20px",
  },
  location: {
    display: "block",
    textAlign: "center",
    fontSize: 14,
  },
  buttonGroup: {
    textAlign: "center",
    marginTop: "50px",
  },
  textLeft: {
    textAlign: "left",
  },
  listItemWrapper: {
    maxWidth: "300px",
    margin: "50px auto",
  },
  checkIcon: {
    fontSize: "20px",
    marginRight: theme.spacing(1),
  },
  dFlex: {
    display: "flex",
  },
  alignItemsCenter: {
    alignItems: "center",
  },
  contentTitle: {
    marginTop: "20px",
  },
  textWhite: {
    color: "#fff",
  },
  textDarkSeagreen: {
    color: "#38847c",
  },
  textBluishSeagreen: {
    color: "#32a5b6",
  },

  bgLightCyan: {
    backgroundColor: "#b9e6f0",
  },
  bgCyan: {
    backgroundColor: "#99d9ea",
  },
  bgSeagreen: {
    backgroundColor: theme.backgroundSeagreen,
  },
  bgLightMustartd: {
    backgroundColor: "#efe4b0",
  },
  bgWhite: {
    backgroundColor: "#fff",
    "&:hover": {
      backgroundColor: "#fff",
    },
  },
  bgGreen: {
    backgroundColor: theme.backgroundSeagreen,
    color: "#fff",
    "&:hover": {
      backgroundColor: theme.backgroundSeagreenDark,
    },
  },
  bgPink: {
    backgroundColor: "#fdafcb",
  },
  bgDarkSeagreen: {
    backgroundColor: "#38847c",
    "&:hover": {
      backgroundColor: "#22544f",
    },
  },
  bgBluishSeagreen: {
    backgroundColor: "#32a5b6",
    "&:hover": {
      backgroundColor: "#2b8997",
    },
  },
  cursorPointer: {
    cursor: "pointer",
  },
  cardButtonTopMargin: {
    marginTop: theme.spacing(3),
  },
  containerAlignRight: {
    marginLeft: "auto",
  },
  smallIcon: {
    fontSize: 13,
    padding: 0,
  },
  viewProfileLink: {
    display: "flex",
    alignItems: "center",
  },
  profileCardContainer: {
    maxWidth: "800px",
  },
  smallCardAction: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  appstoreIconsWraper: {
    padding: "20px 10px 15px 10px",
    backgroundColor: "#dce2e9",
  },
  appStoreLogoContainer: {
    textAlign: "center",
  },
  appStoreLogo: {
    maxWidth: "150px",
    marginRight: "5px",
    maxHeight: "44px",
  },
  btnContained: {
    backgroundColor: theme.backgroundSeagreen,
    color: "#fff",
    "&:hover": {
      backgroundColor: theme.backgroundSeagreenDark,
    },
  },
  hiwCard: {
    padding: "20px 10px",

    position: "relative",
    zIndex: 4,
  },
  smallTitle: {
    color: "#aaa",
    fontWeight: 600,
    textTransform: "uppercase",
  },
  textDarkSeaGreen: {
    color: "#244844 !important",
  },
  sectionSmallHeading: {
    fontSize: "1.8rem",
    fontWeight: "bold",
  },

  icon: {
    width: "35px",
  },

  iconSection: {
    marginTop: theme.spacing(4),
  },
  extraSmallHeading: {
    fontWeight: 700,
    fontSize: ".9rem",
  },

  cardActionSmall: {
    marginBottom: theme.spacing(1),
    textAlign: "center !important",
    justifyContent: "center",
  },
  unstyledButton: {
    fontSize: "1.1rem",
    textTransform: "initial",
    background: "transparent",
    boxShadow: "none",
    color: theme.palette.secondary.main,
    "&:hover": {
      background: "transparent",
    },
  },
  verticalSeparator: {
    width: "2px",
    height: "100%",
    backgroundColor: "#aaa",
    margin: "auto",
  },

  verticalSeparatorWrapper: {
    backgroundColor: "#f9fafb",
    overflow: "hidden",
    position: "relative",

    "&::after": {
      content: '""',
      position: "absolute",
      width: "2px",
      height: "100%",
      backgroundColor: "#ddd",
      margin: "auto",
      left: 0,
      right: 0,
      marginLeft: "auto",
      marginRight: "auto",
      top: "150px",
      sIndex: 1,
    },
  },
  smallContainerPosition: {
    position: "relative",
  },
  gridSeparator: {
    position: "relative",
    "&::after": {
      content: '""',
      position: "absolute",
      width: "2px",
      height: "100%",
      backgroundColor: "#ddd",
      margin: "auto",
      left: 0,
      right: 0,
      marginLeft: "auto",
      marginRight: "auto",
      top: "0px",
    },
  },
  spacer: {
    paddingBottom: theme.spacing(10),
  },
  spacerBanner: {
    marginTop: "20px",
    marginBottom: "20px",
  },
  customCardAction: {
    display: "block",
    paddingTop: theme.spacing(8),
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
  actionHeading: {
    fontWeight: 800,
  },
  lastStepWrapper: {
    position: "relative",
    zIndex: 2,
    backgroundColor: "#f9fafb",
    paddingBottom: theme.spacing(10),
  },
  lastStepHiw: {
    padding: theme.spacing(5),
    textAlign: "center",
    borderRadius: "10px",
    backgroundColor: `#38847c !important`,
    "&:hover": {
      backgroundColor: `${theme.bgDarkSeagreen} !important`,
    },
  },
  bordText: {
    fontSize: "1.5rem",
    fontWeight: 500,
  },
  darkSectionIconWrapper: {
    "& img": {
      maxWidth: "70px",
    },
  },
  sectionShrinked: {
    backgroundColor: "red",
    border: "solid 5px red",
  },
  sectionSmallPadding: {
    paddingBottom: theme.spacing(12),
    [theme.breakpoints.down("sm")]: {
      paddingBottom: theme.spacing(5),
    },
  },
  sectionPaddinBottom: {},
  hiwSideImage: {
    width: "100%",
    position: "relative",
    zIndex: 5,
  },
  fullWidthImageSection: {
    // marginBottom: "30px",
  },
  fullPageImage: {
    width: "100%",
  },
  extraSmallContainer: {
    maxWidth: "1000px",
    marginLeft: "auto",
    marginRight: "auto",
  },
  howItWorksWrapper: {
    marginTop: "20px",
    background: "#618cc7",
    padding: "20px 0",
    [theme.breakpoints.up("lg")]: {
      maxHeight: "690px",
      overflow: "hidden",
    },
  },
  hiwHeading: {
    fontSize: "30px",
    fontWeight: "bold",
    textAlign: "center",
    margin: "0 auto",
    marginTop: "30px",
    fontFamily: "Open Sans",
    color: "#2d2534",
    [theme.breakpoints.down("sm")]: {
      marginTop: "0px",
    },
  },

  headingDivider: {
    color: "#fff",
    textAlign: "center",
    fontSize: "20px",
    fontWeight: 100,
  },
  titleWrapper: {
    // display: "flex",
    //marginTop: "30px",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      marginTop: "0px",
    },
  },
  hiwSubHeading: {
    fontSize: "28px",
    fontWeight: "bold",
    textAlign: "center",
    color: "#fff",
    fontFamily: "Jeko",
    maxWidth: "55%",
    margin: "0 auto",
    [theme.breakpoints.down("md")]: {
      maxWidth: "75%",
    },
    [theme.breakpoints.down("sm")]: {
      // marginLeft: "20px",
      textAlign: "center",
      maxWidth: "100%",
    },
  },
  hiwPara: {
    fontSize: "18px",
    fontWeight: "400",
    textAlign: "center",
    color: "#fff",
    // maxWidth: "90%",
    // margin: "0 auto",
    marginBottom: "20px",
    fontFamily: "Jeko",
    marginTop: "10px",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  hiwH2: {
    fontSize: "28px",
    fontWeight: "bold",
    textAlign: "center",
    color: "#000",
    // background: "#fff",
    border: "4px solid #fff",
    fontFamily: "Jeko",
    marginLeft: "10px",
    [theme.breakpoints.down("xs")]: {
      marginLeft: "0px",
      width: "100%",
      textAlign: "left",
    },
  },
  hiwP2: {
    // fontSize: "24px",
    fontWeight: "bold",
    // textAlign: "center",
    color: "#000",
    background: "#fff",
    marginTop: "10px",
    fontFamily: "Jeko",
    padding: "0 4px",
  },
  blackText: {
    color: "#000",
  },
  hiwImgWrapper: {
    [theme.breakpoints.down("md")]: {
      marginTop: "50px",
    },
  },
  hiwImg: {
    width: "100%",
  },
  numberWrapperHidden: {
    visibility: "hidden",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  numberWrapper: {
    background: "#fff",
    color: "#000",
    borderRadius: "50%",
    padding: "15px",
    fontWeight: "bold",
    height: "60px",
    fontSize: "26px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Jeko",
    marginBottom: "0px",
    [theme.breakpoints.down("xs")]: {
      alignSelf: "start",
      marginBottom: "10px",
    },
    // display: "inline",
  },
  medicineIconWrapper: {
    // background: "#fff",
    maxWidth: "300px",
    margin: "0 auto",
    textAlign: "center",
    padding: "20px 0",
  },
  step: {
    maxWidth: "100px",
    margin: "0 auto",
    textAlign: "center",
    color: "#c5c5c5",
    fontWeight: "bold",
    fontFamily: "Jeko",
    padding: "8px",
  },
  leftContainerHiw: {
    position: "relative",
    alignItems: "center",
    left: "80px",
    [theme.breakpoints.down("sm")]: {
      left: "0",
    },
  },
  hitTextWrapper: {
    height: "100%",
    display: "flex",
    aligntItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  whiteText: {
    color: "#fff",
    fontWeight: "bold",
  },
  bgBannerBlue: {
    backgroundColor: "#618cc7",
    "&:hover": {
      backgroundColor: "#4b70a2",
    },
  },
}));

function Home(props) {
  document.body.style.background = "#fff";

  const theme = useTheme();

  const md = useMediaQuery(theme.breakpoints.down("md"));
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const xs = useMediaQuery(theme.breakpoints.down("xs"));

  const classes = useStyles();

  const { isAuthenticated, user } = useAuth0();

  // const role = utils.getRole(user);
  const isAdmin = utils.isAdmin(user);

  if (isAuthenticated && props.isProcessing) {
    return <Loading />;
  }
  // else if( isAuthenticated && isAdmin ){
  //   return <Redirect to='/directory' />
  // }
  else if (isAuthenticated && props.isMember === false && !isAdmin) {
    return <Redirect to="/signup" />;
  }

  return (
    <div className={classes.root} id="home">
      {/* Banner */}
      <div className={`${classes.banner} auc-banner-wrapper`}>
        {/* <EmbeddedVideo /> */}
        <div className={classes.bannerContainer}>
          <div className={classes.bannerTypographyOuterWrapper}>
            <Container maxWidth="lg">
              <div className={classes.bannerTyporgraphWrapper}>
                <img src={newLogo} className={classes.newLogo} alt="logo" />
                <Typography className={`${classes.bannerHeading}`} variant="h1">
                  Tova
                </Typography>
                <p className={classes.bannerRunningText}>
                  Video chat with the next available U.S. doctor - 24/7 within
                  minutes - and have treatments mailed directly to your home.
                </p>

                {/* <div className={classes.appStoreLogoContainer}>
                  <NavLink target="_blank" rel="noreferrer" to="/">
                    <img
                      src={applestoreLogo}
                      alt=""
                      className={classes.appStoreLogo}
                    />
                  </NavLink>
                  <NavLink target="_blank" rel="noreferrer" to="/">
                    <img
                      src={playstoreLogo}
                      alt=""
                      className={classes.appStoreLogo}
                    />
                  </NavLink>
                </div> */}

                <div className={classes.buttonWrapper}>
                  <Button
                    className={`${classes.bannerButtonContaind} ${classes.bgBannerBlue} ${classes.textWhite}`}
                    variant="contained"
                    component={NavLink}
                    to="/individual/signup"
                    style={{ marginRight: "0" }}
                  >
                    Get started
                  </Button>
                </div>
              </div>
            </Container>
          </div>
        </div>
      </div>
      {/* /Banner */}
      {/* how it works */}
      <div className={classes.howItWorksWrapper}>
        <Container maxWidth="lg">
          <Grid container style={{ justifyContent: "space-between" }}>
            <Grid
              item
              xs={12}
              md={4}
              lg={4}
              className={classes.leftContainerHiw}
            >
              {/* <Box className={classes.titleWrapper}>
                <Box className={classes.numberWrapperHidden}>01</Box>

                <Typography variant="h4" className={classes.hiwHeading}>
                  How It Works
                  <Box className={classes.headingDivider}>-</Box>
                </Typography>
              </Box> */}
              <Box className={classes.hitTextWrapper}>
                <Box className={classes.titleWrapper}>
                  {/* <Box className={classes.numberWrapper}>01</Box> */}
                  <Box className={classes.step}>STEP 1</Box>
                  <Typography variant="h4" className={classes.hiwSubHeading}>
                    See a doctor within minutes
                  </Typography>
                </Box>

                <Typography className={classes.hiwPara}>
                  Tova doctors can diagnose, prescribe medicine. order lab
                  tests, recommend referrals, write a doctors note for work or
                  school, and so much more. All from within the app.
                </Typography>
                <Box className={classes.titleWrapper}>
                  <Box className={classes.spacerBanner}></Box>
                  <Box className={classes.step}>STEP 2</Box>
                  <Typography className={classes.hiwSubHeading} variant="h4">
                    Medicine to your door
                  </Typography>
                </Box>
                <Typography className={classes.hiwPara}>
                  Have your new prescription delivered to your home with{" "}
                  <span className={classes.whiteText}>same-day shiping</span>,
                  or sent to your local pharmacy for pickup.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={7} lg={6}>
              <div className={classes.hiwImgWrapper}>
                <img
                  src={howItWorksImg}
                  alt="how-it-works"
                  className={classes.hiwImg}
                />
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
      {/* /how it works */}

      {/* App store icons */}
      <div className={classes.appstoreIconsWraper}>
        <div className={classes.appStoreLogoContainer}>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://apps.apple.com/in/app/tova-patient/id376771144"
          >
            <AppStoreIcon
              style={{
                width: "150px",
                marginRight: "4px",
              }}
            />
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://play.google.com/store/apps/details?id=com.tovamedical.patient&pli=1"
          >
            <GooglePlayIcon style={{ width: "150px" }} />
          </a>
        </div>
      </div>
      {/* /App store icons */}

      {/* V3 Section 1 */}
      <div
        className={`${classes.sectionPadding} ${classes.smallContainer} ${classes.bgLightGray} ${classes.topExtraMargin}`}
      >
        <Container className={classes.containerCustomWidth}>
          <div className={classes.sectionHeader}>
            <Typography className={classes.capsSmallHeading} variant="h6">
              how we can help
            </Typography>
            <Typography className={classes.sectionHeading} variant="h2">
              Let's get started
            </Typography>
            <Typography className={classes.smallHeading} variant="h5">
              Talk to a doctor
            </Typography>
          </div>
          <TalkToADoctor />
        </Container>
      </div>
      {/* /V3 Section 1 */}

      {/* V3 Section 2 */}
      <div
        id="types-of-care"
        className={`${classes.sectionPadding} ${classes.smallContainer} ${classes.bgCyan} `}
      >
        <Container className={classes.containerCustomWidth}>
          <TypesOfCare
            content={{
              section1: {
                align: "center",
                heading: "Care for every part of your life",
                description:
                  "Our primary care doctors can help you with a broad range of health issues, medications and lab orders by video appointment",
              },
              section2: {
                align: "center",
                heading: "Mental & behavioral",
                description:
                  "Talk with our Primary Care doctors about a broad range of issues like sleep, anxiety, or addictive behaviors. Or schedule ongoing appointments with a licensed therapist.",
              },
              section3: {
                align: "center",
                heading: "",
                description: "",
              },
            }}
          />
        </Container>
      </div>
      {/* /V3 Section 2 */}

      {/* V3 Section 4 */}
      <div className={` ${classes.bgLightGray} ${classes.smallContainer} `}>
        <Container fixed>
          <Grid
            container
            direction={xs || sm || md ? "column-reverse" : "row"}
            alignItems="center"
            spacing={8}
          >
            <Grid item xl={6} lg={6} sm={12} md={12} xs={12}>
              <div
                className={`${classes.sectionTypographyWrapper} ${classes.marginBottomLg}`}
              >
                <div className={classes.textCenter}>
                  <img
                    src={doctorPatientIcon}
                    className={classes.smallImage}
                    alt="urgent care"
                  />
                </div>

                <Typography
                  className={`${classes.capsSmallHeading} ${classes.textDarkSeagreen} ${classes.textCenter}`}
                  variant="h6"
                >
                  Urgent & primary care
                </Typography>
                <Typography
                  className={classes.sectionHeading}
                  align="center"
                  variant="h3"
                >
                  Seeing a doctor has never been easier
                </Typography>

                <p className={`${classes.p} ${classes.textCenter}`}>
                  Use the Tova mobile app to video call with the next available
                  doctor to diagnose issues and prescribe treatments. Pick up
                  prescriptions at your local pharmacy or have them shipped
                  directly to your home.
                </p>

                <div className={classes.textCenter}>
                  <Button
                    className={`${classes.bannerButtonContaind} ${classes.bgBluishSeagreen} ${classes.textWhite} `}
                    variant="contained"
                    component={NavLink}
                    to="/individual/signup"
                  >
                    Get Started
                  </Button>
                </div>
              </div>
            </Grid>
            <Grid item xl={6} lg={6} sm={12} md={12} xs={12}>
              <div
                className={`${classes.alignRight} ${classes.customContentHeight}`}
              >
                <img
                  src={doctorCallImage}
                  alt="Prescriptions delivered right to your door"
                  className={classes.mobileImage}
                />
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
      {/* /V3 Section 4 */}

      {/* V3 Section 3 */}
      <div
        className={`${classes.sectionPadding} ${classes.smallContainer}  ${classes.bgSeagreen}`}
      >
        <Container fixed>
          <Grid container direction="row" spacing={8}>
            <Grid item xl={6} lg={6} sm={12} md={12} xs={12}>
              <img
                src={pillsImage}
                alt="Prescriptions delivered right to your door"
                className={classes.sideImage}
              />
            </Grid>
            <Grid item xl={6} lg={6} sm={12} md={12} xs={12}>
              <div className={classes.sectionTypographyWrapper}>
                <Box className={classes.medicineIconWrapper}>
                  <img
                    src={medicine}
                    style={{ height: "80px" }}
                    alt="medicine"
                  />
                  <Typography
                    className={`${classes.capsSmallHeading} ${classes.textPrimary} ${classes.textWhite} ${classes.contentTitle}`}
                  >
                    URGENT AND PRIMARY CARE
                  </Typography>
                </Box>
                <Typography
                  className={`${classes.sectionHeading} ${classes.textCenter}`}
                  variant="h3"
                >
                  Medication delivery
                </Typography>

                <p className={`${classes.p} ${classes.textCenter}`}>
                  We know that you are concerned about getting any necessary
                  medications quickly. Tova has partnered with multiple
                  pharmacies so you can have your prescriptions delivered right
                  to your door, usually within just one day.
                </p>
                <p className={`${classes.p} ${classes.textCenter}`}>
                  Patients may also choose to have prescriptions sent to their
                  local pharmacy for same-day pick up.
                </p>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
      {/* /V3 Section 3 */}

      <div
        id="how-tova-works"
        style={{ paddingTop: "100px" }}
        className={`${classes.verticalSeparatorWrapper} `}
      >
        {/* Section 4 */}
        <div
          className={`${classes.sectionSmallPadding} ${classes.bgLightGray} ${classes.smallContainer} ${classes.verticalSeparatorWrapper}`}
        >
          <Container
            maxWidth="md"
            className={`${classes.containerSmallerWidth} `}
          >
            <div className={classes.sectionHeader}>
              <Typography className={classes.sectionHeading} variant="h2">
                How Tova Works
              </Typography>
            </div>

            <Grid
              container
              direction={sm ? "column" : "row"}
              alignItems={sm ? "center" : "stretch"}
              justify={sm ? "center" : "space-between"}
              spacing={10}
            >
              <Grid item xl={6} lg={6} md={6} sm={9} xs={12}>
                <Card
                  className={`${classes.card} ${classes.hiwCard} ${classes.shadow}  ${classes.textCenter}`}
                >
                  <CardContent>
                    <Typography
                      className={classes.smallTitle}
                      color="textSecondary"
                      gutterBottom
                    >
                      Step 1
                    </Typography>
                    <Typography
                      className={`${classes.sectionSmallHeading} ${classes.textCenter} ${classes.textDarkSeaGreen}`}
                      variant="h5"
                      component="h2"
                    >
                      Set up your free account
                    </Typography>
                    <Typography variant="body2" className={classes.marginTop}>
                      You will get an instant access to free healthcare tools
                      like our healthcare records and vaccine certificate
                      storage, while also being able to view the app and its
                      features.
                    </Typography>
                    <Box component="div" className={classes.iconSection}>
                      <Grid container spacing={3}>
                        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                          <Box
                            component="div"
                            className={classes.iconContentWrapper}
                          >
                            <Box
                              component="div"
                              className={classes.iconWrapper}
                            >
                              <img
                                alt=""
                                src={HealthcareIcon}
                                className={classes.icon}
                              />
                            </Box>
                            <Typography
                              variant="body1"
                              className={`${classes.extraSmallHeading} ${classes.textDarkSeaGreen}`}
                            >
                              Personalized Care
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                          <Box
                            component="div"
                            className={classes.iconContentWrapper}
                          >
                            <Box
                              component="div"
                              className={classes.iconWrapper}
                            >
                              <img
                                alt=""
                                src={StethoscopeIcon}
                                className={classes.icon}
                              />
                            </Box>
                            <Typography
                              variant="body1"
                              className={`${classes.extraSmallHeading} ${classes.textDarkSeaGreen}`}
                            >
                              Symptom Assessment
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </CardContent>
                  <CardActions className={classes.cardActionSmall}>
                    <Button
                      className={classes.unstyledButton}
                      fullWidth
                      size="small"
                      endIcon={<AngleRightIcon color="secondary" />}
                      component={NavLink}
                      to="/individual/signup"
                    >
                      Set up your account now
                    </Button>
                  </CardActions>
                </Card>
              </Grid>

              <Grid item xl={6} lg={6} md={6} sm={9} xs={12}>
                <Box component="div" className={classes.hiwImageWrapper}>
                  <img
                    src={DoubleMobile}
                    alt=""
                    className={classes.hiwSideImage}
                  />
                </Box>
              </Grid>
            </Grid>
          </Container>
        </div>
        {/* /Section 4 */}

        {/* V3 Section 5 */}
        <div
          className={` ${classes.bgLightGray}  ${classes.smallContainer} ${classes.smallContainerPosition}`}
          style={{ marginTop: "100px !important" }}
        >
          <Container maxWidth="md" className={classes.containerSmallerWidth}>
            <Card
              className={`${classes.card} ${classes.shadow} ${classes.extraPadding} `}
            >
              <CardContent className={classes.cardContent}>
                <div className={classes.sectionHeader}>
                  <Typography
                    className={classes.smallTitle}
                    color="textSecondary"
                    gutterBottom
                  >
                    Step 2
                  </Typography>
                  <Typography
                    className={`${classes.sectionSmallHeading} ${classes.sectionHeading} ${classes.textDarkSeaGreen}`}
                    variant="h2"
                  >
                    Choose your membership
                  </Typography>
                  {/* <Typography
                    className={`${classes.p} ${classes.pMarginBottom} ${classes.subtitle1} ${classes.sectionDescription}`}
                  >
                    The right care for you
                  </Typography> */}
                </div>
                <Grid
                  container
                  direction="row"
                  alignItems="flex-start"
                  style={{ justifyContent: "center" }}
                  className={xs ? "" : classes.gridSeparator}
                >
                  <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                    <Card
                      className={`${classes.extraPadding} ${classes.priceCard}`}
                    >
                      <CardContent className={classes.cardContent}>
                        <div className={classes.planContentWrapper}>
                          <Typography
                            className={`${classes.priceCardMainHeading} ${classes.textBluishSeagreen} ${classes.textCenter}`}
                            variant="h4"
                          >
                            Tova Prime
                          </Typography>
                          <div className={classes.listItemWrapper}>
                            <Typography
                              className={classes.subtitle1}
                              variant="subtitle1"
                            >
                              $30 per month
                            </Typography>
                          </div>

                          <Typography
                            className={`${classes.priceCardMainHeading} ${classes.textBluishSeagreen} ${classes.textCenter}`}
                            variant="h5"
                          >
                            $39
                          </Typography>
                          <Typography>
                            Per visit{" "}
                            <span className={classes.textOrange}>
                              (50% off)
                            </span>
                          </Typography>
                        </div>
                      </CardContent>
                      <CardActions
                        className={`${classes.cardAction} ${classes.alignCenter}`}
                      >
                        <Button
                          className={`${classes.cardButtonTopMargin} ${classes.bgBluishSeagreen} ${classes.textWhite}`}
                          variant="contained"
                          component={NavLink}
                          to="/individual/signup"
                        >
                          Get tova prime
                        </Button>
                      </CardActions>
                    </Card>
                  </Grid>

                  <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                    <Card
                      className={`${classes.extraPadding} ${classes.priceCard}`}
                    >
                      <CardContent className={classes.cardContent}>
                        <div className={classes.planContentWrapper}>
                          <Typography
                            className={`${classes.priceCardMainHeading} ${classes.textDarkSeagreen} ${classes.textCenter}`}
                            variant="h4"
                          >
                            Tova Basic
                          </Typography>
                          <div className={classes.listItemWrapper}>
                            <Typography
                              className={classes.subtitle1}
                              variant="subtitle1"
                            >
                              Pay per visit
                            </Typography>
                          </div>

                          <Typography
                            className={`${classes.priceCardMainHeading} ${classes.textDarkSeagreen} ${classes.textCenter}`}
                            variant="h5"
                          >
                            $80
                          </Typography>
                          <Typography>Per visit</Typography>
                        </div>
                      </CardContent>
                      <CardActions
                        className={`${classes.cardAction} ${classes.alignCenter}`}
                      >
                        <Button
                          className={`${classes.cardButtonTopMargin}  ${classes.bgDarkSeagreen} ${classes.textWhite}`}
                          variant="contained"
                          component={NavLink}
                          to="/individual/signup"
                        >
                          Get tova basic
                        </Button>
                      </CardActions>
                    </Card>
                  </Grid>
                </Grid>
              </CardContent>
              <CardActions
                className={`${classes.textCenter} ${classes.customCardAction}`}
              >
                <Typography
                  align="center"
                  className={classes.actionHeading}
                  variant="h6"
                >
                  Affordable doctor visits - without the hassle of insurance
                </Typography>
                <Typography align="center">
                  No more waiting hours at the doctors office or having to keep
                  timely appointments. Login and see a doctor via video call.
                  It's that easy!
                </Typography>
              </CardActions>
            </Card>
            {/* {sm || xs ? null : <Box className={classes.spacer} />} */}
          </Container>
        </div>
        {/* /V3 Section 5 */}

        {/* Section 4 */}
        <div
          style={{ paddingBottom: "40px", paddingTop: "100px" }}
          className={` ${classes.bgLightGray} ${classes.smallContainer} ${classes.verticalSeparatorWrapper}`}
        >
          <Container
            maxWidth="md"
            className={`${classes.containerSmallerWidth} `}
          >
            <Grid
              container
              direction={sm ? "column" : "row"}
              alignItems="center"
              justify={sm ? "center" : "space-between"}
              spacing={10}
            >
              <Grid item xl={6} lg={6} md={6} sm={9} xs={12}>
                <Box component="div" className={classes.hiwImageWrapper}>
                  <img
                    src={MobileCircle}
                    alt=""
                    className={classes.hiwSideImage}
                  />
                </Box>
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={9} xs={12}>
                <Card
                  className={`${classes.card} ${classes.hiwCard} ${classes.shadow} ${classes.extraPadding}  ${classes.textCenter}`}
                >
                  <CardContent>
                    <Typography
                      className={classes.smallTitle}
                      color="textSecondary"
                      gutterBottom
                    >
                      Step 3
                    </Typography>
                    <Typography
                      className={`${classes.sectionSmallHeading} ${classes.textCenter} ${classes.textDarkSeaGreen}`}
                      variant="h5"
                      component="h2"
                    >
                      Connect with a doctor or book an appointment
                    </Typography>
                    <Typography variant="body2" className={classes.marginTop}>
                      {" "}
                      Tova doctors can diagnose, prescribe medicine, order lab
                      tests, recommend referrals, write a doctors note for work
                      or school, and so much more. They provide a detailed
                      treatment plan after every virtual visit to help keep you
                      on track. You also have delivery pharmacy options, so you
                      can have your medicine delivered right to your door,
                      usually in less than one day.
                    </Typography>
                    <Typography variant="body2" className={classes.marginTop}>
                      {" "}
                      Tova verifies all consulting doctors are U.S.
                      board-certified and licensed by state, so you'll be met
                      with the utmost professionalism. All visits are
                      confidential and HIPAA compliant, and you also have 24/7
                      digital access to your own personal health records, stored
                      privately and securely.
                    </Typography>
                  </CardContent>
                  <CardActions className={classes.cardActionSmall}>
                    <Button
                      className={`${classes.btnContained} `}
                      variant="contained"
                      component={NavLink}
                      to="/individual/signup"
                    >
                      Get started
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            </Grid>
          </Container>
          {/* <Box
            className={`${classes.textWhite} ${classes.lastStepHiw}`}
            component="div"
          >
            <Typography className={classes.bordText}>
              In-person doctor visits and urgent care clinics can cost hundreds
              of dollars. Tova is a fraction of the cost and there's no waiting
              in line and no transportation hassles. See a doctor in minutes!
            </Typography>
          </Box> */}
        </div>
        {/* /Section 4 */}

        {/* Green Section */}
        <div
          style={{ marginTop: "80px" }}
          className={` ${classes.bgLightGray} ${classes.sectionPaddinBottom} ${classes.extraSmallContainer} ${classes.smallContainerPosition}`}
        >
          <div className={classes.lastStepWrapper}>
            <Container maxWidth="lg">
              <Box
                className={`${classes.textWhite} ${classes.lastStepHiw}`}
                component="div"
              >
                <Box component="div" className={classes.darkSectionIconWrapper}>
                  <img src={DonationIcon} alt="" />
                </Box>
                <Typography className={classes.bordText}>
                  In-person doctor visits and urgent care clinics can cost
                  hundreds of dollars. Tova is a fraction of the cost and
                  there's no waiting in line and no transportation hassles. See
                  a doctor in minutes!
                </Typography>
              </Box>
            </Container>
          </div>
        </div>
        {/* /Green Section */}
      </div>

      {/* V3 Section 6 */}
      <div
        id="our-doctors"
        className={`${classes.sectionPadding} ${classes.bgPink} ${classes.smallContainer}`}
      >
        <div className={classes.sectionHeader}>
          <Typography
            className={`${classes.sectionHeading} ${classes.descriptiveHeading}`}
            variant="h4"
            style={{ marginBottom: "0" }}
          >
            Doctors in your state. Visits on your schedule. Total commitment to
            providing the best possible care.
          </Typography>
        </div>
        {/* <Container maxWidth="md" className={classes.profileCardContainer}>
          <Grid
            container
            direction="row"
            alignItems="flex-start"
            justifyContent="center"
            spacing={3}
          >
            <Grid item xl={4} lg={4} md={4} sm={5} xs={7}>
              <Card className={`${classes.card} ${classes.shadow} `}>
                <CardContent className={classes.cardContent}>
                  <div className={classes.profileBox}>
                    <Avatar className={classes.avatar} src={User1} alt="" />
                    <span className={classes.username}>Dr. Robert Malone</span>
                    <small
                      className={`${classes.spetialization} ${classes.textPrimary} `}
                    >
                      Primary care
                    </small>
                    <div className={`${classes.location}  `}>
                      <div>Hockessin, DE</div>
                      <div>8 years experience</div>
                    </div>
                  </div>
                </CardContent>
                <CardActions
                  className={`${classes.textCenter} ${classes.smallCardAction}`}
                >
                  <NavLink
                    to="/"
                    className={`${classes.textPrimary} ${classes.selfCenter} ${classes.textDecorationNone}`}
                  >
                    <div className={classes.viewProfileLink}>
                      <span>View Profile </span>
                      <ArrowForwardIcon className={classes.smallIcon} />
                    </div>
                  </NavLink>
                </CardActions>
              </Card>
            </Grid>
            <Grid item xl={4} lg={4} md={4} sm={5} xs={7}>
              <Card className={`${classes.card} ${classes.shadow} `}>
                <CardContent className={classes.cardContent}>
                  <div className={classes.profileBox}>
                    <Avatar className={classes.avatar} src={User2} alt="" />
                    <span className={classes.username}>
                      Dr. James McCullough
                    </span>
                    <small
                      className={`${classes.spetialization} ${classes.textPrimary}`}
                    >
                      Primary care
                    </small>
                    <div className={`${classes.location} `}>
                      <div>Long Island, NY</div>
                      <div>4 years experience</div>
                    </div>
                  </div>
                </CardContent>
                <CardActions
                  className={`${classes.textCenter} ${classes.smallCardAction} `}
                >
                  <NavLink
                    to="/"
                    className={`${classes.textPrimary} ${classes.selfCenter} ${classes.textDecorationNone}`}
                  >
                    <div className={classes.viewProfileLink}>
                      <span>View Profile </span>
                      <ArrowForwardIcon className={classes.smallIcon} />
                    </div>
                  </NavLink>
                </CardActions>
              </Card>
            </Grid>
            <Grid item xl={4} lg={4} md={4} sm={5} xs={7}>
              <Card className={`${classes.card} ${classes.shadow} `}>
                <CardContent className={classes.cardContent}>
                  <div className={classes.profileBox}>
                    <Avatar className={classes.avatar} src={User3} alt="" />
                    <span className={classes.username}>
                      Dr. Elizabeth Clarke
                    </span>
                    <small
                      className={`${classes.spetialization} ${classes.textPrimary}`}
                    >
                      Primary care
                    </small>
                    <div className={`${classes.location} `}>
                      <div>Jacksonville, FL</div>
                      <div>9 years experience</div>
                    </div>
                  </div>
                </CardContent>
                <CardActions
                  className={`${classes.textCenter} ${classes.smallCardAction} `}
                >
                  <NavLink
                    to="/"
                    className={`${classes.textPrimary} ${classes.selfCenter} ${classes.textDecorationNone}`}
                  >
                    <div className={classes.viewProfileLink}>
                      <span>View Profile </span>
                      <ArrowForwardIcon className={classes.smallIcon} />
                    </div>
                  </NavLink>
                </CardActions>
              </Card>
            </Grid>
          </Grid>
        </Container> */}
      </div>
      {/* /V3 Section 6 */}

      {/* V3 Section 7 */}
      <div
        className={`${classes.bgLightCyan} ${classes.sectionTopBottomPadding}`}
      >
        <div className={classes.sectionHeader}>
          <Typography className={classes.sectionHeading} variant="h2">
            Find the care that's right for you
          </Typography>
          <p className={`${classes.sectionDescription} ${classes.runningText}`}>
            See an urgent care doctor for immediate issues, or choose a primary
            care physician that you an continuously see to to manage chronic or
            ongoing conditions.
          </p>
        </div>
        <Container fixed>
          <Grid container direction="row" spacing={5}>
            <Grid item xl={6} lg={6} sm={12} xs={12}>
              <div className={classes.textWrapper}>
                <Typography variant="h6" className={classes.smallHeading}>
                  Urgent care:
                </Typography>
                <div className={classes.accordianWrapper}>
                  <Accordion className={classes.accordion}>
                    <AccordionSummary
                      className={`${classes.accordionSummary}`}
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className={classes.accordionSummaryHeading}>
                        COVID-19 or flu advice
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.accordionDetails}>
                      <ul className={classes.ul}>
                        <li className={classes.listItem}>
                          COVID-19 treatment, including prophylactic
                          (preventive) and early (home) treatment
                        </li>
                        <li className={classes.listItem}>
                          Hydroxychloroquine (HCQ), Ivermectin, and other
                          COVID-19 medications
                        </li>
                        <li className={classes.listItem}>
                          COVID-19 vaccination advice
                        </li>
                        <li className={classes.listItem}>
                          Post COVID health concerns
                        </li>
                        <li className={classes.listItem}>Cough or wheezing</li>
                      </ul>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion className={classes.accordion}>
                    <AccordionSummary
                      className={`${classes.accordionSummary}`}
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                    >
                      <Typography className={classes.accordionSummaryHeading}>
                        Prescriptions and refills
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.accordionDetails}>
                      <ul className={classes.ul}>
                        <li className={classes.listItem}>
                          Prescriptions for non-emergency health concern
                        </li>
                        <li className={classes.listItem}>
                          Short duration Rx refills.
                        </li>
                      </ul>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion className={classes.accordion}>
                    <AccordionSummary
                      className={`${classes.accordionSummary}`}
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel3a-content"
                      id="panel3a-header"
                    >
                      <Typography className={classes.accordionSummaryHeading}>
                        Ear, nose, or throat issues
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.accordionDetails}>
                      <ul className={classes.ul}>
                        <li className={classes.listItem}>Cold of cough</li>
                        <li className={classes.listItem}>Throat pain</li>
                        <li className={classes.listItem}>Ear pain</li>
                        <li className={classes.listItem}>Sinus congestion</li>
                        <li className={classes.listItem}>Sore throat</li>
                        <li className={classes.listItem}>Headaches</li>
                        <li className={classes.listItem}>Allergies</li>
                      </ul>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion className={classes.accordion}>
                    <AccordionSummary
                      className={`${classes.accordionSummary}`}
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel4a-content"
                      id="panel4a-header"
                    >
                      <Typography className={classes.accordionSummaryHeading}>
                        Heart, lung, or chest issues
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.accordionDetails}>
                      <ul className={classes.ul}>
                        <li className={classes.listItem}>Blood pressure</li>
                        <li className={classes.listItem}>Palpitations</li>
                        <li className={classes.listItem}>Wheezing</li>
                        <li className={classes.listItem}>Chronic cough</li>
                      </ul>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion className={classes.accordion}>
                    <AccordionSummary
                      className={`${classes.accordionSummary}`}
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel5a-content"
                      id="panel5a-header"
                    >
                      <Typography className={classes.accordionSummaryHeading}>
                        Stomach or Intestinal problems
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.accordionDetails}>
                      <ul className={classes.ul}>
                        <li className={classes.listItem}>Heart burn</li>
                        <li className={classes.listItem}>Abdominal pain</li>
                        <li className={classes.listItem}>Vomiting</li>
                        <li className={classes.listItem}>Diarrhea</li>
                        <li className={classes.listItem}>Hemorrhoids</li>
                      </ul>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion className={classes.accordion}>
                    <AccordionSummary
                      className={`${classes.accordionSummary}`}
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel6a-content"
                      id="panel6a-header"
                    >
                      <Typography className={classes.accordionSummaryHeading}>
                        Skin or hair care
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.accordionDetails}>
                      <ul className={classes.ul}>
                        <li className={classes.listItem}>Rashes</li>
                        <li className={classes.listItem}>Acne</li>
                        <li className={classes.listItem}>Dandruff</li>
                        <li className={classes.listItem}>Itching</li>
                        <li className={classes.listItem}>Lice</li>
                      </ul>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion className={classes.accordion}>
                    <AccordionSummary
                      className={`${classes.accordionSummary}`}
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel7a-content"
                      id="panel7a-header"
                    >
                      <Typography className={classes.accordionSummaryHeading}>
                        Bone, joint or muscle conditions
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.accordionDetails}>
                      <ul className={classes.ul}>
                        <li className={classes.listItem}>
                          Sprains and strains
                        </li>
                        <li className={classes.listItem}>
                          Arm or leg problems
                        </li>
                        <li className={classes.listItem}>Back or waist pain</li>
                      </ul>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion className={classes.accordion}>
                    <AccordionSummary
                      className={`${classes.accordionSummary}`}
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel7a-content"
                      id="panel7a-header"
                    >
                      <Typography className={classes.accordionSummaryHeading}>
                        Vaccine exemption assessment & certificates
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.accordionDetails}>
                      <ul className={classes.ul}>
                        <li className={classes.listItem}>
                          Review of underlying health and conditions
                        </li>
                        <li className={classes.listItem}>
                          Issuance of medical exemption certificate (Only
                          available in select states)
                        </li>
                      </ul>
                    </AccordionDetails>
                  </Accordion>
                </div>
              </div>
            </Grid>
            <Grid item xl={6} lg={6} sm={12} xs={12}>
              <div className={classes.textWrapper}>
                <Typography variant="h6" className={classes.smallHeading}>
                  Primary care:
                </Typography>
                <div className={classes.accordianWrapper}>
                  <Accordion className={classes.accordion}>
                    <AccordionSummary
                      className={`${classes.accordionSummary}`}
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className={classes.accordionSummaryHeading}>
                        New patient
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.accordionDetails}>
                      <ul className={classes.ul}>
                        <li className={classes.listItem}>
                          New health concerns
                        </li>
                        <li className={classes.listItem}>
                          Review of health history and medications
                        </li>
                      </ul>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion className={classes.accordion}>
                    <AccordionSummary
                      className={`${classes.accordionSummary}`}
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                    >
                      <Typography className={classes.accordionSummaryHeading}>
                        Routine check-up or follow-up
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.accordionDetails}>
                      <ul className={classes.ul}>
                        <li className={classes.listItem}>
                          Annual or routine check-up
                        </li>
                        <li className={classes.listItem}>
                          Nutrition and diet guidance
                        </li>
                      </ul>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion className={classes.accordion}>
                    <AccordionSummary
                      className={`${classes.accordionSummary}`}
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel3a-content"
                      id="panel3a-header"
                    >
                      <Typography className={classes.accordionSummaryHeading}>
                        Test and screenings
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.accordionDetails}>
                      <ul className={classes.ul}>
                        <li className={classes.listItem}>
                          Health assessment and screenings
                        </li>
                        <li className={classes.listItem}>
                          Lab tests and interpretation of results
                        </li>
                      </ul>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion className={classes.accordion}>
                    <AccordionSummary
                      className={`${classes.accordionSummary}`}
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel4a-content"
                      id="panel4a-header"
                    >
                      <Typography className={classes.accordionSummaryHeading}>
                        Existing or chronic conditions
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.accordionDetails}>
                      <ul className={classes.ul}>
                        <li className={classes.listItem}>
                          Hypertension/high blood pressure
                        </li>
                        <li className={classes.listItem}>Diabetes</li>
                        <li className={classes.listItem}>High cholestrol</li>
                        <li className={classes.listItem}>GI disorder/GERD</li>
                        <li className={classes.listItem}>Asthma</li>
                        <li className={classes.listItem}>Arthritis</li>
                        <li className={classes.listItem}>Hypothyroidism</li>
                        <li className={classes.listItem}>Allergies</li>
                        <li className={classes.listItem}>Acne</li>
                      </ul>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion className={classes.accordion}>
                    <AccordionSummary
                      className={`${classes.accordionSummary}`}
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel5a-content"
                      id="panel5a-header"
                    >
                      <Typography className={classes.accordionSummaryHeading}>
                        COVID-19 symptoms
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.accordionDetails}>
                      <ul className={classes.ul}>
                        <li className={classes.listItem}>Cough</li>
                        <li className={classes.listItem}>Fever</li>
                        <li className={classes.listItem}>
                          COVID-19 vaccination advice
                        </li>
                        <li className={classes.listItem}>
                          Post-COVID health concerns
                        </li>
                        <li className={classes.listItem}>Cough or wheezing</li>
                      </ul>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion className={classes.accordion}>
                    <AccordionSummary
                      className={`${classes.accordionSummary}`}
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel6a-content"
                      id="panel6a-header"
                    >
                      <Typography className={classes.accordionSummaryHeading}>
                        Prescriptions and refills
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.accordionDetails}>
                      <ul className={classes.ul}>
                        <li className={classes.listItem}>
                          Prescriptions for new health concern
                        </li>
                        <li className={classes.listItem}>
                          Prescriptions for existing conditions
                        </li>
                        <li className={classes.listItem}>
                          Medication management
                        </li>
                        <li className={classes.listItem}>
                          Prescripton refills
                        </li>
                      </ul>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion className={classes.accordion}>
                    <AccordionSummary
                      className={`${classes.accordionSummary}`}
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel7a-content"
                      id="panel7a-header"
                    >
                      <Typography className={classes.accordionSummaryHeading}>
                        Birth control
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.accordionDetails}>
                      <ul className={classes.ul}>
                        <li className={classes.listItem}>
                          Birth control pills
                        </li>
                        <li className={classes.listItem}>Contraceptives</li>
                        <li className={classes.listItem}>
                          Emergency contraception
                        </li>
                        <li className={classes.listItem}>Spermicides</li>
                      </ul>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion className={classes.accordion}>
                    <AccordionSummary
                      className={`${classes.accordionSummary}`}
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel7a-content"
                      id="panel7a-header"
                    >
                      <Typography className={classes.accordionSummaryHeading}>
                        Lifestyle guidance
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.accordionDetails}>
                      <ul className={classes.ul}>
                        <li className={classes.listItem}>Skin or hair</li>
                        <li className={classes.listItem}>Sexual health</li>
                        <li className={classes.listItem}>Emotional health</li>
                        <li className={classes.listItem}>
                          Pre or post-travel care
                        </li>
                      </ul>
                    </AccordionDetails>
                  </Accordion>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
      {/* /V3 Section 7 */}

      {/* V3 Section 8 */}
      <div
        className={`${classes.sectionPadding} ${classes.smallContainer}`}
        id="what-we-dont-treat"
      >
        <Container fixed>
          <Grid container direction="row" spacing={5}>
            <Grid item xl={12} lg={12} sm={12} xs={12}>
              <div className={classes.textWrapper}>
                <div className={classes.accordianWrapper}>
                  <Accordion className={classes.accordion}>
                    <AccordionSummary
                      className={`${classes.accordionSummary}`}
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography
                        className={classes.accordionSummaryHeading}
                        variant="h6"
                      >
                        <div className={classes.flexWrapper}>
                          <img
                            src={banIcon}
                            alt=""
                            className={classes.listIcon}
                          />
                          <span>What we don't treat</span>
                        </div>
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.accordionDetails}>
                      <Grid container direction="row">
                        <Grid item xl={6} lg={6} md={6}>
                          <ul className={classes.ul}>
                            <li className={classes.listItemNegative}>
                              Anabolic steroids and testosterone
                            </li>
                            <li className={classes.listItemNegative}>
                              Prescription weight management
                            </li>
                            <li className={classes.listItemNegative}>
                              Prescription smoking cessation
                            </li>
                            <li className={classes.listItemNegative}>
                              Drug addiction or detox
                            </li>
                            <li className={classes.listItemNegative}>
                              Controlled substances, narcotics, or stimulants
                            </li>
                            <li className={classes.listItemNegative}>
                              Abortion pills
                            </li>
                          </ul>
                        </Grid>
                        <Grid item xl={6} lg={6} md={6}>
                          <ul className={classes.ul}>
                            <li className={classes.listItemNegative}>
                              Competency certification
                            </li>
                            <li className={classes.listItemNegative}>
                              Anti-psychotic medications
                            </li>
                            <li className={classes.listItemNegative}>
                              HIV medications such as PreP or PEP
                            </li>
                            <li className={classes.listItemNegative}>
                              Transgender hormonal care
                            </li>
                            <li className={classes.listItemNegative}>
                              Ordering medical equipment or devices
                            </li>
                          </ul>
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion className={classes.accordion}>
                    <AccordionSummary
                      className={`${classes.accordionSummary}`}
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                    >
                      <Typography
                        className={classes.accordionSummaryHeading}
                        variant="h6"
                      >
                        <div className={classes.flexWrapper}>
                          <img
                            src={ambulanceIcon}
                            alt=""
                            className={classes.listIcon}
                          />
                          <span>
                            Tova Medical virtual visits are not intended for use
                            in medical emergencies
                          </span>
                        </div>
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.accordionDetails}>
                      <Grid container direction="row">
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                          <p>
                            Always call 911 or your local emergency service
                            immediately if you think you have an emergency. If
                            you experience any of the following medical issues
                            or any other critical or life-threatning situations,
                            go immediately to an emergency room:
                          </p>
                        </Grid>
                        <Grid item xl={6} lg={6} md={6}>
                          <ul className={classes.ul}>
                            <li className={classes.listItemNegative}>
                              Chest pain or pressure
                            </li>
                            <li className={classes.listItemNegative}>
                              Convulsion or seizure
                            </li>
                            <li className={classes.listItemNegative}>
                              Difficulty breathing
                            </li>
                            <li className={classes.listItemNegative}>
                              Mental health or substance abuse urgency
                            </li>
                            <li className={classes.listItemNegative}>
                              Moderate to severe burn
                            </li>
                            <li className={classes.listItemNegative}>
                              Pain medication overdose or reaction (Narcotics)
                            </li>
                          </ul>
                        </Grid>
                        <Grid item xl={6} lg={6} md={6}>
                          <ul className={classes.ul}>
                            <li className={classes.listItemNegative}>
                              Poisoning
                            </li>
                            <li className={classes.listItemNegative}>
                              Serious head, neck, or back injury
                            </li>
                            <li className={classes.listItemNegative}>
                              Serious limb impairment or broken bone
                            </li>
                            <li className={classes.listItemNegative}>
                              Significant, uncontrolled bleeding
                            </li>
                            <li className={classes.listItemNegative}>
                              Suicidal ideation
                            </li>
                            <li className={classes.listItemNegative}>
                              Unconsciousness
                            </li>
                          </ul>
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion className={classes.accordion}>
                    <AccordionSummary
                      className={`${classes.accordionSummary}`}
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                    >
                      <Typography
                        className={classes.accordionSummaryHeading}
                        variant="h6"
                      >
                        <div className={classes.flexWrapper}>
                          <img
                            src={notAllowedIcon}
                            alt=""
                            className={classes.listIcon}
                          />
                          <span>What we can't prescribe</span>
                        </div>
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.accordionDetails}>
                      <Grid container direction="row">
                        <Grid item xl={12}>
                          <p>
                            There are some medications that telemedicine
                            providers rarely if ever prescribe or refill.
                          </p>
                          <p className={classes.listHeadingBold}>
                            Opioids and other controlled substance like:
                          </p>
                        </Grid>
                        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                          <ul className={classes.ul}>
                            <li className={classes.listItemNegative}>
                              Adderall
                            </li>
                            <li className={classes.listItemNegative}>Ativan</li>
                            <li className={classes.listItemNegative}>Norco</li>
                          </ul>
                        </Grid>
                        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                          <ul className={classes.ul}>
                            <li className={classes.listItemNegative}>
                              Oxycodone
                            </li>
                            <li className={classes.listItemNegative}>Valium</li>
                            <li className={classes.listItemNegative}>Xanax</li>
                          </ul>
                        </Grid>
                        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                          <p className={classes.listHeadingBold}>
                            Psychiatric drugs like:{" "}
                          </p>
                          <ul className={classes.ul}>
                            <li className={classes.listItemNegative}>
                              Abilify
                            </li>
                            <li className={classes.listItemNegative}>
                              Gabapentin
                            </li>
                            <li className={classes.listItemNegative}>
                              Lithium
                            </li>
                          </ul>
                        </Grid>
                        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                          <p className={classes.listHeadingBold}>
                            Lifestyle drugs like:{" "}
                          </p>
                          <ul className={classes.ul}>
                            <li className={classes.listItemNegative}>
                              Chantix
                            </li>
                            <li className={classes.listItemNegative}>
                              Contrave
                            </li>
                            <li className={classes.listItemNegative}>Qsymia</li>
                          </ul>
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
      {/* /V3 Section 8 */}

      {/* V3 Section 9 */}
      <div
        id="faq"
        className={`${classes.sectionPadding} ${classes.smallContainer} ${classes.bgLightMustartd}`}
      >
        <div className={classes.sectionHeader}>
          <Typography className={classes.sectionHeading} variant="h2">
            Frequently asked questions
          </Typography>
        </div>
        <Container fixed>
          <Grid container direction="row" spacing={5}>
            <Grid item xl={12} lg={12} sm={12} xs={12}>
              <div className={classes.textWrapper}>
                <div className={classes.accordianWrapper}>
                  <Accordion className={`${classes.accordionSimple}`}>
                    <AccordionSummary
                      className={`${classes.accordionSummarySimple}`}
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography
                        className={classes.accordionSummaryHeading}
                        variant="h6"
                      >
                        How much does Tova cost?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container direction="row">
                        <Grid item xl={12}>
                          <p>
                            Tova belives that quality healthcare is a
                            fundamental human right and should be accessible to
                            all. Therefore, we strive to make our services as
                            affordable as possible.
                          </p>
                        </Grid>
                        <Grid item xl={12}>
                          <p className={classes.listHeadingBold}>
                            With Tova Prime, for just $30 per month, you get:
                          </p>
                          <ul className={classes.ulSimple}>
                            <li className={classes.listItemSimple}>
                              24/7 on-demand urgent care to video or text chat
                              with the first available doctor for advice,
                              prescriptions, test orders, and referrals for just
                              $39 per visit. That's 50% off with each visit.
                            </li>
                            <li className={classes.listItemSimple}>
                              Exclusive access to Primary Care video
                              appointments for just $39 per visit. That you get
                              to:
                              <br />
                              <ul className={classes.innerListTopMargin}>
                                <li className={classes.listItemSimple}>
                                  Choose a top-rated primary care doctor
                                </li>
                                <li className={classes.listItemSimple}>
                                  Stay healthy with regular check-ups
                                </li>
                                <li className={classes.listItemSimple}>
                                  Manage any ongoing conditions or concerns over
                                  time
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion className={`${classes.accordionSimple}`}>
                    <AccordionSummary
                      className={`${classes.accordionSummarySimple}`}
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                    >
                      <Typography
                        className={classes.accordionSummaryHeading}
                        variant="h6"
                      >
                        Can Tova doctors write prescriptions?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <p>
                        Yes. Tova doctors can write medically necessary
                        prescriptions, as well as order lab tests and other
                        procedures. Doctors send prescriptions to the member's
                        preferred pharmacy, or fullfill the prescription with
                        our partnered pharmacy who will mail it directly to the
                        member's home.
                      </p>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion className={`${classes.accordionSimple}`}>
                    <AccordionSummary
                      className={`${classes.accordionSummarySimple}`}
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel3a-content"
                      id="panel3a-header"
                    >
                      <Typography
                        className={classes.accordionSummaryHeading}
                        variant="h6"
                      >
                        How do I select my preferred pharmacy?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <p>
                        You select your preferred pharmacy by going to your
                        account page. (If you haven't created your account yet,
                        do so <NavLink to="/individual/signup">HERE</NavLink>).
                        Click the "Pharmacy Settings" icon in the top left of
                        the page once you are logged in. Choose the preferred
                        pharmacy from the list of options.
                      </p>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion className={`${classes.accordionSimple}`}>
                    <AccordionSummary
                      className={`${classes.accordionSummarySimple}`}
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel4a-content"
                      id="panel4a-header"
                    >
                      <Typography
                        className={classes.accordionSummaryHeading}
                        variant="h6"
                      >
                        Can I use health insurance with Tova? How does it work?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <p>
                        No. Tova does not accept insurance plans for either
                        Urgent Care or Primary Care visits. Please check back
                        later for this integration.
                      </p>
                    </AccordionDetails>
                  </Accordion>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
      {/* /V3 Section 9 */}

      {/* Newly Added Full Page Wide image */}
      <div className={classes.fullWidthImageSection}>
        <img src={FullPageImage} className={classes.fullPageImage} alt="" />
      </div>
      {/* /Newly Added Full Page Wide image */}

      {/* V3 Section 10 */}
      <div className={`${classes.sectionPadding}`}>
        <Container fixed>
          <div className={classes.sectionHeader}>
            <Typography
              className={`${classes.capsSmallHeading} ${classes.textPrimary}`}
              variant="h6"
            >
              Ready to get started?
            </Typography>
            <Typography
              className={`${classes.sectionHeading} ${classes.sectionHeadingWidth} ${classes.sectionHeadingCenter}`}
              variant="h2"
            >
              Sign up to the most convenient and affordable online healthcare.
            </Typography>
          </div>
          <div className={`${classes.textCenter}`}>
            <Button
              className={classes.btnContained}
              variant="contained"
              component={NavLink}
              to="/individual/signup"
              color="primary"
            >
              Get Started
            </Button>
          </div>
          {/* <Grid
            container
            direction="row"
            spacing={3}
            className={classes.sectionInnerTopMargin}
          >
            <Grid item xl={6} md={6} xs={12}>
              <Typography align={xs || sm ? "center" : "left"} variant="h6">
                100+ insurance plans accepted
              </Typography>
              <p className={xs || sm ? classes.textCenter : ""}>
                + Health Savings and Flexible Spending Accounts
              </p>
            </Grid>
            <Grid item xl={6} md={6} xs={12}>
              <img src={brandIcons} alt="" className={classes.stretch} />
            </Grid>
          </Grid> */}
        </Container>
      </div>
      {/* /V3 Section 10 */}

      {/* Section 11 */}
      <Footer />
      {/* /Section 11 */}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    isProcessing: state.auth0.isProcessing,
    isMember: state.auth0.isMember,
  };
};

export default connect(mapStateToProps)(withRouter(Home));
