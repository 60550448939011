import React, { useState, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import { useAuth0 } from "@auth0/auth0-react";
import Error from "../Error";
import {
  Box,
  Container,
  Grid,
  CircularProgress,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import utils from "../../helpers/utils";
import Loading from "../../components/Loading";
import HttpError from "../../components/HttpError";
import { Redirect } from "react-router";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBackOutlined";
import { useStyles } from "./styles";
import EditIcon from "@material-ui/icons/Edit";
import axios from "axios";
import useHasPermission from "../../hooks/useHasPermission";

const Admin = (props) => {
  document.body.style.backgroundColor = "#F7F7F7";

  const classes = useStyles();

  const { isAuthenticated, user, error } = useAuth0();
  const isAdmin = utils.isAdmin(user);

  const [hasError, setHasError] = useState(false);
  const [admin, setAdmin] = useState(null);

  useEffect(() => {
    const fetchAdmin = async () => {
      // const res = await services.patients.getPatient(props.match.params.id);
      let url = `${process.env.REACT_APP_SERVER_URL}/admin/${props.match.params.id}/show`;

      const res = await axios.get(url);
      if (res.status === 200) setAdmin(res?.data?.admin);
      else setHasError(true);
    };

    fetchAdmin();
  }, [props.match.params?.id]);

  const { hasPermission } = useHasPermission({ permission: "read:admins" });
  if (!hasPermission) return <Redirect to="/account" />;

  if (isAuthenticated && (props.isProcessing || !props.authUser)) {
    return <Loading />;
  } else if (isAuthenticated && props.isMember === false && !isAdmin) {
    return <Redirect to="/signup" />;
  }

  if (error) {
    return <Error code="500" />;
  }

  if (isAuthenticated && props.isProcessing) {
    return <Loading />;
  } else if (isAuthenticated && props.isMember === false && !isAdmin) {
    return <Redirect to="/signup" />;
  }

  if (error) {
    return <Error code="500" />;
  }

  return (
    <Container maxWidth="lg" className={classes.root}>
      <Grid container direction="row" className={classes.gridWrapper}>
        <div className={classes.headingWrapper}>
          <NavLink className={classes.navLink} to="/admins">
            <ArrowBackIcon className={classes.icon} />
          </NavLink>
          <Typography variant="h5">
            Admin Details
            <div className={classes.divider} />
          </Typography>
        </div>

        <Grid item xs={12}>
          <Box component="div" className={classes.contentSection}>
            {hasError ? (
              <HttpError code="404" />
            ) : (
              <div className={classes.contentWrapper}>
                {!admin && !hasError ? (
                  <div
                    className={`${classes.textCenter} ${classes.loaderWrapper}`}
                  >
                    <CircularProgress size={30} />{" "}
                  </div>
                ) : null}

                {admin ? (
                  <>
                    <div className={classes.textRight}>
                      <Tooltip title="Edit Details">
                        <IconButton
                          component={NavLink}
                          to={`/admin/${props.match.params?.id}/edit`}
                        >
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                    </div>
                    <Grid container>
                      <Grid item xs={12} md={4}>
                        <div className={classes.item}>
                          <Typography className={classes.heading} variant="h6">
                            First Name
                          </Typography>

                          <Typography>{admin?.firstName}</Typography>
                        </div>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        {" "}
                        <div className={classes.item}>
                          <Typography className={classes.heading} variant="h6">
                            Last Name
                          </Typography>

                          <Typography>{admin?.lastName}</Typography>
                        </div>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <div className={classes.item}>
                          <Typography className={classes.heading} variant="h6">
                            Email
                          </Typography>

                          <Typography>{admin?.email}</Typography>
                        </div>
                      </Grid>
                      {/* <Grid item xs={12} md={4}>
                        <div className={classes.item}>
                          <Typography className={classes.heading} variant="h6">
                            Phone Number
                          </Typography>

                          <Typography>{admin?.phoneNumber || "N/A"}</Typography>
                        </div>
                      </Grid> */}
                      <Grid item xs={12} md={4}>
                        <div className={classes.item}>
                          <Typography className={classes.heading} variant="h6">
                            Role
                          </Typography>

                          <Typography>{"Admin"}</Typography>
                        </div>
                      </Grid>
                    </Grid>
                  </>
                ) : null}
              </div>
            )}
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    isProcessing: state.auth0.isProcessing,
    isMember: state.auth0.isMember,
    authUser: state.auth0.user,
  };
};

export default connect(mapStateToProps)(Admin);
