import { useEffect, useState } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import ErrorIcon from "@material-ui/icons/ErrorOutlineOutlined";
import Loading from "../../../components/Loading";
import Alert from "../../../components/Alert";
import { NavLink, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import resetPasswordAction from "../store/actions/resetPasswordAction";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(15),
    height: "100vh",
  },

  formWrapper: {
    marginTop: theme.spacing(4),
  },
  headingVerticalSpacing: {
    marginBottom: theme.spacing(3),
  },
  textBold: {
    fontWeight: 700,
  },
  description: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(4),
  },
  runningText: {
    lineHeight: "26px",
  },
  mainContentWrapper: {
    backgroundColor: "#fff",
    minWidth: "300px",
    maxWidth: "500px",
    padding: theme.spacing(6),
    borderRadius: theme.spacing(2),
    margin: "auto",
  },
  label: {
    marginBottom: theme.spacing(1),
    display: "block",
  },
  submitBtnWrapper: {
    marginTop: theme.spacing(5),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  labelWithIcon: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  // noDecoration: {
  //   textDecoration: "none",
  //   color: theme.palette.primary.main,
  //   cursor: "pointer",
  // },
  smallHeading: {
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(3),
    fontWeight: "600",
  },
  formControlLabel: {
    alignItems: "flex-start !important",
    "& span": {
      fontSize: "15px !important",
    },
    "& .MuiButtonBase-root": {
      paddingTop: 0,
    },
  },

  textCenter: {
    textAlign: "center",
    marginTop: theme.spacing(4),
  },
  errorMessageWrapper: {
    marginTop: theme.spacing(20),
    textAlign: "center",
  },
  errorIcon: {
    color: "red",
    fontSize: "50px",
  },
  errorHeading: {
    marginTop: theme.spacing(2),
  },
  errorMessage: {
    marginBottom: theme.spacing(3),
  },
  btnContained: {
    backgroundColor: theme.backgroundSeagreen,
    color: "#fff",
    "&:hover": {
      backgroundColor: theme.backgroundSeagreenDark,
    },
  },
  noDecoration: {
    textDecoration: "none",
    color: theme.textSeagreen,
    cursor: "pointer",
  },
}));

const DoctorResetPassword = (props) => {
  document.body.style.background = "#fff";

  const classes = useStyles();

  const [formData, setFormData] = useState({
    password: "",
    confirmPassword: "",
    type: "doctor",
  });

  const [status, setStatus] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    if (isLoading) {
      validatePasswordResetLink();
    }
  });

  const validatePasswordResetLink = async () => {
    const id = props.match.params.id;
    const token = props.match.params.token;

    try {
      let response = await axios({
        url: `${process.env.REACT_APP_SERVER_URL}/public/password/reset/request/validate`,
        method: "post",
        data: {
          id,
          token,
        },
      });

      if (typeof response.data !== "undefined") {
        setIsLoading(false);
      }
    } catch (error) {
      if (typeof error.response.data !== "undefined") {
        setStatus(error.response.data.status);
        setMessage(error.response.data.message);
        setIsLoading(false);
      }
    }
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isValidated()) {
      return;
    }

    const id = props.match.params.id;
    const token = props.match.params.token;

    await props.resetPassword(
      `${process.env.REACT_APP_SERVER_URL}/public/reset/password`,
      {
        ...formData,
        id,
        token,
      }
    );
  };

  const isValidated = () => {
    for (let key in formData) {
      if (typeof formData[key] !== "undefined" && !formData[key]) {
        setHasError(true);
        return false;
      }
    }
    return true;
  };

  if (isLoading) {
    return <Loading />;
  } else if (props.isAuthenticated) {
    return <Redirect to="/account" />;
  } else if (status === "error") {
    return (
      <Container fixed>
        <div className={classes.mainContentWrapper}>
          <div className={classes.errorMessageWrapper}>
            <div className={classes.heading}>
              <ErrorIcon className={classes.errorIcon} />
              <Typography className={classes.errorHeading} variant="h4">
                Invalid link
              </Typography>
            </div>
            <Typography className={classes.errorMessage}>{message}</Typography>

            <Button
              variant="contained"
              component={NavLink}
              color="primary"
              to="/admin/forgot/password"
              className={classes.btnContained}
            >
              Send new password reset link
            </Button>
          </div>
        </div>
      </Container>
    );
  }

  return (
    <div className={classes.root}>
      {props.status ? (
        <Alert status={props.status} message={props.message} />
      ) : null}

      <Container fixed>
        <div className={classes.mainContentWrapper}>
          <div className={classes.mainDescriptionWrapper}>
            <Typography variant="h5" className={classes.textBold}>
              Reset Password
            </Typography>
          </div>
          <div className={classes.formWrapper}>
            <form onSubmit={handleSubmit}>
              <div className={classes.section}>
                <Grid container direction="row" spacing={3}>
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <div className={classes.inputGroup}>
                      <label className={classes.label}>New Password</label>
                      <TextField
                        onChange={handleChange}
                        name="password"
                        type="password"
                        label=""
                        variant="filled"
                        fullWidth
                        error={hasError && !formData.password.length}
                        value={formData.password || ""}
                        helperText={
                          hasError && !formData.password.length
                            ? "Required"
                            : ""
                        }
                      />
                    </div>
                  </Grid>
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <div className={classes.inputGroup}>
                      <label className={classes.label}>Confirm Password</label>
                      <TextField
                        onChange={handleChange}
                        name="confirmPassword"
                        type="password"
                        label=""
                        variant="filled"
                        fullWidth
                        error={hasError && !formData.confirmPassword.length}
                        value={formData.confirmPassword || ""}
                        helperText={
                          hasError && !formData.confirmPassword.length
                            ? "Required"
                            : ""
                        }
                      />
                    </div>
                  </Grid>
                </Grid>
              </div>

              <div className={classes.submitBtnWrapper}>
                <Button
                  variant="contained"
                  type="submit"
                  fullWidth
                  color="primary"
                  disabled={props.isProcessing}
                  className={classes.btnContained}
                >
                  {props.isProcessing ? "Processing" : "Reset Password"}
                </Button>
              </div>

              <div className={classes.textCenter}>
                <p>
                  <NavLink
                    className={classes.noDecoration}
                    to="/doctor/forgot/password"
                  >
                    Get new password reset link
                  </NavLink>
                </p>
              </div>
            </form>
          </div>
        </div>
      </Container>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isProcessing: state.auth0.isProcessing,
    status: state.auth0.status,
    message: state.auth0.message,
    isAuthenticated: state.auth0.isAuthenticated,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetPassword: (url, formData) =>
      dispatch(resetPasswordAction(url, formData)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DoctorResetPassword));
