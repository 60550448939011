import JekoRegular from "./FontsFree-Net-Jeko-Regular.ttf";
import JekoBold from "./FontsFree-Net-Jeko-Bold.ttf";

export const jeko = {
  fontFamily: "Jeko",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 400,
  src: `
    local('Jeko'),
    local('Jeko-Regular'),
    url(${JekoRegular}) format('truetype')
  `,
};

export const jekoBold = {
  fontFamily: "JekoBold",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 700,
  src: `
      local('Jeko'),
      local('Jeko-Bold'),
      url(${JekoBold}) format('truetype')
    `,
};
