import { useEffect, useState } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField/TextField";
import Loading from "../../../components/Loading";
import Alert from "../../../components/Alert";
import bg7 from "../../../assets/images/bg2.jpg";
import { Redirect } from "react-router";
import { connect } from "react-redux";
import updateAccountInfoAction from "../store/actions/updateAccountInfoAction";
import axios from "axios";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import utils from "../../../helpers/utils";
import { KeyboardDatePicker } from "@material-ui/pickers";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(15),

    height: "100vh",
    backgroundImage: `url(${bg7})`,
    backgroundRepeat: "no-repeat",
    position: "center, center",
    backgroundSize: "cover",
    [theme.breakpoints.down("sm")]: {
      backgroundSize: "contained",
      //minHeight: "932px",
      backgroundPosition: "-500px",
    },
  },

  headingVerticalSpacing: {
    marginBottom: theme.spacing(3),
  },
  textBold: {
    fontWeight: 700,
  },
  description: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(4),
  },
  runningText: {
    lineHeight: "26px",
  },
  mainContentWrapper: {
    backgroundColor: "#fff",
    minWidth: "300px",
    maxWidth: "500px",
    padding: theme.spacing(6),
    borderRadius: theme.spacing(2),
  },
  label: {
    marginBottom: theme.spacing(1),
    display: "block",
  },
  submitBtnWrapper: {
    marginTop: theme.spacing(5),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  labelWithIcon: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  noDecoration: {
    textDecoration: "none",
    color: theme.textSeagreen,
    cursor: "pointer",
  },
  smallHeading: {
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(3),
    fontWeight: "600",
  },
  formControlLabel: {
    alignItems: "flex-start !important",
    "& span": {
      fontSize: "15px !important",
    },
    "& .MuiButtonBase-root": {
      paddingTop: 0,
    },
  },

  textCenter: {
    textAlign: "center",
    marginTop: theme.spacing(4),
  },

  btnContained: {
    backgroundColor: theme.backgroundSeagreen,
    color: "#fff",
    "&:hover": {
      backgroundColor: theme.backgroundSeagreenDark,
    },
  },
  radioGreen: {
    color: theme.textSeagreen,
  },
  menuPaper: {
    maxHeight: "300px",
  },
}));

const PatientInfo = (props) => {
  document.body.style.background = "#fff";

  const classes = useStyles();

  const [states, setStates] = useState([]);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    dob: new Date(),
    gender: "male",
    phoneNumber: "",
    stateId: "",
    pin: "",
  });

  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    setIsLoading(false);
    getStates();
  }, []);

  const getStates = async () => {
    try {
      const response = await axios({
        url: `${process.env.REACT_APP_SERVER_URL}/public/states`,
        method: "get",
      });

      setStates(response.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDateChange = (date, field) => {
    setFormData({
      ...formData,
      [field]: date,
    });
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]:
        e.target.name === "phoneNumber"
          ? utils.formatPhone(e.target.value)
          : e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isValidated()) {
      await props.updateAccount(
        `${process.env.REACT_APP_SERVER_URL}/public/update/account`,
        {
          ...formData,
          email: props.user.email,
          password: props.user.password,
          authId: props.user.authId,
        }
      );
    }
  };

  const isValidated = () => {
    for (let key in formData) {
      if (typeof formData[key] !== "undefined" && !formData[key]) {
        setHasError(true);
        return false;
      }
    }
    return true;
  };

  if (isLoading) {
    return <Loading />;
  } else if (props.isAuthenticated) {
    return <Redirect to="/account" />;
  } else if (!props.stepOneDone) {
    return <Redirect to="/individual/signup" />;
  } else if (props.stepOneDone && props.stepTwoDone) {
    return <Redirect to="/individual/signup/select-plan" />;
  }

  return (
    <div className={classes.root}>
      {props.status ? (
        <Alert status={props.status} message={props.message} />
      ) : null}

      <Container fixed>
        <div className={classes.mainContentWrapper}>
          <div className={classes.mainDescriptionWrapper}>
            <Typography variant="h5" className={classes.textBold}>
              You're almost done
            </Typography>

            <p className={classes.description}>
              We ask for your birthdate and gender so our doctors have the
              information they need to treat you and answer questions.
            </p>
          </div>
          <div className={classes.formWrapper}>
            <form onSubmit={handleSubmit}>
              <div className={classes.section}>
                <Grid container direction="row" spacing={3}>
                  <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <div className={classes.inputGroup}>
                      <label className={classes.label}>First Name</label>
                      <TextField
                        onChange={handleChange}
                        name="firstName"
                        label=""
                        variant="filled"
                        fullWidth
                        error={hasError && !formData.firstName.length}
                        value={formData.firstName}
                        helperText={
                          hasError && !formData.firstName.length
                            ? "Required"
                            : ""
                        }
                      />
                    </div>
                  </Grid>
                  <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <div className={classes.inputGroup}>
                      <label className={classes.label}>Last Name</label>
                      <TextField
                        onChange={handleChange}
                        name="lastName"
                        label=""
                        variant="filled"
                        fullWidth
                        error={hasError && !formData.lastName.length}
                        value={formData.lastName}
                        helperText={
                          hasError && !formData.lastName.length
                            ? "Required"
                            : ""
                        }
                      />
                    </div>
                  </Grid>
                  <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <div className={classes.inputGroup}>
                      <label className={classes.label}>Date of birth</label>
                      <KeyboardDatePicker
                        autoOk
                        disableFuture
                        fullWidth
                        inputVariant={"filled"}
                        variant="inline"
                        name="dob"
                        format="MM/DD/YYYY"
                        views={["year", "month", "date"]}
                        onChange={(date) => handleDateChange(date, "dob")}
                        error={hasError && !formData.dob.length}
                        value={formData.dob}
                        helperText={
                          hasError && !formData.dob.length ? "Required" : ""
                        }
                      />
                    </div>
                  </Grid>
                  <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    <div className={classes.inputGroup}>
                      <label className={classes.label}>State</label>
                      <FormControl
                        variant="filled"
                        fullWidth
                        className={classes.formControl}
                      >
                        <Select
                          MenuProps={{
                            classes: { paper: classes.menuPaper },
                          }}
                          labelId="state-select-filled-label"
                          id="state-select-filled"
                          value={formData.stateId}
                          onChange={handleChange}
                          name="stateId"
                          error={hasError && !formData.stateId.length}
                        >
                          {/* <MenuItem key={`states-3213012`} value="">
                            Outside of United States
                          </MenuItem> */}
                          {states.map((item) => (
                            <MenuItem key={`states-${item.id}`} value={item.id}>
                              {item.name}
                            </MenuItem>
                          ))}
                        </Select>
                        {hasError && !formData.stateId.length ? (
                          <FormHelperText>Required</FormHelperText>
                        ) : null}
                      </FormControl>
                    </div>
                  </Grid>

                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <div className={classes.inputGroup}>
                      <label className={classes.label}>Phone Number</label>
                      <TextField
                        onChange={handleChange}
                        name="phoneNumber"
                        label=""
                        variant="filled"
                        fullWidth
                        error={hasError && !formData.phoneNumber.length}
                        value={formData.phoneNumber}
                        helperText={
                          hasError && !formData.phoneNumber.length
                            ? "Required"
                            : ""
                        }
                      />
                    </div>
                  </Grid>
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <div className={classes.inputGroup}>
                      <label className={classes.label}>Pin (4 Digit)</label>
                      <TextField
                        onChange={(e) => {
                          let pin = e.target.value.replace(/\D/g, "");
                          if (pin.length < 5) {
                            setFormData({
                              ...formData,
                              [e.target.name]: pin,
                            });
                          }
                        }}
                        name="pin"
                        label=""
                        variant="filled"
                        fullWidth
                        error={hasError && !formData.pin.length}
                        value={formData.pin}
                        helperText={
                          hasError && !formData.pin.length ? "Required" : ""
                        }
                      />
                    </div>
                  </Grid>
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <div className={classes.inputGroup}>
                      <label className={classes.label}>Gender</label>
                      <RadioGroup
                        aria-label="gender"
                        name="gender"
                        value={formData.gender}
                        onChange={handleChange}
                      >
                        <div className={classes.radioWrapper}>
                          <FormControlLabel
                            value="male"
                            control={<Radio />}
                            label="Male"
                          />
                          <FormControlLabel
                            value="female"
                            control={<Radio />}
                            label="Female"
                          />
                        </div>
                      </RadioGroup>
                    </div>
                  </Grid>
                </Grid>
              </div>

              <div className={classes.submitBtnWrapper}>
                <Button
                  variant="contained"
                  type="submit"
                  fullWidth
                  className={classes.btnContained}
                  disabled={props.isProcessing}
                >
                  {props.isProcessing ? "Processing" : "Done"}
                </Button>
              </div>
            </form>
          </div>
        </div>
      </Container>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isProcessing: state.auth0.isProcessing,
    status: state.auth0.status,
    message: state.auth0.message,
    stepOneDone: state.auth0.stepOneDone,
    stepTwoDone: state.auth0.stepTwoDone,
    user: state.auth0.user,
    isAuthenticated: state.auth0.isAuthenticated,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateAccount: (url, formData) =>
      dispatch(updateAccountInfoAction(url, formData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PatientInfo);
