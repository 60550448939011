import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import { useState, useEffect } from "react";
import axios from "axios";
import utils from "../helpers/utils";
import Alert from "./Alert";
import { connect } from "react-redux";
import { KeyboardDatePicker } from "@material-ui/pickers";
import setStripeAccountIdAction from "../pages/store/actions/setStripeAccountIdAction";
import { withRouter } from "react-router-dom";
import AddPerson from "./AddPerson";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(5),
  },
  label: {
    marginBottom: theme.spacing(1),
    display: "block",
  },
  submitBtnWrapper: {
    marginTop: theme.spacing(5),
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  attachDocumentControlWrapper: {
    cursor: "pointer",
    backgroundColor: "#f0f2f4",
    padding: "15px",
    borderRadius: "3px",
  },
  attachDocumentControl: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    display: "block",
  },
  icon: {
    color: theme.palette.primary.main,
    fontSize: "20px",
  },
  hiddenInput: {
    display: "none",
  },
  helperText: {
    color: "red",
    marginLeft: theme.spacing(2),
  },
  dflex: {
    display: "flex",
    alignItems: "center",
  },
  attachment: {
    color: "#666",
    fontWeight: 600,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#f0f2f4",
    textAlign: "center",
    padding: "15px",
    borderRadius: "3px",
    cursor: "pointer",
  },
  subHeading: {
    fontWeight: "bold",
    marginBottom: theme.spacing(3),
    fontSize: "20px",
  },
  sectionTopMargin: {
    marginTop: theme.spacing(5),
  },
  aucdatePicker: {},
}));

const businessTypes = [
  { name: "individual", value: "Individual" },
  { name: "company", value: "Company" },
  { name: "non_profit", value: "Non profit" },
  { name: "government_entity", value: "Government entity (US Only)" },
];
const businessStructures = [
  { name: "sole_proprietorship", value: "Sole Proprietorship" },
  { name: "single_member_llc", value: "Single-member LLC" },
  { name: "multi_member_llc", value: "Multi-member LLC" },
  { name: "private_partnership", value: "Private partnership" },
  { name: "private_corporation", value: "Private corporation" },
  { name: " ", value: "Other/i'm not sure" },
];

const AddBankForm = (props) => {
  const classes = useStyles();
  const { states } = props;
  const mccList = [
    { id: 8011, label: "Doctors" },
    { id: 8099, label: "Medical Services" },
    { id: 8071, label: "Medical and Dental Labs" },
    {
      id: 5047,
      label: "Medical, Dental, Ophthalmic, and Hospital Equipment and Supplies",
    },
  ];

  const [openRepresentativeForm, setOpenRepresentativeForm] = useState(false);
  const [openOwnerForm, setOpenOwnerForm] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isAddBankAccProcessing, setIsAddBankAccProcessing] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [status, setStatus] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [business_type, setBusiness_type] = useState("individual");
  const [business_structure, setBusiness_structure] = useState(
    "sole_proprietorship"
  );
  const [formData, setFormData] = useState({
    name: "", // if business type is not individual
    taxID: "", // if business type is not individual
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    dob: "",
    ssnLast4: "",
    id_number: "",
    address: {
      city: "",
      line1: "",
      postal_code: "",
      state: "",
      country: "US",
    },

    businessProfile: {
      mcc: 5047,
      url: "",
    },
  });
  const [representativeData, setRepresentativeData] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    dob: "",
    ssnLast4: "",
    id_number: "",
    address: {
      city: "",
      line1: "",
      postal_code: "",
      state: "",
      country: "",
    },
    relationship: {
      representative: true,
      executive: true,
      title: "representative",
    },
  });
  const [ownerData, setOwnerData] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    dob: "",
    ssnLast4: "",
    id_number: "",
    address: {
      city: "",
      line1: "",
      postal_code: "",
      state: "",
      country: "",
    },
    relationship: {
      owner: true,
      title: "owner",
    },
  });

  const [bankDetails, setBankDetails] = useState({
    country: "",
    accountHolderName: "",
    routingNumber: "",
    accountNumber: "",
  });

  useEffect(() => {
    pullConnectedAccount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (status) {
        setStatus(false);
      }
    }, 8000);

    return () => clearTimeout(timeout);
  }, [status]);

  const pullConnectedAccount = async () => {
    if (!props.user.stripeAccountId) return;
    setIsLoading(true);

    try {
      const response = await axios({
        url: `${process.env.REACT_APP_SERVER_URL}/account/${props.user.stripeAccountId}`,
        method: "get",
      });
      setIsLoading(false);
      const { data } = response;
      setFormData({
        ...formData,
        name: data?.account?.company?.name,
        address: {
          ...data.account.company.address,
          postal_code: data.account.company.address.postal_code,
          state: String(
            states.find((s) => s.name === data.account.company.address.state)
              ?.id
          ),
        },
      });

      setBusiness_type(response?.data?.account?.business_type);
      setBusiness_structure(response?.data?.account?.company?.structure);
      props.pullAccountPersons(props.user.stripeAccountId);
    } catch (error) {
      setIsLoading(false);
      console.log(error.message);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isValidated()) {
      let data = formData;
      data.email = props.user.email;
      data.dob = utils.getFormattedDate(data.dob, "MM/DD/YYYY");
      data.business_type = business_type;
      data.address.state = getStateNameById(formData?.address?.state);

      if (business_type === "company") {
        data.structure = business_structure;
        // setting bussiness structure to empty string because if business structure type is other then we need to send an empty string to stripe
        if (business_structure === "other") {
          data.structure = "";
        }
      }

      if (openRepresentativeForm) {
        if (!isPersonValidated(representativeData)) return;
        data = {
          ...data,
          representative: {
            ...representativeData,
            address: {
              ...representativeData.address,
              state: getStateNameById(representativeData.address.state),
            },
          },
        };
      }
      if (openOwnerForm) {
        if (!isPersonValidated(ownerData)) return;
        data = {
          ...data,
          owners: {
            ...ownerData,
            address: {
              ...ownerData.address,
              state: getStateNameById(ownerData.address.state),
            },
          },
        };
      }
      setIsProcessing(true);

      try {
        const response = await axios({
          url: `${process.env.REACT_APP_SERVER_URL}/account/banks`,
          method: "post",
          data,
        });

        setIsProcessing(false);
        setStatus(response.data.status);
        setMessage(response.data.message);

        props.setStripeAccountId(response.data.account.id);
        props.pullAccountPersons(response.data.account.id);

        setOpenOwnerForm(false);
        setOpenRepresentativeForm(false);

        // props.history.push("/get/paid");
      } catch (error) {
        setIsProcessing(false);
        setStatus(error.response.data.status);
        setMessage(error.response.data.message);
      }
    }
  };

  const getStateNameById = (id) => {
    return states.find((s) => s.id === Number(id))?.name || "";
  };

  const addBankAccount = async (e) => {
    e.preventDefault();
    if (!props.user.stripeAccountId) return;
    if (isValidatedBankDetails()) {
      setIsAddBankAccProcessing(true);

      try {
        const response = await axios({
          url: `${process.env.REACT_APP_SERVER_URL}/account/link-bank`,
          method: "post",
          data: { stripeAccountId: props.user.stripeAccountId, ...bankDetails },
        });

        setIsAddBankAccProcessing(false);
        setStatus(response.data.status);
        setMessage(response.data.message);
      } catch (error) {
        setIsAddBankAccProcessing(false);
        setStatus(error.response.data.status);
        setMessage(error.response.data.message);
      }
    }
  };

  const isValidatedBankDetails = () => {
    for (let data in bankDetails) {
      if (typeof bankDetails[data] !== "undefined" && !bankDetails[data]) {
        setHasError(true);
        return false;
      }
    }
    return true;
  };

  const isPersonValidated = (args) => {
    for (let data in args) {
      if (typeof args[data] !== "undefined" && !args[data]) {
        for (let key in args[data]) {
          if (typeof key !== "undefined" && !args[data][key]) {
            setHasError(true);
            return false;
          }
        }
        setHasError(true);
        return false;
      }
    }
    return true;
  };

  const isValidated = () => {
    if (business_type === "individual") {
      delete formData.name;
      delete formData.taxID;
    } else {
      delete formData.firstName;
      delete formData.lastName;
      delete formData.ssnLast4;
      delete formData.id_number;
      delete formData.dob;
    }
    for (let data in formData) {
      if (typeof formData[data] !== "undefined" && !formData[data]) {
        console.log(data, formData[data], "first");
        for (let key in formData[data]) {
          if (typeof key !== "undefined" && !formData[data][key]) {
            console.log(key, formData[data][key], "second");
            setHasError(true);
            return false;
          }
        }
        setHasError(true);
        return false;
      }
    }
    return true;
  };
  const getSelectedMcc = (selected) => {
    let mcc = null;
    for (let item of mccList) {
      if (item.id === selected) {
        mcc = item.label;
        break;
      }
    }
    return mcc;
  };

  const setBusinessStructure = (val) => {
    if (val === "single_member_llc" || val === "sole_proprietorship") {
      setOpenOwnerForm(false);
    } else {
      setOpenOwnerForm(true);
    }
    setOpenRepresentativeForm(true);
    setBusiness_structure(val);
  };

  const handleDateChange = (date, field) => {
    setFormData({
      ...formData,
      [field]: date,
    });
  };

  if (isLoading) {
    return (
      <div style={{ textAlign: "center", padding: "50px" }}>
        <CircularProgress size={30} />;
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <form onSubmit={handleSubmit}>
        {status ? (
          <Alert key={utils.uuid()} status={status} message={message} />
        ) : null}

        <Typography
          className={`${classes.subHeading} ${classes.sectionTopMargin}`}
          variant="h6"
        >
          Business Type
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <div className={classes.inputGroup}>
              <FormControl fullWidth className={classes.formControl}>
                <label
                  htmlFor="select-business-type-label"
                  className={classes.label}
                >
                  Type
                </label>
                <Select
                  labelId="select-business-type-label"
                  name="mcc"
                  variant="filled"
                  value={business_type}
                  onChange={(e) => {
                    if (e.target.value === "individual") {
                      setFormData({ ...formData, name: "", taxID: "" });
                      setOpenRepresentativeForm(false);
                      setOpenOwnerForm(false);
                    } else {
                      setOpenRepresentativeForm(true);
                      setOpenOwnerForm(false);
                    }
                    setBusiness_type(e.target.value);
                  }}
                  // renderValue={(value) => getSelectedMcc(value)}
                  disabled={Boolean(props.user.stripeAccountId)}
                >
                  {businessTypes.map((type) => (
                    <MenuItem
                      key={type.name}
                      value={type.name}
                      selected={formData.businessType === "individual"}
                    >
                      {type.value}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>
                  You cannot update business type once saved. If you made a
                  mistake, please contact our support
                </FormHelperText>
              </FormControl>
            </div>
          </Grid>
          {business_type === "company" && (
            <Grid item xs={12}>
              <div className={classes.inputGroup}>
                <FormControl
                  fullWidth
                  className={classes.formControl}
                  disabled={Boolean(props.user.stripeAccountId)}
                >
                  <label
                    htmlFor="select-business-type-label"
                    className={classes.label}
                  >
                    Business structure
                  </label>
                  <Select
                    labelId="select-business-type-label"
                    name="mcc"
                    variant="filled"
                    value={business_structure}
                    onChange={(e) => setBusinessStructure(e.target.value)}
                    // renderValue={"sole_partnership"}
                  >
                    {businessStructures.map((structure) => (
                      <MenuItem
                        key={structure.name}
                        value={structure.name}
                        selected={business_structure === "sole_proprietorship"}
                      >
                        {structure.value}
                      </MenuItem>
                    ))}
                  </Select>

                  <FormHelperText>
                    You cannot update business structure once saved. If you made
                    a mistake, please contact our support
                  </FormHelperText>
                </FormControl>
              </div>
            </Grid>
          )}
        </Grid>
        <Typography
          className={classes.subHeading}
          variant="h6"
          style={{ marginTop: "24px" }}
        >
          Legal Details
        </Typography>

        <Grid container spacing={3}>
          {business_type === "individual" ? (
            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
              <div className={classes.inputGroup}>
                <label className={classes.label}>First Name</label>
                <TextField
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      [e.target.name]: e.target.value,
                    })
                  }
                  name="firstName"
                  label=""
                  variant="filled"
                  fullWidth
                  error={hasError && !formData.firstName}
                  value={formData.firstName}
                  helperText={hasError && !formData.firstName ? "Required" : ""}
                />
              </div>
            </Grid>
          ) : (
            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
              <div className={classes.inputGroup}>
                <label className={classes.label}>Name</label>
                <TextField
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      [e.target.name]: e.target.value,
                    })
                  }
                  name="name"
                  label=""
                  variant="filled"
                  fullWidth
                  error={hasError && !formData?.name?.length}
                  value={formData?.name}
                  helperText={
                    hasError && !formData?.name?.length ? "Required" : ""
                  }
                />
              </div>
            </Grid>
          )}
          {business_type === "individual" ? (
            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
              <div className={classes.inputGroup}>
                <label className={classes.label}>Last Name</label>
                <TextField
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      [e.target.name]: e.target.value,
                    })
                  }
                  name="lastName"
                  label=""
                  variant="filled"
                  fullWidth
                  error={hasError && !formData.lastName}
                  value={formData.lastName}
                  helperText={hasError && !formData.lastName ? "Required" : ""}
                />
              </div>
            </Grid>
          ) : (
            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
              <div className={classes.inputGroup}>
                <label className={classes.label}>Tax ID</label>
                <TextField
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      [e.target.name]: e.target.value.replace(/\D/g, ""),
                    })
                  }
                  name="taxID"
                  label=""
                  variant="filled"
                  fullWidth
                  error={hasError && !formData?.taxID}
                  value={formData?.taxID}
                  helperText={hasError && !formData?.taxID ? "Required" : ""}
                />
              </div>
            </Grid>
          )}
          <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
            <div className={classes.inputGroup}>
              <label className={classes.label}>Email</label>
              <TextField
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    [e.target.name]: e.target.value,
                  });
                }}
                name="email"
                label=""
                variant="filled"
                fullWidth
                error={hasError && !formData.email}
                value={formData.email}
                helperText={hasError && !formData.email ? "Required" : ""}
              />
            </div>
          </Grid>

          <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
            <div className={classes.inputGroup}>
              <label className={classes.label}>Phone Number</label>
              <TextField
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    [e.target.name]: utils.formatPhone(
                      e.target.value,
                      "stripe"
                    ),
                  });
                }}
                name="phone"
                label=""
                variant="filled"
                fullWidth
                error={hasError && !formData.phone}
                value={formData.phone}
                helperText={hasError && !formData.phone ? "Required" : ""}
              />
            </div>
          </Grid>

          {business_type === "individual" && (
            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
              <div className={classes.inputGroup}>
                <label className={classes.label}>Date of birth</label>
                <KeyboardDatePicker
                  autoOk
                  className={classes.aucdatePicker}
                  disableFuture
                  fullWidth
                  inputVariant={"filled"}
                  variant="inline"
                  name="dob"
                  format="MM/DD/YYYY"
                  views={["year", "month", "date"]}
                  onChange={(date) => {
                    handleDateChange(date?.$d, "dob");
                  }}
                  error={hasError && !formData.dob}
                  value={formData.dob}
                  helperText={hasError && !formData.dob ? "Required" : ""}
                />
              </div>
            </Grid>
          )}
          {business_type === "individual" && (
            <>
              <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                <div className={classes.inputGroup}>
                  <label className={classes.label}>SSN Last 4 Digits</label>
                  <TextField
                    onChange={(e) => {
                      let last4 = e.target.value.replace(/\D/g, "");

                      if (last4.length < 5) {
                        setFormData({
                          ...formData,
                          [e.target.name]: e.target.value,
                        });
                      }
                    }}
                    name="ssnLast4"
                    label=""
                    variant="filled"
                    fullWidth
                    error={hasError && !formData?.ssnLast4?.length}
                    value={formData?.ssnLast4}
                    helperText={
                      hasError && !formData?.ssnLast4?.length ? "Required" : ""
                    }
                  />
                </div>
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                <div className={classes.inputGroup}>
                  <label className={classes.label}>ID Number</label>
                  <TextField
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        [e.target.name]: e.target.value.replace(/\D/g, ""),
                      });
                    }}
                    name="id_number"
                    label=""
                    variant="filled"
                    fullWidth
                    error={hasError && !formData?.id_number?.length}
                    value={formData?.id_number}
                    helperText={
                      hasError && !formData?.id_number?.length ? "Required" : ""
                    }
                  />
                </div>
              </Grid>
            </>
          )}
        </Grid>
        <Typography
          className={`${classes.subHeading} ${classes.sectionTopMargin}`}
          variant="h6"
        >
          Location Details
        </Typography>
        <Grid container spacing={3}>
          <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
            <div className={classes.inputGroup}>
              <label className={classes.label}>Address Line</label>
              <TextField
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    address: {
                      ...formData.address,
                      [e.target.name]: e.target.value,
                    },
                  });
                }}
                name="line1"
                label=""
                variant="filled"
                fullWidth
                error={hasError && !formData.address.line1}
                value={formData.address.line1}
                helperText={
                  hasError && !formData.address.line1 ? "Required" : ""
                }
              />
            </div>
          </Grid>

          <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
            <div className={classes.inputGroup}>
              <label className={classes.label}>City</label>

              <TextField
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    address: {
                      ...formData.address,
                      [e.target.name]: e.target.value,
                    },
                  });
                }}
                name="city"
                label=""
                variant="filled"
                fullWidth
                error={hasError && !formData.address.city}
                value={formData.address.city}
                helperText={
                  hasError && !formData.address.city ? "Required" : ""
                }
              />
            </div>
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
            <div className={classes.inputGroup}>
              <label className={classes.label}>State</label>
              {/* <TextField
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    address: {
                      ...formData.address,
                      [e.target.name]: e.target.value,
                    },
                  });
                }}
                name="state"
                label=""
                variant="filled"
                fullWidth
                error={hasError && !formData.address.state.length}
                value={formData.address.state}
                helperText={
                  hasError && !formData.address.state.length ? "Required" : ""
                }
              /> */}
              <FormControl
                variant="filled"
                fullWidth
                className={classes.formControl}
              >
                <Select
                  MenuProps={{
                    classes: { paper: classes.menuPaper },
                  }}
                  value={formData?.address?.state}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      address: {
                        ...formData.address,
                        state: e.target.value,
                      },
                    })
                  }
                  name="state"
                  error={hasError && !formData?.address?.state}
                >
                  {states.map((item) => (
                    <MenuItem key={`states-${item.id}`} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
                {hasError && !formData.licStateId ? (
                  <FormHelperText>Required</FormHelperText>
                ) : null}
              </FormControl>
            </div>
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
            <div className={classes.inputGroup}>
              <label className={classes.label}>Country</label>
              <TextField
                readOnly
                label=""
                variant="filled"
                fullWidth
                value={"United States"}
              />
            </div>
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
            <div className={classes.inputGroup}>
              <label className={classes.label}>Postal Code</label>
              <TextField
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    address: {
                      ...formData.address,
                      [e.target.name]: e.target.value,
                    },
                  });
                }}
                name="postal_code"
                label=""
                variant="filled"
                fullWidth
                error={hasError && !formData.address.postal_code}
                value={formData.address.postal_code}
                helperText={
                  hasError && !formData.address.postal_code ? "Required" : ""
                }
              />
            </div>
          </Grid>
        </Grid>
        <Typography
          className={`${classes.subHeading} ${classes.sectionTopMargin}`}
          variant="h6"
        >
          Business Profile
        </Typography>
        <Grid container spacing={3}>
          <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
            <div className={classes.inputGroup}>
              <FormControl fullWidth className={classes.formControl}>
                <label htmlFor="select-mcc-label" className={classes.label}>
                  Merchant Category
                </label>
                <Select
                  labelId="select-mcc-label"
                  id="select-mcc"
                  name="mcc"
                  variant="filled"
                  value={formData.businessProfile.mcc}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      businessProfile: {
                        ...formData.businessProfile,
                        [e.target.name]: e.target.value,
                      },
                    });
                  }}
                  renderValue={(value) => getSelectedMcc(value)}
                >
                  {mccList.map((mcc) => (
                    <MenuItem
                      key={mcc.id}
                      value={mcc.id}
                      selected={formData.businessProfile.mcc === mcc.id}
                    >
                      {mcc.label}
                    </MenuItem>
                  ))}
                </Select>

                {hasError && !formData.businessProfile.mcc ? (
                  <FormHelperText>Required</FormHelperText>
                ) : (
                  ""
                )}
              </FormControl>
            </div>
          </Grid>

          <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
            <div className={classes.inputGroup}>
              <label className={classes.label}>Business Website URL</label>
              <TextField
                onChange={(e) => {
                  setFormData({
                    ...formData,

                    businessProfile: {
                      ...formData.businessProfile,
                      [e.target.name]: e.target.value,
                    },
                  });
                }}
                name="url"
                label=""
                variant="filled"
                fullWidth
                error={hasError && !formData.businessProfile.url}
                value={formData.businessProfile.url}
                helperText={
                  hasError && !formData.businessProfile.url ? "Required" : ""
                }
              />
            </div>
          </Grid>
        </Grid>
        {openOwnerForm && (
          <AddPerson
            formData={ownerData}
            setFormData={setOwnerData}
            hasError={hasError}
            title="Company Owner"
            states={states}
          />
        )}
        {openRepresentativeForm && (
          <AddPerson
            formData={representativeData}
            setFormData={setRepresentativeData}
            hasError={hasError}
            title="Company Representative"
            states={states}
          />
        )}
        <div className={classes.submitBtnWrapper}>
          <Button
            variant="contained"
            type="submit"
            color="primary"
            disabled={isProcessing}
          >
            {isProcessing
              ? "Processing"
              : props?.user?.stripeAccountId
              ? "Update Account"
              : "Create Account"}
          </Button>
        </div>
      </form>
      <form onSubmit={addBankAccount}>
        <Typography className={`${classes.subHeading}`} variant="h6">
          Bank Details
        </Typography>
        {!props.user.stripeAccountId && (
          <div className={` ${classes.sectionTopMargin}`}>
            <FormHelperText>
              Please fill in all the details above related to your payout
              account before adding your bank details
            </FormHelperText>
          </div>
        )}
        {props.user.stripeAccountId && (
          <>
            <Grid container spacing={3}>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <div className={classes.inputGroup}>
                  <label className={classes.label}>Accountholder Name</label>
                  <TextField
                    onChange={(e) =>
                      setBankDetails({
                        ...bankDetails,
                        [e.target.name]: e.target.value,
                      })
                    }
                    name="accountHolderName"
                    label=""
                    variant="filled"
                    fullWidth
                    error={hasError && !bankDetails.accountHolderName}
                    value={bankDetails.accountHolderName}
                    helperText={
                      hasError && !bankDetails.accountHolderName
                        ? "Required"
                        : ""
                    }
                  />
                </div>
              </Grid>
              <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                <div className={classes.inputGroup}>
                  <label className={classes.label}>Routing Number</label>
                  <TextField
                    onChange={(e) =>
                      setBankDetails({
                        ...bankDetails,
                        [e.target.name]: e.target.value,
                      })
                    }
                    name="routingNumber"
                    label=""
                    variant="filled"
                    fullWidth
                    error={hasError && !bankDetails.routingNumber}
                    value={bankDetails.routingNumber}
                    helperText={
                      hasError && !bankDetails.routingNumber ? "Required" : ""
                    }
                  />
                </div>
              </Grid>

              <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                <div className={classes.inputGroup}>
                  <label className={classes.label}>Account Number</label>
                  <TextField
                    onChange={(e) =>
                      setBankDetails({
                        ...bankDetails,
                        [e.target.name]: e.target.value,
                      })
                    }
                    name="accountNumber"
                    label=""
                    variant="filled"
                    fullWidth
                    error={hasError && !bankDetails.accountNumber}
                    value={bankDetails.accountNumber}
                    helperText={
                      hasError && !bankDetails.accountNumber ? "Required" : ""
                    }
                  />
                </div>
              </Grid>
            </Grid>

            <div className={classes.submitBtnWrapper}>
              <Button
                variant="contained"
                type="submit"
                color="primary"
                disabled={isAddBankAccProcessing}
              >
                {isAddBankAccProcessing ? "Processing" : "Add Bank Account"}
              </Button>
            </div>
          </>
        )}
      </form>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth0.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setStripeAccountId: (id) => dispatch(setStripeAccountIdAction(id)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AddBankForm));
