import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const GlobalScroller = (props) => {
  const location = useLocation();
  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        window.scrollTo({
          top: Math.abs(elem.offsetTop - 64),
          left: 0,
          behavior: "smooth",
        });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location]);
  return props.children;
};

export default GlobalScroller;
