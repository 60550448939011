import makeStyles from "@material-ui/core/styles/makeStyles";
import { Box, Typography, Grid, Container } from "@material-ui/core";
import Footer from "../../components/Footer";
import newLogo from "../../assets/images/newest-logo-updated-new.png";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    // width: "100% !important",
    paddingTop: "100px",
    background: "#f5f5f7",
  },
  contentContainer: {
    borderRadius: "15px",
    // padding: "20px",
    minHeight: "400px",
    margin: "20px 0 40px 0",
  },
  smallHeading: {
    textTransform: "uppercase",
    color: theme.paragraph.color,
    fontWeight: 600,
    marginBottom: "10px",
    fontSize: "12px",
  },
  mediumHeading: {
    // color: theme.paragraph.color,
    fontWeight: "bold",
    marginBottom: "10px",
    fontSize: "1.5rem",
  },
  leftContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "#fafafa",
    borderTopLeftRadius: "20px",
    borderBottomLeftRadius: "20px",
    [theme.breakpoints.down("sm")]: {
      borderTopLeftRadius: "20px",
      borderTopRightRadius: "20px",
      borderBottomLeftRadius: "0px",
    },
  },
  newLogo: {
    width: "200px",
    opacity: "0.2",
  },
  rightContainer: {
    background: "#fff",
    borderTopRightRadius: "20px",
    borderBottomRightRadius: "20px",
    padding: "20px",
    [theme.breakpoints.down("sm")]: {
      borderTopRightRadius: "0px",
      borderBottomRightRadius: "20px",
      borderBottomLeftRadius: "20px",
    },
  },
  rightContainerContant: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  mail: {
    color: "#32a5b6",
    fontSize: ".9rem !important",
    textDecoration: "none",
  },
}));

function Relations() {
  document.body.style.background = "#fff";

  const classes = useStyles();

  return (
    <Box className={classes.wrapper}>
      <Container maxWidth="lg">
        <Typography className={classes.smallHeading}>LATEST NEWS</Typography>
        <Typography variant="h4" className={classes.mediumHeading}>
          Investor Relations
        </Typography>
        <Grid container className={classes.contentContainer}>
          <Grid item xs={12} md={8} lg={9} className={classes.leftContainer}>
            <img src={newLogo} className={classes.newLogo} alt="" />
          </Grid>
          <Grid item xs={12} md={4} lg={3} className={classes.rightContainer}>
            <Box className={classes.rightContainerContant}>
              <Box>
                <Typography className={classes.smallHeading}>
                  INVESTOR RELATIONS
                </Typography>
                <Typography variant="h4" className={classes.mediumHeading}>
                  For investor inquiries,
                  <br />
                  Please contact:
                  <br />
                  <a
                    className={classes.mail}
                    href="mailto:support@tovamedical.com"
                  >
                    support@tovamedical.com
                  </a>
                </Typography>
              </Box>
              <Box></Box>
            </Box>
          </Grid>
        </Grid>
      </Container>

      <Footer />
    </Box>
  );
}

export default Relations;
