import { createMuiTheme } from "@material-ui/core/styles";
import { jeko, jekoBold } from "./fonts/fonts";

export const theme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      custom: 1440,
      xl: 1920,
    },
  },
  palette: {
    background: {
      default: "#f7f7f7",
    },
    secondary: {
      dark: "#575757",
      main: "#469eb5",
      light: "#D9D9D9",
    },
    info: {
      main: "#32a5b6",
    },
  },
  typography: {
    fontFamily: ["Open Sans", "sans-serif"].join(","),
    fontWeight: 400,
    fontSize: 16,
    color: "#333333",
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": [jeko, jekoBold],
      },
    },
    MuiTypography: {
      h1: {
        fontFamily: ["Jeko", "sans-serif"].join(","),
        letterSpacing: "1px",
      },
      h2: {
        fontFamily: ["Jeko", "sans-serif"].join(","),
        letterSpacing: "1px",
      },
      h3: {
        fontFamily: ["Jeko", "sans-serif"].join(","),
        letterSpacing: "1px",
      },
      h4: {
        fontFamily: ["Jeko", "sans-serif"].join(","),
        letterSpacing: "1px",
      },
      h5: {
        fontFamily: ["Jeko", "sans-serif"].join(","),
        letterSpacing: "1px",
      },
      h6: {
        fontFamily: ["Jeko", "sans-serif"].join(","),
        letterSpacing: "1px",
      },
    },
    MuiStepper: {
      root: {
        backgroundColor: "transparent",
        padding: 0,
      },
    },
    MuiInputLabel: {
      root: {
        fontSize: "16px",
      },
    },
    MuiInputBase: {
      root: {
        "&:hover": {
          "@media(min-width: 0px)": {
            backgroundColor: "#f0f2f4 !important",
          },
        },
        "&$focused": {
          "@media(min-width: 0px)": {
            backgroundColor: "#f0f2f4 !important",
            //boxShadow: "0 3px 60px rgba(0,0,0,0.11)",
          },
        },
        "&$disabled": {
          //backgroundColor: "#ffffff !important",
        },
      },
    },
    MuiFilledInput: {
      root: {
        backgroundColor: "#f0f2f4",
        // borderTopLeftRadius: "10px",
        // borderTopRightRadius: "10px",
        // borderBottomLeftRadius: "10px",
        // borderBottomRightRadius: "10px",
        // "&:hover": {
        //   backgroundColor: "#ffffff",
        // },
        // "&$focused": {
        //   backgroundColor: "#ffffff",
        //   boxShadow: "0 3px 60px rgba(0,0,0,0.11)",
        // },
      },
      input: {
        padding: "17px 12px 10px",
      },
      underline: {
        "&&&:before": {
          //borderBottom: "none",
          borderColor: "transparent",
          transition: "none",
        },
        "&&:after": {
          //borderBottom: "none",
          transition: "none",
        },
      },
    },

    MuiAppBar: {
      root: {
        zIndex: 900,
        alignItems: "stretch",
        backgroundColor: "#fff !important",
        boxShadow: "0 3px 30px rgba(0,0,0,0.16)",
        "& nav a": {
          textDecoration: "none",
          display: "inline-flex",
          justifyContent: "center",
          alignItems: "center",

          "& span": {
            fontSize: "16px",
            fontWeight: 600,
            textTransform: "capitalize",
            display: "inline-flex",
          },
        },
        "& nav div": {
          textDecoration: "none",
          display: "inline-flex",
          justifyContent: "center",
          alignItems: "center",

          "& span": {
            fontSize: "16px",
            fontWeight: 600,
            textTransform: "capitalize",
            display: "inline-flex",
          },
        },
      },
    },
    MuiToolbar: {
      root: {
        display: "flex",
        justifyContent: "space-between",
        margin: 0,
        padding: 0,
        height: "55px",
      },
    },
    MuiButton: {
      root: {
        padding: "10px 30px",
        textTransform: "capitalize",
        borderRadius: 50,
        height: "auto",
        fontWeight: 600,

        boxShadow: "0 3px 6px rgba(0,0,0,0.11)",
      },
    },
    MuiPaper: {
      root: {
        boxShadow: "0 3px 60px rgba(0,0,0,0.11)",
      },
    },
    MuiPickersBasePicker: {
      container: {
        "& *": {
          boxShadow: "none !important",
        },
        "& .MuiToolbar-root": {
          backgroundColor: "#32a5b6 !important",
        },
        "& .MuiPickersDay-daySelected": {
          backgroundColor: "#32a5b6 !important",
        },
      },
    },
  },
  // other global tyles
  border: {
    color: "#d9d9d9",
  },
  navItem: {
    color: "#333333",
  },
  paragraphDark: {
    color: "#333333",
  },
  paragraph: {
    color: "#7A7A7A",
  },

  inactive: {
    color: "#e9e9e9",
  },
  active: {
    color: "#31A49D",
  },
  backgroundLight: {
    color: "#F7F7F7",
  },
  button: {
    shadow: "0 3px 6px rgba(0,0,0,0.11)",
  },
  section: {
    boxShadow: "0 3px 20px rgba(0,0,0,0.11)",
    borderRadius: "10px",
  },
  textSeagreen: "#32a5b6 !important",
  backgroundSeagreen: "#32a5b6",
  backgroundSeagreenDark: "#2a8795",
  backgroundTransparentOverlayDark: "rgba(0,0,0,0.5)",
});
