import React, { useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Box,
  CircularProgress,
  Container,
  Grid,
  useMediaQuery,
} from "@material-ui/core";
import VerticalMenu from "../components/VerticalMenu";
import utils from "../helpers/utils";
import { Redirect } from "react-router";
import { connect } from "react-redux";
import SadIcon from "@material-ui/icons/SentimentDissatisfiedOutlined";
import { NavLink } from "react-router-dom";
import axios from "axios";
import ExternalAccounts from "../components/ExternalAccounts";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "calc(100vh - 110px)",
    paddingTop: theme.spacing(30),
  },
  contentSection: {
    boxShadow: theme.section.boxShadow,
    borderRadius: theme.section.borderRadius,
    backgroundColor: "#fff",
    padding: theme.spacing(4),
  },
  label: {
    display: "block",
    fontSize: "12px",
    color: theme.paragraph.color,
  },
  value: {
    display: "block",
    fontWeight: 600,
    color: theme.paragraphDark.color,
  },
  infoWrapper: {
    marginTop: theme.spacing(3),
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  divider: {
    height: "4px",
    marginTop: theme.spacing(1),
    width: theme.spacing(5),
    backgroundColor: theme.palette.primary.main,
  },
  notFoundWrapper: {
    textAlign: "center",
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  sadIcon: {
    fontSize: "80px",
    color: "#ddd",
  },
  warningDescription: {
    color: "#aaa",
    fontWeight: "bold",
  },
  navLink: {
    textDecoration: "none",
    color: theme.palette.primary.main,
    fontWeight: "bold",
  },
  progressWrapper: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    textAlign: "center",
  },
  sectionHeading: {
    fontWeight: 600,
  },
}));

const BankAccount = (props) => {
  document.body.style.backgroundColor = "#F7F7F7";

  const classes = useStyles();

  const theme = useTheme();

  const [isProcessing, setIsProcessing] = useState(false);
  const [account, setAccount] = useState(null);
  const [isAlreadyPulled, setIsAlreadyPulled] = useState(false);

  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const xs = useMediaQuery(theme.breakpoints.down("xs"));

  const { user } = props;
  const role = utils.getRole(user);

  const stripeAccountId = user.stripeAccountId;

  useEffect(() => {
    if (!isAlreadyPulled && !account) {
      pullConnectedAccount();
    }
  });

  const pullConnectedAccount = async () => {
    if (!stripeAccountId) return;

    setIsProcessing(true);
    try {
      const response = await axios({
        url: `${process.env.REACT_APP_SERVER_URL}/account/${stripeAccountId}`,
        method: "get",
      });

      setAccount(response.data.account);
      setIsAlreadyPulled(true);
      setIsProcessing(false);
    } catch (error) {
      setAccount(null);
      setIsAlreadyPulled(true);
      setIsProcessing(false);
    }
  };

  if (role === "doctor" && !user.isApplicationAccepted) {
    return <Redirect to="/application-status" />;
  }

  return (
    <Container maxWidth="lg" className={classes.root}>
      <Grid container direction="row" className={classes.gridWrapper}>
        {!sm && !xs ? (
          <Grid item xl={3} lg={3} md={3}>
            <VerticalMenu active="bank-account" />
          </Grid>
        ) : null}

        <Grid item xl={9} lg={9} md={9} sm={12}>
          <Box component="div" className={classes.contentSection}>
            <Typography className={classes.sectionHeading} variant="h6">
              Get Paid
            </Typography>
            <div className={classes.divider} />
            {/* <BankAccountForm /> */}

            {isProcessing ? (
              <div className={classes.progressWrapper}>
                <CircularProgress size={25} />
              </div>
            ) : null}

            {(!account ||
              (account && !account.external_accounts.data.length)) &&
            !isProcessing ? (
              <div className={classes.notFoundWrapper}>
                <SadIcon className={classes.sadIcon} />
                <Typography variant="h6" className={classes.warningDescription}>
                  No Bank Account or Debit Card Found
                </Typography>
                <p>
                  In order to get paid, you should add either your debit card or
                  your bank account.
                </p>
                <p>
                  <NavLink className={classes.navLink} to="/card/add">
                    Add a Debit Card
                  </NavLink>{" "}
                  or{" "}
                  <NavLink className={classes.navLink} to="/bank/add">
                    Add a Bank Account
                  </NavLink>
                </p>
              </div>
            ) : (
              <ExternalAccounts account={account} />
            )}
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    isProcessing: state.auth0.isProcessing,
    user: state.auth0.user,
  };
};

export default connect(mapStateToProps)(BankAccount);
