import IconButton from "@material-ui/core/IconButton";
import BellIcon from "@material-ui/icons/NotificationsNoneOutlined";
import { Avatar, Button, List, Menu, MenuItem } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { useState } from "react";
import utils from "../helpers/utils";
import { connect } from "react-redux";
import logoutAction from "../pages/auth/store/actions/logoutAction";
import { useAppContext } from "../context/AppContext";

const useStyles = makeStyles((theme) => ({
  navLink: {
    margin: "0 32px",
    [theme.breakpoints.down("lg")]: {
      margin: "0 10px",
    },
  },
  lastNavLink: {
    margin: "0 12px 0 50px",
    [theme.breakpoints.down("lg")]: {
      margin: "0 10px",
    },
  },

  dropdownLink: {
    color: theme.palette.secondary.dark,
    textDecoration: "none",
    minWidth: "200px",
  },

  nav: {
    display: "flex",
    justifyContent: "center",
    menuIcon: {
      color: "red",
    },
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
  },
  button: {
    maxHeight: "48px",
    alignSelf: "center",
    marginLeft: "32px",
    marginRight: "32px",
    padding: "10px 50px",
    color: "#fff",
    "& span": {
      color: "#ffffff",
    },
  },
  navItemLeftBordered: {
    padding: "0 32px 0 45px",
    height: "65px",
    textAlign: "center",
  },
  navItemBordered: {
    padding: "0 32px",
    height: "65px",
  },
  avatarMedium: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    marginRight: theme.spacing(2),
  },
  usernameWrapper: {
    display: "flex",
    flexDirection: "column",
    textAlign: "left !important",
    justifyContent: "flex-start !important",
  },
  username: {
    fontWeight: 600,
    width: "100%",
    fontSize: "25px",
    display: "block !important",
    textAlign: "left !important",
  },
  type: {
    fontWeight: 600,
    width: "100%",
    display: "block !important",
    textAlign: "left !important",
    color: "#fff",
    textTransform: "capitalize",
  },
  textWhite: {
    color: "#fff",
  },
  menuIcon: {
    color: "#fff",
  },
  linkButton: {
    boxShadow: "none",
    marginLeft: theme.spacing(3),
    "&:hover": {
      backgroundColor: "transparent !important",
    },
  },
}));

const AuthenticatedDesktopMenu = (props) => {
  const { user } = props;

  const { notAvailable, openPopup } = useAppContext();

  const role = utils.getRole(user);

  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (role === "patient") {
    return (
      <List
        component="nav"
        className={classes.nav}
        aria-label="main navigation"
      >
        <Button
          className={classes.linkButton}
          disableRipple
          disableTouchRipple
          color="inherit"
          onClick={(e) => {
            if (notAvailable) {
              openPopup();
            }
          }}
        >
          My Care
        </Button>
        <Button
          className={classes.linkButton}
          disableRipple
          disableTouchRipple
          color="inherit"
          onClick={(e) => {
            if (notAvailable) {
              openPopup();
            }
          }}
        >
          My Records
        </Button>
        {/* <Button
          className={classes.linkButton}
          disableRipple
          disableTouchRipple
          color="inherit"
        >
          Notifications
        </Button> */}
        <Button
          className={classes.linkButton}
          disableRipple
          disableTouchRipple
          color="inherit"
          component={NavLink}
          to="/account"
          onClick={(e) => {
            if (notAvailable) {
              openPopup();
            }
          }}
        >
          Account
        </Button>

        <IconButton onClick={handleClick}>
          <Avatar
            alt={user.nickname}
            src={user.picture}
            className={classes.avatarMedium}
          />
        </IconButton>

        <Menu
          id="simple-menu1"
          className={classes.dropdownMenu}
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {/* <MenuItem onClick={handleClose}>
            <NavLink className={classes.dropdownLink} to="/account">
              Account
            </NavLink>
          </MenuItem> */}
          {/* <MenuItem onClick={handleClose}>
            <NavLink className={classes.dropdownLink} to="/password">
              Password
            </NavLink>
          </MenuItem> */}
          {/* <MenuItem>
            <NavLink className={classes.dropdownLink} to="/settings">
              Settings
            </NavLink>
          </MenuItem> */}
          <MenuItem onClick={() => props.logout()}>Logout</MenuItem>
        </Menu>
      </List>
    );
  }

  return (
    <List component="nav" className={classes.nav} aria-label="main navigation">
      <div className={classes.navItemLeftBordered}>
        <IconButton edge="start" aria-label="menu">
          <BellIcon className={classes.textWhite} fontSize="large" />
        </IconButton>
      </div>
      {/* <div className={classes.navItemBordered} to="/are-you-a-patient">
        <ListItem button disableRipple>
          <div className={classes.avatarWrapper}>
            <Avatar
              alt={user.nickname}
              src={user.picture}
              className={classes.avatarMedium}
            />
            <div className={classes.usernameWrapper}>
              <span className={classes.username}>{user.name}</span>
              <small className={classes.type}>{role ? role : "User"}</small>
            </div>
          </div>
        </ListItem>
      </div> */}

      {/* <div
        className={classes.lastNavLink}
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <IconButton edge="start" aria-label="menu">
          <MenuIcon className={classes.menuIcon} fontSize="large" />
        </IconButton>
      </div> */}
      <IconButton onClick={handleClick}>
        <Avatar
          alt={user.nickname}
          src={user.picture}
          className={classes.avatarMedium}
        />
      </IconButton>

      <Menu
        id="simple-menu"
        className={classes.dropdownMenu}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose}>
          <NavLink className={classes.dropdownLink} to="/account">
            Account
          </NavLink>
        </MenuItem>
        {/* <MenuItem onClick={handleClose}>
          <NavLink className={classes.dropdownLink} to="/password">
            Password
          </NavLink>
        </MenuItem> */}
        <MenuItem onClick={() => props.logout()}>Logout</MenuItem>
      </Menu>
    </List>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.auth0.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: (url) => dispatch(logoutAction(url)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthenticatedDesktopMenu);
