import { UPDATE_AUTH_STATUS_SUCCESS } from "./actionTypes";

const updateAuthStatusAction = () => {
  return async (dispatch, getState) => {
    dispatch({
      type: UPDATE_AUTH_STATUS_SUCCESS,
    });
  };
};

export default updateAuthStatusAction;
