import React, { useState, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { useAuth0 } from "@auth0/auth0-react";
import Error from "../Error";
import {
  Box,
  Container,
  Grid,
  CircularProgress,
  Chip,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import utils from "../../helpers/utils";
import Loading from "../../components/Loading";
import HttpError from "../../components/HttpError";
import { Redirect } from "react-router";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBackOutlined";
import EditIcon from "@material-ui/icons/Edit";
import axios from "axios";
import DoctorReviews from "./DoctorReviews";
import useHasPermission from "../../hooks/useHasPermission";

const Doctor = (props) => {
  document.body.style.backgroundColor = "#F7F7F7";

  const useStyles = makeStyles((theme) => ({
    root: {
      height: "calc(100vh - 110px)",
      paddingTop: theme.spacing(30),
    },
    heading: {
      fontSize: "16px",
      color: "#939393",
    },
    contentSection: {
      boxShadow: theme.section.boxShadow,
      borderRadius: theme.section.borderRadius,
      backgroundColor: "#fff",
      padding: theme.spacing(4),
    },

    divider: {
      height: "4px",
      marginTop: theme.spacing(1),
      width: theme.spacing(5),
      backgroundColor: theme.palette.primary.main,
    },
    headingWrapper: {
      display: "flex",
      alignItems: "center",
      marginBottom: theme.spacing(5),
    },
    navLink: {
      color: "#000",
    },
    icon: {
      fontSize: "2.5rem",
      marginRight: theme.spacing(2),
    },
    table: {
      minWidth: 1000,
    },
    tableHeading: {
      fontWeight: "bold",
      letterSpacing: "1px",
    },
    iconWrapper: {
      width: theme.spacing(13),
    },

    textCenter: {
      textAlign: "center !important",
    },
    textRight: {
      textAlign: "right !important",
    },
  }));

  const classes = useStyles();

  const { isAuthenticated, user, error } = useAuth0();
  const isAdmin = utils.isAdmin(user);

  const [hasError, setHasError] = useState(false);
  const [doctor, setDoctor] = useState(null);

  useEffect(() => {
    const fetchDoctor = async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/accounts/doctor/${props.match.params?.id}/show`
      );
      if (res.status === 200) setDoctor(res.data);
      else setHasError(true);
    };

    fetchDoctor();
  }, [props.match.params?.id]);

  const { hasPermission } = useHasPermission({ permission: "read:doctors" });
  if (!hasPermission) return <Redirect to="/account" />;

  if (isAuthenticated && props.isProcessing) {
    return <Loading />;
  } else if (isAuthenticated && props.isMember === false && !isAdmin) {
    return <Redirect to="/signup" />;
  }

  if (error) {
    return <Error code="500" />;
  }

  return (
    <Container maxWidth="lg" className={classes.root}>
      <Grid container direction="row" className={classes.gridWrapper}>
        {/* {!sm && !xs ? (
          <Grid item xl={3} lg={3} md={3}>
            <VerticalMenu active="account" />
          </Grid>
        ) : null} */}

        <div className={classes.headingWrapper}>
          <NavLink className={classes.navLink} to="/doctors">
            <ArrowBackIcon className={classes.icon} />
          </NavLink>
          <Typography variant="h5">
            Doctor Details
            <div className={classes.divider} />
          </Typography>
        </div>

        <Grid item xs={12}>
          <Box component="div" className={classes.contentSection}>
            {hasError ? (
              <HttpError code="404" />
            ) : (
              <div className={classes.contentWrapper}>
                {!doctor && !hasError ? (
                  <div
                    className={`${classes.textCenter} ${classes.loaderWrapper}`}
                  >
                    <CircularProgress size={30} />
                  </div>
                ) : null}

                {doctor ? (
                  <Grid container spacing={4}>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                      <div className={classes.textRight}>
                        <Tooltip title="Edit Details">
                          <IconButton
                            component={NavLink}
                            to={`/doctor/${props.match.params?.id}/edit`}
                          >
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                      </div>
                    </Grid>
                    {/* <Grid item xl={3} lg={3} md={4} sm={4} xs={6}>
                      <Typography className={classes.heading}>
                        Application status
                      </Typography>
                      <Typography>
                        {doctor.accepted ? <>Accepted</> : <>Pending</>}
                      </Typography>
                    </Grid> */}
                    <Grid item xl={3} lg={3} md={4} sm={4} xs={6}>
                      <Typography className={classes.heading}>Name</Typography>
                      <Typography>
                        {doctor.firstName} {doctor.lastName}
                      </Typography>
                    </Grid>
                    <Grid item xl={3} lg={3} md={4} sm={4} xs={6}>
                      <Typography className={classes.heading}>Email</Typography>
                      <Typography>{doctor.email}</Typography>
                    </Grid>
                    <Grid item xl={3} lg={3} md={4} sm={4} xs={6}>
                      <Typography className={classes.heading}>
                        Date of birth
                      </Typography>
                      <Typography>
                        {utils.getFormattedDate(doctor.dob, "MM/DD/YYYY")}
                      </Typography>
                    </Grid>
                    <Grid item xl={3} lg={3} md={4} sm={4} xs={6}>
                      <Typography className={classes.heading}>
                        Phone Number
                      </Typography>
                      <Typography>{doctor.phoneNumber}</Typography>
                    </Grid>
                    <Grid item xl={3} lg={3} md={4} sm={4} xs={6}>
                      <Typography className={classes.heading}>
                        License country
                      </Typography>
                      <Typography>{doctor.licCountry}</Typography>
                    </Grid>
                    <Grid item xl={3} lg={3} md={4} sm={4} xs={6}>
                      <Typography className={classes.heading}>
                        License number
                      </Typography>
                      <Typography>{doctor.licNumber}</Typography>
                    </Grid>
                    <Grid item xl={3} lg={3} md={4} sm={4} xs={6}>
                      <Typography className={classes.heading}>
                        License expiry date
                      </Typography>
                      <Typography>
                        {utils.getFormattedDate(
                          doctor.licExpDate,
                          "MM/DD/YYYY"
                        )}
                      </Typography>
                    </Grid>
                    <Grid item xl={3} lg={3} md={4} sm={4} xs={6}>
                      <Typography className={classes.heading}>
                        Degree
                      </Typography>
                      <Typography>{doctor.degree}</Typography>
                    </Grid>

                    <Grid item xl={3} lg={3} md={4} sm={4} xs={6}>
                      <Typography className={classes.heading}>
                        Medical school
                      </Typography>
                      <Typography>{doctor.medicalSchool}</Typography>
                    </Grid>
                    <Grid item md={6} sm={6} xs={12}>
                      <Typography className={classes.heading}>
                        Specialties
                      </Typography>
                      <div className={classes.chipsWrapper}>
                        <Box display={"flex"} flexWrap="wrap">
                          {doctor?.specialties?.map((specialty, index) => (
                            <Chip
                              key={specialty.id}
                              label={specialty.text}
                              className={classes.chip}
                              style={{ margin: "5px" }}
                            />
                          ))}
                        </Box>
                      </div>
                    </Grid>
                  </Grid>
                ) : null}
              </div>
            )}
          </Box>
        </Grid>
      </Grid>
      {/* Reviews */}
      <DoctorReviews />
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    isProcessing: state.auth0.isProcessing,
    isMember: state.auth0.isMember,
    authUser: state.auth0.user,
  };
};

export default connect(mapStateToProps)(Doctor);
