import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import DeleteIcon from "@material-ui/icons/DeleteForeverOutlined";
import { KeyboardDatePicker } from "@material-ui/pickers";

const useStyles = makeStyles((theme) => ({
  slash: {
    color: "#aaa",
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    fontSize: "1.5rem",
  },
  label: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    display: "block",
  },
  runningText: {
    lineHeight: "26px",
  },
  descriptionWrapper: {
    marginBottom: theme.spacing(5),
  },
  submitBtnWrapper: {
    textAlign: "right",
    marginTop: theme.spacing(5),
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  select: {
    "& #spaciality-select-filled > div": {
      display: "flex",
      flexWrap: "wrap",
    },
  },
  menuPaper: {
    maxHeight: "300px",
  },
  btnContained: {
    backgroundColor: theme.backgroundSeagreen,
    color: "#fff",
    "&:hover": {
      backgroundColor: theme.backgroundSeagreenDark,
    },
  },
  itemWrapper: {
    display: "flex",
    alignItems: "flex-end",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "35px",
      marginBottom: "25px",
      borderBottom: "dashed 1px #ddd",
    },
  },
  headingVerticalSpacing: {
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(3),
  },
  textBold: {
    fontWeight: 500,
    fontSize: "1rem",
  },
  headingWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  addButton: {
    boxShadow: "none",
    color: theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
}));

const AdditionalLicenses = (props) => {
  const classes = useStyles();
  const { states, handleAdditionalLicences } = props;

  const [licenses, setLicenses] = useState([]);

  useEffect(() => {
    if (props.isEditing) {
      let licencesData = props.additionalLicenses.map((el) => ({
        licNumber: el?.licNumber,
        licExpDate: el?.licExpDate,
        licStateId: el?.licState?.id,
      }));
      setLicenses(licencesData);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDateChange = (index, date, field) => {
    // setFormData({
    //   ...formData,
    //   [field]: date,
    // });

    let tempLicenses = licenses;

    tempLicenses[index][field] = date;

    setLicenses(tempLicenses);
    handleAdditionalLicences(tempLicenses);
  };

  const handleChange = (e, index) => {
    let tempLicenses = licenses;

    tempLicenses[index][e.target.name] = e.target.value;

    setLicenses(tempLicenses);
    handleAdditionalLicences(tempLicenses);
  };

  const handleDelete = (index) => {
    const tempLicenses = licenses.filter((licence, i) => index !== i);
    setLicenses(tempLicenses);
    handleAdditionalLicences(tempLicenses);
  };

  const handleAddLicense = () => {
    const tempLicenses = licenses;

    tempLicenses.push({
      licNumber: "",
      licExpDate: new Date(),
      licStateId: "",
    });

    setLicenses(tempLicenses);
    handleAdditionalLicences(tempLicenses);
  };

  // console.log({
  //   licNumber: "",
  //   licExpDate: new Date(),
  //   licStateId: "",
  // });

  return (
    <Box component="div">
      <Box component="div" className={classes.headingWrapper}>
        <Typography
          variant="h6"
          className={`${classes.textBold} ${classes.headingVerticalSpacing}`}
        >
          What additional states are you licensed in? (optional)
        </Typography>
        <Button
          disableRipple
          disableFocusRipple
          disableTouchRipple
          className={classes.addButton}
          onClick={handleAddLicense}
        >
          + Add
        </Button>
      </Box>

      {licenses.map((license, index) => {
        return (
          <Box key={index} component="div" className={classes.itemWrapper}>
            <Grid container spacing={3}>
              <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                <div className={classes.inputGroup}>
                  <label className={classes.label}>State/Province</label>
                  <FormControl
                    variant="filled"
                    fullWidth
                    className={classes.formControl}
                    placeholder="State/Province"
                  >
                    <Select
                      MenuProps={{
                        classes: { paper: classes.menuPaper },
                      }}
                      labelId="state-select-filled-label"
                      id="state-select-filled"
                      value={licenses[index].licStateId}
                      onChange={(e) => handleChange(e, index)}
                      name="licStateId"
                    >
                      {states.map((item) => (
                        <MenuItem key={`states-${item.id}`} value={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </Grid>
              <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                <div className={classes.inputGroup}>
                  <label className={classes.label}>License number</label>
                  <TextField
                    onChange={(e) => handleChange(e, index)}
                    name="licNumber"
                    label=""
                    variant="filled"
                    fullWidth
                    value={licenses[index].licNumber}
                  />
                </div>
              </Grid>
              <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                <div className={classes.inputGroup}>
                  <label className={classes.label}>Expiration date</label>

                  <KeyboardDatePicker
                    autoOk
                    fullWidth
                    disableToolbar
                    inputVariant={"filled"}
                    variant="inline"
                    name="licExpDate"
                    format="MM/DD/YYYY"
                    views={["year", "month", "date"]}
                    onChange={(date) =>
                      handleDateChange(index, date?.$d, "licExpDate")
                    }
                    value={licenses[index].licExpDate}
                  />
                </div>
              </Grid>
            </Grid>
            <Box component="div">
              <IconButton onClick={(e) => handleDelete(index)}>
                <DeleteIcon />
              </IconButton>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default AdditionalLicenses;
