import { CardElement } from "@stripe/react-stripe-js";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { TextField } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import StripeInput from "./StripeInput";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import utils from "../../helpers/utils";
import { NavLink, Redirect, withRouter } from "react-router-dom";
import stripePaymentAction from "../../pages/auth/store/actions/stripePaymentAction";
import Alert from "../Alert";
import processingAction from "../../pages/auth/store/actions/processingAction";
import axios from "axios";
import updateAuthStatusAction from "../../pages/auth/store/actions/updateAuthStatusAction";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(8),
  },
  mainDescriptionWrapper: {
    marginBottom: theme.spacing(5),
  },
  textBold: {
    fontWeight: 700,
  },
  textCenter: {
    textAlign: "center",
  },
  label: {
    marginBottom: theme.spacing(1),
    display: "block",
  },
  inputGroup: {
    marginBottom: theme.spacing(3),
  },
  extraBottomMargin: {
    marginBottom: theme.spacing(4),
  },
  noDecoration: {
    textDecoration: "none",
    color: theme.textSeagreen,
    cursor: "pointer",
  },
  submitBtnWrapper: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  orderSummaryWrapper: {
    marginTop: theme.spacing(3),
    width: "100%",
  },
  orderItem: {
    display: "flex",
    justifyContent: "space-between",
    padding: theme.spacing(2),
  },
  itemHeading: {
    fontWeight: "600",
    display: "block",
  },
  itemDate: {
    fontSize: "14px",
    color: "#888",
  },
  itemPrice: {
    fontWeight: 700,
    fontSize: "18px",
  },
  bgLight: {
    backgroundColor: "#f0f2f4",
  },
  bgSecondaryLight: {
    backgroundColor: "#99d9ea6b",
  },
  textSecondary: {
    color: theme.palette.secondary.main,
  },
  promoCodeWrapper: {
    marginTop: theme.spacing(2),
  },
  addPromoCode: {
    cursor: "pointer",
    fontWeight: 700,
  },
  smMarginTop: {
    marginTop: theme.spacing(5),
  },
  btnContained: {
    backgroundColor: theme.backgroundSeagreen,
    color: "#fff",
    "&:hover": {
      backgroundColor: theme.backgroundSeagreenDark,
    },
  },
}));

const StripeForm = (props) => {
  const classes = useStyles();

  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const [stripeError, setStripeError] = useState(false);
  const [stripeErrorMesssage, setStripeErrorMessage] = useState("");
  const [hasError, setHasError] = useState(false);
  const [amount] = useState(props.product ? props.product.amount : 30);
  const [found, setFound] = useState(true);
  const [accountChecked, setAccountChecked] = useState(false);
  const [formData, setFormData] = useState({
    cardHolderName: "",
    // postalCode: "",
    email: "",
  });

  useEffect(() => {
    if (
      typeof props.match.params.patientId !== "undefined" &&
      !accountChecked
    ) {
      getAccount();
    }
  });

  const getAccount = async () => {
    try {
      await axios({
        url: `${process.env.REACT_APP_SERVER_URL}/public/accounts/${props.match.params.patientId}`,
      });

      setAccountChecked(true);
    } catch (err) {
      setAccountChecked(true);
      setFound(false);
    }
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const isValidated = () => {
    for (let key in formData) {
      if (typeof formData[key] !== "undefined" && !formData[key]) {
        setHasError(true);
        return false;
      }
    }
    return true;
  };

  const handleCardChange = (e) => {
    if (typeof e.error === "undefined") {
      setStripeError(false);
      setStripeErrorMessage("");
    } else {
      setStripeError(true);
      setStripeErrorMessage(e.error.message);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isValidated()) return;

    await props.setProcessing(true);

    let authId = null;

    if (props.user && typeof props.user.sub !== "undefined") {
      authId = props.user.sub;
    } else {
      authId = `auth0|${props.match.params.patientId}`;
    }

    const { stripe, elements } = props;
    stripe
      .createPaymentMethod({
        type: "card",
        card: elements.getElement(CardElement),
      })
      .then(({ paymentMethod }) => {
        props.payNow(
          `${process.env.REACT_APP_SERVER_URL}/public/create/subscription`,
          {
            cardHolderName: formData.cardHolderName,
            paymentMethodId: paymentMethod.id,
            email: formData.email,
            authId,
          },
          function (response) {
            if (typeof props.match.params.patientId !== "undefined") {
              if (response) {
                props.history.push(
                  `${
                    props.location.pathname
                  }?id=${response.subscriptionId.replace("sub_", "")}`
                );
              } else {
                props.history.push(`${props.location.pathname}?status=error`);
              }
            } else {
              if (props.wasAuthenticated) {
                props.updateAuthStatus();
              }
            }
          }
        );
      })
      .catch((error) => {
        if (typeof props.match.params.patientId !== "undefined") {
          props.history.push(`${props.location.pathname}?status=error`);
        }
      });
  };

  if (typeof props.match.params.patientId !== "undefined" && !found) {
    return <Redirect to="/not-found" />;
  } else if (typeof props.match.params.patientId === "undefined") {
    if (props.isAuthenticated && props.wasAuthenticated) {
      return <Redirect to="/account" />;
    } else if (!props.isAuthenticated && !props.stepOneDone) {
      return <Redirect to="/individual/signup" />;
    } else if (
      !props.isAuthenticated &&
      props.stepOneDone &&
      !props.stepTwoDone
    ) {
      return <Redirect to="/individual/signup/account-info" />;
    }
  }

  return (
    <div className={classes.root}>
      {props.status ? (
        <Alert status={props.status} message={props.message} />
      ) : null}
      <div className={classes.mainDescriptionWrapper}>
        <Typography
          variant="h5"
          className={`${classes.textBold} ${classes.textCenter}`}
        >
          Start your Tova Prime membership
        </Typography>
      </div>
      <form onSubmit={handleSubmit}>
        <Grid
          container
          spacing={sm ? 0 : 10}
          direction={sm ? "column-reverse" : "row"}
        >
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <div className={sm ? classes.smMarginTop : ""}>
              <Typography
                className={`${classes.textBold} ${classes.extraBottomMargin}`}
                variant="h6"
              >
                Payment details
              </Typography>
              <div
                className={`${classes.inputGroup} ${classes.inputGroupExtraTopMargin}`}
              >
                <label className={classes.label}>Cardholder Name</label>
                <TextField
                  onChange={handleChange}
                  name="cardHolderName"
                  label=""
                  variant="filled"
                  fullWidth
                  error={hasError && !formData.cardHolderName.length}
                  value={formData.cardHolderName}
                  helperText={
                    hasError && !formData.cardHolderName.length
                      ? "Required"
                      : ""
                  }
                />
              </div>
              <div
                className={`${classes.inputGroup} ${classes.inputGroupExtraTopMargin}`}
              >
                <label className={classes.label}>Email</label>
                <TextField
                  onChange={handleChange}
                  name="email"
                  label=""
                  variant="filled"
                  fullWidth
                  error={hasError && !formData.email.length}
                  value={formData.email}
                  helperText={
                    hasError && !formData.email.length ? "Required" : ""
                  }
                />
              </div>
              <div
                className={`${classes.inputGroup} ${classes.inputGroupExtraTopMargin}`}
              >
                <label className={classes.label}>Card</label>
                <TextField
                  error={stripeError}
                  helperText={stripeErrorMesssage}
                  variant="filled"
                  required
                  fullWidth
                  onChange={handleCardChange}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    inputComponent: StripeInput,
                    inputProps: {
                      component: CardElement,
                    },
                  }}
                />
              </div>
              {/* <div
                className={`${classes.inputGroup} ${classes.inputGroupExtraTopMargin}`}
              >
                <label className={classes.label}>Postal Code</label>
                <TextField
                  onChange={handleChange}
                  name="postalCode"
                  label=""
                  variant="filled"
                  fullWidth
                  error={hasError && !formData.postalCode.length}
                  value={formData.postalCode}
                  helperText={
                    hasError && !formData.postalCode.length ? "Required" : ""
                  }
                />
              </div> */}

              <div
                className={`${classes.inputGroup} ${classes.inputGroupExtraTopMargin}`}
              >
                <p>
                  By purchasing a subscription, you will be charged{" "}
                  <strong>$30</strong> per month starting{" "}
                  {utils.getFormattedDate(null, "MMMM D, YYYY")}. You can cancel
                  your subscription at any time, either through your account or
                  by contacting us at{" "}
                  <a
                    className={classes.noDecoration}
                    href={`mailto:${process.env.REACT_APP_TOVA_SUPPORT_EMAIL}`}
                  >
                    {process.env.REACT_APP_TOVA_SUPPORT_EMAIL}
                  </a>
                </p>
              </div>
              <div
                className={`${classes.inputGroup} ${classes.inputGroupExtraTopMargin}`}
              >
                <span>By continuing, you agree to Tova's </span>{" "}
                <NavLink className={classes.noDecoration} to="/terms">
                  Terms of Use
                </NavLink>
                .
                {/* , including the{" "}
                <NavLink
                  className={classes.noDecoration}
                  to="/premium-services-subscription-agreement"
                >
                  Premium Services Subscription Agreement
                </NavLink>
                . */}
              </div>
              <div className={classes.submitBtnWrapper}>
                <Button
                  variant="contained"
                  type="submit"
                  fullWidth
                  className={classes.btnContained}
                  disabled={props.isProcessing}
                >
                  {props.isProcessing
                    ? "Processing"
                    : "Start Your Prime Membership"}
                </Button>
              </div>
            </div>
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <div className={classes.paymentFormWrapper}>
              <Typography className={classes.textBold} variant="h6">
                Order summary
              </Typography>
              <div className={classes.orderSummaryWrapper}>
                <div className={`${classes.orderItem} ${classes.bgLight}`}>
                  <div className={classes.itemInfoWrapper}>
                    <span className={classes.itemHeading}>Membership fee</span>
                    <span className={classes.itemDate}>
                      Next charge on{" "}
                      {utils.getFormattedDate(null, "MMMM D, YYYY", {
                        month: 1,
                      })}
                    </span>
                  </div>
                  <div className={classes.priceWrapper}>
                    <span className={classes.itemPrice}>{`$${amount}`}</span>
                  </div>
                </div>
                <div
                  className={`${classes.orderItem} ${classes.bgSecondaryLight}`}
                >
                  <div className={classes.itemInfoWrapper}>
                    <span className={classes.itemHeading}>Today's total</span>
                  </div>
                  <div className={classes.priceWrapper}>
                    <span className={classes.itemPrice}>{`$${amount}`}</span>
                  </div>
                </div>
                <div className={classes.promoCodeWrapper}>
                  <span
                    className={`${classes.addPromoCode} ${classes.textSecondary}`}
                  >
                    + Add promo code
                  </span>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isProcessing: state.auth0.isProcessing,
    status: state.auth0.status,
    message: state.auth0.message,
    user: state.auth0.user,
    wasAuthenticated: state.auth0.wasAuthenticated,
    product: state.auth0.product,
    stepOneDone: state.auth0.stepOneDone,
    stepTwoDone: state.auth0.stepTwoDone,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    payNow: (url, formData, cb) =>
      dispatch(stripePaymentAction(url, formData, cb)),
    setProcessing: (status) => dispatch(processingAction(status)),
    updateAuthStatus: () => dispatch(updateAuthStatusAction()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(StripeForm));
