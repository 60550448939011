import React, { useEffect } from "react";

const BeaconSDK = () => {
  useEffect(() => {
    if (window?.Beacon) {
      // console.log("Initializing Beacon");
    } else {
      // console.log("Beacon initialzation failed");
    }
  });
  return (
    <>
      {/* Initializing Beacon SDK */}
      <div>
        <script type="text/javascript">
          {window?.Beacon("init", "362f70d3-572c-4f7b-84b2-dbb3eba38203")}
        </script>
      </div>
    </>
  );
};

export default BeaconSDK;
