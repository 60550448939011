import BankIcon from "@material-ui/icons/AccountBalanceOutlined";
import CardIcon from "@material-ui/icons/CreditCardOutlined";
import AlertMessage from "@material-ui/lab/Alert";
import { AlertTitle } from "@material-ui/lab";
import { Button, Grid, makeStyles, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/AddOutlined";
import TrashIcon from "@material-ui/icons/DeleteOutlineOutlined";
import { useRef, useState } from "react";
import utils from "../helpers/utils";
import Alert from "./Alert";
import CircularProgressBar from "./CircularProgressBar";
import axios from "axios";
import { NavLink, withRouter } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  iconWrapper: {
    paddingRight: theme.spacing(1),
  },

  item: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    backgroundColor: "#eeeeee61",
    padding: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  innerWrapper: {
    display: "flex",
  },
  bankName: {
    fontSize: "22px",
    fontWeight: 600,
    display: "flex",
    alignItems: "center",
  },
  routingNumber: {
    marginRight: theme.spacing(2),
  },
  status: {
    fontSize: "14px",
    marginLeft: theme.spacing(1),
  },
  success: {
    color: "green",
  },
  alertTitle: {
    fontWeight: "bold",
  },
  alert: {
    marginBottom: theme.spacing(3),
  },
  smallHeading: {
    fontWeight: "bold",
    fontSize: "1.2rem",
  },
  inputGroup: {
    marginBottom: theme.spacing(4),
  },
  p: {
    marginTop: theme.spacing(1),
  },
  label: {
    display: "block",
  },
  addFileLink: {
    color: theme.palette.primary.main,
    display: "flex",
    alignItems: "center",
    fontWeight: 700,
    cursor: "pointer",
  },
  marginTop: {
    marginTop: theme.spacing(6),
  },
  icon: {
    fontSize: "25px",
  },
  inputField: {
    display: "none",
  },
  buttonWrapper: {
    marginTop: theme.spacing(3),
    textAlign: "right",
  },
  fileInfo: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#f5f5f5",
    padding: theme.spacing(1),
    borderRadius: "3px",
  },
  statusMessage: {
    color: "red",
    fontSize: "0.9rem",
  },
  error: {
    backgroundColor: "#fdecea",
    border: "solid 1px red",
  },
  infoWrapper: {
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
  },
  fieldInfoMargin: {
    marginBottom: theme.spacing(2),
  },
  fileName: {
    fontWeight: "bold",
    fontSize: "0.9rem",
  },
  smallFont: {
    fontSize: "12px",
  },
  trashIcon: {
    color: "#888",
    cursor: "pointer",
    "&:hover": {
      color: "#333",
    },
  },
  progressButtonWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  uploadingLabel: {
    marginLeft: theme.spacing(2),
  },
  bankIcon: {
    fontSize: "50px",
    color: "#666",
  },
  cardNumber: {
    position: "relative",
    left: "-15px",
    fontSize: "12px",
  },
  linkWrapper: {
    display: "flex",
    justifyContent: "center",
    marginBottom: theme.spacing(2),
    "& a": {
      textDecoration: "none",
      margin: "0 5px",
    },
    "& .MuiSvgIcon-root": {
      fontSize: "23px",
    },
  },
}));

const ExternalAccounts = (props) => {
  const classes = useStyles();

  const frontInputRef = useRef();
  const backInputRef = useRef();
  const ownershipInputRef = useRef();

  const [isProcessing, setIsProcessing] = useState(false);
  const [progress, setProgress] = useState(0);
  const [status, setStatus] = useState("");
  const [message, setMessage] = useState("");
  const [alertKey, setAlertKey] = useState("");
  const [frontError, setFrontError] = useState(null);
  const [backError, setBackError] = useState(null);
  const [documentErrors, setDocumentErrors] = useState(null);
  const [formData, setFormData] = useState({
    front: null,
    back: null,
    documents: null,
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    const obj = utils.getVerificationTypes(props.account);

    if (obj.bankAccountOwnershipVerification) {
      if (!formData.documents) {
        setAlertKey(utils.uuid());
        setStatus("error");
        setMessage("Please select required documents to upload");
        return;
      }
    }

    if (obj.identityVerification) {
      if (!formData.front || !formData.back) {
        setAlertKey(utils.uuid());
        setStatus("error");
        setMessage("Please select required documents to upload");
        return;
      }
    }

    setIsProcessing(true);

    let data = new FormData();

    data.append("front", formData.front);
    data.append("back", formData.back);

    for (let index in formData.documents) {
      data.append(`documents`, formData.documents[index]);
    }

    try {
      let response = await axios({
        url: `${process.env.REACT_APP_SERVER_URL}/account/verification`,
        method: "post",
        data: data,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          let percent = Math.round((loaded / total) * 100);
          setProgress(percent);
        },
      });

      setProgress(100);
      setIsProcessing(false);
      setStatus(response.data.status);
      setMessage(response.data.message);
      props.history.push("/get/paid");
    } catch (error) {
      setIsProcessing(false);
      if (typeof error.response !== "undefined") {
        setStatus(error.response.data.status);
        setMessage(error.response.data.message);
      }
    }
  };

  const onClose = () => {
    setStatus("");
    setMessage("");
  };

  if (!props.account) {
    return null;
  }

  const verifications = utils.getVerificationTypes(props.account);

  return (
    <div className={classes.root}>
      {props.account &&
      (verifications.bankAccountOwnershipVerification ||
        verifications.identityVerification) ? (
        <form onSubmit={handleSubmit}>
          <div className={classes.statusWrapper}>
            <AlertMessage className={classes.alert} severity="error">
              <AlertTitle className={classes.alertTitle}>
                Verification required
              </AlertTitle>
              Please upload the required documents to complete the verification
              process.
            </AlertMessage>

            {status && message ? (
              <Alert
                key={alertKey}
                onClose={onClose}
                status={status}
                message={message}
              />
            ) : null}

            <Grid container spacing={3}>
              {!verifications.identityVerification ? null : (
                <>
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <div className={classes.inputGroup}>
                      <Typography className={classes.smallHeading} variant="h6">
                        Identity Verification
                      </Typography>
                      <p className={classes.p}>
                        The front and back of an identifying document, either a
                        passport or local ID card. The uploaded file needs to be
                        a color image (smaller than 8,000px by 8,000px), in JPG
                        or PNG, or PDF format, and less than 10 MB in size.
                      </p>
                    </div>
                  </Grid>
                  <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
                    {formData.front ? (
                      <div className={classes.fieldInfoWrapper}>
                        <div
                          className={`${classes.fileInfo} ${
                            frontError ? classes.error : ""
                          }`}
                        >
                          <div className={classes.infoWrapper}>
                            <Typography className={classes.fileName}>
                              {formData.front.name}
                            </Typography>
                            <div className={classes.smallFont}>
                              {utils.getFileSize(formData.front)}
                            </div>
                          </div>
                          <div className={classes.action}>
                            <TrashIcon
                              onClick={(e) => {
                                setFrontError(null);
                                setFormData({ ...formData, front: null });
                              }}
                              className={classes.trashIcon}
                            />
                          </div>
                        </div>
                        {frontError ? (
                          <Typography
                            className={`${classes.statusMessage} ${classes.errorMessage}`}
                          >
                            {frontError}
                          </Typography>
                        ) : null}
                      </div>
                    ) : (
                      <div className={classes.fielGroup}>
                        <span
                          className={classes.addFileLink}
                          onClick={(e) => frontInputRef.current.click()}
                        >
                          <AddIcon className={classes.icon} /> Select document
                          front
                        </span>
                        <input
                          type="file"
                          ref={frontInputRef}
                          className={classes.inputField}
                          onChange={(e) => {
                            const file = e.target.files[0];
                            const isValidSize = utils.hasValidSize(file);
                            const isValidType = utils.isValidFile(file);

                            if (!isValidSize) {
                              setFrontError("File size should be under 10 MB");
                            } else if (!isValidType) {
                              setFrontError(
                                "Only jpg, png & pdf files are accepted"
                              );
                            } else {
                              setFrontError(null);
                            }

                            setFormData({
                              ...formData,
                              front: file,
                            });
                          }}
                        />
                      </div>
                    )}
                  </Grid>
                  <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
                    {formData.back ? (
                      <div className={classes.fieldInfoWrapper}>
                        <div
                          className={`${classes.fileInfo} ${
                            backError ? classes.error : ""
                          }`}
                        >
                          <div className={classes.infoWrapper}>
                            <Typography className={classes.fileName}>
                              {formData.back.name}
                            </Typography>
                            <div className={classes.smallFont}>
                              {utils.getFileSize(formData.back)}
                            </div>
                          </div>
                          <div className={classes.action}>
                            <TrashIcon
                              onClick={(e) => {
                                setBackError(null);
                                setFormData({ ...formData, back: null });
                              }}
                              className={classes.trashIcon}
                            />
                          </div>
                        </div>
                        {backError ? (
                          <Typography
                            className={`${classes.statusMessage} ${classes.errorMessage}`}
                          >
                            {backError}
                          </Typography>
                        ) : null}
                      </div>
                    ) : (
                      <div className={classes.fielGroup}>
                        <span
                          className={classes.addFileLink}
                          onClick={(e) => backInputRef.current.click()}
                        >
                          <AddIcon className={classes.icon} /> Select document
                          back
                        </span>
                        <input
                          type="file"
                          ref={backInputRef}
                          className={classes.inputField}
                          onChange={(e) => {
                            const file = e.target.files[0];
                            const isValidSize = utils.hasValidSize(file);
                            const isValidType = utils.isValidFile(file);

                            if (!isValidSize) {
                              setBackError("File size should be under 10 MB");
                            } else if (!isValidType) {
                              setBackError(
                                "Only jpg, png & pdf files are accepted"
                              );
                            } else {
                              setBackError(null);
                            }

                            setFormData({
                              ...formData,
                              back: file,
                            });
                          }}
                        />
                      </div>
                    )}
                  </Grid>
                </>
              )}

              {!verifications.bankAccountOwnershipVerification ? null : (
                <>
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <div className={classes.inputGroup}>
                      <Typography
                        className={`${classes.smallHeading} ${classes.marginTop}`}
                        variant="h6"
                      >
                        Bank Account Ownership Verification
                      </Typography>
                      <p className={classes.p}>
                        One or more documents that support the Bank account
                        ownership verification requirement. Must be a document
                        associated with the account’s primary active bank
                        account that displays the last 4 digits of the account
                        number, either a statement or a voided check. The file
                        formats can be JPG or PNG or PDF.
                      </p>
                    </div>
                  </Grid>
                  <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                    {formData.documents ? (
                      formData.documents.map((document, key) => {
                        return (
                          <div
                            key={key}
                            className={`${classes.fieldInfoWrapper} ${classes.fieldInfoMargin}`}
                          >
                            <div
                              className={`${classes.fileInfo} ${
                                documentErrors[key] ? classes.error : ""
                              }`}
                            >
                              <div className={classes.infoWrapper}>
                                <Typography className={classes.fileName}>
                                  {document.name}
                                </Typography>
                                <div className={classes.smallFont}>
                                  {utils.getFileSize(document)}
                                </div>
                              </div>
                              <div className={classes.action}>
                                <TrashIcon
                                  onClick={(e) => {
                                    const errors = documentErrors;

                                    if (errors) {
                                      errors[key] = null;
                                      setDocumentErrors(errors);
                                    }

                                    let tempDocuments = formData.documents;

                                    tempDocuments.splice(key, 1);

                                    if (!tempDocuments.length) {
                                      tempDocuments = null;
                                    }

                                    setFormData({
                                      ...formData,
                                      documents: tempDocuments,
                                    });
                                  }}
                                  className={classes.trashIcon}
                                />
                              </div>
                            </div>
                            {documentErrors ? (
                              <Typography
                                className={`${classes.statusMessage} ${classes.errorMessage}`}
                              >
                                {documentErrors[key]}
                              </Typography>
                            ) : null}
                          </div>
                        );
                      })
                    ) : (
                      <div className={classes.inputGroup}>
                        <span
                          className={classes.addFileLink}
                          onClick={(e) => {
                            ownershipInputRef.current.click();
                          }}
                        >
                          <AddIcon className={classes.icon} /> Select one or
                          more documents
                        </span>
                        <input
                          type="file"
                          multiple
                          ref={ownershipInputRef}
                          className={classes.inputField}
                          onChange={(e) => {
                            const files = e.target.files;
                            let file = null;
                            const errors = [];
                            const tempFiles = [];
                            const length = files.length - 1;

                            for (let index in files) {
                              if (!(index <= length)) continue;

                              file = files[index];

                              const isValidSize = utils.hasValidSize(file);
                              const isValidType = utils.isValidFile(file);

                              if (!isValidSize) {
                                errors.splice(
                                  index,
                                  0,
                                  "File size should be under 10 MB"
                                );
                              } else if (!isValidType) {
                                errors.splice(
                                  index,
                                  0,
                                  "Only jpg, png & pdf files are accepted"
                                );
                              } else {
                                errors.splice(index, 0, null);
                              }
                              tempFiles.splice(index, 0, file);
                            }

                            setDocumentErrors(errors);

                            setFormData({
                              ...formData,
                              documents: tempFiles,
                            });
                          }}
                        />
                      </div>
                    )}
                  </Grid>
                </>
              )}
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <div className={classes.buttonWrapper}>
                  <Button
                    type="submit"
                    disabled={isProcessing}
                    variant="contained"
                    color="primary"
                  >
                    {isProcessing ? (
                      <div className={classes.progressButtonWrapper}>
                        <CircularProgressBar progress={progress} />{" "}
                        <div className={classes.uploadingLabel}>
                          {progress === 100
                            ? "Submitting Documents..."
                            : "Uploading"}
                        </div>
                      </div>
                    ) : (
                      "Upload"
                    )}
                  </Button>
                </div>
              </Grid>
            </Grid>
          </div>
        </form>
      ) : null}

      {!verifications.bankAccountOwnershipVerification &&
      !verifications.identityVerification &&
      props.account
        ? props.account.external_accounts.data.map((act) => {
            return (
              <div key={act.id} className={classes.item}>
                <div className={classes.innerWrapper}>
                  <div className={classes.iconWrapper}>
                    {act.object === "bank_account" ? (
                      <BankIcon className={classes.bankIcon} />
                    ) : (
                      <CardIcon className={classes.bankIcon} />
                    )}
                  </div>
                  <div className={classes.description}>
                    <div className={classes.bankName}>
                      {act.object === "bank_account"
                        ? act.bank_name
                        : act.brand}{" "}
                      <span className={`${classes.status} ${classes.success}`}>
                        Verified
                      </span>
                    </div>
                    <div className={classes.bankDetails}>
                      <span className={classes.routingNumber}>
                        {act.routing_number}
                      </span>
                      {act.object === "bank_account" ? (
                        <span className={classes.routingNumber}>
                          **** {act.last4}
                        </span>
                      ) : (
                        <span className={classes.cardNumber}>
                          Exp: {act.exp_month}/{act.exp_year} {"  "} ****{" "}
                          {act.last4}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className={classes.controls}></div>
              </div>
            );
          })
        : null}

      <p className={classes.linkWrapper}>
        Want to add a different{" "}
        <NavLink className={classes.addFileLink} to="/card/add">
          Debit Card
        </NavLink>{" "}
        or{" "}
        <NavLink className={classes.addFileLink} to="/bank/add">
          Bank Account
        </NavLink>{" "}
        instead?
      </p>
    </div>
  );
};

export default withRouter(ExternalAccounts);
