import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { useState } from "react";
import axios from "axios";
import utils from "../helpers/utils";
import Alert from "./Alert";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(5),
  },
  label: {
    marginBottom: theme.spacing(1),
    display: "block",
  },
  submitBtnWrapper: {
    marginTop: theme.spacing(5),
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  subHeading: {
    fontWeight: "bold",
    marginBottom: theme.spacing(3),
    fontSize: "20px",
  },
  sectionTopMargin: {
    marginTop: theme.spacing(5),
  },
}));

const AddPaymentMethodForm = (props) => {
  const classes = useStyles();

  const currentYear = utils.getFullYear();

  const years = [
    currentYear,
    currentYear + 1,
    currentYear + 2,
    currentYear + 3,
    currentYear + 4,
    currentYear + 5,
    currentYear + 6,
    currentYear + 7,
    currentYear + 8,
    currentYear + 9,
  ];
  const months = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
  ];

  const [isProcessing, setIsProcessing] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [status, setStatus] = useState("");
  const [message, setMessage] = useState("");
  // const [states, setStates] = useState(null);
  const [formData, setFormData] = useState({
    card: {
      expMonth: "",
      expYear: "",
      number: "",
      cvc: "",
    },
    billing: {
      name: "",
      email: "",
      phone: "",
      postal_code: "",
      country: "",
    },
  });

  // useEffect(() => {
  //   getStates();
  // }, []);

  // const getStates = async () => {
  //   try {
  //     const response = await axios({
  //       url: `${process.env.REACT_APP_SERVER_URL}/public/states`,
  //       method: "get",
  //     });

  //     setStates(response.data);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isValidated()) {
      setIsProcessing(true);

      let data = formData;

      try {
        const response = await axios({
          url: `${process.env.REACT_APP_SERVER_URL}/payment/methods`,
          method: "post",
          data,
        });

        if (typeof props.paymentMethodId !== "undefined") {
          props.paymentMethodId(response.data.id);
        }

        setIsProcessing(false);
        setStatus(response.data.status);
        setMessage(response.data.message);
      } catch (error) {
        setIsProcessing(false);
        setStatus(error.response.data.status);
        setMessage(error.response.data.message);
      }
    }
  };

  const isValidated = () => {
    for (let data in formData) {
      for (let key in formData[data]) {
        if (
          typeof formData[data][key] !== "undefined" &&
          !formData[data][key]
        ) {
          if (
            key === "email" &&
            !utils.isValidFormat(formData[data][key], key)
          ) {
            setHasError(true);
            return false;
          } else {
            setHasError(true);
            return false;
          }
        }
      }
    }
    return true;
  };

  return (
    <div className={classes.root}>
      {status ? <Alert status={status} message={message} /> : null}

      <form onSubmit={handleSubmit}>
        <Typography
          className={`${classes.subHeading} ${classes.sectionTopMargin}`}
          variant="h6"
        >
          Billing Details
        </Typography>

        <Grid container spacing={3}>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <div className={classes.inputGroup}>
              <label className={classes.label}>Cardholder Name</label>
              <TextField
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    billing: {
                      ...formData.billing,
                      [e.target.name]: e.target.value,
                    },
                  })
                }
                name="name"
                label=""
                variant="filled"
                fullWidth
                error={hasError && !formData.billing.name.length}
                value={formData.billing.name}
                helperText={
                  hasError && !formData.billing.name.length ? "Required" : ""
                }
              />
            </div>
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
            <div className={classes.inputGroup}>
              <label className={classes.label}>Email Address</label>
              <TextField
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    billing: {
                      ...formData.billing,
                      [e.target.name]: e.target.value,
                    },
                  })
                }
                name="email"
                label=""
                variant="filled"
                fullWidth
                error={hasError && !formData.billing.email.length}
                value={formData.billing.email}
                helperText={
                  hasError && !formData.billing.email.length ? "Required" : ""
                }
              />
            </div>
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
            <div className={classes.inputGroup}>
              <label className={classes.label}>Phone</label>
              <TextField
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    billing: {
                      ...formData.billing,
                      [e.target.name]: utils.formatPhone(e.target.value),
                    },
                  })
                }
                name="phone"
                label=""
                variant="filled"
                fullWidth
                error={hasError && !formData.billing.phone.length}
                value={formData.billing.phone}
                helperText={
                  hasError && !formData.billing.phone.length ? "Required" : ""
                }
              />
            </div>
          </Grid>

          <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
            <div className={classes.inputGroup}>
              <label className={classes.label}>Country</label>
              <TextField
                readOnly
                label=""
                variant="filled"
                fullWidth
                value={formData.billing.country}
              />
              <small>Only US cards can be added</small>
            </div>
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
            <div className={classes.inputGroup}>
              <label className={classes.label}>Zip</label>
              <TextField
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    billing: {
                      ...formData.billing,
                      [e.target.name]: e.target.value,
                    },
                  })
                }
                name="postal_code"
                label=""
                variant="filled"
                fullWidth
                error={hasError && !formData.billing.postal_code.length}
                value={formData.billing.postal_code}
                helperText={
                  hasError && !formData.billing.postal_code.length
                    ? "Required"
                    : ""
                }
              />
            </div>
          </Grid>

          <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
            <div className={classes.inputGroup}>
              <label className={classes.label}>Card Number</label>
              <TextField
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    card: {
                      ...formData.card,
                      [e.target.name]: utils.formatCard(e.target.value),
                    },
                  });
                }}
                name="number"
                label=""
                variant="filled"
                fullWidth
                error={hasError && !formData.card.number.length}
                value={formData.card.number}
                helperText={
                  hasError && !formData.card.number.length ? "Required" : ""
                }
              />
            </div>
          </Grid>
          <Grid item xl={2} lg={2} md={2} sm={6} xs={12}>
            <FormControl
              variant="filled"
              className={classes.formControl}
              fullWidth
              error={hasError && !formData.card.expMonth.length}
            >
              <label className={classes.label}>Expiry Month</label>
              <Select
                labelId="expiry-month-label"
                id="expiry-month"
                value={formData.card.expMonth}
                name="expMonth"
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    card: {
                      ...formData.card,
                      [e.target.name]: e.target.value,
                    },
                  })
                }
              >
                {months.map((month) => (
                  <MenuItem key={month} value={month}>
                    {month}
                  </MenuItem>
                ))}
              </Select>
              {hasError && !formData.card.expMonth.length ? (
                <FormHelperText>Required</FormHelperText>
              ) : null}
            </FormControl>
          </Grid>
          <Grid item xl={2} lg={2} md={2} sm={6} xs={12}>
            <FormControl
              variant="filled"
              className={classes.formControl}
              fullWidth
              error={hasError && !formData.card.expYear.length}
            >
              <label className={classes.label}>Expiry Year</label>
              <Select
                labelId="expiry-year-label"
                id="expiry-year"
                value={formData.card.expYear}
                name="expYear"
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    card: {
                      ...formData.card,
                      [e.target.name]: e.target.value,
                    },
                  })
                }
              >
                {years.map((year) => (
                  <MenuItem key={year} value={year}>
                    {year}
                  </MenuItem>
                ))}
              </Select>
              {hasError && !formData.card.expYear.length ? (
                <FormHelperText>Required</FormHelperText>
              ) : null}
            </FormControl>
          </Grid>
          <Grid item xl={2} lg={2} md={2} sm={6} xs={12}>
            <div className={classes.inputGroup}>
              <label className={classes.label}>CVC</label>
              <TextField
                onChange={(e) => {
                  let cvc = e.target.value.replace(/\D/g, "");

                  if (cvc && cvc.length < 5) {
                    setFormData({
                      ...formData,
                      card: {
                        ...formData.card,
                        [e.target.name]: cvc,
                      },
                    });
                  }
                }}
                name="cvc"
                label=""
                variant="filled"
                fullWidth
                error={hasError && !formData.card.cvc.length}
                value={formData.card.cvc}
                helperText={
                  hasError && !formData.card.cvc.length ? "Required" : ""
                }
              />
            </div>
          </Grid>
        </Grid>
        <div className={classes.submitBtnWrapper}>
          <Button
            variant="contained"
            type="submit"
            color="primary"
            disabled={isProcessing}
          >
            {isProcessing ? "Processing" : "Submit"}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default AddPaymentMethodForm;
