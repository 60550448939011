import { combineReducers } from 'redux';
import auth0Reducer from '../../pages/auth/store/reducers/auth0Reducer';


const appReducer = combineReducers({
    auth0: auth0Reducer,
})

const rootReducer = (state, action) => {
    return appReducer( state, action)
}

export default rootReducer; 