import { Container, Grid, Typography, useMediaQuery } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { NavLink } from "react-router-dom";
import facebookIcon from "../assets/images/icons/facebook.svg";
import twitterIcon from "../assets/images/icons/twitter.svg";
import linkedinIcon from "../assets/images/icons/linkedin.svg";
import instagramIcon from "../assets/images/icons/instagram.svg";
import Logo from "./Logo";
import utils from "../helpers/utils";
import BeaconSDK from "./Beacon/Beacon";
import AppStoreIcon from "./Icons/AppStoreIcon";
import GooglePlayIcon from "./Icons/GooglePlayIcon";

const useStyles = makeStyles((theme) => ({
  root: {
    //backgroundColor: theme.palette.primary.main,
    backgroundColor: "#fffaf4",
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(2),
  },
  innerWrapper: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.between("xs", "sm")]: {
      flexDirection: "column",
    },
  },
  horizontalNavWrapper: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(15),
    alignItems: "center",
    paddingRight: theme.spacing(6),
    [theme.breakpoints.down("md")]: {
      justifyContent: "flex-start",
      alignItems: "center !important",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: 0,
      flexDirection: "column",
      alignItems: "initial !important",
    },
  },
  horizontalNavLinks: {
    maxWidth: "59%",
    "& > a": {
      // color: "#000",
      color: "#000",
      marginRight: theme.spacing(3),
      textDecoration: "none",
      "&:hover": {
        // color: "#000",
        color: "#000",
      },
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        display: "block",
        margin: "10px 0 10px 0",
      },
      [theme.breakpoints.down("xs")]: {
        width: "100%",
        display: "block",
        margin: "0px 0 10px 0px",
      },
    },

    [theme.breakpoints.down("md")]: {
      paddingLeft: theme.spacing(2),
      flexDirection: "row",
    },
    [theme.breakpoints.down("sm")]: {
      paddingRight: theme.spacing(0),
      justifyContent: "flex-start",
    },
    [theme.breakpoints.down("xs")]: {
      padding: 0,
      margin: 0,
      justifyContent: "flex-start",
    },
  },
  marginLeft: {
    marginLeft: theme.spacing(0),
  },
  footerMainHeading: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(0),
    color: "#000",
    fontWeight: "bold",
    fontSize: "1.4rem",
    lineHeight: "35px",
  },
  stretch: {
    width: "100%",
    borderRadius: "5px",
  },
  appStoreLogo: {
    maxWidth: "150px",
  },
  brandInfo: {
    padding: 0,
    display: "flex",
    flexDirection: "column",
    //alignContent: "space-between",
    maxWidth: "370px",
    [theme.breakpoints.down("xs")]: {
      maxWidth: "auto",
    },
  },
  navigation: {
    padding: 0,
    margin: "30px 0 0 0",
    listStyle: "none",
    "& li": {
      margin: "10px 0",
    },
    "& a": {
      color: "#000",
      textDecoration: "none",
      "&:hover": {
        color: "#000",
      },
    },
    [theme.breakpoints.down("md")]: {
      margin: "30px 0 30px 20px",
    },
    [theme.breakpoints.down("xs")]: {
      margin: "30px 0 30px 0px",
    },
  },
  navigationH6: {
    fontSize: "18px",
    color: "#000",
    fontWeight: 600,
    [theme.breakpoints.down("md")]: {
      margin: "0 0 0 20px",
    },
    [theme.breakpoints.down("xs")]: {
      margin: "0 0 0 0",
    },
  },
  logo: {
    maxWidth: "200px",
    "& img": {
      width: "100%",
    },
  },
  p: {
    color: theme.paragraph.color,
    paddingLeft: "20px",
  },
  socialIcons: {
    display: "flex",
    paddingLeft: "20px",
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(0),
      maxWidth: "40%",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
      marginTop: theme.spacing(4),
      paddingLeft: "10px",
      marginBottom: "20px",
    },
    // [theme.breakpoints.down("xs")]: {
    //   width: "100%",
    //   marginTop: theme.spacing(4),
    // },
  },
  smallContainer: {
    [theme.breakpoints.down("xs")]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
  },
  icon: {
    width: "45px",
    height: "45px",
    borderRadius: "100%",
    border: `solid 1px #000`,
    display: "block",
    textAlign: "center",
    paddingTop: "10px",
    marginRight: "10px",
    color: "#000",
    "& img": {
      width: "20px",
      height: "20px",
    },
    "&:hover": {
      boxShadow: "0 3px 6px rgba(0,0,0,0.16)",
    },
  },
  copyright: {
    color: "#000",
    marginTop: "30px",
    marginBottom: "10px",
    fontSize: "1rem",
    [theme.breakpoints.down("xs")]: {
      marginBottom: "30px",
    },
  },
  flexGrowFull: {
    flexGrow: 1,
    width: "100%",
  },
}));

const Footer = () => {
  const classes = useStyles();

  const theme = useTheme();

  const md = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <footer className={`${classes.root} ${classes.smallContainer}`}>
      <Container fixed>
        <Grid container direction="row" align="flex-start" alignItems="center">
          <Grid item xl={4} lg={4} md={6} xs={12}>
            <div className={classes.brandInfo}>
              <Logo className={classes.logo} type="colored" width="130px" />
              <div>
                <Typography className={classes.footerMainHeading} variant="h6">
                  Get the Free App for Patients
                </Typography>
                <Grid
                  container
                  alignContent="stretch"
                  //className={classes.marginLeft}
                  spacing={1}
                >
                  <Grid item xs={12} sm={6}>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://apps.apple.com/in/app/tova-patient/id376771144"
                    >
                      <AppStoreIcon style={{ width: "150px" }} />
                    </a>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://play.google.com/store/apps/details?id=com.tovamedical.patient&pli=1"
                    >
                      <GooglePlayIcon style={{ width: "150px" }} />
                    </a>
                  </Grid>
                </Grid>
                <Typography className={classes.footerMainHeading} variant="h6">
                  Get the Free App for Doctors
                </Typography>
                <Grid
                  container
                  alignContent="stretch"
                  // className={classes.marginLeft}
                  spacing={1}
                >
                  <Grid item xs={12} sm={6}>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://apps.apple.com/us/app/tovahealth-for-doctors/id1601260310"
                    >
                      <AppStoreIcon style={{ width: "150px" }} />
                    </a>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://play.google.com/store/apps/details?id=com.tovamedical.doctor"
                    >
                      <GooglePlayIcon style={{ width: "150px" }} />
                    </a>
                  </Grid>
                </Grid>
              </div>
              <p className={classes.copyright}>
                Copyright &copy; {utils.getFullYear()} Tova Medical LLC
              </p>
            </div>
          </Grid>

          <Grid item xl={8} lg={8} md={12} xs={12}>
            <Grid
              container
              justify={md ? "flex-start" : "flex-end"}
              direction="row"
            >
              <Grid item xl={4} md={4} xs={12}>
                <Typography className={classes.navigationH6} variant="h6">
                  Product
                </Typography>
                <ul className={classes.navigation}>
                  <li>
                    <NavLink to="/#types-of-care">Types of care</NavLink>
                  </li>
                  <li>
                    <NavLink to="/#our-doctors">Our doctors</NavLink>
                  </li>
                  <li>
                    <NavLink to="/#what-we-dont-treat">
                      What we don't treat
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/#faq">FAQ</NavLink>
                  </li>
                </ul>
              </Grid>
              <Grid item xl={4} md={4} xs={12}>
                <Typography className={classes.navigationH6} variant="h6">
                  Company
                </Typography>
                <ul className={classes.navigation}>
                  <li>
                    <NavLink to="/about">About us</NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="#"
                      onClick={(e) => {
                        e.preventDefault();
                        window.Beacon("open");
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      Contact us
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/newsroom">Newsroom</NavLink>
                  </li>
                  <li>
                    <NavLink to="/investor/relations">
                      Investor Relations
                    </NavLink>
                  </li>
                  {/* <li>
                    <NavLink to="/for-patients">Security & privacy</NavLink>
                  </li> */}
                </ul>
              </Grid>
            </Grid>
            <div className={`${classes.horizontalNavWrapper}`}>
              <div className={classes.horizontalNavLinks}>
                <NavLink to="/terms">Terms</NavLink>
                <NavLink to="/privacy">Privacy</NavLink>
                <NavLink to="/cookies">Cookies</NavLink>
                <NavLink to="/termsmedical">Terms for Medical Experts</NavLink>
              </div>

              <div className={classes.socialIcons}>
                <a
                  target="_blank"
                  rel="noreferrer"
                  className={classes.icon}
                  href="https://www.facebook.com/TovaMedical"
                >
                  <img src={facebookIcon} alt="" />
                </a>
                <a
                  target="_blank"
                  rel="noreferrer"
                  className={classes.icon}
                  href="https://www.twitter.com/TovaMedical"
                >
                  <img src={twitterIcon} alt="" />
                </a>
                <a
                  target="_blank"
                  rel="noreferrer"
                  className={classes.icon}
                  href="https://www.linkedin.com/company/tovamedical"
                >
                  <img src={linkedinIcon} alt="" />
                </a>
                <a
                  target="_blank"
                  rel="noreferrer"
                  className={classes.icon}
                  href="https://www.instagram.com/TovaMedical"
                >
                  <img src={instagramIcon} alt="" />
                </a>
              </div>
            </div>
          </Grid>
        </Grid>
      </Container>
      <BeaconSDK />
    </footer>
  );
};

export default Footer;
