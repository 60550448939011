import {
  RESET_PASSWORD_IN_PROGRESS,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  DISMISS_STATUS_MESSAGE,
} from "./actionTypes";

import axios from "axios";

const resetPasswordAction = (url, data) => {
  return async (dispatch, getState) => {
    dispatch({
      type: RESET_PASSWORD_IN_PROGRESS,
    });

    if (!data.confirmPassword || !data.password) {
      dispatch({
        type: RESET_PASSWORD_ERROR,
        payload: {
          status: "error",
          message: "All fields are mandatory.",
        },
      });
    } else if (data.confirmPassword !== data.password) {
      dispatch({
        type: RESET_PASSWORD_ERROR,
        payload: {
          status: "error",
          message: "Mismatch new password and confirm password.",
        },
      });
    } else {
      try {
        let response = await axios({
          url,
          method: "POST",
          data,
        });
        if (response.data) {
          dispatch({
            type: RESET_PASSWORD_SUCCESS,
            payload: {
              user: response.data.user,
              auth: response.data.auth,
            },
          });
        }
      } catch (error) {
        if (typeof error.response.data !== "undefined") {
          dispatch({
            type: RESET_PASSWORD_ERROR,
            payload: {
              status: "error",
              message: error.response.data,
            },
          });
        }
      }
    }

    setTimeout(() => {
      dispatch({
        type: DISMISS_STATUS_MESSAGE,
      });
    }, 10000);
  };
};

export default resetPasswordAction;
