import React, { useState, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Error from "../Error";
import {
  Box,
  Grid,
  CircularProgress,
  IconButton,
  Tooltip,
  Avatar,
} from "@material-ui/core";
import Loading from "../../components/Loading";
import HttpError from "../../components/HttpError";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import axios from "axios";
import Rating from "@material-ui/lab/Rating";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import Modal from "../../components/Modal";
import Alert from "../../components/Alert";
import { Divider } from "@material-ui/core";
import useHasPermission from "../../hooks/useHasPermission";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "calc(100vh - 110px)",
    paddingTop: theme.spacing(30),
  },
  heading: {
    fontSize: "16px",
    color: "#939393",
  },
  contentSection: {
    boxShadow: theme.section.boxShadow,
    borderRadius: theme.section.borderRadius,
    backgroundColor: "#fff",
    padding: theme.spacing(4),
  },

  divider: {
    height: "4px",
    marginTop: theme.spacing(1),
    width: theme.spacing(5),
    backgroundColor: theme.palette.primary.main,
  },
  headingWrapper: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(5),
  },
  navLink: {
    color: "#000",
  },
  icon: {
    fontSize: "2.5rem",
    marginRight: theme.spacing(2),
  },
  table: {
    minWidth: 1000,
  },
  tableHeading: {
    fontWeight: "bold",
    letterSpacing: "1px",
  },
  iconWrapper: {
    width: theme.spacing(13),
  },

  textCenter: {
    textAlign: "center !important",
  },
  textRight: {
    textAlign: "right !important",
  },
  ratingWrapper: {
    padding: 0,
    marginTop: 10,
    display: "flex",
  },
  ratingContentWrapper: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    paddingLeft: 20,
  },
  padding0: {
    padding: 0,
  },
  deleteIcon: {
    cursor: "pointer",
  },
  reviewerName: {
    fontWeight: "bold",
    fontSize: 18,
    textTransform: "capitalize",
  },
  reviewText: {
    width: "100%",
    // wordBreak: "break-all",
    textAlign: "justify",
    marginBottom: 5,
    marginTop: 10,
  },
  nameAndRatingWrapper: {
    marginLeft: 10,
  },
}));

const tempReviews = [
  {
    id: 1,
    rating: "4",
    review:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    createdAt: new Date(),
    updatedAt: new Date(),
    subPatient: {
      id: 12,
      firstName: "ali",
      lastName: "last name",
      gender: "male",
      dob: "date",
      phoneCountryCode: "+1",
      phoneNumber: "9090909",
      profilePic: null,
      pin: "123",
      isDeleted: false,
    },
  },
  {
    id: 2,
    rating: "1",
    review: null,
    createdAt: new Date(),
    updatedAt: new Date(),
    subPatient: {
      id: 12,
      firstName: "brad",
      lastName: "last name",
      gender: "male",
      dob: "date",
      phoneCountryCode: "+1",
      phoneNumber: "9090909",
      profilePic: null,
      pin: "123",
      isDeleted: false,
    },
  },
  {
    id: 3,
    rating: "5",
    review: null,
    createdAt: new Date(),
    updatedAt: new Date(),
    subPatient: {
      id: 12,
      firstName: "cathrine",
      lastName: "last name",
      gender: "male",
      dob: "date",
      phoneCountryCode: "+1",
      phoneNumber: "9090909",
      profilePic: null,
      pin: "123",
      isDeleted: false,
    },
  },
];
const DoctorReviews = (props) => {
  document.body.style.backgroundColor = "#F7F7F7";

  const { id } = useParams();
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [reviews, setReviews] = useState(tempReviews);
  const [hasError, setHasError] = useState(false);
  const [open, setOpen] = useState(false);
  const [message] = useState("Are you sure to delete review?");
  const [reviewId, setReviewId] = useState(null);
  const [alertSettings, setAlertSettings] = useState({ status: false });
  const [isProcessing, setIsProcessing] = useState(false);
  const { hasPermission } = useHasPermission({ permission: "delete:review" });

  useEffect(() => {
    getDoctorReviews();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (alertSettings.status) {
      setTimeout(() => {
        setAlertSettings({ status: false });
      }, 3000);
    }
  }, [alertSettings?.status]);
  const getDoctorReviews = async () => {
    const res = await axios.post(
      `${process.env.REACT_APP_SERVER_URL}/review/get/by-doctor`,
      { doctorId: id }
    );
    setLoading(false);
    if (res.status === 200) setReviews(res.data);
    else setHasError(true);
  };

  const handleConfirmation = async () => {
    setOpen(false);
    let url = `${process.env.REACT_APP_SERVER_URL}/review/delete?id=${reviewId}`;
    setLoading(true);
    const res = await axios.delete(url);
    setLoading(false);
    if (res.status === 200) {
      let filteredReviews = reviews.filter((review) => review.id !== reviewId);
      setReviews(filteredReviews);
      setAlertValues(true, "success", "Review deleted successfully!");
    }
  };

  const deleteReview = (id) => {
    setOpen(true);
    setReviewId(id);
  };

  const setAlertValues = (status, type, message) => {
    setAlertSettings({ status, type, message });
  };

  if (loading) return <Loading />;
  if (hasError) return <Error code="401" />;

  return (
    <>
      <Grid
        container
        direction="row"
        className={classes.gridWrapper}
        style={{ marginTop: 40 }}
      >
        <div className={classes.headingWrapper}>
          <Typography variant="h5">
            Reviews
            <div className={classes.divider} />
          </Typography>
        </div>
        <Grid item xs={12}>
          <Box component="div" className={classes.contentSection}>
            {hasError ? (
              <HttpError code="404" />
            ) : (
              <div className={classes.contentWrapper}>
                {!reviews && !hasError ? (
                  <div
                    className={`${classes.textCenter} ${classes.loaderWrapper}`}
                  >
                    <CircularProgress size={30} />
                  </div>
                ) : null}

                {reviews?.map((review) => (
                  <Grid container spacing={2} key={review?.id}>
                    <Grid item xs={2} className={classes.ratingWrapper}>
                      {review.subPatient.profilePic ? (
                        <Avatar
                          alt={review?.subPatient?.firstName}
                          src={review?.subPatient?.profilePic}
                        />
                      ) : (
                        <Avatar>
                          {review?.subPatient?.firstName
                            ?.charAt(0)
                            ?.toUpperCase()}
                        </Avatar>
                      )}
                      <Box className={classes.nameAndRatingWrapper}>
                        <Typography className={classes.reviewerName}>
                          {review.subPatient.firstName}{" "}
                          {review.subPatient.lastName}
                        </Typography>
                        <Rating
                          name="simple-controlled"
                          value={Number(review?.rating)}
                          disabled
                          size="small"
                        />
                      </Box>
                    </Grid>

                    <Grid item xs={10}>
                      <Box display="flex">
                        <Typography className={classes.reviewText}>
                          {review.review || "No text available"}
                        </Typography>
                        {hasPermission && (
                          <Tooltip
                            title="Delete review"
                            style={{ height: 50 }}
                            onClick={() => deleteReview(review.id)}
                          >
                            <IconButton>
                              <DeleteForeverIcon
                                className={classes.deleteIcon}
                              />
                            </IconButton>
                          </Tooltip>
                        )}
                      </Box>
                    </Grid>
                    <Divider
                      style={{ width: "100%", margin: "40px 0" }}
                      color="primary"
                    />
                  </Grid>
                ))}
                {reviews.length === 0 && (
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography>No reviews availabe</Typography>
                    </Grid>
                  </Grid>
                )}
              </div>
            )}
          </Box>
        </Grid>
      </Grid>

      <Modal
        open={open}
        handleConfirmation={handleConfirmation}
        onClose={() => setOpen(false)}
        message={message}
        buttonTitle={"Delete"}
        isProcessing={isProcessing}
        setIsProcessing={setIsProcessing}
      />

      {alertSettings.status ? (
        <Alert status={alertSettings?.type} message={alertSettings?.message} />
      ) : null}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    isProcessing: state.auth0.isProcessing,
    isMember: state.auth0.isMember,
    authUser: state.auth0.user,
  };
};

export default connect(mapStateToProps)(DoctorReviews);
