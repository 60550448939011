import React, { useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Container, Grid, Box, useMediaQuery } from "@material-ui/core";

import utils from "../helpers/utils";
import { connect } from "react-redux";
// import TalkToADoctor from "../components/TalkToADoctor";
// import TypesOfCare from "../components/TypesOfCare";
import privacy_image from "../assets/images/privacy-shiled-white-2.png";
import VerticalMenu from "../components/VerticalMenu";
// images
import notification_img from "../assets/images/icons/Icons_set/notification.jpg";
import analytics_img from "../assets/images/icons/Icons_set/analytics.png";
import patient_img from "../assets/images/icons/Icons_set/Patients.png";
import doctor_img from "../assets/images/icons/Icons_set/doctors.png";
import doctor_approval_img from "../assets/images/icons/Icons_set/doctor_approval.png";
import { Link, Redirect } from "react-router-dom";
import UpdateEmailOrPassword from "./Doctors/UpdateEmailOrPassword";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "calc(100vh - 110px)",
    paddingTop: theme.spacing(30),
  },
  card: {
    boxShadow: "0 3px 60px rgba(0,0,0,0.16)",
    borderRadius: "5px",
    "&:hover": {
      boxShadow: "0 3px 20px rgba(0,0,0,0.30)",
    },
  },

  contentSection: {
    boxShadow: theme.section.boxShadow,
    borderRadius: theme.section.borderRadius,
    backgroundColor: "#fff",
    padding: theme.spacing(4),
  },
  label: {
    display: "block",
    fontSize: "12px",
    color: theme.paragraph.color,
  },
  value: {
    display: "block",
    fontWeight: 600,
    color: theme.paragraphDark.color,
  },
  infoWrapper: {
    marginTop: theme.spacing(3),
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  divider: {
    height: "4px",
    marginTop: theme.spacing(1),
    width: theme.spacing(5),
    backgroundColor: theme.palette.primary.main,
  },
  sectionHeading: {
    fontWeight: 600,
  },
  sectionMainHeading: {
    fontSize: "40px",
    fontWeight: 600,
    lineHeight: "55px",

    [theme.breakpoints.down("xs")]: {
      fontSize: "33px",
      lineHeight: "45px",
    },
  },
  sectionHeaderWrapper: {
    marginBottom: "50px",
    maxWidth: "75%",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "75%",
      marginLeft: "auto",
      marginRight: "auto",
      textAlign: "center !important",
    },
  },
  contentGridWrapper: {
    paddingBottom: "100px",
  },
  typesOfCareWrapper: {
    marginTop: "80px",
  },
  remoeTopPadding: {
    paddingTop: "150px",
  },
  adminPanelContainer: {
    flexGrow: 1,
  },
}));

const Account = (props) => {
  document.body.style.backgroundColor = "#F7F7F7";

  const classes = useStyles();

  const theme = useTheme();

  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const xs = useMediaQuery(theme.breakpoints.down("xs"));

  const [cards, setCards] = useState(panelCards);
  useEffect(() => {
    if (!utils.hasPermission(props.user, "write:doc")) {
      setCards((prev) => prev.filter((c) => c.text !== "Admins"));
    }
  }, [props?.user]);

  const { user } = props;
  const role = utils.getRole(user);

  if (role === "doctor" && !user.isApplicationAccepted) {
    return <Redirect to="/application-status" />;
  }
  if (role === "patient") {
    return <Redirect to="/account" />;
  }

  return (
    <Container
      maxWidth="lg"
      className={`${classes.root} ${
        // role === "patient" ? classes.remoeTopPadding : ""
        classes.remoeTopPadding
      }`}
    >
      {role === "patient" && (
        <Grid container direction="row" className={classes.gridWrapper}>
          {!sm && !xs ? (
            <Grid item xl={3} lg={3} md={3}>
              <VerticalMenu active="account" />
            </Grid>
          ) : null}

          <Grid item xl={9} lg={9} md={9} sm={12} xs={12}>
            <Box component="div" className={classes.contentSection}>
              {/* <Typography className={classes.sectionHeading} variant="h6">
                Settings
              </Typography> */}
              <div className={classes.divider} />
              <UpdateEmailOrPassword />
            </Box>
          </Grid>
        </Grid>
      )}

      {role?.includes("admin") && (
        <div className={classes.adminPanelContainer}>
          <Box>
            <Typography variant="h4" style={{ marginBottom: "20px" }}>
              Administration Panel
            </Typography>
          </Box>
          <Grid container spacing={4}>
            {cards.map((el, index) => (
              <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={index}>
                <Link
                  to={el.path}
                  style={{ textDecoration: "none", color: "var(--primary)" }}
                >
                  <Box
                    style={{ backgroundColor: "#fff", padding: "10px" }}
                    className={classes.card}
                  >
                    <Box style={{ textAlign: "center" }}>
                      <img
                        src={el.img ? el.img : privacy_image}
                        height="150px"
                        alt="panel_img"
                      />
                    </Box>
                    <Box>
                      <Typography
                        variant="subtitle1"
                        style={{ textAlign: "center", fontSize: ".8rem" }}
                      >
                        {el.text}
                      </Typography>
                    </Box>
                  </Box>
                </Link>
              </Grid>
            ))}
          </Grid>
        </div>
      )}

      {role?.includes("doctor") && (
        <Grid container direction="row" className={classes.gridWrapper}>
          {!sm && !xs ? (
            <Grid item xl={3} lg={3} md={3}>
              <VerticalMenu active="account" />
            </Grid>
          ) : null}

          <Grid item xl={9} lg={9} md={9} sm={12} xs={12}>
            <Box component="div" className={classes.contentSection}>
              <Typography className={classes.sectionHeading} variant="h6">
                Settings
              </Typography>
              <div className={classes.divider} />
              <UpdateEmailOrPassword />
            </Box>
          </Grid>
        </Grid>
      )}
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    isProcessing: state.auth0.isProcessing,
    user: state.auth0.user,
  };
};

export default connect(mapStateToProps)(Account);

const panelCards = [
  { text: "Support Tickets", img: "", path: "#" },
  { text: "Refunder Tool", img: "", path: "#" },
  { text: "Patient Lookup", img: patient_img, path: "/patients" },
  { text: "Doctor Lookup", img: doctor_img, path: "/doctors" },
  { text: "Analytics", img: analytics_img, path: "/analytics" },
  { text: "Approval Doctors", img: doctor_approval_img, path: "/applications" },
  {
    text: "In App Notification",
    img: notification_img,
    path: "/notifications",
  },
  { text: "Ban users", img: "", path: "#" },
  // { text: "Review Remover", img: "", path: "#" },
  { text: "Email Notification", img: notification_img, path: "#" },
  { text: "In App Patient Review", img: "", path: "#" },
  { text: "In App Doctor Review", img: "", path: "#" },
  { text: "Admins", img: "", path: "/admins" },
];
