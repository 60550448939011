import React from "react";
import {
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Chip,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import brainIcon from "../assets/images/icons/mental-and-behavioral-4.svg";
import onlineTherapyIcon from "../assets/images/icons/online-therapy-4.svg";
import childrenHealthIcon from "../assets/images/icons/childrens-care-5.svg";
import womensHealthIcon from "../assets/images/icons/womens-health-4.svg";
import mensHealthIcon from "../assets/images/icons/mens-health-4.svg";
import sexualHealthIcon from "../assets/images/icons/sexual-health-4.svg";
import chronicCareIcon from "../assets/images/icons/chronic-care-5.svg";
import wellnessIcon from "../assets/images/icons/wellness-prevention-family-4.svg";
import travelMedicineIcon from "../assets/images/icons/travel-medicine-4.svg";
import seniorHealthIcon from "../assets/images/icons/senior-health-4.svg";
import vaccineIcon from "../assets/images/icons/vaccine-4.svg";
import covidIcon from "../assets/images/icons/covid19-treatment-4.svg";
import { useAppContext } from "../context/AppContext";

const useStyles = makeStyles((theme) => ({
  sectionHeader: {
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
  },
  sectionHeading: {
    fontSize: "40px",
    fontWeight: 600,
    lineHeight: "55px",
    marginBottom: "30px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "33px",
      lineHeight: "45px",
    },
  },
  lessMarginBottom: {
    marginBottom: "-15px",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "75%",
      marginLeft: "auto",
      marginRight: "auto",
      textAlign: "center !important",
    },
  },
  sectionDescription: {
    maxWidth: "705px",
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: theme.spacing(7),
  },
  extraBottomMargin: {
    marginBottom: "30px",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "75%",
      marginLeft: "auto",
      marginRight: "auto",
      textAlign: "center !important",
    },
  },
  runningText: {
    lineHeight: "25px",
  },
  card: {
    boxShadow: "none",
    borderRadius: "5px",
    padding: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(1),
    },
    "&:hover": {
      boxShadow: "0 3px 20px rgba(0,0,0,0.30)",
    },
  },
  cardIconLarge: {
    width: "120px",
    height: "120px",
    [theme.breakpoints.down("xs")]: {
      width: "50px",
      height: "50px",
    },
  },
  cardIconSmall: {
    width: "70px",
    height: "70px",
    [theme.breakpoints.down("xs")]: {
      width: "50px",
      height: "50px",
    },
  },
  cardContent: {
    position: "relative",
    paddingBottom: 0,
  },
  contentWrapper: {
    display: "flex",
  },
  cardHeadingLarge: {
    fontSize: "23px",
    fontWeight: 600,
    color: theme.paragraphDark.color,
  },
  cardHeadingSmallBoxLarge: {
    fontSize: "20px",
    fontWeight: 600,
    marginBottom: 0,
    color: theme.paragraphDark.color,
  },
  cardTextWrapper: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  cardSmallRunningText: {
    fontSize: "15px",
    margin: 0,
    color: "#666",
  },
  cursorPointer: {
    cursor: "pointer",
  },
  shadow: {
    boxShadow: "0 3px 60px rgba(0,0,0,0.16)",
  },
  cardAction: {
    marginTop: 0,
    width: "100%",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  cardChip: {
    backgroundColor: "#4DD35C",
    color: "#fff",
    fontWeight: 600,
    marginLeft: "auto",
  },
  justifySpaceBetwen: {
    alignItems: "center",
    width: "100%",
  },
  cardDelText: {
    marginRight: theme.spacing(1),
    fontSize: "16px",
    color: "#666",
    fontWeight: 600,
  },
}));

const TypesOfCare = (props) => {
  const { notAvailable, openPopup } = useAppContext();
  const classes = useStyles();
  const { showUnavailablePopup } = props;
  const showPopup = typeof showUnavailablePopup !== "undefined" ? true : false;
  const redirectToSignup = () => {
    if (notAvailable && showPopup) {
      openPopup();
    } else {
      props.history.push("/individual/signup");
    }
  };
  return (
    <>
      <div className={classes.sectionHeader}>
        <Typography
          className={`${classes.sectionHeading} ${
            props.content.section1.align === `left`
              ? classes.lessMarginBottom
              : ""
          }`}
          align={props.content.section1.align}
          variant="h2"
        >
          {props.content.section1.heading}
        </Typography>
        <p
          className={`${
            props.content.section1.align === "center"
              ? classes.sectionDescription
              : classes.extraBottomMargin
          } ${classes.runningText}`}
          style={{ textAlign: props.content.section1.align }}
        >
          {props.content.section1.description}
        </p>
      </div>
      <Grid
        container
        direction="row"
        style={{ justifyContent: "center" }}
        spacing={1}
      >
        <Grid item xl={6} lg={6} md={6} sm={10} xs={12}>
          <Card
            onClick={redirectToSignup}
            className={`${classes.card} ${classes.shadow} ${classes.cursorPointer}`}
          >
            <CardContent className={classes.cardContent}>
              <div className={classes.contentWrapper}>
                <div className={classes.iconLargeWrapper}>
                  <CardMedia
                    className={classes.cardIconSmall}
                    image={womensHealthIcon}
                  />
                </div>
                <div className={classes.cardTextWrapper}>
                  <Typography
                    className={classes.cardHeadingSmallBoxLarge}
                    variant="h6"
                    gutterBottom
                  >
                    Women's health
                  </Typography>
                  <p className={`${classes.cardSmallRunningText}`}>
                    UTI, birth control, menopause, period problems, yeast
                    infections, skin and hair care.
                  </p>
                </div>
              </div>
            </CardContent>
            <CardActions className={classes.cardAction}>
              <div
                className={`${classes.contentWrapper} ${classes.justifySpaceBetwen}`}
              >
                <small className={classes.cardSmallText}>
                  Only available with Tova Prime
                </small>
                <Chip label="$39" className={classes.cardChip} />
              </div>
            </CardActions>
          </Card>
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={10} xs={12}>
          <Card
            onClick={redirectToSignup}
            className={`${classes.card} ${classes.shadow} ${classes.cursorPointer}`}
          >
            <CardContent className={classes.cardContent}>
              <div className={classes.contentWrapper}>
                <div className={classes.iconLargeWrapper}>
                  <CardMedia
                    className={classes.cardIconSmall}
                    image={childrenHealthIcon}
                  />
                </div>
                <div className={classes.cardTextWrapper}>
                  <Typography
                    className={classes.cardHeadingSmallBoxLarge}
                    variant="h6"
                    gutterBottom
                  >
                    Children's health
                  </Typography>
                  <p className={`${classes.cardSmallRunningText}`}>
                    Cold & flu symptoms, diarrhea or constipation, skin, rashes,
                    allergies.
                  </p>
                </div>
              </div>
            </CardContent>
            <CardActions className={classes.cardAction}>
              <div
                className={`${classes.contentWrapper} ${classes.justifySpaceBetwen}`}
              >
                <small className={classes.cardSmallText}>
                  Only available with Tova Prime
                </small>
                <Chip label="$39" className={classes.cardChip} />
              </div>
            </CardActions>
          </Card>
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={10} xs={12}>
          <Card
            onClick={redirectToSignup}
            className={`${classes.card} ${classes.shadow} ${classes.cursorPointer}`}
          >
            <CardContent className={classes.cardContent}>
              <div className={classes.contentWrapper}>
                <div className={classes.iconLargeWrapper}>
                  <CardMedia
                    className={classes.cardIconSmall}
                    image={mensHealthIcon}
                  />
                </div>
                <div className={classes.cardTextWrapper}>
                  <Typography
                    className={classes.cardHeadingSmallBoxLarge}
                    variant="h6"
                    gutterBottom
                  >
                    Men's health
                  </Typography>
                  <p className={`${classes.cardSmallRunningText}`}>
                    STI symptoms, erection issues, bladder or bowel issues, skin
                    and hair care.
                  </p>
                </div>
              </div>
            </CardContent>
            <CardActions className={classes.cardAction}>
              <div
                className={`${classes.contentWrapper} ${classes.justifySpaceBetwen}`}
              >
                <small className={classes.cardSmallText}>
                  Only available with Tova Prime
                </small>
                <Chip label="$39" className={classes.cardChip} />
              </div>
            </CardActions>
          </Card>
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={10} xs={12}>
          <Card
            onClick={redirectToSignup}
            className={`${classes.card} ${classes.shadow} ${classes.cursorPointer}`}
          >
            <CardContent className={classes.cardContent}>
              <div className={classes.contentWrapper}>
                <div className={classes.iconLargeWrapper}>
                  <CardMedia
                    className={classes.cardIconSmall}
                    image={sexualHealthIcon}
                  />
                </div>
                <div className={classes.cardTextWrapper}>
                  <Typography
                    className={classes.cardHeadingSmallBoxLarge}
                    variant="h6"
                    gutterBottom
                  >
                    Sexual health
                  </Typography>
                  <p className={`${classes.cardSmallRunningText}`}>
                    STI prevention or testing, erectile dysfunction, birth
                    control counseling.
                  </p>
                </div>
              </div>
            </CardContent>
            <CardActions className={classes.cardAction}>
              <div
                className={`${classes.contentWrapper} ${classes.justifySpaceBetwen}`}
              >
                <small className={classes.cardSmallText}>
                  Only available with Tova Prime
                </small>
                <Chip label="$39" className={classes.cardChip} />
              </div>
            </CardActions>
          </Card>
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={10} xs={12}>
          <Card
            onClick={redirectToSignup}
            className={`${classes.card} ${classes.shadow} ${classes.cursorPointer}`}
          >
            <CardContent className={classes.cardContent}>
              <div className={classes.contentWrapper}>
                <div className={classes.iconLargeWrapper}>
                  <CardMedia
                    className={classes.cardIconSmall}
                    image={chronicCareIcon}
                  />
                </div>
                <div className={classes.cardTextWrapper}>
                  <Typography
                    className={classes.cardHeadingSmallBoxLarge}
                    variant="h6"
                    gutterBottom
                  >
                    Chronic care
                  </Typography>
                  <p className={`${classes.cardSmallRunningText}`}>
                    Diabetes, high BP, allergies, high cholestrol,
                    gastroitestinal disorders, arthritis.
                  </p>
                </div>
              </div>
            </CardContent>
            <CardActions className={classes.cardAction}>
              <div
                className={`${classes.contentWrapper} ${classes.justifySpaceBetwen}`}
              >
                <small className={classes.cardSmallText}>
                  Only available with Tova Prime
                </small>
                <Chip label="$39" className={classes.cardChip} />
              </div>
            </CardActions>
          </Card>
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={10} xs={12}>
          <Card
            onClick={redirectToSignup}
            className={`${classes.card} ${classes.shadow} ${classes.cursorPointer}`}
          >
            <CardContent className={classes.cardContent}>
              <div className={classes.contentWrapper}>
                <div className={classes.iconLargeWrapper}>
                  <CardMedia
                    className={classes.cardIconSmall}
                    image={wellnessIcon}
                  />
                </div>
                <div className={classes.cardTextWrapper}>
                  <Typography
                    className={classes.cardHeadingSmallBoxLarge}
                    variant="h6"
                    gutterBottom
                  >
                    Wellness, prevention & lifestyle
                  </Typography>
                  <p className={`${classes.cardSmallRunningText}`}>
                    Weight counseling, skin and hair care, supplements, health
                    screenings, stress reduction.
                  </p>
                </div>
              </div>
            </CardContent>
            <CardActions className={classes.cardAction}>
              <div
                className={`${classes.contentWrapper} ${classes.justifySpaceBetwen}`}
              >
                <small className={classes.cardSmallText}>
                  Only available with Tova Prime
                </small>
                <Chip label="$39" className={classes.cardChip} />
              </div>
            </CardActions>
          </Card>
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={10} xs={12}>
          <Card
            onClick={redirectToSignup}
            className={`${classes.card} ${classes.shadow} ${classes.cursorPointer}`}
          >
            <CardContent className={classes.cardContent}>
              <div className={classes.contentWrapper}>
                <div className={classes.iconLargeWrapper}>
                  <CardMedia
                    className={classes.cardIconSmall}
                    image={travelMedicineIcon}
                  />
                </div>
                <div className={classes.cardTextWrapper}>
                  <Typography
                    className={classes.cardHeadingSmallBoxLarge}
                    variant="h6"
                    gutterBottom
                  >
                    Travel medicine
                  </Typography>
                  <p className={`${classes.cardSmallRunningText}`}>
                    Vaccinations and medications, pre-travel counseling,
                    post-travel care.
                  </p>
                </div>
              </div>
            </CardContent>
            <CardActions className={classes.cardAction}>
              <div
                className={`${classes.contentWrapper} ${classes.justifySpaceBetwen}`}
              >
                <small className={classes.cardSmallText}>
                  Only available with Tova Prime
                </small>
                <Chip label="$39" className={classes.cardChip} />
              </div>
            </CardActions>
          </Card>
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={10} xs={12}>
          <Card
            onClick={redirectToSignup}
            className={`${classes.card} ${classes.shadow} ${classes.cursorPointer}`}
          >
            <CardContent className={classes.cardContent}>
              <div className={classes.contentWrapper}>
                <div className={classes.iconLargeWrapper}>
                  <CardMedia
                    className={classes.cardIconSmall}
                    image={seniorHealthIcon}
                  />
                </div>
                <div className={classes.cardTextWrapper}>
                  <Typography
                    className={classes.cardHeadingSmallBoxLarge}
                    variant="h6"
                    gutterBottom
                  >
                    Senior health
                  </Typography>
                  <p className={`${classes.cardSmallRunningText}`}>
                    Muscle or joint pain, medication management, preventive
                    health screenings.
                  </p>
                </div>
              </div>
            </CardContent>
            <CardActions className={classes.cardAction}>
              <div
                className={`${classes.contentWrapper} ${classes.justifySpaceBetwen}`}
              >
                <small className={classes.cardSmallText}>
                  Only available with Tova Prime
                </small>
                <Chip label="$39" className={classes.cardChip} />
              </div>
            </CardActions>
          </Card>
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={10} xs={12}>
          <Card
            onClick={redirectToSignup}
            className={`${classes.card} ${classes.shadow} ${classes.cursorPointer}`}
          >
            <CardContent className={classes.cardContent}>
              <div className={classes.contentWrapper}>
                <div className={classes.iconLargeWrapper}>
                  <CardMedia
                    className={classes.cardIconSmall}
                    image={covidIcon}
                  />
                </div>
                <div className={classes.cardTextWrapper}>
                  <Typography
                    className={classes.cardHeadingSmallBoxLarge}
                    variant="h6"
                    gutterBottom
                  >
                    COVID-19 treatment
                  </Typography>
                  <p className={`${classes.cardSmallRunningText}`}>
                    Evaluation and medication for Covid-19, such as
                    hydroxychloroquine azithromycin, or ivermectin.
                  </p>
                </div>
              </div>
            </CardContent>
            <CardActions className={classes.cardAction}>
              <div
                className={`${classes.contentWrapper} ${classes.justifySpaceBetwen}`}
              >
                <small className={classes.cardSmallText}>
                  Only available with Tova Prime
                </small>
                <Chip label="$39" className={classes.cardChip} />
              </div>
            </CardActions>
          </Card>
        </Grid>

        <Grid item xl={6} lg={6} md={6} sm={10} xs={12}>
          <Card
            onClick={redirectToSignup}
            className={`${classes.card} ${classes.shadow} ${classes.cursorPointer}`}
          >
            <CardContent className={classes.cardContent}>
              <div className={classes.contentWrapper}>
                <div className={classes.iconLargeWrapper}>
                  <CardMedia
                    className={classes.cardIconSmall}
                    image={vaccineIcon}
                  />
                </div>
                <div className={classes.cardTextWrapper}>
                  <Typography
                    className={classes.cardHeadingSmallBoxLarge}
                    variant="h6"
                    gutterBottom
                  >
                    Vaccine or vaccine exemption
                  </Typography>
                  <p className={`${classes.cardSmallRunningText}`}>
                    Vaccine guidance or health evaluation and analysis for
                    vaccine excemption certificates. (Only available in select
                    states.)
                  </p>
                </div>
              </div>
            </CardContent>
            <CardActions className={classes.cardAction}>
              <div
                className={`${classes.contentWrapper} ${classes.justifySpaceBetwen}`}
              >
                <small className={classes.cardSmallText}>
                  Only available with Tova Prime
                </small>
                <Chip label="$39" className={classes.cardChip} />
              </div>
            </CardActions>
          </Card>
        </Grid>
      </Grid>

      <div className={`${classes.sectionHeader} ${classes.marginTop}`}>
        <br />
        <br />
        <br />
        <Typography
          align={props.content.section2.align}
          className={`${classes.sectionHeading} ${
            props.content.section1.align === `left`
              ? classes.lessMarginBottom
              : ""
          }`}
          variant="h2"
          style={{ marginBottom: "0 !important" }}
        >
          {props.content.section2.heading}
        </Typography>
        <p
          className={`${
            props.content.section2.align === "center"
              ? classes.sectionDescription
              : classes.extraBottomMargin
          } ${classes.runningText}`}
          style={{ textAlign: props.content.section2.align }}
        >
          {props.content.section2.description}
        </p>
      </div>
      <Grid
        container
        direction="row"
        style={{ justifyContent: "center" }}
        spacing={1}
      >
        <Grid item xl={6} lg={6} md={6} sm={10} xs={12}>
          <Card
            onClick={redirectToSignup}
            className={`${classes.card} ${classes.shadow} ${classes.cursorPointer}`}
          >
            <CardContent className={classes.cardContent}>
              <div className={classes.contentWrapper}>
                <div className={classes.iconLargeWrapper}>
                  <CardMedia
                    className={classes.cardIconSmall}
                    image={brainIcon}
                  />
                </div>
                <div className={classes.cardTextWrapper}>
                  <Typography
                    className={classes.cardHeadingSmallBoxLarge}
                    variant="h6"
                    gutterBottom
                  >
                    Mental & behavioral health
                  </Typography>
                  <p className={`${classes.cardSmallRunningText}`}>
                    Talk to primary care doctor about depression, sleep issues,
                    school or work stress.
                  </p>
                </div>
              </div>
            </CardContent>
            <CardActions className={classes.cardAction}>
              <div
                className={`${classes.contentWrapper} ${classes.justifySpaceBetwen}`}
              >
                <small className={classes.cardSmallText}>
                  Only available with Tova Prime
                </small>
                <Chip label="$39" className={classes.cardChip} />
              </div>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={10} xs={12}>
          <Card
            onClick={redirectToSignup}
            className={`${classes.card} ${classes.shadow} ${classes.cursorPointer}`}
          >
            <CardContent className={classes.cardContent}>
              <div className={classes.contentWrapper}>
                <div className={classes.iconLargeWrapper}>
                  <CardMedia
                    className={classes.cardIconSmall}
                    image={onlineTherapyIcon}
                  />
                </div>
                <div className={classes.cardTextWrapper}>
                  <Typography
                    className={classes.cardHeadingSmallBoxLarge}
                    variant="h6"
                    gutterBottom
                  >
                    Online therapy
                  </Typography>
                  <p className={`${classes.cardSmallRunningText}`}>
                    Ongoing video or text chat support with a licensed
                    therapist.
                  </p>
                </div>
              </div>
            </CardContent>
            <CardActions className={classes.cardAction}>
              <div
                className={`${classes.contentWrapper} ${classes.justifySpaceBetwen}`}
              >
                <small className={classes.cardSmallText}>
                  Only available with Tova Prime
                </small>
                <Chip label="$39" className={classes.cardChip} />
              </div>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default withRouter(TypesOfCare);
