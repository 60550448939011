import {
  GET_PRODUCT_SUCCESS,
  GET_PRODUCT_ERROR,
  DISMISS_STATUS_MESSAGE,
} from "./actionTypes";

import axios from "axios";

const getStripeProductAction = () => {
  return async (dispatch, getState) => {
    try {
      let response = await axios({
        url: `${process.env.REACT_APP_SERVER_URL}/public/get/product`,
        method: "GET",
      });

      dispatch({
        type: GET_PRODUCT_SUCCESS,
        payload: { product: response.data },
      });
    } catch (error) {
      dispatch({
        type: GET_PRODUCT_ERROR,
        payload: {
          status: "error",
          message: error.response.data,
        },
      });
    }

    setTimeout(() => {
      dispatch({
        type: DISMISS_STATUS_MESSAGE,
      });
    }, 10000);
  };
};

export default getStripeProductAction;
