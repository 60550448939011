import {
    UPDATE_PROCESSING_STATUS
} from './actionTypes';



const processingAction = ( processing  ) =>{
    return async ( dispatch, getState ) => {

        dispatch({
            type: UPDATE_PROCESSING_STATUS,
            payload: {
                processing: processing
            }
        });  
    }
}

export default processingAction;