import React, { useState, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { useAuth0 } from "@auth0/auth0-react";
import Error from "./Error";
import {
  Box,
  Container,
  Grid,
  // useMediaQuery,
  Chip,
  CircularProgress,
  Button,
  IconButton,
} from "@material-ui/core";
import utils from "../helpers/utils";
import Loading from "../components/Loading";
import HttpError from "../components/HttpError";
import { Redirect } from "react-router";
import { connect } from "react-redux";
import axios from "axios";
import { NavLink, useHistory, useParams } from "react-router-dom";
import Modal from "../components/Modal";
import Alert from "../components/Alert";
import ArrowBackIcon from "@material-ui/icons/ArrowBackOutlined";
import ArrowForwardIcon from "@material-ui/icons/ArrowForwardOutlined";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "calc(100vh - 110px)",
    paddingTop: theme.spacing(30),

    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(20),
    },
  },
  contentSection: {
    boxShadow: theme.section.boxShadow,
    borderRadius: theme.section.borderRadius,
    backgroundColor: "#fff",
    padding: theme.spacing(4),
    marginTop: 40,
  },
  label: {
    display: "block",
    fontSize: "12px",
    color: theme.paragraph.color,
  },
  value: {
    display: "block",
    fontWeight: 600,
    color: theme.paragraphDark.color,
  },
  infoWrapper: {
    marginTop: theme.spacing(3),
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  divider: {
    height: "4px",
    marginTop: theme.spacing(1),
    width: theme.spacing(5),
    backgroundColor: theme.palette.primary.main,
  },

  item: {
    marginBottom: theme.spacing(3),
  },
  mt: {
    marginTop: theme.spacing(6),
  },
  heading: {
    fontSize: "14px",
    color: "#666",
  },
  textSuccess: {
    color: "green",
    fontSize: "20px",
  },
  textGrey: {
    color: "orange",
    fontSize: "20px",
  },
  chipsWrapper: {
    paddingTop: theme.spacing(1),
  },
  chip: {
    marginRight: theme.spacing(1),
  },
  loaderWrapper: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  textCenter: {
    textAlign: "center !important",
  },
  btnContained: {
    backgroundColor: theme.backgroundSeagreen,
    color: "#fff",
    "&:hover": {
      backgroundColor: theme.backgroundSeagreenDark,
    },
  },
  navLink: {
    textDecoration: "none",
    color: "#000",
  },
  icon: {
    fontSize: "2.5rem",
    marginRight: theme.spacing(2),
  },
  headingWrapper: {
    display: "flex",
  },
  approveBtnWrapper: {
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: "40px",
  },
  rejectBtn: {
    background: "transparent",
  },
}));

const ShowApplication = (props) => {
  document.body.style.backgroundColor = "#F7F7F7";

  const classes = useStyles();
  const history = useHistory();
  const params = useParams();

  // const theme = useTheme();

  // const sm = useMediaQuery(theme.breakpoints.down("sm"));
  // const xs = useMediaQuery(theme.breakpoints.down("xs"));

  const { isAuthenticated, user, error } = useAuth0();
  const isAdmin = utils.isAdmin(user);

  const [hasError, setHasError] = useState(false);
  const [application, setApplication] = useState(null);
  const [shouldAccept, setShouldAccept] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [buttonTitle, setButtonTitle] = useState("");
  const [status, setStatus] = useState("");
  const [statusMessage, setStatusMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    pullEntity(params.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (status) {
      const timeout = setTimeout(() => setStatus(""), 4000);
      return () => clearTimeout(timeout);
    }
  }, [status]);

  const pullEntity = async (id) => {
    if (typeof id === "undefined") {
      setHasError(true);
      return;
    }
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}/doc-applications/${id}`)
      .then((res) => {
        if (res?.status === 200) {
          setLoading(false);
          setApplication(res.data);
          setDisabled(false);
        }
      })
      .catch((err) => {
        if (err?.response?.status === 404) {
          history.goBack();
          setStatus("error");
          setStatusMessage("No application found");
          setLoading(false);
          setDisabled(true);
        }
        if (err?.response?.status === 500) {
          setStatus("error");
          setStatusMessage("Something went wrong please try again");
          setLoading(false);
          setDisabled(true);
        }
      });
  };

  const handleOpenModal = (status) => {
    setOpen(true);

    if (status) {
      setMessage("Are you sure you want to reject this application?");
      setButtonTitle("Reject");
    } else {
      setMessage("Are you sure you want to Accept this application?");
      setButtonTitle("Accept");
    }
  };

  const handleConfirmation = () => {
    axios({
      url: `${process.env.REACT_APP_SERVER_URL}/public/accept-application`,
      method: "put",
      data: { accept: shouldAccept, id: application.id },
    })
      .then((response) => {
        if (response.status === 200) {
          setStatus("success");
          setApplication({
            ...application,
            accepted: shouldAccept,
          });
          setOpen(false);
          setStatusMessage(response.data.message);
          setIsProcessing(false);
          if (response.data.message === "application was rejected") {
            history.push("/applications");
          }
        }
      })
      .catch((err) => {
        setStatus("error");
        setOpen(false);
        setStatusMessage(err.response.data.message);
      });
  };
  const handleCloseModal = () => {
    setOpen(false);
  };

  const nextApplication = async () => {
    let id = Number(props.match.params.id) + 1;
    pullEntity(id);
    history.push(`/applications/${id}`);
  };
  const previousApplication = () => {
    let id = Number(props.match.params.id) - 1;
    if (id) {
      history.push(`/applications/${id}`);
      pullEntity(id);
    } else {
      setStatus("error");
      setStatusMessage(`No application with id ${id}`);
    }
  };

  if (isAuthenticated && props.isProcessing) {
    return <Loading />;
  } else if (isAuthenticated && props.isMember === false && !isAdmin) {
    return <Redirect to="/signup" />;
  }

  if (error) {
    return <Error code="500" />;
  }

  return (
    <Container maxWidth="lg" className={classes.root}>
      <Grid container direction="row" className={classes.gridWrapper}>
        <Grid item xs={12}>
          <div className={classes.headingWrapper}>
            <NavLink className={classes.navLink} to="/account">
              <ArrowBackIcon className={classes.icon} />
            </NavLink>
            <Typography variant="h5">
              Application details
              <div className={classes.divider} />
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12}>
          <Box component="div" className={classes.contentSection}>
            {hasError ? (
              <HttpError code="404" />
            ) : (
              <div className={classes.contentWrapper}>
                {!application && !hasError ? (
                  <div
                    className={`${classes.textCenter} ${classes.loaderWrapper}`}
                  >
                    {" "}
                    <CircularProgress size={30} />{" "}
                  </div>
                ) : null}
                {loading && (
                  <div style={{ textAlign: "center" }}>
                    <CircularProgress size={30} />
                  </div>
                )}
                {application && loading === false ? (
                  <>
                    <Grid container>
                      <Grid item xs={12} md={4}>
                        <div className={`${classes.item}`}>
                          <Typography className={classes.heading} variant="h6">
                            Application status
                          </Typography>
                          {application.accepted ? (
                            <div className={classes.textSuccess}>Approved</div>
                          ) : (
                            <div className={classes.textGrey}>Pending</div>
                          )}
                        </div>
                      </Grid>
                      <DetailPair
                        title="Email"
                        value={`${application?.email}`}
                      />
                      <DetailPair
                        title="Name"
                        value={`${application.firstName} ${application.lastName}`}
                      />
                      <DetailPair
                        title="Date of birth"
                        value={utils.getFormattedDate(
                          application.dob,
                          "MM/DD/YYYY"
                        )}
                      />

                      <DetailPair
                        title="Phone number"
                        value={application.phoneNumber}
                      />
                      <DetailPair
                        title="Medical/Graduate School"
                        value={application.medicalSchool}
                      />
                      <DetailPair title="Degree" value={application.degree} />
                      <DetailPair
                        title="Graduation Year"
                        value={application.graduationYear}
                      />
                      <DetailPair
                        title="Personal NPI Number"
                        value={application.personalNpiNumber}
                      />
                      {/* <DetailPair
                        title="License state"
                        value={application.licState.name}
                      /> */}

                      <Grid item xs={12}>
                        <div className={classes.item}>
                          <Typography className={classes.heading} variant="h6">
                            License states
                          </Typography>
                          <div className={classes.chipsWrapper}>
                            {application?.licenses?.map((state) => (
                              <Chip
                                key={state.id}
                                label={state.name}
                                className={classes.chip}
                              />
                            ))}
                          </div>
                        </div>
                      </Grid>

                      <DetailPair
                        title="License number"
                        value={application.licNumber}
                      />
                      <DetailPair
                        title="License expiry date"
                        value={utils.getFormattedDate(
                          application.licExpDate,
                          "MM/DD/YYYY"
                        )}
                      />
                      <DetailPair
                        title="License country"
                        value={application.licCountry}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <div className={classes.item}>
                        <Typography className={classes.heading} variant="h6">
                          Specialties
                        </Typography>
                        <div className={classes.chipsWrapper}>
                          {application?.specialties.map((specialty) => (
                            <Chip
                              key={specialty.id}
                              label={specialty.text}
                              className={classes.chip}
                            />
                          ))}
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} className={classes.approveBtnWrapper}>
                      <Box>
                        <IconButton
                          onClick={previousApplication}
                          title="Previous application"
                        >
                          <ArrowBackIcon className={classes.icon} />
                        </IconButton>
                        <IconButton
                          onClick={nextApplication}
                          title="Next application"
                          disabled={disabled}
                        >
                          <ArrowForwardIcon className={classes.icon} />
                        </IconButton>
                      </Box>
                      <Box>
                        <Button
                          variant="contained"
                          type="submit"
                          color="primary"
                          // disabled={props.isProcessing}
                          onClick={() => {
                            handleOpenModal(true, application.id);
                            setShouldAccept(false);
                          }}
                          className={classes.btnContained}
                          style={{ marginRight: "20px" }}
                        >
                          {"Reject"}
                        </Button>
                        {!application.accepted && (
                          <Button
                            variant="contained"
                            type="submit"
                            color="primary"
                            // disabled={props.isProcessing}
                            onClick={() => {
                              handleOpenModal(
                                application.accepted,
                                application.id
                              );
                              setShouldAccept(true);
                            }}
                            className={classes.btnContained}
                          >
                            {"Approve"}
                          </Button>
                        )}
                      </Box>
                    </Grid>
                  </>
                ) : null}
              </div>
            )}
          </Box>
        </Grid>
      </Grid>
      <Modal
        open={open}
        handleConfirmation={handleConfirmation}
        onClose={handleCloseModal}
        message={message}
        buttonTitle={buttonTitle}
        isProcessing={isProcessing}
        setIsProcessing={setIsProcessing}
      />
      {status ? <Alert status={status} message={statusMessage} /> : null}
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    isProcessing: state.auth0.isProcessing,
    isMember: state.auth0.isMember,
  };
};

export default connect(mapStateToProps)(ShowApplication);

const DetailPair = ({ title, value }) => {
  const classes = useStyles();
  return (
    <Grid item xs={12} md={4}>
      <div className={classes.item}>
        <Typography className={classes.heading} variant="h6">
          {title}
        </Typography>
        <Typography>{value}</Typography>
      </div>
    </Grid>
  );
};
