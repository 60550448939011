// import { useState } from "react";
import { Button, useMediaQuery, useTheme, makeStyles } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const useStyles = makeStyles((theme) => ({
  dialog: {
    padding: theme.spacing(2),
  },
  dialogAction: {
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },
  button: {
    padding: "5px 20px",
  },
  transparentBg: {
    backgroundColor: "transparent",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "transparent",
      boxShadow: "none",
    },
  },
}));

const Modal = (props) => {
  const classes = useStyles();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Dialog
      fullScreen={fullScreen}
      open={props.open}
      onClose={() => props.onClose()}
      aria-labelledby="dialog-title"
      className={classes.dialog}
    >
      <DialogTitle id="dialog-title">Confirmation!</DialogTitle>
      <DialogContent>
        <DialogContentText>{props.message}</DialogContentText>
      </DialogContent>
      <DialogActions className={classes.dialogAction}>
        <Button
          className={`${classes.button} ${classes.transparentBg}`}
          autoFocus
          variant="contained"
          onClick={() => props.onClose()}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={(e) => {
            props.setIsProcessing(true);
            props.handleConfirmation();
          }}
          color="primary"
          autoFocus
          className={classes.button}
          disabled={props.isProcessing}
        >
          {props.buttonTitle}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Modal;
