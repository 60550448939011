import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import GlobeImage from "../assets/images/globe.png";

import { makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  globWrapper: {
    textAlign: "center",
  },
  dialogContent: {
    padding: "8px 40px",
  },
  globeImage: {
    maxWidth: "100px",
  },
  heading: {
    fontWeight: 600,
    fontSize: "1.7rem",
    textAlign: "center",
    maxWidth: "450px",
    margin: "40px auto 0 auto",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.4rem",
    },
  },
  description: {
    color: "#888",
    textAlign: "center",
    maxWidth: "450px",
    margin: "10px auto 0 auto",
  },
  btnContained: {
    backgroundColor: theme.backgroundSeagreen,
    color: "#fff",
    "&:hover": {
      backgroundColor: theme.backgroundSeagreenDark,
    },
  },
  dialogAction: {
    padding: "20px 56px",
  },
}));

const AlertDialog = ({ handleClose, open }) => {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent className={classes.dialogContent}>
        <div className={classes.globWrapper}>
          <img src={GlobeImage} alt="" className={classes.globeImage} />
        </div>

        <Typography className={classes.heading} variant="h3">
          This feature is not available in your country yet.
        </Typography>

        <Typography className={classes.description}>
          TovaHealth Web is not available in your country yet. To see a doctor,
          please download the TovaHealth Patient mobile app in the App Store or
          Google Play.
        </Typography>
      </DialogContent>
      <DialogActions className={classes.dialogAction}>
        <Button
          fullWidth
          variant="contained"
          className={classes.btnContained}
          onClick={handleClose}
          color="primary"
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AlertDialog;
