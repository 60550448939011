import { Box } from "@material-ui/core";
import { NavLink, withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import whiteLogo from "../assets/images/tova_logo_white.png";
import greenLogo from "../assets/images/logo7_green.png";
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
  logo: {
    maxWidth: theme.spacing(16),
  },
}));

const Logo = (props) => {
  const classes = useStyles();

  const { isAuthenticated, ...rest } = props;

  if (typeof props.width !== "undefined") {
    return (
      <Box component="div" className={rest.className}>
        <NavLink to="/">
          <img
            src={
              props.type === "white" || props.type === "footer"
                ? whiteLogo
                : greenLogo
            }
            style={{ width: props.width }}
            alt="Tova"
          />
        </NavLink>
      </Box>
    );
  }

  return (
    <Box component="div" className={rest.className}>
      <NavLink to={isAuthenticated ? "/account" : "/"}>
        <img
          src={
            props.type === "white" || props.type === "footer"
              ? greenLogo
              : greenLogo
          }
          className={classes.logo}
          alt="Tova"
        />
      </NavLink>
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth0.isAuthenticated,
  };
};

export default connect(mapStateToProps)(withRouter(Logo));
